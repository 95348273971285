import React from "react";
import Button from "../components/Button.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

function BackButton(props) {
  const navigate = useNavigate();
  return (
    <div className="button-group button-group--left back-button__wrap">
      <Button
        className="back-button"
        action="tertiary"
        label=""
        onClick={(e) => {
          props.navigateUrl ? navigate(props.navigateUrl) : navigate(-1);
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="back-button__icon" />
      </Button>
    </div>
  );
}

export default BackButton;
