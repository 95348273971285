import axios from "../_helpers/axiosinstance";

const getFiles = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_external_services}/nhd/api/external/v3/listFiles`,
    {
      requiresIdentityId: true,
    }
  );

  return response;
};

const getExport = async (v3Data) => {
  const response =  await axios.post(
      `${process.env.REACT_APP_nhd_external_services}/nhd/api/external/v3/downloadExport`,
      {
          date: v3Data.date,
          fileName: v3Data.fileName,
          s3Path: v3Data.s3Path,
      },
      {
        requiresIdentityId: true,
        responseType: "blob"
      }
  );

  return response;
}

const requestExport = async() => {
    return await axios.get(`${process.env.REACT_APP_nhd_external_services}/nhd/api/external/v3/request-export`,
        {
            requiresIdentityId: true
        });
}

const V3Service = {
  getFiles,
  getExport,
  requestExport
};

export default V3Service;
