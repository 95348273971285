import React from "react";
import Button from "../Button";
import "./FullExportModal.css";

const FullExportModal = (props) => {
  return (
    <div
      className="content-area"
      id="content-area-container"
      style={{
        width: "50%",
        background: "white",
        boxShadow: "rgb(136, 136, 136) 0px 0px 8px 3px",
      }}
    >
      <div id="content-area">
        <div className="content_layout_fe">
          <header className="header_fe">Full Export Warning</header>

          <div>
            <p className="content_fe">
              By clicking "Accept," you agree not to share the extracted data with unauthorised parties.
              For audit purposes, a record of the individual and the date on which the data was exported will be
              maintained.
            </p>

            <div className="button-group">
              <Button action="primary" label="Accept" onClick={props.accept}/>
              <Button action="secondary" label="Reject" onClick={props.reject}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullExportModal;
