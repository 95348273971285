import React from "react";
import "./BouncingDotsLoader.css";

const BouncingDotsLoader = (props) => {
  return (
    <div className="bouncing-loader">
      <div style={props.style}></div>
      <div style={props.style}></div>
      <div style={props.style}></div>
    </div>
  );
};

export default BouncingDotsLoader;
