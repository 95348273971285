import React from 'react';

function Header(props) {
  return (
    <div className="header">
      <div className="header__text">
        <h1 className="header__title">{props.title}</h1>
        <p className="header__sub-title">The National Hazard Directory</p>
      </div>
      <svg id="b385ddaa-43cc-4ab1-a082-b090f137efcc" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 1813.74 680.71" className="header__logo network-rail-logo" alt="NetworkRail">
        <g id="a22e6eca-9a2a-4841-af2a-c2e7d449aa16" data-name="b62f62f6-6b6b-4cc2-a4f2-ff3606ca40e0">
          <path className="network-rail-logo__path" d="M172.68,142l27-131.42c2-9.59,2-9.59,11.94-9.59h38.91c1.37,5.93-1.05,11.06-2.13,16.13-11.19,52.42-22.67,104.79-34.07,157.16-2.19,10-4.56,20-6.61,30.09-.57,2.77-1.73,3.88-4.52,3.87-18.93-.06-37.85-.15-56.77.06-4.32,0-4.47-3.15-5.21-5.56-4.85-15.81-9.47-31.69-14.14-47.55q-12.33-41.82-24.66-83.68C102,70,101.53,68.21,99.8,67q-9.69,46.62-19.35,93-4.59,22.2-8.91,44.47c-.55,2.85-1.84,3.72-4.59,3.76-14.32.18-28.65-.62-43,.42a2.22,2.22,0,0,1-2.56-1.83,2.45,2.45,0,0,1,0-.81,51.72,51.72,0,0,1,.93-6.77q18.6-84.36,37.24-168.73c1.79-8.08,4-16.1,5.09-24.27C65.33,1.46,67.5.93,71.5,1c17.78.16,35.55.21,53.33,0,3.89-.06,5.72,1.21,6.71,4.79,2.21,8,4.66,15.86,7,23.8q15.84,54.6,31.5,109.32C170.4,140.29,170.81,141.61,172.68,142Z"/>
          <path className="network-rail-logo__path" d="M1419.8,114c6.77,2.3,10.71,7.34,13.76,13.39,4.42,8.77,6.21,18.32,8.53,27.73,4.28,17.37,8.77,34.69,13.25,52.35-6.63,1.66-12.88,1.4-19.1,1.32-9.16-.11-18.32-.38-27.48-.57-5.42-.12-5.57-.08-6.81-6-3.37-16.2-6.59-32.42-9.87-48.64a5,5,0,0,0-.11-.85c-4.35-18.17-8.73-25.88-30.83-26-13-.08-13-.2-15.55,12.54q-6.3,31-12.66,62c-1.38,6.71-2.83,7.91-9.72,7.46a260.56,260.56,0,0,0-36,.06,38.55,38.55,0,0,1-4.3,0c-3.09-.11-4.47-.77-3.58-4.69,4.56-20,8.65-40.15,13-60.22l24-111.25c1.87-8.65,3.61-17.32,5.52-26,1.34-6.09,1.66-6.4,8-6.42,20.07-.06,40.16-.56,60.21.16,15.66.57,31.5.23,46.84,4.55,8.07,2.28,15.69,5.46,22,11,13,11.47,16.6,26.67,14.62,42.85-3.64,29.72-20.6,46.94-50.23,52.82A4.9,4.9,0,0,0,1419.8,114Zm-62.25-27.16c14,.77,27.57.68,41.09-1.88a35.74,35.74,0,0,0,12.69-4.82c8-5,11.71-15,9.51-24.79-1.79-8-8.43-13.12-18.1-14.52-6.55-1-13.08-.31-19.61-.2-4.27.08-8.43-1.07-12.79-.36-3,.49-4.49,1.49-5,4.45-1.33,7-3.13,13.9-4.23,20.92s-4.19,13.5-3.56,21.2Z"/>
          <path className="network-rail-logo__path" d="M667.43,105.15q-10.38,21.48-20.74,43c-8.9,18.53-17.86,37-26.6,55.65-1.66,3.52-3.52,5.21-7.72,5.14-16-.28-32.11-.08-48.17-.13-6.13,0-6.22-.21-6.73-6.08C555,174,552.59,145.19,550,116.44c-1.3-14.51-2.94-29-4.38-43.48-.26-2.57-.23-5.16-.45-7.72s.89-3.57,3.44-3.53c6,.09,12,0,18.07,0h28.64c1.78,34.77.48,68.81,1.46,103.16,3-2.3,3.7-5.5,5-8.25q20-43.14,39.72-86.37c.72-1.56,1.46-3.11,2.16-4.67,1.11-2.5,2.51-4,5.75-4q26.66.29,53.32,0c3.5,0,4.41,1.27,4.38,4.5-.1,11.17,0,22.34,0,33.51,0,16.58,0,33.17.19,49.75,0,4.8.6,9.61,1,16.29,1.71-3.32,2.7-5,3.51-6.84q20.71-46.08,41.37-92.19c1.06-2.37,1.56-5,5-4.95h49c-6.2,11.86-11.86,22.7-17.63,33.5q-27.9,52.65-55.84,105.26c-5.41,10.18-2.64,8.28-14.29,8.37-12.61.11-25.23,0-37.84,0-2.29,0-4.57-.15-6.86-.25-3-.14-4.26-1.38-4.41-4.77-.83-19.16-1-38.34-1-57.51-.05-13.74,0-27.48,0-41.21Z"/>
          <path className="network-rail-logo__path" d="M1254.87,206.83c-1.45,2-2.62,2.07-3.74,2-15.74-1.13-31.5-.56-47.24-.48-4.33,0-5.82-1.63-7.29-5.33-7.58-19.09-15.48-38.05-23.3-57-1.18-2.85-2.55-5.62-4.36-9.59-5.06,23-10,44.65-14.44,66.36-.88,4.29-2.21,5.68-6.58,5.6-14.32-.27-28.66-.1-43-.09a4.07,4.07,0,0,1-3.47-1.3c5.84-26.41,11.77-53.08,17.63-79.76q12.66-57.66,25.22-115.34C1146.79.37,1146.62.34,1158.21.35c11.18,0,22.36-.06,33.54,0,6.49,0,6.83.38,5.46,6.93-2.81,13.39-5.75,26.76-8.72,40.12-4.69,21.12-9.37,42.23-14.26,63.3-.5,2.15-1.27,4.1,1,5.15s3.45-1.37,4.51-2.67q19.08-23.57,37.93-47.32c1.61-2,2.85-4.14,6-4.12,18,.08,35.94,0,54.68,0-4.89,7.8-11,13.52-16.27,19.8-12.93,15.31-26.21,30.32-39.36,45.45-4.6,5.3-4.6,5.29-1.5,11.59Z"/>
          <path className="network-rail-logo__path" d="M875.15,211.94c-13.93-.38-26.14-1.25-37.84-5.68-23.38-8.88-36.11-25.82-39.4-50.44a88.78,88.78,0,0,1,4.6-42.12c9-25.16,27.14-41.39,52.1-49.61a115.23,115.23,0,0,1,65.09-2.47c26.43,6.62,49.12,28.11,48.83,62.21-.11,13-1.71,25.64-6.48,37.73-10.87,27.58-32.48,41.66-60.42,47.84C892.33,211.46,882.86,211.15,875.15,211.94Zm40.06-85.1c0-3.72.37-7.48-.07-11.14-1.39-11.5-9.34-19.78-20.21-21.25-9.85-1.34-18.94.76-26.34,7.52-16.4,15-21.92,33.64-16.83,55.14,2.09,8.88,7.9,15.5,16.88,17.79,13.48,3.43,25.44.14,34.78-10.61S915.33,140.54,915.21,126.84Z"/>
          <path className="network-rail-logo__path" d="M1505.82,102.6c.71-5.76,1.42-11.64,2.19-17.5.19-1.41.69-2.78.86-4.19,2.28-18.09,0-14.16,16.66-18.36,19.84-5,40.2-6.25,60.45-2.33,18,3.49,33,11.73,39.6,30.22,3.84,10.78,3,22,1.17,33.08-3,17.74-8,35-11.72,52.61-1.88,8.94-3.77,17.87-4.82,27-.64,5.55-.75,5.66-6.31,5.7q-15.06.15-30.11,0c-6.44-.07-6.34-.22-5.85-7.13.26-3.59,2.3-7.07.7-11.24-5.16,4.76-9.45,10.2-15.62,13.46-17.92,9.46-36.63,10.36-55.7,4.79-16.56-4.84-24.55-17-26-33.6-2.19-25.2,10.73-41.87,33.38-51.24,20.51-8.48,42.15-9.87,64.08-7.9,1.13.1,2.25.4,3.39.51,7.42.67,8.56-.53,7.57-7.78-.57-4.24-2.88-7.38-6.11-9.94a29.37,29.37,0,0,0-17.5-6.17c-15.4-.66-30.18,1.88-44.19,8.51C1510.21,101.87,1508.6,103.35,1505.82,102.6Zm55.35,40.05a69.35,69.35,0,0,0-25.65,3.46,27.3,27.3,0,0,0-12.21,7.69c-6.75,7.76-4.72,17.55,4.58,22.07a19.87,19.87,0,0,0,10.8,2.2c15.22-1.58,26.45-9,32.29-23.29,5.48-13.44,4.83-11.93-7.53-12.13C1562.59,142.63,1561.73,142.65,1561.17,142.65Z"/>
          <path className="network-rail-logo__path" d="M291.92,149.69c-.7,10.91,3.38,18.43,12.32,23.15A38,38,0,0,0,319,177.08a113.07,113.07,0,0,0,56-8.84c2.51,2.42.38,4.45,0,6.49-1.3,7.87-2.61,15.75-4.11,23.59-.52,2.67-.76,5.36-4.51,6.39-23.39,6.39-47,10.09-71,4.67-28.64-6.47-45.88-24.48-51-50.45-4.16-21,.58-40.93,11.19-59.27,15.65-27.07,40-39.73,70.71-41.33,11.52-.6,23,.39,33.85,4.2,23.55,8.24,36,24.7,38.6,51.44,1,10-.27,20-2.07,29.87-1,5.69-1.26,5.81-7.27,5.82H300.8Zm2.79-30.1c2,2.17,3.79,1.68,5.45,1.68h50.67c2.12,0,4.72.8,5.25-2.71,1.74-11.43-7.35-24.22-18.83-25.76a39.7,39.7,0,0,0-16.16.92C307.57,97.56,299.33,106.62,294.71,119.59Z"/>
          <path className="network-rail-logo__path" d="M506.86,175.3c1.25,2.82.3,5.63-.13,8.35-.79,5.07-2,10.07-2.79,15.14-1.69,10.72-1.6,11.06-12.31,12C476.57,212,461.5,213.51,446.7,208c-21.08-7.85-31.56-25.68-28-48,3.28-20.57,8.75-40.68,12.7-61.73-6.54-1.68-13.4.15-20.54-1.19,1.89-11,3.68-21.59,5.57-32.19.4-2.28,1.92-3.27,4.32-3.21,4.58.1,9.17.07,13.76,0,5.87-.08,5.88-.11,7.19-6q3.57-15.87,7.08-31.74c.44-2,1-3.86,1.48-5.8a2.83,2.83,0,0,1,2.94-2.5h48.13c-3.45,15.59-6.71,30.28-10.17,45.91h40.17c-.62,9.69-2.67,18.25-4.28,26.84-1.72,9.19-1.88,9.16-11,9.16s-18.35.24-27.52-.09c-4.19-.15-6.26,1.2-7.12,5.25-3.82,18.13-7.91,36.21-11.6,54.38-2,10,2.21,16.68,11.93,19.68,5.55,1.71,11.05,2.31,16.86.55C501.19,176.58,503.69,174.72,506.86,175.3Z"/>
          <path className="network-rail-logo__path" d="M1718.45,208.23c2.28-10.89,4.34-20.85,6.46-30.8q12.6-59.37,25.25-118.77,5.52-25.92,11.21-51.8C1762.8.4,1763,.34,1769,.31q19.77-.09,39.55,0c5.57,0,5.93.66,4.57,6.87-4.3,19.78-8.69,39.53-12.92,59.32q-12.24,57.3-24.36,114.59c-1.6,7.53-3.43,15-4.78,22.58-.62,3.46-2,4.72-5.57,4.67C1750.33,208.15,1735.14,208.23,1718.45,208.23Z"/>
          <path className="network-rail-logo__path" d="M1100.33,106.81c-6.4-3.62-12.47-5.72-19.24-5.84-17.85-.31-29.68,7.21-37.06,23.65-4.61,10.29-5.86,21.43-8.39,32.24q-5.43,23.32-10.16,46.84c-.79,3.94-2.32,5.24-6.18,5.19-13.19-.16-26.39,0-39.58-.1-6.15,0-6.6-.74-5.39-6.38q12.06-56.06,24.08-112.11c1.72-8.09,3-16.27,4.28-24.45.4-2.63.94-4.17,4-4.15,14.3.1,28.61,0,43.74,0-1.61,8.78-3.12,17-4.62,25.2l1,.44c2-2.87,4-5.79,6.16-8.6,13.14-17.43,32.28-24.17,53.15-18.77,5.79,1.5,6.14,2,4.65,7.61C1107.43,80.51,1104,93.36,1100.33,106.81Z"/>
          <path className="network-rail-logo__path" d="M1718.63,62.26c-4.78,22.31-9.51,44.26-14.2,66.22q-7.38,34.7-14.73,69.4c-.18.84-.17,1.72-.35,2.55-1.61,7.81-1.64,7.4-9.65,7.89-12,.75-24,.28-36,.5h-7.18c.33-8.64,2.63-16.28,4.24-24,8.19-39,16.67-78,25-117,1.27-5.92,1.21-6,7-6,14-.06,28.07,0,42.11,0A33.82,33.82,0,0,1,1718.63,62.26Z"/>
          <path className="network-rail-logo__path" d="M1672.33,37.9c2.58-12.2,4.9-23.54,7.39-34.84.63-2.89,3.17-2.8,5.52-2.79h18.88c7.44,0,14.88-.12,22.31,0,4.84.06,5.34.43,4.51,4.69-1.91,9.77-4.16,19.47-6.28,29.2-.44,2-.94,3.78-3.68,3.77C1705,37.86,1689.08,37.9,1672.33,37.9Z"/>
          <polygon className="network-rail-logo__path" points="-0.02 240.47 1764.44 240.34 1670.25 680.72 1426.76 615.83 1759.95 242.31 1759.25 242.31 1292.1 581.64 976.75 496.52 1752.93 242.31 1748 242.31 786.97 446.3 -0.02 240.47"/>
        </g>
      </svg>
    </div>
  )
};

export default Header;