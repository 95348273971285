import React, {Fragment, useContext, useEffect, useState} from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {useLocation, useNavigate} from "react-router-dom";
import {ThemeContext} from "../../contexts/ThemeContextProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../components/Button";
import {faArrowRight, faFolderOpen, faUsersCog} from "@fortawesome/free-solid-svg-icons";
import {faFileImport} from "@fortawesome/free-solid-svg-icons/faFileImport";
import {faUnlockKeyhole} from "@fortawesome/free-solid-svg-icons/faUnlockKeyhole";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons/faUserPlus";

export default function UserManagementDash(props) {
    const { user } = useAuthenticator((context) => [context.user]);
    const context = useContext(ThemeContext);

    let navigate = useNavigate();
    const { state } = useLocation();

    return (
        <div className="page" id="dataManDashPage">
            <Header title="User Management" />
            <BackButton
                navigateUrl={state?.previousPage ? state.previousPage : "/admindash"}
            />
            <div>
                <div className="admin-dash--card_wrap">
                    <div className="card admin-dash--card">
                        <FontAwesomeIcon icon={faUsersCog} className="card__icon" />
                        <h3 className="card__title">User Management</h3>
                        <p className="card__description">
                            Manage NHD Users.
                        </p>
                        <Button
                            action="round"
                            label="Hazard Code"
                            onClick={() => navigate("/usermanagement")}
                        >
                            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
                        </Button>
                    </div>
                    <div className="card admin-dash--card">
                        <FontAwesomeIcon icon={faUnlockKeyhole} className="card__icon" />
                        <h3 className="card__title">User Permission Requests</h3>
                        <p className="card__description">
                            Manage User Permission Requests.
                        </p>
                        <Button
                            action="round"
                            label="User Permission Request Management"
                            onClick={() => navigate("/userpermission-management")}
                        >
                            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
                        </Button>
                    </div>
                    {user.signInUserSession.accessToken.payload["cognito:groups"].includes("ontrac-admin-group") &&
                        <div className="card admin-dash--card">
                            <FontAwesomeIcon icon={faUserPlus} className="card__icon"/>
                            <h3 className="card__title">Onboarding requests</h3>
                            <p className="card__description">
                                User Onboarding requests.
                            </p>
                            <Button
                                action="round"
                                label="User Onboarding"
                                onClick={() => navigate("/onboarding")}
                            >
                                <FontAwesomeIcon icon={faArrowRight} className="button__icon"/>
                            </Button>
                        </div>
                    }
                </div>
            </div>


        </div>
    );

}