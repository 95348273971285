import React, {useEffect, useState} from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import StandardReportsForm from "../components/StandardReportsForm.js";
import StandardReportsService from "../services/standardreports.sevice.js";
import ReactTable from "../components/ReactTable/ReactTable.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faExclamationTriangle,} from "@fortawesome/free-solid-svg-icons";
import {exportCsv} from "../_helpers/makeCsv.js";
import generatePrintFriendlyTable from "../_helpers/generatePrintFriendlyTable.js";
import {toast} from "react-toastify";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus";

function AppendixAPage(props) {
  let reportTitle = "Appendix A - ELRS";
  let reportInfo = "Filter the ELR list by route.";
  let fields = ["route"];

  const [values, setValues] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [customSearchItems, setCustomSearchItems] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
      StandardReportsService.appendixAELRs(0, 10, {all: true})
          .then((response) => {
              setCustomSearchItems(response.data);
          })
          .catch((error) => {
              setCustomSearchItems({
                  status: {
                      loading: false,
                      error: true,
                  },
              });
          });
  }, [setCustomSearchItems]);

  const handlePageSize = (val) => {
    setPage(0);
    setPageSize(val);
  };

  function handleSubmit(pageVal, pageSizeVal) {
    setCustomSearchItems({
      status: {
        loading: true,
        error: false,
      },
    });
    let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
    let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
    let all = values?.route === "";
    StandardReportsService.appendixAELRs(pageNumber, pageSizeNumber, all === false ? values : {all: true})
        .then((response) => {
          setCustomSearchItems(response.data);
        })
        .catch((error) => {
          setCustomSearchItems({
            status: {
              loading: false,
              error: true,
            },
          });
        });
  }

  const handleReset = (initialVals) => {
      setValues(initialVals);
      StandardReportsService.appendixAELRs(0, 10, {all: true})
          .then((response) => {
              setCustomSearchItems(response.data);
          })
          .catch((error) => {
              setCustomSearchItems({
                  status: {
                      loading: false,
                      error: true,
                  },
              });
          });
  }

  const handleExport = () => {
      let all = values?.route === "";
    StandardReportsService.appendixAELRsExport(page, pageSize, all === false ? values : {all: true})
      .then((response) => {
          toast.success(`Your Export has been sent and you will receive an email shortly with the attached report.`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
      })
      .catch((error) => {});
  };

  return (
    <div className="page" id="appendixAPage">
      <Header title="Appendix A - ELRs" />
      <BackButton
        setPage={props.setPage}
        target={["standardReportsPage", "backward"]}
      />

      <StandardReportsForm
        data={props}
        reportTitle={reportTitle}
        reportInfo={reportInfo}
        fields={fields}
        handleSubmit={() => handleSubmit(0, undefined)}
        handleReset={() => handleReset()}
        values={values}
        setValues={setValues}
      />
      <div>
        {customSearchItems?.status?.loading === true && (
          <div className="loadingCircle">
            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
          </div>
        )}
        {customSearchItems?.status?.error === true && (
          <div className="loadingCircle">
            Error fetching data
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
        )}
        {customSearchItems?.results && customSearchItems?.results.length > 0 && (
          <ReactTable
            hideColumns={["id"]}
            tableData={customSearchItems.results}
            currentPage={customSearchItems?.pageNumber}
            totalPages={customSearchItems?.totalPages}
            pageSize={customSearchItems?.pageSize}
            totalElements={customSearchItems?.totalElements}
            setPage={(val) => {
              setPage(val);
              handleSubmit(val, undefined);
            }}
            setPageSize={(val) => {
              handlePageSize(val);
              handleSubmit(0, val);
            }}
            exportCsv={{ name: "appendixA", action: handleExport }}
          ></ReactTable>
        )}
          {customSearchItems?.results && customSearchItems?.results.length === 0 && (
              <div className="loadingCircle">
                  No Results Based On Your Search Criteria
                  <span style={{marginLeft: "10px"}}> <FontAwesomeIcon icon={faMagnifyingGlassMinus}/> </span>
              </div>
          )}
      </div>
    </div>
  );
}

export default AppendixAPage;
