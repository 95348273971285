import React, {useState} from "react";
import Header from "../../components/Header.js";
import Button from "../../components/Button.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faClipboardList, faDownload,
  faFileExport,
  faFolderOpen,
  faTrashCan,
  faUsersCog
} from "@fortawesome/free-solid-svg-icons";
import {UserService as userService} from "../../services/user.service";
import {useNavigate} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {toast} from "react-toastify";
import {faDatabase} from "@fortawesome/free-solid-svg-icons/faDatabase";
import Modal from "react-modal";
import TermnsAndConditions from "../../components/TermnsAndConditions/TermnsAndConditions";
import FullExportModal from "../../components/FullExportModal/FullExportModal";

function AdminDashPage(props) {
  const [message, setMessage] = useState("");
  const { user } = useAuthenticator((context) => [context.user]);
  const [modalIsOpen, setIsOpen] = useState(false);

  let navigate = useNavigate();

  function handleFullExport() {
    setIsOpen(true);
  }

  function acceptFullExport() {
    setIsOpen(false);
    downloadFullExport();
  }

  function rejectFullExport() {
    setIsOpen(false);
    //new API Reject
  }

  const downloadFullExport = () => {
    userService
        .downloadFullExport()
        .then((response) => {
          toast.success(`Request for Full Extract. Please wait for Email.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((error) => {
          toast.error(`Request for Full Extract Failed`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
  };

  return (
    <div className="page" id="adminDashPage">
      <Header title="NHD Administration" />
      <div>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={rejectFullExport}
            shouldCloseOnOverlayClick={false}
            className="ModalFE"
            overlayClassName="Overlay"
            contentLabel="Example Modal"
        >
          <FullExportModal reject={rejectFullExport} accept={acceptFullExport}/>
        </Modal>
      </div>
      <div>
        <div className="admin-dash--card_wrap">
          <div className="card admin-dash--card">
            <FontAwesomeIcon icon={faUsersCog} className="card__icon" />
            <h3 className="card__title">User Management</h3>
            <p className="card__description">
              Manage NHD Users.
            </p>
            <Button
                action="round"
                label="User Management"
                onClick={() => navigate("/usermanagementDash")}
            >
              <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
            </Button>
          </div>
            {user.signInUserSession.accessToken.payload["cognito:groups"].includes("ontrac-admin-group") &&
                <div className="card admin-dash--card">
                    <FontAwesomeIcon icon={faUsersCog} className="card__icon" />
                    <h3 className="card__title">Organisations Management</h3>
                    <p className="card__description">
                        Search current organisations, add new organisations, edit organisations and assocaited licenses.
                    </p>
                    <Button
                        action="round"
                        label="User Management"
                        onClick={() => navigate("/orgmanagement")}
                        >
                        <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
                    </Button>
                </div>
            }
          <div className="card admin-dash--card">
            <FontAwesomeIcon icon={faFolderOpen} className="card__icon" />
            <h3 className="card__title">Data Management</h3>
            <p className="card__description">
              Manage NHD System Data.
            </p>
            <Button
                action="round"
                label="Data Management"
                onClick={() => navigate("/dataManagementDash")}
            >
              <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
            </Button>
          </div>
          <div className="card admin-dash--card">
            <FontAwesomeIcon icon={faClipboardList} className="card__icon" />
            <h3 className="card__title">Admin Reports</h3>
            <p className="card__description">
              Run Admin Reports.
            </p>
            <Button
                action="round"
                label="Admin Reports"
                onClick={() => navigate("/adminreports")}
            >
              <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
            </Button>
          </div>
          <div className="card admin-dash--card">
            <FontAwesomeIcon icon={faTrashCan} className="card__icon"/>
            <h3 className="card__title">Inactive Hazards</h3>
            <p className="card__description">
              Inactive or Deleted Hazards.
            </p>
            <Button
                action="round"
                label="Inactive Hazards"
                onClick={() => navigate("/inactiveHazards")}
            >
              <FontAwesomeIcon icon={faArrowRight} className="button__icon"/>
            </Button>
          </div>
          <div className="card admin-dash--card">
            <FontAwesomeIcon icon={faDatabase} className="card__icon"/>
            <h3 className="card__title">Hazards waiting Review/Rejection</h3>
            <p className="card__description">
              Hazards waiting for review or rejected.
            </p>
            <Button
                action="round"
                label="Hazards waiting Review/Rejection"
                onClick={() => navigate("/hazardsReviewRejection")}
            >
              <FontAwesomeIcon icon={faArrowRight} className="button__icon"/>
            </Button>
          </div>
          <div className="card admin-dash--card">
            <FontAwesomeIcon icon={faDownload} className="card__icon"/>
            <h3 className="card__title">Download Full Data Export</h3>
            <p className="card__description">
              Download Full Data Export.
            </p>
            <Button
                action="round"
                label="Download Full Data Export"
                onClick={handleFullExport}
            >
              <FontAwesomeIcon icon={faArrowRight} className="button__icon"/>
            </Button>
          </div>
          {user.signInUserSession.accessToken.payload["cognito:groups"].includes("ontrac-admin-group") &&
              (<div className="card admin-dash--card">
                <FontAwesomeIcon icon={faFileExport} className="card__icon"/>
                <h3 className="card__title">V3 Export</h3>
                <p className="card__description">
                  Request and Download V3 Export.
                </p>
                <Button
                    action="round"
                    label="V3 Export"
                    onClick={() => navigate("/v3Export")}
                >
                  <FontAwesomeIcon icon={faArrowRight} className="button__icon"/>
                </Button>
              </div>)}
        </div>
      </div>


    </div>
  );
}

export default AdminDashPage;
