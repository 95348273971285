import React, {useEffect, useState} from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import Upload from "../../components/Upload.js";
import Button from "../../components/Button.js";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faFile, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import BulkUploadService from "../../services/bulk-upload.service.js";
import {toast} from "react-toastify";
import downloadFile from "../../_helpers/downloadFile";

function ImportBoundaryDataPage(props) {

  const [loading, setLoading] = useState(false);
  const [boundaryFile, setBoundaryFile] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    return () => {
      removeBoudaryFileUrl();
    };
  }, []);

  const removeBoudaryFileUrl = () => {
    boundaryFile.forEach((file) => {
      URL.revokeObjectURL(file.preview);
    });
  };

  const removeBoundaryFile = (e, fileName) => {
    e && e.stopPropagation();

    let arr = [];
    boundaryFile.forEach((file) => {
      if (file.name === fileName) {
        URL.revokeObjectURL(file.preview);
      } else arr.push(file);
    });

    setBoundaryFile(arr);
  };

  const boundaryTemplate = () => {
    BulkUploadService.boundaryTemplate()
      .then((response) => {
          const url = response.data.s3url;
          const link = document.createElement("a");
          const filename = response.data.filename;
          link.href = url;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      })
      .catch((error) => console.log(error));
  };
  const uploadBoundaryData = event => {
    if (event.detail === 1) {
      let file = boundaryFile[0];
      let params = {};
      params.file = file;
      BulkUploadService.uploadBoundaryData(params)
          .then((response) => {
            console.log(response);
            toast.success(`The system is uploading your file for processing. Please check your email shortly for more information.`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            removeBoundaryFile(undefined, file.name);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  };

  return (
    <div className="page" id="boundaryDataPage">
      <Header title="Boundary Data Page" />
      <BackButton
        setPage={props.setPage}
        target={["adminDashPage", "backward"]}
      />

      <h3>ELR Boundary Update</h3>
        <p style={{fontWeight: "bold"}}>By using this service you will update the Subzone Boundary, which may have an effect on the
            ELR Boundary as the system will calculate the lowest and maximum values for that ELR.</p>
      <div className="bulk-upload">
        <Button
          action="secondary"
          label="Download Boundary Template for Updating Route/ELR/MDUs"
          onClick={boundaryTemplate}
        >
          <FontAwesomeIcon icon={faDownload} className="button__icon" />
        </Button>
        {boundaryFile.length > 0 ? (
          <Button
            action="primary"
            label="Confirm and upload  Boundary Data"
            onClick={uploadBoundaryData}
          ></Button>
        ) : (
          <Upload
            name="uploadBoundary"
            label="Bulk Upload Boundary Data"
            setFiles={setBoundaryFile}
            files={boundaryFile}
            disabled={false}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        )}

        {boundaryFile.length > 0 && (
          <>
            <div className="create-form__section">
              <h4>Previews</h4>
              <div className="gallery">
                {boundaryFile?.map((file) => {
                  if (file.type.includes("image")) {
                    return (
                      <div className="image__wrap">
                        <Button
                          action="tertiary"
                          onClick={(e) => removeBoundaryFile(e, file.name)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                        <div>{`${file.name} - ${file.size / 1000}KB`}</div>
                        <img src={file.preview} alt="" className="image" />
                      </div>
                    );
                  } else {
                    return (
                      <div className="image__wrap">
                        <Button
                          action="tertiary"
                          onClick={(e) => removeBoundaryFile(e, file.name)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                        <div
                          style={{ maxWidth: "100%", wordBreak: "break-word" }}
                        >{`${file.name} - ${file.size}KB`}</div>
                        <FontAwesomeIcon icon={faFile} />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ImportBoundaryDataPage;
