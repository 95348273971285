import React from "react";

function Button(props) {
  let classProps = ["button"];
  if (props.action) {
    classProps.push("button--" + props.action);
  }
  if (props.fullwidth) {
    classProps.push("button--fullwidth");
  }
  if (props.disabled) {
    classProps.push("button--disabled");
  }
  if (props.className) {
    classProps.push(props.className);
  }

  let buttonProps = {};
  if (props.onClick) {
    buttonProps.onClick = props.onClick;
  }
  if (props.disabled) {
    buttonProps.disabled = props.disabled;
  }
  if (props.name) {
    buttonProps.name = props.name;
  }
  if (props.type) {
    buttonProps.type = props.type;
  }
  if (props.value) {
    buttonProps.value = props.value;
  }

  return (
    <button className={classProps.join(" ")} {...buttonProps}>
      <span className="button__text">{props.label}</span> {props.children}
    </button>
  );
}

export default Button;
