import axios from "../_helpers/axiosinstance";

const accessPoints = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/access-points`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      zonecode: params?.route.zonecode || undefined,
      elr: params?.elr?.elr || "",
      accessType: params?.accessType.value || "Any",
      startMileage: params?.startMileage || undefined,
      endMileage: params?.endMileage || undefined,
    }
  );

  return response;
};

const accessPointsExport = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/access-points/export`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      zonecode: params?.route.zonecode || undefined,
      elr: params?.elr?.elr || "",
      accessType: params?.accessType.value || "Any",
      startMileage: params?.startMileage || undefined,
      endMileage: params?.endMileage || undefined,
    },
      {
          requiresIdentityId: true,
      }
  );

  return response;
};

const appendixAELRs = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/elrs/index`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      all: params.all || false,
      zonecode: params.route?.zonecode || "",
    }
  );

  return response;
};

const appendixAELRsExport = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/elrs/index/export`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      all: params.all || false,
      zonecode: params.route?.zonecode || "",
    },
      {
          requiresIdentityId: true,
      }
  );

  return response;
};

const siteWork = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/hazards/site/work`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      zonecode: params.elr?.zonecode || "",
      elr: params?.elr?.elr || "",
      startMileage: params?.startMileage || undefined,
      endMileage: params?.endMileage || undefined,
    }
  );

  return response;
};

const siteWorkExport = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/hazards/site/work/export`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      zonecode: params.elr?.zonecode || "",
      elr: params?.elr?.elr || "",
      startMileage: params?.startMileage || undefined,
      endMileage: params?.endMileage || undefined,
    },
      {
          requiresIdentityId: true,
      }
  );

  return response;
};

const extractElr = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/hazards/extract/elr`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      elr: params.elr?.elr || "",
      zonecode: params.elr?.zonecode || "",
    }
  );

  return response;
};

const extractElrExport = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/hazards/extract/elr/export`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      elr: params?.elr?.elr || "",
      zonecode: params.elr?.zonecode || "",
    },
      {
          requiresIdentityId: true,
      }
  );

  return response;
};

const hazardsRed = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/hazards/red`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      zonecode: params.route?.zonecode || "",
      subzone: params.mdu?.subzonecode || "",
    }
  );

  return response;
};

const hazardsRedExport = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/hazards/red/export`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      zonecode: params.mdu?.zonecode || "",
      subzone: params.mdu?.subzonecode || "",
    },
      {
          requiresIdentityId: true,
      }
  );

  return response;
};

const searchMDU = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/subzones`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      subzone: params.mdu?.subzonecode || "",
    }
  );

  return response;
};

const searchMDUExport = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_standard_reports_ingress}/nhd/api/standard-reports/subzones/export`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      subzone: params.mdu?.subzonecode || "",
    },
      {
          requiresIdentityId: true,
      }
  );

  return response;
};

const StandardReportsService = {
  accessPoints,
  accessPointsExport,
  appendixAELRs,
  appendixAELRsExport,
  siteWork,
  siteWorkExport,
  extractElr,
  extractElrExport,
  hazardsRed,
  hazardsRedExport,
  searchMDU,
  searchMDUExport,
};

export default StandardReportsService;
