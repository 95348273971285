import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState,} from "react";
import Select, {createFilter} from "react-select";
import {ThemeContext} from "../../contexts/ThemeContextProvider";
import MenuList from "./MenuList";
import "./ReactSelect.css";
import PropTypes from "prop-types";

const ReactSelect = forwardRef((props, ref) => {
  const [error, setError] = useState();
  const [errorMsg, setErrorMsg] = useState();

  const context = useContext(ThemeContext);
  useImperativeHandle(ref, () => ({
    validateField() {
      return validate();
    },
  }));

  let classProps = ["select__wrap"];
  if (props.disabled) {
    classProps.push("select__wrap--disabled");
  }
  if (props.className) {
    classProps.push(props.className);
  }
  if (error) {
    classProps.push("select__wrap--error");
  }

  useEffect(() => {
    setError();
    setErrorMsg();

    if (props.fieldsErrors?.[props.name]) {
      if (props.fieldsErrors?.[props.name] === "required") {
        setError(true);
        setErrorMsg("This field is required");
        return false;
      } else {
        setError(true);
        setErrorMsg("Invalid value");
        return false;
      }
    } else {
      setError(false);
      setErrorMsg();
    }
  }, [props.fieldsErrors?.[props.name]]);

  function validate() {
    if ((!props.value || props.value === "" || props.value.length === 0) && props.required) {
      setError(true);
      setErrorMsg("This field is required");
      return false;
    } else if (props.fieldsErrors?.[props.name]) {
      if (props.fieldsErrors?.[props.name] === "required") {
        setError(true);
        setErrorMsg("This field is required");
        return false;
      } else {
        setError(true);
        setErrorMsg("Invalid value");
        return false;
      }
    } else {
      setError(false);
      setErrorMsg();
      return true;
    }
  }

  const getAllOptions = () => {
    return {
      label: "Select all",
      value: "*"
    }
  }

  const getLabel = (ele) => {
    let label = "";
    props.valueLabel?.forEach((element, index) => {
      label += ele[element];
      if (index != props.valueLabel.length - 1) {
        label += " - ";
      }
    });
    let isObject =
      typeof ele === "object" && !Array.isArray(ele) && ele !== null;
    if (!isObject) {
      return ele;
    }
    return label;
  };

  const getValue = (value) => {
    if (Array.isArray(value)) {
      let valueObj = [];
      value.forEach((ele) => {
        valueObj.push({ value: ele, label: getLabel(ele) });
      });
      return valueObj;
    } else {
      let obj = { value: props.value, label: getLabel(props.value) };
      return obj;
    }
  };

  const getOptions = () => {
    if (!props.data) {
      return [];
    }

    let values = [];

    if (props.data?.length > 0) {
      if (props.selectAll) {
        values.push(getAllOptions());
      }
      props.data?.map((ele) => (values.push({
        value: ele,
        label: getLabel(ele),
      })));
    }
    return values;
  };

  const colourStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    // control: (styles) => ({ ...styles, backgroundColor: "white" }),
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        color: isDisabled ? "#9ba1a3" : "default",
        borderColor: isDisabled ? "#e4e7e8" : "default",
        backgroundColor: isDisabled
          ? context.context.isDarkMode
            ? "#262629"
            : "#fcfeff"
          : context.context.isDarkMode
          ? "#262629"
          : "#fff",
        cursor: isDisabled ? "not-allowed" : "default",
        minHeight: "unset",
        // maxHeight: "33.5px",
        border: "1px solid #b3b8ba",
        borderRadius: "3px",
        boxShadow: context.context.isDarkMode ? "none" : "default",
      };
    },

    input: (base) => ({
      ...base,

      borderColor: context.context.isDarkMode ? "#6c7173" : "default",
      color: context.context.isDarkMode ? "#ccd0d1" : "default",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,

        // borderColor: isDisabled ? "#e4e7e8" : "default",
        // backgroundColor: isDisabled ? "#fcfeff" : "default",
        // cursor: isDisabled ? "not-allowed" : "default",
        background: context.context.isDarkMode
          ? isSelected
            ? "#545454"
            : "#262629"
          : "default",
        borderColor: context.context.isDarkMode ? "#6c7173" : "default",
        color: context.context.isDarkMode ? "#ccd0d1" : "default",
        // width: "fit-content",
        // color: "#FFF",
        // cursor: isDisabled ? "not-allowed" : "default",
        whiteSpace: "nowrap",
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: "unset",
      };
    },
  };

  const formatMileage = (elrText) => {
    var startmiles = props.value.startmiles;
    var endmiles = props.value.endmiles;

    if ((startmiles - Math.floor(startmiles)) !== 0) {
      startmiles = startmiles.toString();
      let l = startmiles.substring(startmiles.indexOf('.') +1).length;
      if (l < 4) {
        let split = startmiles.split('.');
        split[1] = split[1].padEnd(4, '0');
        startmiles = split[0] + "." + split[1];
      }
    }

    if ((endmiles - Math.floor(endmiles)) !== 0) {
      endmiles = endmiles.toString();
      let l = endmiles.substring(endmiles.indexOf('.') +1).length;
      if (l < 4) {
        let split = endmiles.split('.');
        split[1] = split[1].padEnd(4, '0');
        endmiles = split[0] + "." + split[1];
      }
    }

    return (
        <div>
          <span>{elrText ? "ELR Boundary Range " : "DU ELR Range "}</span>
          <strong>{startmiles}</strong> to{" "}
          <strong>{endmiles}</strong> miles.
        </div>
    );
  }

  return (
    <div
      className={classProps.join(" ")}
      key={props.keyprops ? props.keyprops : props.name}
    >
      <label className="select__label" htmlFor={props.name}>
        {props.label ? props.label : props.name}:{" "}
        {props.required ? <span className="small">[Required]</span> : ""}
      </label>

      <Select
        options={getOptions()}
        value={props.value ? getValue(props.value) : ""}
        filterOption={createFilter({ ignoreAccents: false })}
        components={{ MenuList: MenuList }}
        classNamePrefix={`${
          context.context.isDarkMode ? "darkcustom-select" : ""
        } custom-select`}
        menuPortalTarget={document.body}
        styles={colourStyles}
        captureMenuScroll={false}
        closeMenuOnScroll={(event) => {
          return event.target.attributes[0]?.value !== "scrollContainer";
        }}
        isClearable
        placeholder={props.loading ? "Loading..." : `${props.placeholder}...`}
        isDisabled={props.loading || props.isDisabled ? true : false}
        isLoading={props.loading ? true : false}
        // menuIsOpen={true}
        name="elr"
        onChange={(e, actionType) => {
          // console.log(e);
          // console.log(actionType);
          let value = [];
          if (actionType?.option?.value === "*") {
            props.data.forEach((ele) => {
              value.push(ele);
            })
          }
          if (props.isMulti && value.length === 0) {
            e.forEach((ele) => {
              value.push(ele.value);
            });
          } if (value.length === 0) {
            value = e?.value;
          }

          props.onChange(value, props.name, actionType);
          setError(null);
          setErrorMsg(null);
        }}
        onBlur={(e) => props.required && validate()}
        isMulti={props.isMulti || false}
      ></Select>
      {error ? <span className="input__error">{errorMsg}</span> : ""}
      {props.name === "elr" || props.name.startsWith("elrSSM") && props.value && (
        <div
          className="left elr-info"
          style={{ width: "100%", display: "block" }}
        >
          <div id="elr-info" style={{ display: "block" }}>
            {formatMileage(true)}
            {/*<strong>{props.value.startmiles}</strong> to{" "}*/}
            {/*<strong>{ props.value.endmiles}</strong> miles.*/}
          </div>
        </div>
      )}
      {props.name === "mdu" && props.value && (
        <div
          className="left elr-info"
          style={{ width: "100%", display: "block" }}
          >
          <div id="elr-info" style={{ display: "block" }}>
            {formatMileage(false)}
            {/*<strong>{props.value.startmiles}</strong> to{" "}*/}
            {/*<strong>{ props.value.endmiles}</strong> miles.*/}
          </div>
        </div>
        )}
    </div>
  );
});

export default ReactSelect;
