import React, {useEffect, useRef, useState} from "react";
import Button from "./Button.js";
import Input from "./Input.js";

import "react-dates/initialize";
import GeneralService from "../services/general.service";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import Checkbox from "./Checkbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

function CreateHazardTypeForm(props) {
  const initialVals = props.initialVals
    ? props.initialVals
    : {
        description: "",
        hazardCode: "",
        enabled: false
      };
  const { state } = useLocation();
  const [values, setValues] = useState(initialVals);
  const [error, setError] = useState(null);

  let navigate = useNavigate();

  const hazardCodeInput = useRef(null);
  const hazardCodeDescriptionInput = useRef(null);

    useEffect(() => {
        if (state?.data) {
            setValues(state.data);
        }
    }, [state]);

  function handleInputChange(e, name) {
      setValues((values) => ({ ...values, [e.target.name]: e.target.value }));
  }

  const validForm = () => {
      let validHazardCode = hazardCodeInput.current.validateField();
      let validDescription = hazardCodeDescriptionInput.current.validateField();

      return !!(validHazardCode && validDescription);
  }

  function handleSubmit() {
      if (validForm()) {
          if (values.id) {
              GeneralService.updateHazardCode(values)
                  .then((response) => {
                      toast.success(`Updated Hazard Code with ${values.hazardCode}`, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                      });
                      navigate("/hazardcodemanagement");
                  })
                  .catch((error) => {
                      console.log(error);
                  });
          } else {
              GeneralService.createHazardCode(values)
                  .then((response) => {
                      toast.success(`Created Hazard Code with ${values.hazardCode}`, {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                      });
                      navigate("/hazardcodemanagement");
                  }).catch((error) => {
                  if (error.response.status === 409) {
                      let errorData = error.response.data;
                      setError(errorData.apierror.message);
                  }
              });
          }
      }
  }

  function handleReset() {
    setValues({ ...initialVals });
    setError(null)
  }

  function handleOnchange(e) {
      const value =
          e.target.type === "checkbox" ? e.target.checked : e.target.value;
      setValues((values) => ({
          ...values,
          [e.target.name]: value,
      }));
  }

    return (
    <div className="create-form">
      <div className="create-form__section">
          <div>
              <p><FontAwesomeIcon icon={faInfoCircle} color={"blue"}/> Hazard codes are categorised and filtered on the hazard notification form by the following rules:</p>
              <ul>
                  <li>Any hazard codes starting with an 'E' are categorised and fall under the Environmental filter</li>
                  <li>Any hazard code starting with ‘M’ are categorised and fall under Access Points filter</li>
                  <li>Any Hazard code starting with 'H' are categorized and fall under the Health & Safety Hazards filter</li>
                  <li>Anything else falls under 'Other Hazards'</li>
              </ul>
          </div>
        <Input
          ref={hazardCodeDescriptionInput}
          name="description"
          label="Hazard Description"
          value={values.description}
          onChange={handleInputChange}
          required={true}
        />
        <Input
          ref={hazardCodeInput}
          name="hazardCode"
          label="Hazard Code"
          value={values.hazardCode}
          onChange={handleInputChange}
          required={true}
        />
          <Checkbox
              key="enabled"
              name="enabled"
              value="enabled"
              label="Enabled"
              onChange={(e) => handleOnchange(e)}
              checked={values.enabled || false}
          />
      </div>

      <div className="button-group create-form__submit">
        <Button action="secondary" label="Reset" onClick={handleReset} />
        <Button action="primary" label="Submit" onClick={handleSubmit} />
      </div>
        <div className="form-error">
            <span className="input__error">{error}</span>
        </div>
    </div>
  );
}

export default CreateHazardTypeForm;
