import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";

function MobilePhoneCoveragePage(props) {
  return (
    <div className="page" id="mobilePhoneCoveragePage">
      <Header title="Mobile Phone Coverage" />
      <BackButton navigateUrl="/generalinfo" />

      <div className="mobile-coverage__wrap">
        <div className="mobile-coverage">
          <h2 className="mobile-coverage__h2">
            Mobile Telephone Service Coverage – Track-side
          </h2>

          <p className="mobile-coverage__p">
            All Network Rail staff and Contractors should be aware that mobile
            cellular telephones may not operate on all parts of the railway
            network. In some cases intermittent operation may be experienced.
            The dedicated railway GSM-R is optimised for operation at train cab
            level and therefore may also not provide coverage at ground level
            trackside.{" "}
          </p>

          <p className="mobile-coverage__p">
            Safe Systems of Work requiring communications shall be planned
            taking into account the lack of universal coverage. Where
            consideration is being given to use of mobile telephone
            communications as part of a safe system of work the Work Planner may
            obtain guidance on mobile coverage in the area concerned from the
            following sources:
          </p>

          <p className="mobile-coverage__p">
            <a
              href="http://www.o2.co.uk/coveragechecker"
              target="_blank"
              rel="noreferrer"
            >
              http://www.o2.co.uk/coveragechecker
            </a>
            <br />
            <a
              href="http://www.three.co.uk/_standalone/Coverage_checker"
              target="_blank"
              rel="noreferrer"
            >
              http://www.three.co.uk/_standalone/Coverage_checker
            </a>
            <br />
            <a
              href="http://m.vodafone.co.uk/mobile/welcome/support/coverage-checker/index.htm"
              target="_blank"
              rel="noreferrer"
            >
              http://m.vodafone.co.uk/mobile/welcome/support/coverage-checker/index.htm
            </a>
            <br />
            <a
              href="http://www.virginmobile.com/vm/ukCoverage.do?contentId=coverage.in.uk.howdoi.sm235"
              target="_blank"
              rel="noreferrer"
            >
              http://www.virginmobile.com/vm/ukCoverage.do?contentId=coverage.in.uk.howdoi.sm235
            </a>
          </p>
        </div>
        <div className="mobile-coverage mobile-coverage__warn">
          <h3 className="mobile-coverage__warn-text">
            Any Safe System of Work which requires the use of mobile or fixed
            line-side telephones shall include provisions for testing the
            communications prior to the start of work and periodically during
            the work.
          </h3>
        </div>
      </div>
    </div>
  );
}

export default MobilePhoneCoveragePage;
