import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Header from "../components/Header";
import Button from "../components/Button";
import BouncingDotsLoader from "../components/Loader/BouncingDotsLoader";
import {UserService as userService} from "../services/user.service";
import BackButton from "../components/BackButton";
import Input from "../components/Input";

const UserPermissionRequests = () => {
    const { state } = useLocation();
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        if (state.data) {
            setDetails(state.data);
        }
    }, [state]);

    const handleAccept = () => {
        setLoading(true);
        userService
            .userPermissionAccept(details)
            .then((response) => {
                setLoading(false);
                navigate("/usermanagement", {
                    replace: true,
                    state: {
                        onboardingRequest: {
                            message: `User Permission Request accepted for ${details.email}`,
                            status: "success",
                        },
                    },
                });
            })
            .catch((error) => {
                setLoading(false);
                navigate("/usermanagement", {
                    replace: true,
                    state: {
                        onboardingRequest: {
                            message: `Error accepting user permission request for ${details.email}`,
                            status: "error",
                        },
                    },
                });
            });
    };

    const handleReject = () => {
        userService
            .userPermissionReject(details._id)
            .then((response) => {
                setLoading(false);
                navigate("/usermanagement", {
                    replace: true,
                    state: {
                        onboardingRequest: {
                            message: `User Permission request rejected for ${details.email}`,
                            status: "success",
                        },
                    },
                });
            })
            .catch((error) => {
                setLoading(false);
                navigate("/usermanagement", {
                    replace: true,
                    state: {
                        onboardingRequest: {
                            message: `Error rejecting user permission request for ${details.email}`,
                            status: "error",
                        },
                    },
                });
            });
    };

    return (
        <div className="page" id={`${state?.title?.toLowerCase || ""} `}>
            <Header title={`User Permission Request Details`} />
            <BackButton />
            {details && (
                <div className="filter-form">
                    <div className="filter-form__controller">
                        <h2 className="filter-form__title inline-block">Request Details</h2>
                    </div>
                    <div>
                        <div className="filter-form__group fields-wrapper">
                            <Input
                                key={`name`}
                                name={`name`}
                                label={`Name`}
                                value={details.name}
                                readOnly={true}
                            />
                            <Input
                                key={`email`}
                                name={`email`}
                                label={`Email`}
                                value={details.email}
                                readOnly={true}
                            />
                            <Input
                                key={`requested_permission`}
                                name={`requested_permission`}
                                label={`Requested Permission`}
                                value={details.requested_permission}
                                readOnly={true}
                            />
                        </div>

                        <div className="button-group filter-form__submit">
                            {!loading && (
                                <>
                                    <Button
                                        action="primary"
                                        label="Accept"
                                        onClick={handleAccept}
                                    />
                                    <Button
                                        action="secondary"
                                        label="Reject"
                                        onClick={handleReject}
                                    />
                                </>
                            )}
                            {loading && (
                                <>
                                    <Button
                                        action="primary"
                                        label="Reject"
                                        onClick={() => {}}
                                        className={"hiden"}
                                    />{" "}
                                    <BouncingDotsLoader></BouncingDotsLoader>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserPermissionRequests;