import React, {useEffect, useState} from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import Button from "../../components/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BulkUploadService from "../../services/bulk-upload.service";
import {toast} from "react-toastify";
import {faDownload, faFile, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Upload from "../../components/Upload";
import {useNavigate} from "react-router-dom";

function HazardsImportDataPage(props) {

    const [hazardFile, setHazardFile] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        return () => {
            reoveHazardFileURL();
        };
    }, []);

    const reoveHazardFileURL = () => {
        hazardFile.forEach((file) => {
            URL.revokeObjectURL(file.preview);
        });
    };

    const removeHazardFile = (e, fileName) => {
        e && e.stopPropagation();

        let arr = [];
        hazardFile.forEach((file) => {
            if (file.name === fileName) {
                URL.revokeObjectURL(file.preview);
            } else arr.push(file);
        });

        setHazardFile(arr);
    };

    const hazardsTemplate = () => {
        BulkUploadService.hazardsTemplate()
            .then((response) => {
                const url = response.data.s3url;
                const link = document.createElement("a");
                const filename = response.data.filename;
                link.href = url;
                link.setAttribute("download", filename); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => console.log(error));
    };

    const uploadHazards = event => {
        console.log(event.detail)
        if (event.detail === 1) {
            let file = hazardFile[0];
            let params = {};
            params.file = file;
            BulkUploadService.hazards(params)
                .then((response) => {
                    console.log(response);
                    toast.success(`The system is uploading your file for processing. Please check your email shortly for more information.`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    removeHazardFile(undefined, file.name);
                    navigate("/dataManagementDash");
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <div className="page" id="dataManagementPage">
            <Header title="Bulk Hazards Import" />
            <BackButton
                setPage={props.setPage}
                target={["adminDashPage", "backward"]}
            />

            <h2>Bulk Hazards Import</h2>

            <div className="bulk-upload">
              <Button
                action="secondary"
                label="Download Bulk Upload Template for Hazards"
                onClick={hazardsTemplate}
              >
                <FontAwesomeIcon icon={faDownload} className="button__icon" />
              </Button>
              {hazardFile.length > 0 ? (
                <Button
                  action="primary"
                  label="Confirm and upload hazards"
                  onClick={uploadHazards}
                ></Button>
              ) : (
                <Upload
                  name="uploadHazards"
                  label="Bulk Upload Hazards"
                  setFiles={setHazardFile}
                  files={hazardFile}
                  disabled={false}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              )}

              {hazardFile.length > 0 && (
                <>
                  <div className="create-form__section">
                    <h4>Previews</h4>
                    <div className="gallery">
                      {hazardFile?.map((file) => {
                        if (file.type.includes("image")) {
                          return (
                            <div className="image__wrap">
                              <Button
                                action="tertiary"
                                onClick={(e) => removeHazardFile(e, file.name)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                              <div>{`${file.name} - ${file.size / 1000}KB`}</div>
                              <img src={file.preview} alt="" className="image" />
                            </div>
                          );
                        } else {
                          return (
                            <div className="image__wrap">
                              <Button
                                action="tertiary"
                                onClick={(e) => removeHazardFile(e, file.name)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                              <div
                                style={{ maxWidth: "100%", wordBreak: "break-word" }}
                              >{`${file.name} - ${file.size}KB`}</div>
                              <FontAwesomeIcon icon={faFile} />
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
        </div>
    )
}

export default HazardsImportDataPage;