import axios from "../_helpers/axiosinstance";

const getAllInactiveHazards = async (pageSize, pageNumber, sortOptions, totalElements) => {
    let sortBy = undefined;
    let sortOrder = undefined;

    if (sortOptions?.accessor) {
        sortBy = sortOptions.accessor;
    }

    if (sortOptions?.direction) {
        sortOrder = sortOptions.direction;
    }

    const response = axios.post(
        `${process.env.REACT_APP_nhd_hazard_search_ingress}/nhd/api/hazard/inactive/all`,
        {
            page: pageNumber === 0 ? 0 : pageNumber || 0,
            pageSize: pageSize === 0 ? 0 : pageSize || 10,
            sortBy: sortBy || undefined,
            sortOrder: sortOrder || undefined,
            totalElements: totalElements ? totalElements : undefined,
        },
        {

        }
    );
    return response;
}

export const InactiveHazardsService = {
    getAllInactiveHazards
}