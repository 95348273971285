import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";

function BuriedServicesPage(props) {
  return (
    <div className="page " id="buriedServicesPage">
      <Header title="Buried Services" />
      <BackButton navigateUrl="/generalinfo" />

      <div className="buried-services__wrap">
        <div className="buried-services">
          <h2 className="buried-services__h2">Buried Services Information</h2>

          <p className="buried-services__p">
            Where work is to be carried out that will cause ground disturbance a
            request for Buried Services Information must be made.
          </p>

          <p className="buried-services__p">
            Please contact the Buried Services team to submit a request:
          </p>

          <p className="buried-services__p">
            <b>
              The Buried Services NST
              <br />
              Network Rail
              <br />
              4C Hudson House
              <br />
              Toft Green
              <br />
              York
              <br />
              YO1 6HP
            </b>
          </p>

          <p className="buried-services__p">
            <b>
              Tel: <a href="tel:01904522190">01904 522190</a>
              <br />
              Fax: 01904 523236
            </b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default BuriedServicesPage;
