import React, {useContext} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faMoon, faQuestionCircle, faSignOutAlt, faSun, faUser} from "@fortawesome/free-solid-svg-icons";
import {useLocation, useNavigate} from "react-router-dom";
import {ThemeContext} from "../contexts/ThemeContextProvider";

function UserNav(props) {
  let navigate = useNavigate();
  const { pathname } = useLocation();

  const contextType = useContext(ThemeContext);
  return (
    <nav className="user-nav">
      <ul
        className={
          props.open ? "user-nav__ul user-nav__ul--open" : "user-nav__ul"
        }
      >
        <li className="user-nav__li">
          <button
            className="user-nav__button"
            onMouseDown={() => navigate("/privacy")}
          >
            <FontAwesomeIcon icon={faEye} className="user-nav__icon" />
            <span className="user-nav__title">Privacy & Cookies</span>
          </button>
        </li>

        <li className="user-nav__li">
          <button
            className="user-nav__button"
            onMouseDown={() => navigate("/userguide")}
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="user-nav__icon"
            />
            <span className="user-nav__title">User Guide</span>
          </button>
        </li>
        <li className="user-nav__li">
          <button
            className="user-nav__button"
            onMouseDown={() => {
              props.setDarkMode(!props.darkMode);
              contextType.toggleTheme();
            }}
          >
            <FontAwesomeIcon
              icon={props.darkMode ? faSun : faMoon}
              className="user-nav__icon"
            />
            <span className="user-nav__title">
              {props.darkMode ? "Light Mode" : "Dark Mode"}
            </span>
          </button>
        </li>
        <li className="user-nav__li">
          {/*<button*/}
          {/*  className="user-nav__button"*/}
          {/*  onMouseDown={() => navigate("/profile")}*/}
          {/*>*/}
          <button
              className="user-nav__button"
              onMouseDown={() => navigate("/profile", {
                state: { title: "User Profile", formType: "profile", previousPath: pathname },
              })}
          >
            <FontAwesomeIcon icon={faUser} className="user-nav__icon" />
            <span className="user-nav__title">Profile</span>
          </button>
        </li>
        <li className="user-nav__li">
          <button
            className="user-nav__button"
            onMouseDown={() => navigate("/logout")}
          >
            <FontAwesomeIcon icon={faSignOutAlt} className="user-nav__icon" />
            <span className="user-nav__title">Logout</span>
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default UserNav;
