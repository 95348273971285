import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

function UserGuidePage(props) {
  return (
    <div className="page" id="userGuidePage">
      <Header title="User Guide" />
      <BackButton navigateUrl="/" />

      <div className="user-guide__wrap">
        <div className="user-guide">
          <h2 className="user-guide__h2">Download User Guide</h2>
          <p className="user-guide__p">
            Here you can download a copy of the National Hazard Directory User
            Guide.
          </p>

          <p className="user-guide__p">
            <a
              className="user-guide__download button button--primary"
              href=".\downloads\National-Hazard-Directory-User-Guide.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faDownload} className="button__icon" />{" "}
              <span className="button__text">Download User Guide PDF</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserGuidePage;
