import React, {useEffect, useState} from "react";
import Header from "../../../components/Header";
import "../DashboardPage.css";
import {useNavigate} from "react-router-dom";
import DashboardService from "../../../services/dashboard.service";
import Tile from "../../../components/DashboardTile/Tile";
import ReactSelect from "../../../components/Select/ReactSelect";
import Button from "../../../components/Button";
import ReactTable from "../../../components/ReactTable/ReactTable";
import generalService from "../../../services/general.service";
import GeneralService from "../../../services/general.service";
import {DateRangePicker} from "react-dates";
import moment from "moment/moment";
import {Default, Mobile} from "../../admin/AdminReportsPage";

const WHESADashboardPage = (props) => {
  const initialVals = props.initialVals
      ? props.initialVals
      : {
        route: "",
        elr: "",
        mdu: "",
        startDate: null,
        endDate: null,
      };

  let navigate = useNavigate();
  const [routes, setRoutes] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [values, setValues] = useState();
  const [filteredElr, setFilteredElr] = useState();
  const [filteredMdu, setFilteredMdu] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [hazards, setHazards] = useState();
  const [focused, setFocused] = useState();
  const [sortOptions, setSortOptions] = useState({accessor: '', direction: ''});

  const handleClick = (e, type) => {
    navigate("/register", {
      state: { type: type, previousPage: "/whesadashboard" },
    });
  };

  function getWhseaReviewDateData(values, pageNumber, pageSize) {
    DashboardService.getWhseaReviewDate(values, pageNumber, pageSize, sortOptions).then((response) =>{
      setHazards(response.data);
    }).catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    DashboardService.getWhsesa()
      .then((response) => {
        if (!unmounted) {
          setLoading(false);
          setData(response.data);
        }
      })
      .catch((error) => {
        if (!unmounted) {
          setLoading(false);
        }
      });
    getWhseaReviewDateData();
    GeneralService.getRoutes(true)
      .then((response) => {
        setRoutes(response.data);
      })
      .catch((error) => {
        // setRoutes("error");
        console.log(error);
      });
    return () => {
      unmounted = true;
      //cancel axios token
    };
  }, []);

  useEffect(() => {
    if (hazards?.results.length > 0) {
      setValues((values) => ({
        ...values,
        sortBy: sortOptions?.accessor,
        sortOrder: sortOptions?.direction,
      }));
      handleSubmit(undefined, 0, pageSize);
    }
  }, [sortOptions])

  function handleInputChange(e, name) {
    if (e === null) {
      return;
    }
    if (e._isAMomentObject) {
      setValues((values) => ({ ...values, [name]: e }));
    } else {
      setValues((values) => ({ ...values, [e.target.name]: e.target.value }));
    }
  }

  const handleInputChangeG = (e, name) => {
    if (name === "route") {
      if (e !== undefined) {
        setFilteredElr(["loading..."]);
        setValues((values) => ({
          ...values,
          [name]: e ? e : "",
        }));
        generalService
            .getElrsZonecode(e.zonecode)
            .then((response) => {
              setFilteredElr(response.data);
            })
            .catch((error) => {
              setFilteredElr();
            });
      } else {
        setValues((values) => ({
          ...values,
          elr: "",
          mdu: "",
        }));
        setFilteredElr();
      }
    }
    if (name === "elrWd") {
      if (e !== undefined) {
        setFilteredMdu(["loading..."]);
        setValues((values) => ({
          ...values,
          elr: e ? e : "", mdu: "",
        }));
        generalService
            .getMDUSubzonecode({
              zonecode: e.zonecode,
              elr: e.elr,
            })
            .then((response) => {
              setFilteredMdu(response.data);
            })
            .catch((error) => {
              setFilteredMdu();
            });
      } else {
        setValues((values) => ({
          ...values,
          mdu: "",
        }));
        setFilteredMdu();
      }
    }

    if (name === "mduWd") {
      setValues((values) => ({
        ...values,
        ["mdu"]: e ? e : "",
      }));
    } 

  }

  const handlePageSize = (val) => {
    setPage(0);
    setPageSize(val);
  };

  function handleTableSubmit(pageVal, pageSizeVal) {
    let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
    let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
    getWhseaReviewDateData(values, pageNumber, pageSizeNumber);
  }

  function handleSubmit(e, pageVal, pageSizeVal) {
    let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
    let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
    getWhseaReviewDateData(values, pageNumber, pageSizeNumber);
  }

  function handleReset() {
    setValues({ ...initialVals });
    getWhseaReviewDateData(null, 0, 10);
  }

  const handleTableSort = (accessor) => {
    let direction = sortOptions?.direction;

    if (!direction) {
      direction = 'dsc';
    } else if (direction === 'dsc') {
      direction = 'asc';
    } else {
      direction = '';
    }

    setSortOptions({
      accessor : accessor,
      direction : direction
    });
  };

  return (
    <div className="page page--show" id="searchPage">
      <Header title="WHSEA Dashboard" />
      <h2>Submissions to action</h2>
      <div className="tiles__wrap">
        <div className="tile__group">
          <Tile
            title={"New Hazard"}
            loading={loading}
            data={data?.total_new_hazards || 0}
            desc={
              "New Hazard Submissions requiring approval within 7 days"
            }
            onClick={(e) => handleClick(e, "New Hazard")}
          />

          <Tile
            title={"Amended Hazard"}
            loading={loading}
            data={data?.total_amend_hazards || 0}
            desc={
              "Hazard amendment submissions requiring approval within 7 days"
            }
            onClick={(e) => handleClick(e, "Amended Hazard")}
          />

          <Tile
            title={"Hazard Deletion"}
            loading={loading}
            data={data?.total_hazard_delete || 0}
            desc={
              "Hazard Deletion requests that need to be approved within 7 days"
            }
            onClick={(e) => handleClick(e, "Hazard Deletion")}
          />
        </div>
      </div>
      {hazards && (
          <div className="filter-form">
            <div className="filter-form__controller">
              <h2 className="filter-form__title inline-block">
                Hazards with up coming review dates
              </h2>
            </div>
            <div>
              <h2 className="filter-form__title inline-block">Search Filters</h2>
              <div className="filter-form__group fields-wrapper">
                <ReactSelect
                    name="route"
                    label="Route"
                    placeholder="Select Route"
                    valueLabel={["zonedescription"]}
                    data={routes}
                    onChange={handleInputChangeG}
                    value={values?.route}
                />
                <ReactSelect
                    name="elrWd"
                    label="ELR"
                    placeholder="Select ELR"
                    valueLabel={["elr", "description"]}
                    data={filteredElr || props.elrs}
                    loading={filteredElr && filteredElr[0] === "loading..."}
                    onChange={handleInputChangeG}
                    value={values?.elr}
                />
                <ReactSelect
                    name="mduWd"
                    label="MDU"
                    placeholder="Select MDU"
                    valueLabel={["subzonecode", "description"]}
                    data={filteredMdu || props.mduList}
                    loading={filteredMdu && filteredMdu[0] === "loading..."}
                    onChange={handleInputChangeG}
                    value={values?.mdu}
                />
                <div className="input__wrap input__wrap--double">
                  <label className="input__label">Date:</label>
                  <Mobile>
                    <DateRangePicker
                        isOutsideRange={() => false}
                        startDate={values?.startDate}
                        startDateId="start-date"
                        endDate={values?.endDate}
                        endDateId="end-date"
                        onDatesChange={({ startDate, endDate }) => {
                          handleInputChange(startDate, "startDate");
                          handleInputChange(endDate, "endDate");
                        }}
                        focusedInput={focused}
                        onFocusChange={(focused) => setFocused(focused)}
                        displayFormat={() => "DD MMM YYYY"}
                        showDefaultInputIcon={true}
                        block={true}
                        keepOpenOnDateSelect={true}
                        numberOfMonths={1}
                        required={true}
                    />
                  </Mobile>
                  <Default>
                    <DateRangePicker
                        isOutsideRange={() => false}
                        minDate={moment("2022-01-01")}
                        startDate={values?.startDate}
                        startDateId="start-date"
                        endDate={values?.endDate}
                        endDateId="end-date"
                        onDatesChange={({ startDate, endDate }) => {
                          handleInputChange(startDate, "startDate");
                          handleInputChange(endDate, "endDate");
                        }}
                        focusedInput={focused}
                        onFocusChange={(focused) => setFocused(focused)}
                        displayFormat={() => "DD MMMM YYYY"}
                        showDefaultInputIcon={true}
                        block={true}
                        keepOpenOnDateSelect={true}
                        required={true}
                    />
                  </Default>
                </div>
              </div>
              <div className="button-group filter-form__submit">
                <Button action="secondary" label="Reset" onClick={handleReset} />
                <Button
                    action="primary"
                    label="Filter"
                    onClick={(e) => handleSubmit(e, undefined, undefined)}
                />
              </div>
              <div style={{marginTop: "20px"}}>
                <ReactTable
                    tableData={hazards?.results}
                    currentPage={hazards?.pageNumber}
                    totalPages={hazards?.totalPages}
                    pageSize={hazards?.pageSize}
                    totalElements={hazards?.totalElements}
                    showColumns={hazards?.headers}
                    customHeader={true}
                    customSearch={true}
                    setPage={(val) => {
                      setPage(val);
                      handleTableSubmit(val, undefined);
                    }}
                    setPageSize={(val) => {
                      handlePageSize(val);
                      handleTableSubmit(0, val);
                    }}
                    sortOptions={sortOptions}
                    setSortOptions={setSortOptions}
                    handleTableSort={handleTableSort}
                />
              </div>
            </div>
          </div>
      )}
    </div>
  );
};

export default WHESADashboardPage;
