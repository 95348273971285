import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";

function DeleteHazardPage(props) {
  return (
    <div className="page" id="deleteHazardPage">
      <Header title="Remove a Hazard" />
      <BackButton />

      <p>Delete Hazard</p>
    </div>
  );
}

export default DeleteHazardPage;
