import React, {useEffect, useRef, useState} from "react";
import Header from "../../../components/Header";
import "../DashboardPage.css";
import {useNavigate} from "react-router-dom";
import DashboardService from "../../../services/dashboard.service";
import Tile from "../../../components/DashboardTile/Tile";
import ReactTable from "../../../components/ReactTable/ReactTable";
import ReactSelect from "../../../components/Select/ReactSelect";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Checkbox from "../../../components/Checkbox";
import GeneralService from "../../../services/general.service";
import {UserService} from "../../../services/user.service";
import {toast} from "react-toastify";
import BulkUploadService from "../../../services/bulk-upload.service";

const SSMDashboardPage = (props) => {
    const initialVals = {
        page: 0,
        pageSize: 10,
        hazardCodes: [],
        excHazardCodes: false,
        incHazardCodes: false,
        elrs: {

        },
    };
    let navigate = useNavigate();
    const [values, setValues] = useState(initialVals);
    const [data, setData] = useState();
    const [priorityHazards, setPriorityHazards] = useState();
    const [hazardCodes, setHazardCodes] = useState();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sortOptions, setSortOptions] = useState({accessor: '', direction: ''});
    const [userElrs, setUserElrs] = useState();
    const [filteredElr, setFilteredElr] = useState([]);
    const elrSelectInput = useRef(null);
    const [selectElr, setSelectElr] = useState(false);
    const [selectHazardCodes, setSelectHazardCodes] = useState(false);
    const hazardCodesRef = useRef(null);
    const [error, setError] = useState(null);
    const [fieldsErrors, setFieldsErrors] = useState();

    const handleClick = (e, type) => {
        navigate("/register", {
            state: { type: type, previousPage: "/smmdashboard" },
        });
    };

    async function getSSMPriorityData(page, pageSize) {
        await DashboardService.getSSMPriorityHazards(page, pageSize, sortOptions)
            .then((response) => {
                setPriorityHazards(response.data);
            }).catch((error) => {
                console.log(error);
            });
    }

    async function getSSMHazards(data) {
        await BulkUploadService.getSSMHazards(data)
            .then((response) => {
                toast.success(`Your Export has been sent and you will receive an email shortly with the attached report.`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        async function getData() {
            await DashboardService.getSSM()
                .then((response) => {
                    setData(response.data);
            });
            await GeneralService.getHazardCodesAll(true)
                .then((response) => {
                    setHazardCodes(response.data);
                })
                .catch((error) => {
                    // setRoutes("error");
                    console.log(error);
                });
            await UserService.getUserElrsFromCognitoUsername(props.user.username)
                .then((response) => {
                    setUserElrs(response.data);
                    setFilteredElr(response.data);
                }).catch((error) => {
                    console.log(error);
                });

        }

        if (data === undefined && hazardCodes === undefined && userElrs === undefined
            && priorityHazards === undefined) {
            getData();
            getSSMPriorityData();
        }
    }, [data, hazardCodes, filteredElr, priorityHazards, props.user.username]);

    useEffect(() => {
        if (priorityHazards?.results.length > 0) {
            handleTableSubmit(0,10);
        }
    }, [sortOptions])

    const handleHazardOpen = (e, row) => {
        let value = row.row.original.submissionType;
        switch (value) {
            case "New Submission":
                navigate(`/newhazard/${row.row.original.hazard_id}`, {
                    state: {data: row.row?.original, pageTitle: "New Hazard"},
                });
                break;
            case "Pending Request":
                navigate(`/amendhazard/${row.row.original.hazard_id}`, {
                    state: {data: row.row?.original, pageTitle: "Amend Hazard"},
                });
                break;
            case "Proposed Deletion":
                navigate(`/deletehazard/${row.row.original.hazard_id}`, {
                    state: {data: row.row?.original, pageTitle: "Hazard Deletion"},
                });
                break;
            case "Pending Re-instatement":
                navigate(`/amendhazard/${row.row.original.hazard_id}`, {
                    state: {data: row.row?.original, pageTitle: "Amend Hazard"},
                });
                break;
            default:
                navigate(`/viewhazard/${row.row.original.hazard_id}`, {
                    state: { data: row.row?.original, actionType: "edit" },
                });
                break;
        }
    }

    const handlePageSize = (val) => {
        setPage(0);
        setPageSize(val);
    };

    function handleTableSubmit(pageVal, pageSizeVal) {
        let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
        let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
        getSSMPriorityData(pageNumber, pageSizeNumber);
    }

    const handleTableSort = (accessor) => {
        let direction = sortOptions?.direction;

        if (!direction) {
            direction = 'dsc';
        } else if (direction === 'dsc') {
            direction = 'asc';
        } else {
            direction = '';
        }

        setSortOptions({
            accessor : accessor,
            direction : direction
        });
    };

    const handleInputChange = (e) => {
        setValues((values) => ({
            ...values,
            elrs: {
                ...values.elrs,
                [e.target.name]: e.target.value
            }
        }));
    };

    const handleSelectInputChange = (value, name, actionType) => {
        if (name === "hazardCodes") {
            setFieldsErrors((old) => ({ ...old, hazardCodes: undefined }));
            setError();
            setValues((values) => ({
                ...values,
                [name]: value ? value : []
            }));
        } else {
            if (actionType?.action === "select-option") {
                setValues((values) => ({
                    ...values,
                    elrs: {
                        ...values.elrs,
                        [name]: value
                    }

                }));
                setFilteredElr(filteredElr.filter((elr) => {
                    if (elr.elr !== value.elr) {
                        return true;
                    }
                }));
            }
            if (actionType?.action === "clear") {
                setValues((values) => ({
                    ...values,
                    elrs: {
                        ...values.elrs,
                        [name]: value
                    }

                }));
                let elrs = [...filteredElr];
                elrs.push(actionType?.removedValues[0].value);
                elrs = elrs.sort((a, b) => {
                    return a.elr.localeCompare(b.elr);
                });
                setFilteredElr(elrs);
            }
        }
    }

    function handleCheckboxChange(e, name) {
        if (name === "excludeElr" || name === "includeElr") {
            setError(null);
            setSelectElr(e.target.checked);
            if (name === "excludeElr") {
                if (e.target.checked) {
                    setValues((values) => ({
                        ...values,
                        elrs: {
                            ...values.elrs,
                            excElr: e.target.checked,
                            incElr: false
                        },
                    }));
                } else {
                    setValues((values) => ({
                        ...values,
                        elrs: initialVals.elrs
                    }));
                    setFilteredElr([...userElrs]);
                }
            } else {
                if (e.target.checked) {
                    setValues((values) => ({
                        ...values,
                        elrs: {
                            ...values.elrs,
                            excElr: false,
                            incElr: e.target.checked,
                        },
                    }));
                } else {
                    setValues((values) => ({
                        ...values,
                        elrs: initialVals.elrs
                    }));
                    setFilteredElr([...userElrs]);
                }
            }
        }
        if (name === "includeHazardCode" || name === "excludeHazardCode") {
            setSelectHazardCodes(e.target.checked);
            setFieldsErrors((old) => ({ ...old, hazardCodes: undefined }));
            if (name === "excludeHazardCode") {
                if (e.target.checked) {
                    setValues((values) => ({
                        ...values,
                        excHazardCodes: e.target.checked,
                        incHazardCodes: false
                    }));
                } else {
                    setValues((values) => ({
                        ...values,
                        excHazardCodes: false,
                        incHazardCodes: false,
                        hazardCodes: initialVals.hazardCodes
                    }));
                }
            } else {
                if (e.target.checked) {
                    setValues((values) => ({
                        ...values,
                        excHazardCodes: false,
                        incHazardCodes: e.target.checked
                    }));
                } else {
                    setValues((values) => ({
                        ...values,
                        excHazardCodes: false,
                        incHazardCodes: false,
                        hazardCodes: initialVals.hazardCodes
                    }));
                }
            }
        }
    }

    function handleSubmit(csv) {
        let valid = true;
        console.log("submit");
        console.log(values);
        let data = {
            elrs: {},
            hazardCodes: {}
        };
        var elrs = {...values.elrs};
        let elrValues = new Map();
        let objectKeys = Object.keys(elrs);
        console.log(objectKeys);
        if (objectKeys.length > 0) {
            for (let i = 0; i < objectKeys.length; i++) {
                if (objectKeys[i] === "excElr" && elrs.excElr === true) {
                    data.elrs.filterElrs = "exc";
                } if (objectKeys[i] === "incElr" && elrs.incElr === true) {
                    data.elrs.filterElrs = "inc";
                }
                if (objectKeys[i].includes("elrSSM1") && !elrValues.has("elrSSM1") && elrs.elrSSM1 !== undefined) {
                    elrValues.set("elr1", {
                        elr: elrs.elrSSM1.elr,
                        startMileage: elrs.elr1StartMileage !== null ? elrs.elr1StartMileage : null,
                        endMileage: elrs.elr1EndMileage !== null ? elrs.elr1EndMileage : null
                    });
                }
                if (objectKeys[i].includes("elrSSM2") && !elrValues.has("elrSSM2") && elrs.elrSSM2 !== undefined) {
                    elrValues.set("elr2", {
                        elr: elrs.elrSSM2.elr,
                        startMileage: elrs.elr2StartMileage !== null ? elrs.elr2StartMileage : null,
                        endMileage: elrs.elr2EndMileage !== null ? elrs.elr2EndMileage : null
                    });
                }
                if (objectKeys[i].includes("elrSSM3") && !elrValues.has("elrSSM3") && elrs.elrSSM3 !== undefined) {
                    elrValues.set("elr3", {
                        elr: elrs.elrSSM3.elr,
                        startMileage: elrs.elr3StartMileage !== null ? elrs.elr3StartMileage : null,
                        endMileage: elrs.elr3EndMileage !== null ? elrs.elr3EndMileage : null
                    });
                }
                if (objectKeys[i].includes("elrSSM4") && !elrValues.has("elrSSM4") && elrs.elrSSM4 !== undefined) {
                    elrValues.set("elr4", {
                        elr: elrs.elrSSM4.elr,
                        startMileage: elrs.elr4StartMileage !== null ? elrs.elr4StartMileage : null,
                        endMileage: elrs.elr4EndMileage !== null ? elrs.elr4EndMileage : null
                    });
                }
                if (objectKeys[i].includes("elrSSM5") && !elrValues.has("elrSSM5") && elrs.elrSSM5 !== undefined) {
                    elrValues.set("elr5", {
                        elr: elrs.elrSSM5.elr,
                        startMileage: elrs.elr5StartMileage !== null ? elrs.elr5StartMileage : null,
                        endMileage: elrs.elr5EndMileage !== null ? elrs.elr5EndMileage : null
                    });
                }
            }
            if (elrValues.size > 0) {
                data.elrs.elrs = elrValues;
                console.log(data);
                valid = true;
            } else {
                valid = false;
                setError("Please Provide at least one ELR.")
            }
        }
        if (values.excHazardCodes || values.incHazardCodes) {
            if (hazardCodesRef.current.validateField()) {
                let filterHazardCodes = null;
                if (values.excHazardCodes) {
                    filterHazardCodes = "exc";
                }
                if (values.incHazardCodes) {
                    filterHazardCodes = "inc";
                }
                data.hazardCodes = {
                    filterHazardCodes: filterHazardCodes || undefined,
                    hazardCodes: values.hazardCodes
                }
                setFieldsErrors((old) => ({ ...old, hazardCodes: undefined }));
                valid = true;
            } else {
                setFieldsErrors((old) => ({ ...old, hazardCodes: "error" }));
                setError("Please check fields or errors highlighted in red, and amend the information to continue.");
                valid = false;
            }

        }

        if (valid) {
            getSSMHazards(data);
        }
    }

    function handleReset() {
        setValues({ ...initialVals });
        setFilteredElr([...userElrs]);
        setFieldsErrors((old) => ({ ...old, hazardCodes: undefined }));
        setError(null);
    }

    return (
        <div className="page page--show" id="ssmDash">
            <Header title="SSM Dashboard" />
            <h2>Pending Approval</h2>
            {priorityHazards?.results && (
                <div>
                    <div className="search__wrap">
                        <h5 style={{ color: "#cd6f66", width: "100%", flex: "2" }}>
                            High Priority Requests to be approved within 24hrs
                        </h5>
                    </div>
                    <div style={{marginTop: "20px"}}>
                        <ReactTable
                            tableData={priorityHazards?.results}
                            currentPage={priorityHazards?.pageNumber}
                            totalPages={priorityHazards?.totalPages}
                            pageSize={priorityHazards?.pageSize}
                            totalElements={priorityHazards?.totalElements}
                            showColumns={priorityHazards?.headers}
                            customHeader={true}
                            customSearch={true}
                            setPage={(val) => {
                                setPage(val);
                                handleTableSubmit(val, undefined);
                            }}
                            setPageSize={(val) => {
                                handlePageSize(val);
                                handleTableSubmit(0, val);
                            }}
                            sortOptions={sortOptions}
                            setSortOptions={setSortOptions}
                            handleTableSort={handleTableSort}
                            canOpen={true}
                            editHeader={"Open"}
                            handleOpen={(e, row) => {
                                handleHazardOpen(e, row);
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="tiles__wrap">
                <div className="tile__group">
                    <Tile
                        title={"New Hazard"}
                        // loading={loading}
                        data={data?.total_new_hazards || 0}
                        desc={
                            "New Hazard Submissions requiring approval within 7 days"
                        }
                        onClick={(e) => handleClick(e, "New Hazard")}
                    />

                    <Tile
                        title={"Amended Hazard"}
                        // loading={loading}
                        data={data?.total_amend_hazards || 0}
                        desc={
                            "Hazard amendment submissions requiring approval within 7 days"
                        }
                        onClick={(e) => handleClick(e, "Amended Hazard")}
                    />

                    <Tile
                        title={"Hazard Deletion"}
                        // loading={loading}
                        data={data?.total_hazard_delete || 0}
                        desc={
                            "Hazard Deletion requests that need to be approved within 7 days"
                        }
                        onClick={(e) => handleClick(e, "Hazard Deletion")}
                    />
                </div>
            </div>
            <div>
                <h2 className="filter-form__title inline-block">
                    All Hazards Under your Assigned Route(s)
                </h2>
                <div style={{marginTop: "10px"}}>
                    <div className="create-form">
                        <div className="create-form__section">
                            <h2>Search Filters</h2>
                            <p>To include/Exclude ELR(s) and or mileage range use the selection below. You can select
                                up to 5 to include or exclude.</p>
                            <div className={"ssm-search-form__section__checkbox"}>
                                <Checkbox
                                    name="excludeElr"
                                    value="excElr"
                                    label="Exclude ELR(s)"
                                    checked={values.elrs?.excElr}
                                    onChange={handleCheckboxChange}
                                />
                                <Checkbox
                                    name="includeElr"
                                    value="incElr"
                                    label="Include ELR(s)"
                                    checked={values.elrs?.incElr}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                            <div className={"input__wrap input_elr_mileage"}>
                                <ReactSelect
                                    ref={elrSelectInput}
                                    name="elrSSM1"
                                    label="ELR 1"
                                    placeholder="Select ELR"
                                    valueLabel={["elr", "description"]}
                                    data={filteredElr}
                                    value={values.elrs?.elrSSM1}
                                    isDisabled={!selectElr}
                                    onChange={handleSelectInputChange}
                                />
                                <Input
                                    name="elr1StartMileage"
                                    label="From Miles"
                                    type="number"
                                    min={values.elrs?.elrSSM1?.startmiles}
                                    max={values.elrs?.elrSSM1?.endmiles}
                                    step="0.0001"
                                    value={values.elrs?.elr1StartMileage}
                                    onChange={handleInputChange}
                                    // fieldsErrors={fieldsErrors}
                                    disabled={!selectElr}
                                />
                                <Input
                                    name="elr1EndMileage"
                                    label="To Miles"
                                    step="0.0001"
                                    type="number"
                                    min={values.elrs?.elrSSM1?.startmiles}
                                    max={values.elrs?.elrSSM1?.endmiles}
                                    value={values.elrs?.elr1EndMileage}
                                    onChange={handleInputChange}
                                    // fieldsErrors={fieldsErrors}
                                    disabled={!selectElr}
                                />
                            </div>
                            <div className={"input__wrap input_elr_mileage"}>
                                <ReactSelect
                                    name="elrSSM2"
                                    label="ELR 2"
                                    placeholder="Select ELR"
                                    valueLabel={["elr", "description"]}
                                    data={filteredElr}
                                    value={values.elrs?.elrSSM2}
                                    isDisabled={!selectElr}
                                    onChange={handleSelectInputChange}
                                />
                                <Input
                                    name="elr2StartMileage"
                                    label="From Miles"
                                    type="number"
                                    min={values.elrs?.elrSSM2?.startmiles}
                                    max={values.elrs?.elrSSM2?.endmiles}
                                    step="0.0001"
                                    value={values.elrs?.elr2StartMileage}
                                    onChange={handleInputChange}
                                    // fieldsErrors={fieldsErrors}
                                    disabled={!selectElr}
                                />
                                <Input
                                    name="elr2EndMileage"
                                    label="To Miles"
                                    step="0.0001"
                                    type="number"
                                    min={values.elrs?.elrSSM2?.startmiles}
                                    max={values.elrs?.elrSSM2?.endmiles}
                                    value={values.elrs?.elr2EndMileage}
                                    onChange={handleInputChange}
                                    // fieldsErrors={fieldsErrors}
                                    disabled={!selectElr}
                                />
                            </div>
                            <div className={"input__wrap input_elr_mileage"}>
                                <ReactSelect
                                    name="elrSSM3"
                                    label="ELR 3"
                                    placeholder="Select ELR"
                                    valueLabel={["elr", "description"]}
                                    data={filteredElr}
                                    value={values.elrs?.elrSSM3}
                                    isDisabled={!selectElr}
                                    onChange={handleSelectInputChange}
                                />
                                <Input
                                    name="elr3StartMileage"
                                    label="From Miles"
                                    type="number"
                                    min={values.elrs?.elrSSM3?.startmiles}
                                    max={values.elrs?.elrSSM3?.endmiles}
                                    step="0.0001"
                                    value={values.elrs?.elr3StartMileage}
                                    onChange={handleInputChange}
                                    // fieldsErrors={fieldsErrors}
                                    disabled={!selectElr}
                                />
                                <Input
                                    name="elr3EndMileage"
                                    label="To Miles"
                                    step="0.0001"
                                    type="number"
                                    min={values.elrs?.elrSSM3?.startmiles}
                                    max={values.elrs?.elrSSM3?.endmiles}
                                    value={values.elrs?.elr3EndMileage}
                                    onChange={handleInputChange}
                                    // fieldsErrors={fieldsErrors}
                                    disabled={!selectElr}
                                />
                            </div>
                            <div className={"input__wrap input_elr_mileage"}>
                                <ReactSelect
                                    name="elrSSM4"
                                    label="ELR 4"
                                    placeholder="Select ELR"
                                    valueLabel={["elr", "description"]}
                                    data={filteredElr}
                                    value={values.elrs?.elrSSM4}
                                    isDisabled={!selectElr}
                                    onChange={handleSelectInputChange}
                                />
                                <Input
                                    name="elr4StartMileage"
                                    label="From Miles"
                                    type="number"
                                    min={values.elrs?.elrSSM4?.startmiles}
                                    max={values.elrs?.elrSSM4?.endmiles}
                                    step="0.0001"
                                    value={values.elrs?.elr4StartMileage}
                                    onChange={handleInputChange}
                                    // fieldsErrors={fieldsErrors}
                                    disabled={!selectElr}
                                />
                                <Input
                                    name="elr4EndMileage"
                                    label="To Miles"
                                    step="0.0001"
                                    type="number"
                                    min={values.elrs?.elrSSM4?.startmiles}
                                    max={values.elrs?.elrSSM4?.endmiles}
                                    value={values.elrs?.elr4EndMileage}
                                    onChange={handleInputChange}
                                    // fieldsErrors={fieldsErrors}
                                    disabled={!selectElr}
                                />
                            </div>
                            <div className={"input__wrap input_elr_mileage"}>
                                <ReactSelect
                                    name="elrSSM5"
                                    label="ELR 5"
                                    placeholder="Select ELR"
                                    valueLabel={["elr", "description"]}
                                    data={filteredElr}
                                    value={values.elrs?.elrSSM5}
                                    isDisabled={!selectElr}
                                    onChange={handleSelectInputChange}
                                />
                                <Input
                                    name="elr5StartMileage"
                                    label="From Miles"
                                    type="number"
                                    min={values.elrs?.elrSSM5?.startmiles}
                                    max={values.elrs?.elrSSM5?.endmiles}
                                    step="0.0001"
                                    value={values.elrs?.elr5StartMileage}
                                    onChange={handleInputChange}
                                    // fieldsErrors={fieldsErrors}
                                    disabled={!selectElr}
                                />
                                <Input
                                    name="elr5EndMileage"
                                    label="To Miles"
                                    step="0.0001"
                                    type="number"
                                    min={values.elrs?.elrSSM5?.startmiles}
                                    max={values.elrs?.elrSSM5?.endmiles}
                                    value={values.elrs?.elr5EndMileage}
                                    onChange={handleInputChange}
                                    // fieldsErrors={fieldsErrors}
                                    disabled={!selectElr}
                                />
                            </div>
                        </div>
                        <div className="search-form__section">
                            <h3>Hazard Code Include/Exclude</h3>
                            <p>
                                To include/Exclude Hazard Codes. By Default All are Included.
                            </p>
                            <div className={"ssm-search-form__section__checkbox"}>
                                <Checkbox
                                    name="excludeHazardCode"
                                    value="exHazardCode"
                                    label="Exclude Hazard Code(s)"
                                    checked={values?.excHazardCodes}
                                    onChange={handleCheckboxChange}

                                />
                                <Checkbox
                                    name="includeHazardCode"
                                    value="incHazardCode"
                                    label="Include Hazard Code(s)"
                                    checked={values?.incHazardCodes}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                            <div className={"filter-form__group fields-wrapper"}>
                                <ReactSelect
                                    ref={hazardCodesRef}
                                    name="hazardCodes"
                                    label="Hazard Code"
                                    placeholder="Select Hazard Code"
                                    valueLabel={["hazardCode", "description"]}
                                    data={hazardCodes}
                                    value={values?.hazardCodes}
                                    onChange={handleSelectInputChange}
                                    isMulti={true}
                                    isDisabled={!selectHazardCodes}
                                    required={selectHazardCodes}
                                    fieldsErrors={fieldsErrors}
                                />
                            </div>
                        </div>
                        <div className="button-group filter-form__submit">
                            <Button action="secondary" label="Reset" onClick={handleReset}/>
                            <Button action="primary" label="Export"
                                    onClick={() => handleSubmit(true)}/>

                        </div>
                        {error && (
                            <div className="form-error">
                                <span className="input__error">{error}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SSMDashboardPage;