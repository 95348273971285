import React, {useEffect, useState} from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import Button from "../../components/Button.js";
import ReactTable from "../../components/ReactTable/ReactTable.js";
import GeneralService from "../../services/general.service.js";
import {useNavigate} from "react-router-dom";

function HazardCodeManagementPage(props) {
  const [hazardCodes, setHazardCodes] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [userFile, setUserFile] = useState([]);
  let navigate = useNavigate();
  const [sortOptions, setSortOptions] = useState({accessor: '', direction: ''});

  useEffect(() => {
    GeneralService.getHazardCodesPaged({ page: 0, pageSize: 10 })
      .then((response) => {
        setHazardCodes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (hazardCodes?.results.length > 0) {
      handleSubmit(undefined, undefined, sortOptions);
    }
  }, [sortOptions])

  const handlePageSize = (val) => {
    setPage(0);
    setPageSize(val);
  };

  function handleSubmit(pageVal, pageSizeVal) {
    let params = {};
    let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
    let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
    params.page = pageNumber;
    params.pageSize = pageSizeNumber;
    GeneralService.getHazardCodesPaged(params, sortOptions)
      .then((response) => {
        setHazardCodes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleEdit = (e, row) => {
      navigate("/adminedithazardtype", {
          state: { data: row.row?.original, pageTitle: "Edit Hazard Code" },
      });
  };

  const handleTableSort = (accessor) => {
    let direction = sortOptions?.direction;

    if (!direction) {
      direction = 'dsc';
    } else if (direction === 'dsc') {
      direction = 'asc';
    } else {
      direction = '';
    }

    setSortOptions({
      accessor : accessor,
      direction : direction
    });
  };

  return (
    <div className="page" id="dataManagementPage">
      <Header title="Hazard Code Management" />
      <BackButton
        setPage={props.setPage}
        target={["adminDashPage", "backward"]}
      />

      <h2>Hazard Types</h2>
      {hazardCodes != null && hazardCodes?.results?.length > 0 &&
      <ReactTable
        tableData={hazardCodes?.results}
        currentPage={hazardCodes?.pageNumber}
        totalPages={hazardCodes?.totalPages}
        pageSize={hazardCodes?.pageSize}
        totalElements={hazardCodes?.totalElements}
        showColumns={hazardCodes?.headers}
        customHeader={true}
        customSearch={true}
        setPage={(val) => {
          setPage(val);
          handleSubmit(val, undefined);
        }}
        setPageSize={(val) => {
          handlePageSize(val);
          handleSubmit(0, val);
        }}
        editAction={(e, row) => {
          handleEdit(e, row);
        }}
        sortOptions={sortOptions}
        setSortOptions={setSortOptions}
        handleTableSort={handleTableSort}
      ></ReactTable>
      }

      <div className="button-group">
        <Button
          action="primary"
          label="Add New Hazard Code"
          onClick={() => navigate("/adminaddhazardtype", {
            state: {pageTitle: "Add New Hazard Code"}
          })}
        />
      </div>
    </div>
  );
}

export default HazardCodeManagementPage;
