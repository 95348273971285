import axios from "../_helpers/axiosinstance";

const getHazardsInHolding = async (values, pageSize, pageNumber, sortOptions) => {
    let sortBy = undefined;
    let sortOrder = undefined;

    if (sortOptions?.accessor) {
        sortBy = sortOptions.accessor;
    } else if (values?.sortBy) {
        sortBy = values.sortBy;
    }

    if (sortOptions?.direction) {
        sortOrder = sortOptions.direction;
    } else if (values?.sortOrder) {
        sortOrder = values.sortOrder;
    }

    const response = axios.post(
        `${process.env.REACT_APP_nhd_hazard_search_ingress}/nhd/api/hazard/hazardsInHolding`,
        {
            page: pageNumber === 0 ? 0 : pageNumber || 0,
            pageSize: pageSize === 0 ? 0 : pageSize || 10,
            sortBy: sortBy || undefined,
            sortOrder: sortOrder || undefined,
            route: values?.route?.zonecode || undefined,
            mdu: values?.mdu.subzonecode || undefined,
        },
    );
    return response;
}

const notifySubmitter = async (hazardId) => {
    const response = axios.post(
        `${process.env.REACT_APP_nhd_hazard_search_ingress}/nhd/api/hazard/notifySubmitter`,
        {
            hazardId: hazardId,
        },
        {
            requiresIdentityId: true,
        }
    );
    return response;
}

const exportHazardsHolding = async (values, pageSize, pageNumber) => {
    const response = await axios.post(`${process.env.REACT_APP_nhd_hazard_search_ingress}/nhd/api/hazard/hazardsInHolding/export`,
    {
        page: pageNumber === 0 ? 0 : pageNumber || 0,
        pageSize: pageSize === 0 ? 0 : pageSize || 10,
        route: values?.route?.zonecode || undefined,
        mdu: values?.mdu.subzonecode || undefined,
    },
    {
        requiresIdentityId: true,
        responseType: "blob"
    });
    return response;
}

export const HazardsInHoldingReportService = {
    getHazardsInHolding,
    notifySubmitter,
    exportHazardsHolding
}