import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import BackButton from "../../components/BackButton";
import {InactiveHazardsService} from "../../services/inactiveHazards.service";
import ReactTable from "../../components/ReactTable/ReactTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus";
import {faEnvelope, faUndo} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

function InactiveHazards(props) {

    const navigate = useNavigate();
    const [hazards, setHazards] = useState();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sortOptions, setSortOptions] = useState({accessor: '', direction: ''});

    function getInactiveHazards(pageNumber, pageSize, totalElements) {
        setLoading(true);
        InactiveHazardsService.getAllInactiveHazards(pageSize, pageNumber, sortOptions, totalElements)
            .then((response) => {
                setHazards(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        getInactiveHazards();
    }, []);

    useEffect(() => {
        if (hazards?.results.length > 0) {
            handleSubmit(undefined, undefined, sortOptions);
        }
    }, [sortOptions])

    const handlePageSize = (val) => {
        setPage(0);
        setPageSize(val);
    };

    const handleTableSort = (accessor) => {
        let direction = sortOptions?.direction;

        if (!direction) {
            direction = 'dsc';
        } else if (direction === 'dsc') {
            direction = 'asc';
        } else {
            direction = '';
        }

        setSortOptions({
            accessor : accessor,
            direction : direction
        });
    };

    function handleSubmit(pageVal, pageSizeVal) {
        let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
        let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
        let totalElements = 0;
        if (pageNumber !== 0) {
            totalElements = hazards?.totalElements
        }
        getInactiveHazards(pageNumber, pageSizeNumber, totalElements);
    }

    const handleEdit = (e, row) => {
        navigate(`/deletehazard/${row.row.original.hazardID}`, {
            state: {
                hazardLive: false,
                hazardInactive: true,
                actionType: "re-instate",
                pageTitle: "Re-instate Hazard"
            },
        });
    }

    return (
        <div className="page" id="deletedHazardsPage">
            <Header title="Inactive/Deleted Hazards" />
            <BackButton
                setPage={props.setPage}
                target={["adminDashPage", "backward"]}
            />

            <h2>Inactive Hazards</h2>
            {hazards?.results && hazards?.results?.length > 0 ? (
                <div>
                    <ReactTable
                        tableData={hazards?.results}
                        showColumns={hazards?.headers}
                        customHeader={true}
                        customSearch={true}
                        currentPage={hazards?.pageNumber}
                        totalPages={hazards?.totalPages}
                        pageSize={hazards?.pageSize}
                        totalElements={hazards?.totalElements}
                        setPage={(val) => {
                            setPage(val);
                            handleSubmit(val, undefined)
                        }}
                        setPageSize={(val) => {
                            handlePageSize(val);
                            handleSubmit(0, val);
                        }}
                        editHeader={"Re-instate Hazard"}
                        editAction={(e, row) => {
                            handleEdit(e, row);
                        }}
                        editIcon={faUndo}
                        sortOptions={sortOptions}
                        setSortOptions={setSortOptions}
                        handleTableSort={handleTableSort}
                    />
                </div>
                ) : <div className="loadingCircle">
                No Results Based On Your Search Criteria
                <span style={{marginLeft: "10px"}}> <FontAwesomeIcon icon={faMagnifyingGlassMinus}/> </span>
        </div>
                }

        </div>
    );

}

export default InactiveHazards;