import React, {useRef} from "react";
import Button from "../Button";
import BouncingDotsLoader from "../Loader/BouncingDotsLoader";
import "./Modal.css";

export const Modal = ({ setShowModal, handleDeleteHazard, loading }) => {
  // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };

  // render the modal JSX in the portal div.
  return (
    <div className="portal__container" ref={modalRef} onClick={closeModal}>
      <div className="modal">
        {/* <h3>Are you sure you wish to delete this item?</h3> */}
        <p>Are you sure you wish to delete this item?</p>
        {!loading && (
          <div className="button-group">
            <Button
              action="primary"
              className="button--warning"
              onClick={handleDeleteHazard}
            >
              Delete
            </Button>
            <Button
              action="secundary"
              // className="button--alert"
              // onClick={handleAcceptHazard}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        )}
        {loading && <BouncingDotsLoader />}
        {/* <button onClick={() => setShowModal(false)}>X</button> */}
      </div>
    </div>
  );
};
