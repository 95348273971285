import React from "react";
import Header from "../components/Header.js";
import Button from "../components/Button.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faAward,
    faClipboardList,
    faExclamationTriangle,
    faFaucet,
    faHardHat,
    faLeaf,
    faLink,
    faMobileAlt,
    faPeopleRobbery,
    faPersonDigging,
    faSubway,
    faTrafficLight,
    faTree
} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

function GeneralInfoPage(props) {
  let navigate = useNavigate();

  function goToPage() {
    window.open("https://www.rssb.co.uk/standards", "_blank");
  }

  return (
    <div className="page" id="generalInfoPage">
      <Header title="General Information" />
      <div className="card__wrap">
        <div className="card card--width20">
          <FontAwesomeIcon icon={faSubway} className="card__icon" />
          <h3 className="card__title">Railway Station Directory</h3>
          <p className="card__description">
            Search for Railway Stations throughout the UK. Detailed by name,
            route, elr, mileage, operator, and station type.
          </p>
          <Button
            action="round"
            label="Station Directory"
            onClick={() => navigate("/stationdirectory")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="card__icon"
          />
          <h3 className="card__title">Generic Hazards</h3>
          <p className="card__description">
            Information about general hazards you may encounter, such as
            asbestos, dangerous plants, and hazardous substances.
          </p>
          <Button
            action="round"
            label="Generic Hazards"
            onClick={() => navigate("/generichazards")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faTrafficLight} className="card__icon" />
          <h3 className="card__title">Signal Wire Degradation</h3>
          <p className="card__description">
            Generic signal wire degradation instructions, working in signaling
            equipment rooms, and apparatus cases.
          </p>
          <Button
            action="round"
            label="Signal Wire Degradation"
            onClick={() => navigate("/signalwiredeg")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faHardHat} className="card__icon" />
          <h3 className="card__title">Safety Hazard Codes</h3>
          <p className="card__description">
            Safety Hazard Codes relating to data contained in the National Hazard Directory.
          </p>
          <Button
            action="round"
            label="Safety Hazard Codes"
            onClick={() => navigate("/safetyhazardcodes")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faTree} className="card__icon" />
          <h3 className="card__title">Environmental Hazard Codes</h3>
          <p className="card__description">
            Environmental asset types relating to data contained in the National
            Hazard Directory.
          </p>
          <Button
            action="round"
            label="Environmental Hazard Codes"
            onClick={() => navigate("/environmentalhazardcodes")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faLeaf} className="card__icon" />
          <h3 className="card__title">Green Zone</h3>
          <p className="card__description">
            Green Zone Availability Information. Information on Green Zone
            working opportunities.
          </p>
          <Button
            action="round"
            label="Green Zone"
            onClick={() => navigate("/greenzone")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faLink} className="card__icon" />
          <h3 className="card__title">Useful Links</h3>
          <p className="card__description">
            Rail information on other sites and services. Including Network Rail
            and National Rail Enquiries.
          </p>
          <Button
            action="round"
            label="Useful Links"
            onClick={() => navigate("/usefullinks")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faAward} className="card__icon" />
          <h3 className="card__title">Standards</h3>
          <p className="card__description">
            Visit the RSSB website for information on standards developed and
            maintained by RSSB. Opens in a new tab.
          </p>
          <Button action="round" label="Standards" onClick={goToPage}>
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faFaucet} className="card__icon" />
          <h3 className="card__title">Buried Services</h3>
          <p className="card__description">
            Where work is to be carried out that will cause ground disturbance a
            request for Buried Services Information must be made.
          </p>
          <Button
            action="round"
            label="Buried Services"
            onClick={() => navigate("/buriedservices")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faPeopleRobbery} className="card__icon" />
          <h3 className="card__title">Crime Hotspots</h3>
          <p className="card__description">Hotspot Route Crime</p>
          <Button
            action="round"
            label="Crime Hotspots"
            onClick={() => navigate("/crimehotspots")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faPersonDigging} className="card__icon" />
          <h3 className="card__title">High Risk Earth Works</h3>
          <p className="card__description">High Risk Earth Works</p>
          <a href="./pdfs/Erth_wrk.pdf" target="_blank">
            <Button action="round" label="High Risk Earth Works">
              <FontAwesomeIcon icon="fa-solid " />
              <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
            </Button>
          </a>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faMobileAlt} className="card__icon" />
          <h3 className="card__title">Mobile Phone Coverage</h3>
          <p className="card__description">
            Track-side mobile telephone service coverage information and
            guidance. Includes links to some mobile network providers.
          </p>
          <Button
            action="round"
            label="Mobile Phone Coverage"
            onClick={() => navigate("/mobilephonecoverage")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faClipboardList} className="card__icon" />
          <h3 className="card__title">Hazard Notification Form</h3>
          <p className="card__description">
            Paper version of the National Hazard Directory – Safety and
            environmental hazard notification form.
          </p>
          <Button
            action="round"
            label="Hazard Notification Form"
            onClick={() => navigate("/hazardnotificationform")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <span className="card--width20"></span>
        <span className="card--width20"></span>
      </div>
    </div>
  );
}

export default GeneralInfoPage;
