import axios from "../_helpers/axiosinstance";
import * as url from "url";

const getWhsesa = async (params) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_dashboard_service_ingress}/nhd/api/dashboard-data/whsea-dashboard`,
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const getWhseaReviewDate = async (values, pageNumber, pageSize, sortOptions) => {
  let sortBy = undefined;
  let sortOrder = undefined;

  if (sortOptions?.accessor) {
      sortBy = sortOptions.accessor;
  }

   if (sortOptions?.direction) {
      sortOrder = sortOptions.direction;
  }
  const response = await axios.post(
      `${process.env.REACT_APP_nhd_dashboard_service_ingress}/nhd/api/dashboard-data/whsea-dashboard/reviewDate`,
      {
          zonecode: values?.route?.zonecode || undefined,
          elr: values?.elr?.elr || undefined,
          subzonecode: values?.mdu?.subzonecode || undefined,
          date_range: values?.startDate !== undefined ? {
              start_date: values?.startDate || undefined,
              end_date: values?.endDate || undefined,
          } : undefined,
          page: pageNumber !== undefined ? pageNumber : 0,
          pageSize: pageSize !== undefined ? pageSize : 10,
          sortBy: sortBy || undefined,
          sortOrder: sortOrder || undefined
      },
      {
        requireIdentityId: true,
      }
  );
  return response;
}

const getSSM = async (params) => {
  axios.defaults.baseURL = process.env.REACT_APP_nhd_dashboard_service_ingress;
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_dashboard_service_ingress}/nhd/api/dashboard-data/ssm-dashboard`,
    {
      requiresIdentityId: true,
    }
  );
  // const data = handleResponse(response);
  // return data;
  return response;
};

const getSSMPriorityHazards = async (page, pageSize, sortOptions) => {
    let sortBy = undefined;
    let sortOrder = undefined;

    if (sortOptions?.accessor) {
        sortBy = sortOptions.accessor;
    }

    if (sortOptions?.direction) {
        sortOrder = sortOptions.direction;
    }

    const response = await axios.post(
        `${process.env.REACT_APP_nhd_dashboard_service_ingress}/nhd/api/dashboard-data/ssm-priority`,
        {
            page: page !== undefined ? page : 0,
            pageSize: pageSize !== undefined ? pageSize : 10,
            sortBy: sortBy || undefined,
            sortOrder: sortOrder || undefined
        },
        {
            requiresIdentityId: true,
        }
    );
    return response;
}

const getSSMHazards = async (data, csv) => {
    // let sortBy = undefined;
    // let sortOrder = undefined;
    let url = `${process.env.REACT_APP_nhd_dashboard_service_ingress}/nhd/api/dashboard-data/ssm-hazards`;
    let config = {
        requiresIdentityId: true,
    }

    // if (sortOptions?.accessor) {
    //     sortBy = sortOptions.accessor;
    // }
    //
    // if (sortOptions?.direction) {
    //     sortOrder = sortOptions.direction;
    // }

    if (data?.elrs?.elrs) {
        data.elrs.elrs = Object.fromEntries(data.elrs.elrs);
    }

    if (csv) {
        url = url + "/export";
        config = {
            requiresIdentityId: true,
            responseType: "blob"
        }
    }

    const response = await axios.post(
        url,
        {
            // page: page !== undefined ? page : 0,
            // pageSize: pageSize !== undefined ? pageSize : 10,
            // sortBy: sortBy || undefined,
            // sortOrder: sortOrder || undefined,
            hazardCodes: data?.hazardCodes || undefined,
            elrs: data?.elrs || undefined
        },
        config
    );
    return response;
}

const DashboardService = {
  getWhsesa,
  getWhseaReviewDate,
  getSSM,
  getSSMPriorityHazards,
  getSSMHazards
};

export default DashboardService;
