import React, {useEffect, useRef, useState} from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import Button from "../../components/Button.js";
import "react-dates/initialize";
import {DateRangePicker} from "react-dates";
import moment from "moment";
import Responsive from "react-responsive";
import ReactSelect from "../../components/Select/ReactSelect.jsx";
import GeneralService from "../../services/general.service";
import {UserService as userService} from "../../services/user.service";
import ReactTable from "../../components/ReactTable/ReactTable";
import downloadFile from "../../_helpers/downloadFile";

function AdminReportsPage(props) {
  const initialVals = props.initialVals
    ? props.initialVals
    : {
        route: [],
        route2: [],
        mdu: [],
        mdu2: [],
        role: [],
        startDate: moment(),
        endDate: moment(),
      };

  const [values, setValues] = useState(initialVals);
  const [routes, setRoutes] = useState();
  const [filteredMdu, setFilteredMdu] = useState();
  const [nonFilteredMdu, setNonFilteredMdu] = useState();
  const [filteredMdu2, setFilteredMdu2] = useState();
  const [nonFilteredMdu2, setNonFilteredMdu2] = useState();
  const [focused, setFocused] = useState();
  const [usersActivityReport, setUsersActivityReport] = useState();
  const [usersPermissionReport, setUsersPermissionReport] = useState();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const [roles, setRoles] = useState();
  const [elrMdus1, setElrMdus1] = useState(new Map());
  const [elrMdus2, setElrMdus2] = useState(new Map());
  const routeSelectInput = useRef(null);
  const mduSelectInput = useRef(null);
  const route2SelectInput = useRef(null);
  const mdu2SelectInput = useRef(null);
  const roleSelectInput = useRef(null);

  useEffect(async () => {
    if (routes === undefined) {
      await GeneralService.getRoutes()
          .then((response) => {
            setRoutes(response.data);
          })
          .catch((error) => {
            // setRoutes("error");
            console.log(error);
          });
    }
  }, [routes]);

  function handleInputChange(e, name) {
    if (e === null) {
      return;
    }
    if (e._isAMomentObject) {
      setValues((values) => ({ ...values, [name]: e }));
    } else {
      setValues((values) => ({ ...values, [e.target.name]: e.target.value }));
    }
  }

  function filteredMDU(value, name) {
    console.log('filter');
    if (name === "route") {
      setFilteredMdu(["loading..."]);
    } else {
      setFilteredMdu2(["loading..."])
    }
    // setValues((values) => ({
    //   ...values,
    // }));
    let zoneCode = [];
    if (value) {
      for (let i = 0; i < value.length; i++) {
        zoneCode[i] = value[i].zonecode;
      }
    }if (zoneCode?.length > 0) {
      GeneralService.getZonecodeMDUSubzonecode({
        zonecode: zoneCode,
      }).then((response) => {
        let mdus = response.data;
        if (name === "route" || name === "mdu1") {
          const notFiltered = [...response.data];
          setNonFilteredMdu(notFiltered);
          if (elrMdus1.size !== 0) {
            var elrMduKeys = Array.from(elrMdus1.keys());
            for (let i = 0; i < elrMduKeys.length; i++) {
              var entry = elrMdus1.get(elrMduKeys[i]);
              if (entry.length > 0) {
                for (let j = 0; j < entry.length; j++) {
                  for (let k = 0; k < mdus.length; k++) {
                    if (entry[j]._id === mdus[k]._id) {
                      mdus.splice(k, 1);
                    }
                  }
                }
              }
            }
          }
          setFilteredMdu(mdus);
        } if (name === "route2" || name === "mdu2") {
          const notFiltered = [...response.data];
          setNonFilteredMdu2(notFiltered);
          if (elrMdus2.size !== 0) {
            var elrMduKeys2 = Array.from(elrMdus2.keys());
            for (let i = 0; i < elrMduKeys2.length; i++) {
              var entry2 = elrMdus2.get(elrMduKeys2[i]);
              if (entry2.length > 0) {
                for (let j = 0; j < entry2.length; j++) {
                  for (let k = 0; k < mdus.length; k++) {
                    if (entry2[j]._id === mdus[k]._id) {
                      mdus.splice(k, 1);
                    }
                  }
                }
              }
            }
          }
          setFilteredMdu2(mdus);
        }
      }).catch((error) => {
        setFilteredMdu();
      })
    }
  }

  const handleInputChangeG = (value, name, actionType) => {
    console.log(value, name, actionType);
  
    if (actionType?.action === "clear") {
      if (name === "role") {
        setValues((values) => ({
          ...values,
          [name]: "",
        }));
      } else if (name.startsWith("mdu")) {
        if (name === "mdu1") {
          setValues((values) => ({
            ...values,
            mdu: initialVals.mdu,
          }));
          elrMdus1.clear();
          setFilteredMdu(nonFilteredMdu);
        } else if (name === "mdu2") {
          setValues((values) => ({
            ...values,
            mdu2: initialVals.mdu2,
          }));
          elrMdus2.clear();
          setFilteredMdu2(nonFilteredMdu2);
        }
      } else if (name === "route") {
        setValues((values) => ({
          ...values,
          [name]: value,
          mdu: initialVals.mdu,
        }));
        elrMdus1.clear();
        setFilteredMdu();
      } else if (name === "route2") {
        setValues((values) => ({
          ...values,
          [name]: value,
          mdu2: initialVals.mdu2,
        }));
        elrMdus2.clear();
        setFilteredMdu2();
      }
    }
  
    // Handle remove-value and pop-value (backspace)
    if (actionType?.action === "remove-value" || actionType?.action === "pop-value") {
      let route = routes.filter((r) => r.zonecode === actionType.removedValue?.value.zonecode);
      let key = route[0]?.zonecode + ", " + route[0]?.zonedescription;
  
      if (name === "mdu1" || name === "route") {
        if (elrMdus1.has(key)) {
          if (name === "mdu1") {
            name = "mdu";
            let keyValue = elrMdus1.get(key);
            keyValue = keyValue?.filter((v) => v.subzonecode !== actionType.removedValue.value.subzonecode);
            elrMdus1.set(key, keyValue);
  
            setValues((values) => ({
              ...values,
              [name]: value,
            }));
  
            if (!filteredMdu.includes(actionType.removedValue.value)) {
              let mdus = [...filteredMdu];
              mdus.push(actionType.removedValue.value);
              mdus.sort((a, b) => a.zonecode.localeCompare(b.zonecode));
              setFilteredMdu(mdus);
            }
          } else {
            let mdus = [];
            elrMdus1.delete(key);
            elrMdus1.forEach((element) => {
              element.forEach((mdu) => mdus.push(mdu));
            });
  
            setValues((values) => ({
              ...values,
              [name]: value,
              mdu: mdus,
            }));
  
            if (name === "route") {
              filteredMDU(value, name);
            }
          }
        } else {
          setValues((values) => ({
            ...values,
            [name]: value,
          }));
          filteredMDU(value, name);
        }
      }
  
      if (name === "mdu2" || name === "route2") {
        if (elrMdus2.has(key)) {
          if (name === "mdu2") {
            let keyValue = elrMdus2.get(key);
            keyValue = keyValue?.filter((v) => v.subzonecode !== actionType.removedValue.value.subzonecode);
            elrMdus2.set(key, keyValue);
  
            setValues((values) => ({
              ...values,
              [name]: value,
            }));
  
            if (!filteredMdu2.includes(actionType.removedValue.value)) {
              let mdus2 = [...filteredMdu2];
              mdus2.push(actionType.removedValue.value);
              mdus2.sort((a, b) => a.zonecode.localeCompare(b.zonecode));
              setFilteredMdu2(mdus2);
            }
          } else {
            let mdus2 = [];
            elrMdus2.delete(key);
            elrMdus2.forEach((element) => {
              element.forEach((mdu) => mdus2.push(mdu));
            });
  
            setValues((values) => ({
              ...values,
              mdu2: mdus2,
              [name]: value,
            }));
  
            if (name === "route2") {
              filteredMDU(value, name);
            }
          }
        } else {
          setValues((values) => ({
            ...values,
            [name]: value,
          }));
          filteredMDU(value, name);
        }
      }
    }
  
    if (actionType?.action === "select-option") {
      let mdus = [];
      if (name === "mdu1") {
        name = "mdu";
        if (actionType.option.value === "*") {
          elrMdus1.clear();
          let keys = values.route.map(route => route.zonecode + ", " + route.zonedescription);
          keys.forEach(key => {
            let data = nonFilteredMdu.filter(mdu => mdu.zonecode === key.split(",")[0]);
            elrMdus1.set(key, data);
            mdus = [...mdus, ...data];
          });
        } else {
          mdus = values.mdu ? [...values.mdu] : [];
          let route = values.route.filter((r) => r.zonecode === actionType.option.value.zonecode);
          let key1 = route[0].zonecode + ", " + route[0].zonedescription;
          if (elrMdus1.has(key1)) {
            let value = elrMdus1.get(key1);
            value.push(actionType.option.value);
            elrMdus1.set(key1, value);
          } else {
            elrMdus1.set(key1, [actionType.option.value]);
          }
          mdus.push(actionType.option.value);
        }
      } else if (name === "mdu2") {
        if (actionType.option.value === "*") {
          elrMdus2.clear();
          let keys = values.route2.map(route => route.zonecode + ", " + route.zonedescription);
          keys.forEach(key => {
            let data = nonFilteredMdu2.filter(mdu => mdu.zonecode === key.split(",")[0]);
            elrMdus2.set(key, data);
            mdus = [...mdus, ...data];
          });
        } else {
          mdus = values.mdu2 ? [...values.mdu2] : [];
          let route2 = values.route2.filter((r) => r.zonecode === actionType.option.value.zonecode);
          let key2 = route2[0].zonecode + ", " + route2[0].zonedescription;
          if (elrMdus2.has(key2)) {
            let value = elrMdus2.get(key2);
            value.push(actionType.option.value);
            elrMdus2.set(key2, value);
          } else {
            elrMdus2.set(key2, [actionType.option.value]);
          }
          mdus.push(actionType.option.value);
        }
      }
      setValues((values) => ({
        ...values,
        [name]: mdus,
      }));
    }
  
    if (actionType?.action !== "clear" && actionType?.action !== "remove-value" && (name === "route" || name === "route2" || name === "role")) {
      setValues((values) => ({
        ...values,
        [name]: value,
      }));
      if (name !== "role") {
        filteredMDU(value, name);
      }
    }
  };
  

  function handleSubmit() {
    let validRoute = routeSelectInput.current.validateField();
    let validMdu = mduSelectInput.current.validateField();
    if (validRoute && validMdu) {
      userService
          .userInactivityReport(values, false)
          .then((response) => {
            setUsersActivityReport(response.data);
            // setUsers(usersData);
            setLoadingUsers(false);
          })
          .catch((error) => {
            console.log(error);
            setUsersActivityReport({});
            setLoadingUsers(false);
          });
    } else {
      setError("Please check fields or errors highlighted in red, and amend the information to continue.");
    }
  }

  function handleSubmit1() {
    let validRoute = route2SelectInput.current.validateField();
    let validMdu = mdu2SelectInput.current.validateField();
    let validRole = roleSelectInput.current.validateField();
    if (validRoute && validMdu && validRole) {
      userService
          .userPermissionReport(values, false)
          .then((response) => {
            setUsersPermissionReport(response.data);
            // setUsers(usersData);
            setLoadingUsers(false);
          })
          .catch((error) => {
            console.log(error);
            setUsersPermissionReport({});
            setLoadingUsers(false);
          });
    } else {
      setError2("Please check fields or errors highlighted in red, and amend the information to continue.");
    }
  }

  function handleReset() {
    setValues((values) => ({
      ...values,
      route: initialVals.route,
      mdu: initialVals.mdu,
    }));
    setUsersActivityReport({});
    elrMdus1.clear();
    setFilteredMdu();
  }

  function handleReset1() {
    setValues((values) => ({
      ...values,
      route2: initialVals.route2,
      mdu2: initialVals.mdu2,
      role: initialVals.role,
    }));
    setUsersPermissionReport({});
    elrMdus2.clear();
    setFilteredMdu2();
  }

  //fetch roles
  useEffect(() => {
    userService
      .getUserGroups()
      .then((response) => {
        console.log(response.data);
        setRoles(response.data);
      })
      .catch((error) => {
        setRoles(error);
      });
  }, [values?.role]);

  const handleExportActivity = () => {
    userService
        .userInactivityReport(values, true)
        .then((response) => {
          downloadFile(response);
        })
        .catch((error) => {
          console.log(error);
    });

  };

  const handleExportPermissions = () => {
    userService
        .userPermissionReport(values, true)
        .then((response) => {
          downloadFile(response);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  return (
    <div className="page" id="adminReportsPage">
      <Header title="Admin Reports" />
      <BackButton
        setPage={props.setPage}
        target={["adminDashPage", "backward"]}
      />

      <div className="admin-report-filter">
        <h2 className="admin-report-filter__title">User Activity Report</h2>
        <ReactSelect
          ref={routeSelectInput}
          name="route"
          label="Route"
          placeholder="Select Route"
          valueLabel={["zonedescription"]}
          data={routes}
          onChange={handleInputChangeG}
          value={values.route}
          required={true}
          isMulti={true}
          selectAll={true}
        />
        <ReactSelect
          ref={mduSelectInput}
          isMulti={true}
          name="mdu1"
          label="MDU"
          placeholder="Select MDU"
          valueLabel={["subzonecode", "description"]}
          data={filteredMdu || props.mduList}
          loading={filteredMdu && filteredMdu[0] === "loading..."}
          onChange={handleInputChangeG}
          value={values?.mdu}
          required={true}
          selectAll={true}
        />

        <div className="input__wrap input__wrap--double">
          <label className="input__label">Date:</label>
          <Mobile>
            <DateRangePicker
              isOutsideRange={() => false}
              startDate={values.startDate}
              startDateId="start-date"
              endDate={values.endDate}
              endDateId="end-date"
              onDatesChange={({ startDate, endDate }) => {
                handleInputChange(startDate, "startDate");
                handleInputChange(endDate, "endDate");
              }}
              focusedInput={focused}
              onFocusChange={(focused) => setFocused(focused)}
              displayFormat={() => "DD MMM YYYY"}
              showDefaultInputIcon={true}
              block={true}
              keepOpenOnDateSelect={true}
              numberOfMonths={1}
              required={true}
            />
          </Mobile>
          <Default>
            <DateRangePicker
              isOutsideRange={() => false}
              minDate={moment("2022-01-01")}
              startDate={values.startDate}
              startDateId="start-date"
              endDate={values.endDate}
              endDateId="end-date"
              onDatesChange={({ startDate, endDate }) => {
                handleInputChange(startDate, "startDate");
                handleInputChange(endDate, "endDate");
              }}
              focusedInput={focused}
              onFocusChange={(focused) => setFocused(focused)}
              displayFormat={() => "DD MMMM YYYY"}
              showDefaultInputIcon={true}
              block={true}
              keepOpenOnDateSelect={true}
              required={true}
            />
          </Default>
        </div>

        <div className="button-group">
          <Button action="secondary" label="Reset" onClick={handleReset} />
          <Button action="primary" label="Submit" onClick={handleSubmit} />
          {error && !loadingUsers && (
              <div className="form-error">
                <span className="input__error">{error}</span>
              </div>
          )}
        </div>
        <div style={{ width: "100%" }}>
          {!loadingUsers &&
            usersActivityReport !== "error" &&
            usersActivityReport?.results?.length > 0 && (
              <>
                <div className="button-group">
                  <Button action="secondary" label="Export CSV" onClick={handleExportActivity} />
                </div>
                <ReactTable
                    tableData={usersActivityReport?.results}
                    showColumns={usersActivityReport?.headers}
                    customHeader={true}
                    customSearch={true}
                    currentPage={usersActivityReport?.pageNumber}
                    totalPages={usersActivityReport?.totalPages}
                    pageSize={usersActivityReport?.pageSize}
                    totalElements={usersActivityReport?.totalElements}
                ></ReactTable>
              </>
            )}
          {!loadingUsers &&
            Array.isArray(usersActivityReport) &&
            usersActivityReport?.length === 0 && (
              <>
                <h2>No users found</h2>
              </>
            )}
          {!loadingUsers && usersActivityReport == "error" && (
            <>
              <h2>Unable to fetch users</h2>
            </>
          )}
        </div>
      </div>

      <div className="admin-report-filter">
        <h2 className="admin-report-filter__title">User Permissions Report</h2>
        <ReactSelect
          ref={route2SelectInput}
          name="route2"
          label="Route"
          placeholder="Select Route"
          valueLabel={["zonedescription"]}
          data={routes}
          onChange={handleInputChangeG}
          value={values.route2}
          required={true}
          isMulti={true}
          selectAll={true}
        />
        <ReactSelect
          ref={mdu2SelectInput}
          isMulti={true}
          name="mdu2"
          label="MDU"
          placeholder="Select MDU"
          valueLabel={["subzonecode", "description"]}
          data={filteredMdu2 || props.mduList}
          loading={filteredMdu2 && filteredMdu2[0] === "loading..."}
          onChange={handleInputChangeG}
          value={values?.mdu2}
          required={true}
          selectAll={true}
        />
        <ReactSelect
          ref={roleSelectInput}
          name="role"
          label="Role"
          placeholder="Select Role"
          valueLabel={["group_name"]}
          data={roles}
          loading={roles && roles[0] === "loading..."}
          onChange={handleInputChangeG}
          value={values?.role}
          required={true}
        />
        <div className="button-group">
          <Button action="secondary" label="Reset" onClick={handleReset1} />
          <Button action="primary" label="Submit" onClick={handleSubmit1} />
          {error2 && !loadingUsers && (
              <div className="form-error">
                <span className="input__error">{error}</span>
              </div>
          )}
        </div>
        <div style={{ width: "100%" }}>
          {!loadingUsers &&
            usersPermissionReport !== "error" &&
            usersPermissionReport?.results?.length > 0 && (
              <>
                <div className="button-group">
                  <Button action="secondary" label="Export CSV" onClick={handleExportPermissions} />
                </div>
                <ReactTable
                    tableData={usersPermissionReport?.results}
                    showColumns={usersPermissionReport?.headers}
                    customHeader={true}
                    customSearch={true}
                    currentPage={usersPermissionReport?.pageNumber}
                    totalPages={usersPermissionReport?.totalPages}
                    pageSize={usersPermissionReport?.pageSize}
                    totalElements={usersPermissionReport?.totalElements}
                ></ReactTable>
              </>
            )}
          {!loadingUsers &&
            Array.isArray(usersPermissionReport) &&
            usersPermissionReport?.length == 0 && (
              <>
                <h2>No users found</h2>
              </>
            )}
          {!loadingUsers && usersPermissionReport == "error" && (
            <>
              <h2>Unable to fetch users</h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminReportsPage;

export const Mobile = (props) => <Responsive {...props} maxWidth={767} />;
export const Default = (props) => <Responsive {...props} minWidth={768} />;
