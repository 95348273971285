import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faUpload} from "@fortawesome/free-solid-svg-icons";

function Upload(props) {
  const [error, setError] = useState();
  let classProps = ["upload__wrap"];
  if (props.disabled) {
    classProps.push("upload__wrap--disabled");
  }
  if (props.className) {
    classProps.push(props.className);
  }

  let inputProps = {
    type: "file",
    className: "upload",
    id: props.id ? props.id : props.name,
    name: props.name,
  };
  if (props.value) {
    inputProps.value = props.value;
  }
  if (props.disabled) {
    inputProps.disabled = props.disabled;
  }
  if (props.readOnly) {
    inputProps.readOnly = props.readOnly;
  }
  if (props.disabled) {
    inputProps.readOnly = "readonly";
  }
  if (props.required) {
    inputProps.required = props.required;
  }
  if (props.accept) {
    inputProps.accept = props.accept;
  }
  if (props.camera) {
    inputProps.capture = true;
  }
  if (props.disabled === false) {
    inputProps.onChange = (e) => {
      let map = new Map();

      if (!props.multiple) {
        let file = e.target.files[0];
        map.set(
          file.name,
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        props.setFiles(Array.from(map.values()));
        setFile(inputProps.id);
        return;
      }

      Array.from(e.target.files).forEach((item) => {
        if (
          props.files?.length +
            e.target.files.length +
            props.documents?.length >
          5
        ) {
          setError(`Total amount of files needs to be equal or less than 5`);
        } else if (item.size > 5000000) {
          setError(`Maximum file size is 5MB`);
        } else {
          setError();
          map.set(
            item.name,
            Object.assign(item, {
              preview: URL.createObjectURL(item),
            })
          );
        }
      });

      props.files && props.files.forEach((item) => map.set(item.name, item));

      let mergedArr = Array.from(map.values());
      props.setFiles(mergedArr);
      setFile(inputProps.id);
    };
  }
  if (props.multiple) {
    inputProps.multiple = props.multiple;
  }

  if (props.onClick) {
    inputProps.onClick = props.onClick;
  }

  const [file, setFile] = useState(null);

  const [title, setTitle] = useState(props.label ? props.label : props.name);

  function showFileDetails(id) {
    if (id !== null) {
      let thisFile = document.getElementById(id);
      let [file] = thisFile.files;
      let { name: fileName, size } = file;
      let fileSize = (size / 1000).toFixed(2);
      let fileNameAndSize = `${fileName} - ${fileSize}KB`;
      setTitle(fileNameAndSize);
    }
  }

  return (
    <>
      <div className={classProps.join(" ")}>
        <label
          className="upload__label"
          htmlFor={props.id ? props.id : props.name}
          style={
            props.disabled
              ? { pointerEvents: "none" }
              : { pointerEvents: "auto" }
          }
        >
          {props.camera ? (
            <FontAwesomeIcon icon={faCamera} className="upload__icon" />
          ) : (
            <FontAwesomeIcon icon={faUpload} className="upload__icon" />
          )}
          {title}
        </label>
        <input {...inputProps} />
        {error ? <span className="input__error">{error}</span> : ""}
      </div>
    </>
  );
}

export default Upload;
