import React, {useEffect, useState} from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import ResTable from "../components/ResTable.js";
import GeneralService from "../services/general.service.js";
import ReactTable from "../components/ReactTable/ReactTable.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch,} from "@fortawesome/free-solid-svg-icons";

function EnvironmentalHazardCodesPage(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    setIsLoaded(false);
    GeneralService.getHazardCodesEnvironmentPaged({ page: page, pageSize: pageSize })
      .then((response) => {
        setIsLoaded(true);
        setItems(response.data);
      })
      .catch((error) => {
        setIsLoaded(true);
        setError(error);
      });
  }, [page, pageSize]);

  // useEffect(() => {
  //   fetch("./json/environmentalHazards.json")
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         setIsLoaded(true);
  //         setItems(result);
  //       },
  //       (error) => {
  //         setIsLoaded(true);
  //         setError(error);
  //       }
  //     );
  // }, []);
  let environmantalHazards = "";
  if (error) {
    environmantalHazards = <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    environmantalHazards = <div>Loading...</div>;
  } else {
    let headers = ["Description", "Item Code"];
    environmantalHazards = <ResTable headers={headers} tableData={items} />;
  }

  return (
    <div className="page" id="environmentalHazardCodesPage">
      <Header title="Environmental Hazard Codes" />
      <BackButton navigateUrl="/generalinfo" />

      {!isLoaded && (
        <div className="loadingCircle">
          <FontAwesomeIcon icon={faCircleNotch} spin={true} />
        </div>
      )}
      {!error && items && isLoaded && (
        <div className="environmental-hazards-codes">
          <ReactTable
            tableData={items.results}
            currentPage={items?.pageNumber}
            totalPages={items?.totalPages}
            pageSize={items?.pageSize}
            totalElements={items?.totalElements}
            setPage={setPage}
            setPageSize={setPageSize}
            hideColumns={[
              "id",
            ]}
          ></ReactTable>
        </div>
      )}
    </div>
  );
}

export default EnvironmentalHazardCodesPage;
