import axios from "../_helpers/axiosinstance";

const onboarding = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_onboarding}`,
    {
      firstname: params.firstname || undefined,
      surname: params.surname || undefined,
      email: params.email || undefined,
      organisation: params.organisation || undefined,
      telNo: params.telNo || undefined,
    }
  );
  // const data = handleResponse(response);
  // return data;
  return response;
};

const OnboardingService = {
  onboarding,
};

export default OnboardingService;
