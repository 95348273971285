import React, {useEffect, useRef, useState} from "react";
import Button from "./Button.js";
import Checkbox from "./Checkbox.js";
import Input from "./Input.js";
import Textarea from "./Textarea";
import Upload from "./Upload.js";

import "react-dates/initialize";
import {SingleDatePicker} from "react-dates";
import moment from "moment";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCircleNotch,
  faEdit,
  faFile, faInfoCircle,
  faTimes,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";

import ApprovePaneEntry from "./ApprovePaneEntry.js";
import ReactSelect from "./Select/ReactSelect.jsx";
import HazardManagementService from "../services/hazard-management.service.js";
import BouncingDotsLoader from "./Loader/BouncingDotsLoader.jsx";
import {useNavigate} from "react-router-dom";
import {Modal} from "./Modal/Modal.jsx";
import ReactTable from "./ReactTable/ReactTable.js";
import generalService from "../services/general.service.js";
import {UserService as userService} from "../services/user.service";
import {faInfo} from "@fortawesome/free-solid-svg-icons/faInfo";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons/faCircleInfo";

function CreateHazardForm(props) {
  let initialVals = {
    location: "",
    routeDuElr: "",
    route: "",
    du: "",
    elr: "",
    localName: "",
    track_id: "",
    postcode: "",
    purpose_usage: "",
    easting: "",
    northing: "",
    latitude: "",
    longitude: "",
    start_mileage: "",
    end_mileage: "",
    hazardType: "",
    hazardCode: "",
    status_code: "",
    hazardCodes: props.data.hazardCodes,
    category: "",
    free_text: "",
    uploadImage: "",
    takePicture: "",
    fromSignature: "",
    fromDate: moment(new Date()),
    name: "",
    company: "",
    address1: "",
    address2: "",
    phone: "",
    email: "",
    toSignature: "",
    toDate: moment(new Date()),
    sendToAddress: "",
    previous_state: "",
    reviewDate: moment(new Date()),
  };

  const [routes, setRoutes] = useState();
  const [values, setValues] = useState(initialVals);
  const [fieldsErrors, setFieldsErrors] = useState();
  const [focused, setFocused] = useState();
  const [tfocused, settFocused] = useState();
  const [reviewDateFocused, setReviewDateFocused] = useState();
  const [openOtherHazard, setOpenOtherHazard] = useState(false);
  const [openOther, setOpenOther] = useState(false);

  const [openFilters, setOpenFilters] = useState(false);

  const [filteredElr, setFilteredElr] = useState();
  const [filteredMdu, setFilteredMdu] = useState();
  const [trackIdLookup, setTrackIdLookup] = useState();
  const [hazardCodes, setHazardCodes] = useState();
  const [statusCodes, setStatusCodes] = useState();
  const [sendToAddresses, setSendToAddresses] = useState();

  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const [files, setFiles] = useState([]);
  const [filesToRemove, setFilesToRemove] = useState([]);

  const [sendToSSMLookup, setSendToSSMLookup] = useState();
  const [showSendToSSMForm, setShowSendToSSMForm] = useState(false);
  const [ssmSentLoading, setSsmSentLoading] = useState(false);
  const [lookupWHSEASet, setlookupWHSEASet] = useState(false);

  const routeSelectInput = useRef(null);
  const elrSelectInput = useRef(null);
  const duSelectInput = useRef(null);
  const postcodeInput = useRef(null);
  const purposeUsageInput = useRef(null);
  const northingInput = useRef(null);
  const eastingInput = useRef(null);
  const longitudeInput = useRef(null);
  const latitudeInput = useRef(null);
  const trackIdSeclect = useRef(null);
  const localNameSelectInput = useRef(null);
  const hazardCodeSelectInput = useRef(null);
  const operationalSelectInput = useRef(null);
  const startMileageSelectInput = useRef(null);
  const endMileageSelectInput = useRef(null);
  const proposeDeleteReason = useRef(null);
  const sendToSSMSelectInput = useRef(null);
  const sendToAddressSelectInput = useRef(null);
  const proposedDeleteRejectReasonInput = useRef(null);
  const locationInput = useRef(null);
  const reinstateReason = useRef(null);
  const approveOperationalStatus = useRef(null);
  const [elrRangeValid, setElrRangeValid] = useState(true);
  const [editChange, setEditChange] = useState(false);
  const [duChange, setDuChange] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const minDate = moment();

  const falseFunc = () => false;

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    return () => {
      reoveFilesURLs();
    };
  }, []);

  const reoveFilesURLs = () => {
    files.forEach((file) => {
      URL.revokeObjectURL(file.preview);
    });
  };

  useEffect(() => {
    setError();
  }, [values]);

  useEffect(() => {
    generalService.getRoutes(true)
      .then((response) => {
        setRoutes(response.data);
      })
      .catch((error) => {
        // setRoutes("error");
        console.log(error);
      });
    generalService
      .getAllTrackId()
      .then((response) => {
        setTrackIdLookup(response.data);
      })
      .catch((error) => {
        setTrackIdLookup({});
        console.log(error);
      });
    generalService.getHazardCodesAll(true)
      .then((response) => {
        setHazardCodes(response.data);
      })
      .catch((error) => {
        // setRoutes("error");
        console.log(error);
      });
    generalService.getOperationStatusAll()
        .then((response) => {
          setStatusCodes(response.data);
        }).catch((error) => {
          console.log(error);
        });
  }, []);

  useEffect(() => {
    if (props.initialVals?.reviewer && !lookupWHSEASet) {
      sendToAddressSelectInput.current.enabled = false;
      setlookupWHSEASet(true);
      userService
        .getUserFromUserId(props.initialVals.reviewer)
        .then((response) => {
          var userDetails = response.data.userDetails;
          let sendToAddresObj = {
            username: userDetails.username,
            name: userDetails.firstName + " " + userDetails.surname,
          };
          setValues((values) => ({
            ...values,
            toSignature:
              response.data.userDetails.firstName +
              " " +
              response.data.userDetails.surname,
            reviewer: userDetails.id,
            name: userDetails.firstName + " " + userDetails.surname,
            company: userDetails.organisation,
            buildingName: userDetails.address.building_name,
            address1: userDetails.address?.address1,
            address2: userDetails.address?.address2,
            address3: userDetails.address?.address3,
            phone: userDetails.telephoneNumber,
            email: response.data.email,
            // sendToAddress: sendToAddresObj,
          }));

          setSendToAddresses([sendToAddresObj]);

          if (props.initialVals?.mdu) {
            userService
              .getUserSubmissionLookupWhsea({
                route: values.route,
                mdu: [props.initialVals.mdu.subzonecode],
              })
              .then((response) => {
                setlookupWHSEASet(true);
                setSendToAddresses(response.data);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } if (props.initialVals?.submissionType !== "New Submission") {
      if (props.initialVals?.route && props.initialVals?.subzone) {
        props.initialVals.reviewer = "";
        setValues((values) => ({
          ...values,
          reviewDate: "", reviewer: ""
        }));
        userService
          .getUserSubmissionLookupWhsea({
            route: props.initialVals.route,
            mdu: [props.initialVals.subzone],
          })
          .then((response) => {
            setSendToAddresses(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    if (props.initialVals?.submittedby) {
      userService
        .getUserFromUserId(props.initialVals.submittedby)
        .then((response) => {
          setValues((values) => ({
            ...values,
            fromSignature:
              response.data.userDetails.firstName +
              " " +
              response.data.userDetails.surname,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      userService
        .getUserFromCognitoUsername(props.data.userName)
        .then((response) => {
          setValues((values) => ({
            ...values,
            fromSignature:
              response.data.userDetails.firstName +
              " " +
              response.data.userDetails.surname,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [values.fromSignature]);

  const handleInputChange = (e, name) => {
    if (e._isAMomentObject) {
      setValues((values) => ({ ...values, [name]: e }));
    } else {
      const target = e.target;
      let value = target.value;
      const name = target.name;

      if (target.type === "checkbox") {
        if (!target.checked) {
          if (value === values[name]) value = "";
        }
      }

      if (name === "postcode") {
        if (!e.target.checkValidity()) {
          //e.target.setCustomValidity("Wrong post code format");
          e.target.reportValidity();
          //return;
        }
      }

      if (name === "category" && value === "other") {
        setOpenOther(true);
      } else if (name === "category" && value !== "other") {
        setOpenOther(false);
      }

      setValues((values) => ({ ...values, [name]: value }));
      if (props.actionType === "myhazards") {
        setEditChange(true);
      }
    }
  };

  const handleInputChangeG = (value, name, actionType) => {
    if (name === "route") {
      if (value !== undefined) {
        setFilteredElr(["loading..."]);
        setValues((values) => ({
          ...values,
          [name]: value ? value : "",
        }));
        generalService
          .getElrsZonecode(value.zonecode)
          .then((response) => {
            setFilteredElr(response.data);
          })
          .catch((error) => {
            setFilteredElr();
          });
      } else {
        setFilteredElr();
      }
      if (values.elr) {
        setValues((values) => ({
          ...values,
          elr: "",
        }));
        setFieldsErrors((old) => ({
          ...old,
          elr: "required",
          mdu: "required",
        }));
        if (approving) {
          setMetaStatus((metaStatus) => ({
            ...metaStatus,
            elr: "edited",
            mdu: "edited",
          }));
        }
      }
      if (values.mdu) {
        setValues((values) => ({
          ...values,
          mdu: "",
        }));
        setFieldsErrors((old) => ({
          ...old,
          mdu: "required",
        }));
        if (approving) {
          setMetaStatus((metaStatus) => ({
            ...metaStatus,
            mdu: "edited",
          }));
        }
      }
    }
    if (name === "elr") {
      if (value !== undefined) {

        setFilteredMdu(["loading..."]);
        setValues((values) => ({
          ...values,
          mdu: "",
        }));

        generalService
          .getSubzoneLookup({
            zonecode: value.zonecode,
            elr: value.elr,
          })
          .then((response) => {
            setFilteredMdu(response.data);
          })
          .catch((error) => {
            setFilteredMdu();
          });
      } else {
        setValues((values) => ({
          ...values,
          mdu: "",
        }));
        setFilteredMdu();
      }

      if (values.mdu) {
        if (values.start_mileage) {
          if (
            values.start_mileage < value.startmiles ||
            values.start_mileage > value.endmiles
            ) {
            setFieldsErrors((old) => ({ ...old, start_mileage: "Error" }));
          } else {
            setFieldsErrors((old) => ({ ...old, start_mileage: undefined }));
          }
        }
        if (values.end_mileage) {
          if (
            values.end_mileage < value.startmiles ||
            values.end_mileage > value.endmiles
            ) {
            setFieldsErrors((old) => ({ ...old, end_mileage: "Error" }));
          } else {
            setFieldsErrors((old) => ({ ...old, end_mileage: undefined }));
          }
        }

        setValues((values) => ({
          ...values,
          mdu: "",
        }));
        setFieldsErrors((old) => ({
          ...old,
          mdu: "required",
        }));
        if (approving) {
          // setElrRangeValid(false);
          setMetaStatus((metaStatus) => ({
            ...metaStatus,
            mdu: "edited",
          }));
        }
      }
      setFieldsErrors((old) => ({ ...old, elr: undefined }));
    }
    if (name === "mdu") {
      if (value !== undefined) {
        setFieldsErrors((old) => ({ ...old, [name]: undefined }));
        if (values.start_mileage !== "") {
          if (values.start_mileage > value.endmiles || values.start_mileage < value.startmiles) {
            if (approving) {
              setElrRangeValid(false);
              setDuChange(true);
              setFieldsErrors((old) => ({...old, start_mileage: "error"}));
              setMetaStatus((metaStatus) => ({
                ...metaStatus,
                start_mileage: "edited"
              }));
            } else {
              if (props.actionType === "myhazards") {
                setFieldsErrors((old) => ({...old, start_mileage: "error"}));
              }
              if (props.actionType === "propose amend" || props.actionType === "new") {
                setElrRangeValid(false);
                setFieldsErrors((old) => ({...old, start_mileage: "error"}));
              }
            }
          }
        }
        if (values.end_mileage !== "") {
          if (values.end_mileage > value.endmiles || values.end_mileage < value.startmiles) {
            if (approving) {
              setElrRangeValid(false);
              setFieldsErrors((old) => ({...old, end_mileage: "error"}));
              setMetaStatus((metaStatus) => ({
                ...metaStatus,
                end_mileage: "edited"
              }));
            } else {
              if (props.actionType === "myhazards") {
                setFieldsErrors((old) => ({...old, end_mileage: "error"}));
              }
              if (props.actionType === "propose amend" || props.actionType === "new") {
                setElrRangeValid(false);
                setFieldsErrors((old) => ({...old, end_mileage: "error"}));
              }
            }
          }
        }
        userService
          .getUserSubmissionLookupWhsea({
            route: values.route,
            mdu: [value.subzone],
          })
          .then((response) => {
            setSendToAddresses(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setValues((values) => ({
          ...values,
          mdu: "",
        }));
        setSendToAddresses();
      }
    }
    if (name === "hazard_code") {
      if (value !== undefined) {
        if (value.hazardCode.startsWith("M")) {
          setValues((values) => ({
            ...values,
            asset_request: true,
          }));
        } else
          setValues((values) => ({
            ...values,
            asset_request: false,
          }));
      } else
        setValues((values) => ({
          ...values,
          asset_request: false,
        }));
    }
    if (name === "sendToAddress") {
      setFieldsErrors((old) => ({ ...old, [name]: undefined }));
      userService.getUserFromCognitoUsername(value.username).then((response) => {
        var userDetails = response.data.userDetails;
        setValues((values) => ({
          ...values,
          reviewer: userDetails.id,
          name: userDetails.firstName + " " + userDetails.surname,
          company: userDetails.organisation,
          buildingName: userDetails.address?.building_name,
          address1: userDetails.address?.address1,
          address2: userDetails.address?.address2,
          address3: userDetails.address?.address3,
          phone: userDetails.telephoneNumber,
          email: response.data.email,
          toSignature: userDetails.firstName + " " + userDetails.surname
        }));
      });
    }

    if (name === "hazardType" && value === "All") {
      generalService.getHazardCodesAll(true)
          .then((response) => {
            setHazardCodes(response.data);
          })
          .catch((error) => {
            // setRoutes("error");
            console.log(error);
          });
    }
    if (name === "hazardType" && value === "Environmental Hazards") {
      generalService.getHazardCodesEnvironment()
        .then((response) => {
          setHazardCodes(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (name === "hazardType" && value === "Health & Safety Codes") {
      generalService.getHazardCodesSafetyCodes()
        .then((response) => {
          setHazardCodes(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (name === "hazardType" && value === "Access Points") {
      generalService.getHazardCodesAccessPoints()
        .then((response) => {
          setHazardCodes(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (name === "hazardType" && value === "Other Hazards") {
      generalService.getHazardCodesOther()
          .then((response) => {
            setHazardCodes(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    }

    if (name === "status_code" && value === undefined) {
      value = "";
    }

    setValues((values) => ({
      ...values,
      [name]: value,
    }));

    if (props.actionType === "myhazards") {
      setEditChange(true);
    }
  };

  const validateMileages = (value, name, e) => {
    if (name === "end_mileage") {
      value = formatMileages(value);
      if (e && !e?.target.checkValidity()) {
        e.target.reportValidity();
        setFieldsErrors((old) => ({ ...old, [name]: "Error" }));
      } else {
        if (values.mdu) {
          if (parseFloat(value) < parseFloat(values.mdu.startmiles) || parseFloat(value) > parseFloat(values.mdu.endmiles)) {
            setFieldsErrors((old) => ({ ...old, [name]: "Error" }));
          } else {
            setFieldsErrors((old) => ({ ...old, [name]: undefined }));
            setElrRangeValid(true);
          }
        } else {
          setFieldsErrors((old) => ({ ...old, [name]: undefined }));
          setElrRangeValid(true);
        }
      }
    }

    if (name === "start_mileage") {
      value = formatMileages(value);
      if (e && !e?.target.checkValidity()) {
        e.target.reportValidity();
        setFieldsErrors((old) => ({ ...old, [name]: "Error" }));
      } else {
        if (values.mdu) {
          if (parseFloat(value) < parseFloat(values.mdu.startmiles) || parseFloat(value) > parseFloat(values.mdu.endmiles)) {
            setFieldsErrors((old) => ({ ...old, [name]: "Error" }));
          } else {
            setFieldsErrors((old) => ({ ...old, [name]: undefined }));
            setElrRangeValid(true);
          }
        } else {
          setFieldsErrors((old) => ({ ...old, [name]: undefined }));
          setElrRangeValid(true);
        }
      }
    }

    setValues((values) => ({ ...values, [name]: value }));
  }

  const validform = () => {
    let validRoute = true;
    let validElr = true;
    let validDu= true;
    let latitude = true;
    let longitude = true;
    let easting = true;
    let northing = true;
    let trackId = true;
    let validLocalName= true;
    let validHazardCode = true;
    let validStartMileage = true;
    let validEndMileage = true;
    if (props.actionType !== "myhazards-propose-delete") {
      validRoute = routeSelectInput.current.validateField();
      validElr = elrSelectInput.current.validateField();
      validDu = duSelectInput.current.validateField();
      latitude = latitudeInput.current.validateField();
      longitude = longitudeInput.current.validateField();
      easting = eastingInput.current.validateField();
      northing = northingInput.current.validateField();
      trackId = trackIdSeclect.current.validateField();
      validLocalName = localNameSelectInput.current.validateField();
      validHazardCode = hazardCodeSelectInput.current.validateField();
      validStartMileage = startMileageSelectInput.current.validateField();
      validEndMileage = endMileageSelectInput.current.validateField();

      if (filteredElr) {
        if (!filteredElr.some((elr) => elr.id === values.elr.id)) {
          setFieldsErrors((old) => ({...old, elr: "error"}));
          return false;
        } else {
          setFieldsErrors((old) => ({...old, elr: undefined}));
        }
      }

      if (filteredMdu) {
        if (!filteredMdu.some((mdu) => mdu.id === values.mdu.id)) {
          setFieldsErrors((old) => ({...old, mdu: "error"}));
          return false;
        } else {
          setFieldsErrors((old) => ({...old, mdu: undefined}));
        }
      }

      if ((values.latitude === "" || values.longitude === "") && 
        (values.easting === "" || values.northing === "")) {
        return false;
      }

      if (values.latitude) {
        const pattern = /^(-?[1-8]?\d(?:\.\d{1,8})?|90(?:\.0{1,8})?)$/;
        const result = pattern.test(values.latitude);
        if (!result) {
          setFieldsErrors((old) => ({...old, latitude: "error"}));
          return false;
        } else {
          setFieldsErrors((old) => ({...old, latitude: undefined}));
        }
      }

      if (values.longitude) {
        const pattern = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,8})?|180(?:\.0{1,8})?)$/;
        const result = pattern.test(values.longitude);
        if (!result) {
          setFieldsErrors((old) => ({...old, longitude: "error"}));
          return false;
        } else {
          setFieldsErrors((old) => ({...old, longitude: undefined}));
        }
      }

      if (values.easting) {
        const pattern = /^\d{1,7}(\.\d{1,4})?$/;
        const result = pattern.test(values.easting);
        if (!result) {
          setFieldsErrors((old) => ({...old, easting: "Error"}));
          return false;
        } else {
          setFieldsErrors((old) => ({...old, easting: undefined}));
        }
      }

      if (values.northing) {
        const pattern = /^\d{1,7}(\.\d{1,4})?$/;
        const result = pattern.test(values.northing);
        if (!result) {
          setFieldsErrors((old) => ({...old, northing: "Error"}));
          return false;
        } else {
          setFieldsErrors((old) => ({...old, northing: undefined}));
        }
      }
    }

    if (props.actionType === "myhazards-propose-delete") {
      let validProposeDeleteReason =
          proposeDeleteReason.current.validateField();
      let validSelectReviewer = sendToAddressSelectInput.current.validateField();
      if (!validProposeDeleteReason || !validSelectReviewer) {
        return false;
      }
    }

    if (props.initialVals?.submission_status !== "Proposed Deletion") {
      let validStatusCode = operationalSelectInput.current.validateField();
      if (!validStatusCode) {
        return false;
      }
    }

    if (props.actionType === "myhazards" && (props.initialVals?.reviewer === values.reviewer) && values.sendToAddress === "" && editChange) {
      setValues((values) => ({
        ...values,
        sendToAddress: undefined,
        reviewer: undefined,
        name: " ",
        company: " ",
        buildingName: " ",
        address1: " ",
        address2: " ",
        address3: " ",
        phone: " ",
        email: " ",
        toSignature: " ",
        ssm_reviewer: ""
      }));
      setFieldsErrors((old) => ({...old, sendToAddress: "error"}));
      setError("Please set the new WHSEA Reviewer as you have changed the data on the form");
      return false;
    }

    if (props.actionType === "myhazards" && values.reviewer === undefined) {
      return false;
    }

    if (
      validRoute &&
      validElr &&
      validDu &&
      validHazardCode &&
      validLocalName &&
      validHazardCode &&
      validStartMileage &&
      trackId &&
      validEndMileage
    ) {
      return true;
    } else {
      return false;
    }
  };

  function handleSubmit() {
    if (validform()) {
      setLoading(true);
      let fileUploadError = false;
      HazardManagementService.create(values)
        .then(async (response) => {
          let filesUpload = files.map((file) => {
            let params = {};
            params.file = file;
            params.fileInformation = {
              hazard_id: response.data.hazard_id,
              title: file.name,
              description: file.name,
              type: file.type,
            };
            HazardManagementService.filesUpload(params)
              .then((response) => {})
              .catch((error) => (fileUploadError = true));
          });
          await filesUpload;
          navigate("/register", { state: { type: "My Hazards" }});
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error)
          if (error.response.status === 401) {
            setError("You are unauthorised to use this service please contact support.");
          } else {
            setError("An unexpected error occurred please contact try again or contact support.");
          }
        });
    } else {
      setError("Please check fields or errors highlighted in red, and amend the information to continue.");
    }
  }

  function handleReset() {
    setValues({ ...initialVals });
  }

  let initialMetaStatus = {
    category: "",
    easting: "",
    elr: "",
    end_mileage: "",
    free_text: "",
    hazardType: "",
    hazard_code: "",
    status_code: "",
    latitude: "",
    local_name: "",
    longitude: "",
    mdu: "",
    northing: "",
    postcode: "",
    purpose_usage: "",
    route: "",
    start_mileage: "",
    track_id: "",
    uploadImage: "",
    location: "",
  };

  let approvedMetaStatus = {
    category: "approved",
    easting: "approved",
    elr: "approved",
    end_mileage: "approved",
    free_text: "approved",
    hazardType: "approved",
    hazard_code: "approved",
    status_code: "approved",
    latitude: "approved",
    local_name: "approved",
    longitude: "approved",
    mdu: "approved",
    northing: "approved",
    postcode: "approved",
    purpose_usage: "approved",
    route: "approved",
    start_mileage: "approved",
    track_id: "approved",
    uploadImage: "approved",
  };

  const [approveStatus, setApproveStatus] = useState("incomplete");

  const [metaStatus, setMetaStatus] = useState(initialMetaStatus);
  const [rejectedReason, setRejectedReason] = useState([]);

  const handleSetRejectedReason = (e, meta) => {
    setRejectedReason((old) => ({ ...old, [meta.target]: e.target.value }));
  };

  function handleAmendHazard(e, meta) {
    if (validform()) {
      setLoading(true);
        HazardManagementService.proposeEdit(values,props.initialVals.submission_status === "Rejected")
          .then(async (response) => {
            let fileUploadError = false;
            let filesRemoved = filesToRemove.map((file) => {
              HazardManagementService.deleteFileById(file)
                .then((response) => {})
                .catch((error) => console.log(error));
            });
            await filesRemoved;

            let filesUpload = files.map((file) => {
              let params = {};
              params.file = file;
              params.fileInformation = {
                hazard_id: response.data.hazard_id,
                title: file.name,
                description: file.name,
                type: file.type,
              };
              HazardManagementService.filesUpload(params)
                .then((response) => {})
                .catch((error) => (fileUploadError = true));
            });
            await filesUpload;
            window.sessionStorage.removeItem("custom-search-back");
            navigate("/register", { state: { type: "My Hazards" } });
            reoveFilesURLs();
            setFiles();
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status === 401) {
              setError("You are unauthorized to use this service please contact support.");
            } if (error.response.status === 409) {
              let errorData = error.response.data;
              setError(errorData.apierror.message);
            } else {
              setError("An unexpected error occurred please contact try again or contact support.");
            }
          });
      } else {
        setError("Please check fields or errors highlighted in red, and amend the information to continue.");
      }
  }

  function handleProposeDeleteHazard(e, meta) {
    let validProposeDeleteReason =
        proposeDeleteReason.current.validateField();
    let validSelectReviewer = sendToAddressSelectInput.current.validateField();
    if (validProposeDeleteReason && validSelectReviewer) {
      setLoading(true);
      HazardManagementService.proposeDelete(values)
          .then((response) => {
            setLoading(false);
            window.sessionStorage.removeItem("custom-search-back");
            navigate("/register", { state: { type: "My Hazards" } });
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status === 401) {
              setError("You are unauthorized to use this service please contact support.");
            } if (error.response.status === 409) {
              let errorData = error.response.data;
              setError(errorData.apierror.message);
            } else {
              setError("An unexpected error occurred please contact try again or contact support.");
            }
          });
    } else {
      setError("Please check fields or errors highlighted in red, and amend the information to continue.");
    }
  }

  function handleProposeReinstateHazard(e, meta) {
    let validReinstateReason =
        reinstateReason.current.validateField();
    let validSelectReviewer = sendToAddressSelectInput.current.validateField();
    if (validReinstateReason && validSelectReviewer) {
      setLoading(true);
      HazardManagementService.proposeReinstateHazard(values)
          .then((response) => {
            setLoading(false);
            navigate("/register", { state: { type: "My Hazards" } });
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status === 401) {
              setError("You are unauthorized to use this service please contact support.");
            } if (error.response.status === 409) {
              let errorData = error.response.data;
              setError(errorData.apierror.message);
            } else {
              setError("An unexpected error occurred please contact try again or contact support.");
            }
          });
    } else {
      setError("Please check fields or errors highlighted in red, and amend the information to continue.");
    }
  }

  function handleDeleteHazard(e, meta) {
    setLoading(true);
    let ssmApproved = false;
    if (props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("SSM") ||
        props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("ssm-admin-group") &&
        values.asset_request) {
      ssmApproved = true;
    }
    HazardManagementService.deleteHazardById(values.hazard_id, ssmApproved)
        .then((response) => {
          setLoading(false);
          if (response.data.asset_request && !response.data.ssm_approved) {
            userService
                .getUserSubmissionLookupSSM({
                  route: props.initialVals.route,
                  mdu: [props.initialVals.subzone],
                })
                .then((response) => {
                  setSendToSSMLookup(response.data);
                })
                .catch((error) => {
                  console.log(error);
                });
            setShowSendToSSMForm(true);
          } else {
            navigate("/register", { state: { type: "Hazard Deletion" } });
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 401) {
            setError("You are unauthorized to use this service please contact support.");
          } else {
            setError("An unexpected error occurred please contact try again or contact support.");
          }
        });
  }

  const handleSendToSSM = () => {
    setSsmSentLoading(true);
    let params = {};
    params.hazard_id = values.hazard_id;
    params.ssm_reviewer = values.sendToSSM.userid;
    HazardManagementService.sendToSSMReview(params)
      .then((response) => {
        navigate("/register");
      })
      .catch((error) => {
        setSsmSentLoading(false);
        console.log(error);
      });
  };

  const openModal = () => {
    setShowModal(true);
  };

  function handleApproveHazard(e, meta) {
    console.log("handleApproveHazard");
  }

  function handleEditHazard(e, meta) {
    if (validform()) {
      setLoading(true);
      HazardManagementService.editHazard(values)
        .then(async (response) => {
          setLoading(false);
          let filesUpload = files.map((file) => {
            let params = {};
            params.file = file;
            params.fileInformation = {
              hazard_id: response.data.hazard_id,
              title: file.name,
              description: file.name,
              type: file.type,
            };
            HazardManagementService.filesUpload(params)
              .then((response) => {})
              .catch((error) => console.log);
          });
          await filesUpload;
          window.sessionStorage.removeItem("custom-search-back");
          navigate("/register", { state: { type: "My Hazards" }});
          setLoading(false);
          // navigate("/register", { state: { type: "Amended Hazard" } });
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 401) {
            setError("You are unauthorized to use this service please contact support.");
          } else {
            setError("An unexpected error occurred please contact try again or contact support.");
          }
        });
    } else {
      setError("Please check fields or errors highlighted in red, and amend the information to continue.");
    }
  }

  const handleRejectHazard = () => {
    let rejectedFields = [];
    for (var prop in metaStatus) {
      if (Object.prototype.hasOwnProperty.call(metaStatus, prop)) {
        if (metaStatus[prop] === "rejected") {
          rejectedFields.push(prop);
        }
      }
    }

    let params = {};
    params.hazard_id = values.hazard_id;
    params.hazard_rejection_data = {};
    rejectedFields.forEach((element) => {
      let paramSet = false;
      if (element === "route") {
        let route = values[element];
        params.hazard_rejection_data[element] = {
          status: "rejected",
          reason: rejectedReason[element] ? rejectedReason[element] : "",
          value: route.zonedescription
        }
        paramSet = true;
      } if (element === "elr") {
       let  elr = values[element];
       params.hazard_rejection_data[element] = {
         status: "rejected",
         reason: rejectedReason[element] ? rejectedReason[element] : "",
         value: elr.elr,
       }
        paramSet = true;
      } if (element === "hazard_code") {
        let val = values[element];
        params.hazard_rejection_data[element] = {
          status: "rejected",
          reason: rejectedReason[element] ? rejectedReason[element] : "",
          value: val.hazardCode,
        }
        paramSet = true;
      } if (element === "status_code") {
        let value = values[element];
        params.hazard_rejection_data[element] = {
          status: "rejected",
          reason: rejectedReason[element] ? rejectedReason[element] : "",
          value: value.status_code
        }
        paramSet = true;
      } if (element === "mdu") {
        let val = values[element];
        params.hazard_rejection_data[element] = {
          status: "rejected",
          reason: rejectedReason[element] ? rejectedReason[element] : "",
          value: val.subzone,
        }
        paramSet = true;
      } if (element === "track_id") {
        let val = values[element];
        params.hazard_rejection_data[element] = {
          status: "rejected",
          reason: rejectedReason[element] ? rejectedReason[element] : "",
          value: val.trackid,
        }
        paramSet = true;
      } if (!paramSet && element !== "hazardType") {
        params.hazard_rejection_data[element] = {
          status: "rejected",
          reason: rejectedReason[element] ? rejectedReason[element] : "",
          value: values[element],
        }
      }

    });

    setLoading(true);
    HazardManagementService.rejectHazard(params)
      .then((response) => {
        setLoading(false);
        navigate("/register")
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError("You are unauthorized to use this service please contact support.");
        } else {
          setError("An unexpected error occurred please contact try again or contact support.");
        }
      });
  };

  const handleProposeDeleteReject = () => {
    let params = {};

    if (proposedDeleteRejectReasonInput.current.validateField()) {
      params.hazard_id = values.hazard_id;
      params.propose_reject_reason = values.proposedDeleteRejectReason;
      setLoading(true);
      HazardManagementService.rejectProposedDeleteHazard(params)
          .then((response) => {
            setLoading(false);
            navigate("/register")
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status === 401) {
              setError("You are unauthorized to use this service please contact support.");
            } else {
              setError("An unexpected error occurred please try again or contact support.");
            }
          });
    } else {
      setError("Please check fields or errors highlighted in red, and amend the information to continue.");
    }
  };

  const handleAcceptHazard = () => {
    if (validform()) {
      let editedFields = [];
      for (var prop in metaStatus) {
        if (Object.prototype.hasOwnProperty.call(metaStatus, prop)) {
          if (metaStatus[prop] === "edited") {
            editedFields.push(prop);
          }
        }
      }
      let params = {};
      let notChanged = false;
      params.hazard_id = values.hazard_id;
      editedFields.forEach((element) => {
        if (element === "elr") {
          if (!notChanged && values["elr"] !== props.initialVals["elr"]) {
            params["elr"] = values["elr"]["elr"];
          } else {
            notChanged = true;
          }
        } else if (element === "mdu") {
          let subzoneChecked = false;
          if (props.initialVals["mdu"] === undefined) {
            if (!notChanged && values["mdu"]["subzone"] !== props.initialVals["subzone"]) {
              params["subzone"] = values["mdu"]["subzone"];
              subzoneChecked = true;
            }
          }
          if (!subzoneChecked && !notChanged && values["mdu"]["subzone"] !== props.initialVals["mdu"]["subzone"]) {
            params["subzone"] = values["mdu"]["subzone"];
            subzoneChecked = true;
          } if (!subzoneChecked) {
            notChanged = true;
          }
        } else if (element === "route") {
          if (!notChanged && values["route"]["zonecode"] !== props.initialVals["route"]["zonecode"]) {
            params["zonecode"] = values["route"]["zonecode"];
          } else {
            notChanged = true;
          }
        } else if (element === "hazard_code") {
          if (!notChanged && values["hazard_code"]["hazardCode"] !== props.initialVals["hazard_code"]["hazardCode"]) {
            params["hazard_code"] = values["hazard_code"]["hazardCode"];
          } else {
            notChanged = true;
          }
        } else if (element === "track_id") {
          let trackIdChecked = false;
          if (props.initialVals["track_id"] === undefined) {
            if (!notChanged && values["track_id"]["trackid"]) {
              params["track_id"] = values["track_id"]["trackid"];
              trackIdChecked = true;
            }
          } if(!trackIdChecked && !notChanged && values["track_id"]["track_id"] !== props.initialVals["track_id"]["trackid"]) {
            params["track_id"] = values["track_id"]["track_id"];
            trackIdChecked = true;
          } if (!trackIdChecked) {
            notChanged = true;
          }
        } else if (element === "hazardType") {
        } else {
          if (!notChanged && values[element] !== props.initialVals[element]) {
            params[element] = values[element];
          } else {
            notChanged = true;
          }
        }
      });
      if (elrRangeValid && !notChanged) {
        params.review_date = values.review_date || undefined;
        params.priority = values.priority || undefined;
        params.fms = values.fms || undefined;
        params.status_code = values.status_code.status_code || undefined
        if ((props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("SSM") ||
                props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("ssm-admin-group")) &&
            values.asset_request) {
          params.ssm_approved = true;
        }
        if (values.asset_request) {
          params.asset_request = values.asset_request;
        }
        if (props.initialVals.submission_status === "SSM Approved With Edits") {
          params.ssm_approved = true;
        }
        setLoading(true);
        let proposeEdit = false;
        if (
            props.initialVals.submission_status === "Pending Request" ||
            props.initialVals.previous_state === "Pending Request"
        ) {
          proposeEdit = true;
        }
        if (
            !proposeEdit &&
            props.initialVals.submission_status === "Rejected" &&
            props.initialVals.previous_state === "Pending Request"
        ) {
          proposeEdit = true;
        }
        HazardManagementService.acceptHazard(params, proposeEdit)
            .then((response) => {
              setLoading(false);
              if (response.data.asset_request && !response.data.ssm_approved) {
                props.initialVals.asset_request = true
                userService
                    .getUserSubmissionLookupSSM({
                      route: props.initialVals.route,
                      mdu: [props.initialVals.subzone],
                    })
                    .then((response) => {
                      setSendToSSMLookup(response.data);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                setShowSendToSSMForm(true);
              } else {
                navigate("/register");
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error.response.status === 401) {
                setError("You are unauthorized to use this service please contact support.");
              } else {
                setError("An unexpected error occurred please contact try again or contact support.");
              }
            });
      } else {
        setError("Please make sure all edited values have been changed.")
      }
    } else {
      setError("Please check fields or errors highlighted in red, and amend the information to continue.");
    }
  };

  function handleApprove(e, meta) {
    const status = "approved";
    setMetaStatus((metaStatus) => ({ ...metaStatus, [meta.target]: status }));
    if (meta.target === "hazard_code") {
      setMetaStatus((metaStatus) => ({ ...metaStatus, ["hazardType"]: status }));
    }
  }

  function handleEdit(e, meta) {
    //console.log("edited");
    const status = "edited";
    setMetaStatus((metaStatus) => ({ ...metaStatus, [meta.target]: status }));
    if (meta.target === "hazard_code") {
      setMetaStatus((metaStatus) => ({ ...metaStatus, ["hazardType"]: status }));
    }
  }

  function handleReject(e, meta) {
    //console.log("rejected");
    const status = "rejected";
    setMetaStatus((metaStatus) => ({ ...metaStatus, [meta.target]: status }));
    if (meta.target === "hazard_code") {
      setMetaStatus((metaStatus) => ({ ...metaStatus, ["hazardType"]: status }));
    }
  }

  function handleUndo(e, meta) {
    //console.log("undo");
    const status = "";
    setMetaStatus((metaStatus) => ({ ...metaStatus, [meta.target]: status }));
    if (meta.target === "status_code") {
      if (values.status_code === "") {
        setValues((values) => ({
          ...values,
          [meta.target]: status,
        }));
      }
    }
    if (meta.target === "route") {
      if (values.route.id === props.initialVals.route.id) return;
      handleInputChangeG(props.initialVals.route, "route");
      handleInputChangeG(props.initialVals.elr, "elr");
      setValues((values) => ({
        ...values,
        mdu: props.initialVals.mdu,
        start_mileage: props.initialVals.start_mileage,
        end_mileage: props.initialVals.end_mileage
      }));
      setMetaStatus((metaStatus) => ({ ...metaStatus, elr: status, mdu: status, start_mileage: status, end_mileage: status }));
      setFieldsErrors((old) => ({...old, mdu: undefined, start_mileage: undefined, end_mileage: undefined}));
      setElrRangeValid(true);
      setDuChange(false);
      return;
    }
    if (meta.target === "elr") {
      if (values.elr.elr === props.initialVals.elr.elr) return;
      handleInputChangeG(props.initialVals.route, "route");
      handleInputChangeG(props.initialVals.elr, "elr");
      setValues((values) => ({
        ...values,
        mdu: props.initialVals.mdu,
        start_mileage: props.initialVals.start_mileage,
        end_mileage: props.initialVals.end_mileage
      }));
      setMetaStatus((metaStatus) => ({ ...metaStatus, elr: status, mdu: status, start_mileage: status, end_mileage: status }));
      setFieldsErrors((old) => ({...old, mdu: undefined, start_mileage: undefined, end_mileage: undefined}));
      setElrRangeValid(true);
      setDuChange(false);
      return;
    }
    if (meta.target === "mdu") {
      if (values.mdu === props.initialVals.mdu) return;
      handleInputChangeG(props.initialVals.route, "route");
      handleInputChangeG(props.initialVals.elr, "elr");
      handleInputChangeG(props.initialVals.mdu, "mdu");
      setValues((values) => ({
        ...values,
        mdu: props.initialVals.mdu,
        start_mileage: props.initialVals.start_mileage,
        end_mileage: props.initialVals.end_mileage
      }));
      setMetaStatus((metaStatus) => ({ ...metaStatus, elr: status, mdu: status, start_mileage: status, end_mileage: status }));
      setFieldsErrors((old) => ({...old, mdu: undefined, start_mileage: undefined, end_mileage: undefined}));
      setElrRangeValid(true);
      setDuChange(false);
      return;
    }
    if (meta.target === "hazard_code") {
      handleInputChangeG(props.initialVals.hazard_code, "hazard_code");
      setMetaStatus((metaStatus) => ({ ...metaStatus, ["hazardType"]: status }));
    }
    setValues((values) => ({
      ...values,
      [meta.target]: props.initialVals[meta.target],
    }));
    if (meta.target === "start_mileage") {
      if (values.mdu !== undefined) {
        if (values.start_mileage >= values.mdu.startmiles && values.start_mileage <= values.mdu.endmiles) {
          setElrRangeValid(true)
        } else {
          setFieldsErrors((old) => ({...old, [meta.target]: "required"}))
        }
      }
    }
    if (meta.target === "end_mileage") {
      if (values.mdu !== undefined) {
        if (values.end_mileage <= values.mdu.endmiles && values.end_mileage >= values.mdu.startmiles) {
          setElrRangeValid(true)
        } else {
          setFieldsErrors((old) => ({...old, [meta.target]: "required"}))
        }
      }
    }
    setRejectedReason((old) => ({ ...old, [meta.target]: "" }));
    // setFieldsErrors((old) => ({ ...old, [meta.target]: undefined }));
  }

  useEffect(() => {
    if (props.initialVals) {
      Object.keys(props.initialVals).forEach(function (key) {
        if (key === "toDate" || key === "fromDate" || key === "review_date") {
          props.initialVals[key] = moment(new Date(props.initialVals[key]));
        }
        setValues((values) => ({ ...values, [key]: props.initialVals[key] }));
      });

      if (values.start_mileage !== "") {
        validateMileages(values.start_mileage, 'start_mileage');
      }
  
      if (values.end_mileage !== "") {
        validateMileages(values.end_mileage, 'end_mileage');
      }

      generalService
        .getElrsZonecode(props.initialVals.zonecode)
        .then((response) => {
          setFilteredElr(response.data);
        })
        .catch((error) => {
          setFilteredElr();
        });
      generalService
        .getSubzoneLookup({
          zonecode: props.initialVals.zonecode,
          elr: props.initialVals.elr.elr,
        })
        .then((response) => {
          setFilteredMdu(response.data);
        })
        .catch((error) => {
          setFilteredMdu();
        });

      // if (props.initialVals.category !== "other") {
      //   let meta = {};
      //   meta.target = "otherDetails";
      //   handleApprove(undefined, meta);
      // }
    }
  }, [props.initialVals]);

  useEffect(() => {
    if (Object.values(metaStatus).includes("")) {
      setApproveStatus("incomplete");
    } else if (Object.values(metaStatus).includes("rejected")) {
      setApproveStatus("rejected");
    } else if (Object.values(metaStatus).includes("edited")) {
      setApproveStatus("edited");
    } else {
      setApproveStatus("approved");
    }

    if (props.actionType === "delete") {
      setApproveStatus("propose-delete");
    }
  }, [metaStatus]);

  const removeFile = (e, fileName) => {
    e.stopPropagation();
    let arr = [];
    files.forEach((file) => {
      if (file.name === fileName) {
        URL.revokeObjectURL(file.preview);
      } else arr.push(file);
    });

    setFiles(arr);
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    return () => {
      files.forEach((file) => {
        URL.revokeObjectURL(file.preview);
      });
    };
  }, []);

  const generateHistoryTableData = () => {
    let data = props.hazardHistory?.map(({ ...rest }) => {
      return rest;
    });

    return data;
  };

  const formatMileages = (mileage) => {
    if (typeof mileage === 'number') {
      mileage = mileage.toString();
    }
  
    const unitRoundFactor = 4;
    let mileageDecimal = '0000';
    let paddedMileage = '';
  
    if (mileage !== '') {
      const milageSplit = mileage.split('.');
  
      if (typeof milageSplit[1] !== 'undefined') {
        mileageDecimal = pad(milageSplit[1], unitRoundFactor);
      }

      mileageDecimal = parseInt(mileageDecimal) > 1759 ? 1759 : mileageDecimal; 
      paddedMileage = milageSplit[0] + '.' + mileageDecimal;
    }
    
    return paddedMileage;
  }

  const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad(0 + str, max) : str;
  };

  const renderRowSubComponent = React.useCallback(({ row }) => {
    let changes = row.original.whats_changed;
    let keys = changes ? Object.keys(changes) : [];
    return keys?.map((key, index) => {
      if (key !== "rejected" && key !== "Reason for Deletion" && key !== "Reason for Rejection" && key !== "Reason for Re-instatement") {
        return (
          <div key={"history-" + key}>
            <span className="field-changed">{key}</span>

            {key === "Document Deleted" || key === "New Document Added" ? (
              <span>{`: ${changes[key]["before"]}`}</span>
            ) : (
              <>
                <span>{`: changed from `}</span>
                <span className="field-before">{changes[key]["before"]}</span>
                <span>{` to `}</span>
                <span className="field-after">{changes[key]["after"]}</span>
              </>
            )}
          </div>
        );
      }
      if (key === "rejected") {
        let rejections = row.original.rejected_data;
        let rejectedKeys = rejections ? Object.keys(rejections) : [];
        return rejectedKeys?.map((key, index) => {
          return (
            <div key={"history-" + key}>
              <span>Rejected</span>
              <span className="field-changed">{`${key}`}</span>
              <span className="field-before">
                Value: {rejections[key]["value"]}
              </span>
              <span> Reason: {rejections[key]["reason"]}</span>
            </div>
          );
        });
      }
      if (key === "Reason for Deletion" || key === "Reason for Rejection" || key === "Reason for Re-instatement") {
        let rejections = row.original.rejected_data;
        let rejectedKeys = rejections ? Object.keys(rejections) : [];
        return (
          <div key={"history-" + key}>
            <span className="field-changed">{key}: </span>
            <span className="field-before">{changes[key]["before"]}</span>
          </div>
        );
      }
    });
  }, []);

  const approvingRolesArray = [
    "admin",
    "whsea-group",
    "whsea-admin-group",
    "SSM",
    "ssm-admin-group",
    "ontrac-admin-group",
  ];
  // const approvingRolesArray = ["admin", "whsea-group", "SSM"];
  const approving =
    props.initialVals &&
    approvingRolesArray.some((ele) =>
      props.user.signInUserSession.accessToken.payload[
        "cognito:groups"
      ].includes(ele)
    ) &&
    (props.actionType === "amend" ||
      props.actionType === "new" ||
      props.actionType === "delete")
      ? true
      : false;

  // const [additionalInfo, setAdditionalInfo] = useState("");
  let displayReviewDate =
       (
              <div className="input__wrap">
                  <label className="input__label">Review date:</label>
                  <SingleDatePicker
                      date={values.review_date}
                      onDateChange={(date) =>
                      handleInputChange(date, "review_date")
                  }
                      focused={reviewDateFocused}
                      onFocusChange={({ focused }) =>
                      setReviewDateFocused(focused)
                  }
                      id="reviewDate"
                      numberOfMonths={1}
                      block={true}
                      showDefaultInputIcon={true}
                      displayFormat={() => "DD MMMM YYYY"}
                      isOutsideRange={date => date.isBefore(minDate.startOf('day'))}
                      disabled={false}
                  />
              </div>
      );


  let approveSubmit = (
    <p>
      Please complete all sections / fields to approve or reject this Hazard
      Submission.
    </p>
  );

  if (approveStatus === "incomplete") {
    approveSubmit = (
      <p>
        Please complete all sections / fields to approve or reject this Hazard
        Submission.
      </p>
    );
  } else if (approveStatus === "edited") {
    approveSubmit = (
      <>
      {displayReviewDate}
      <div>
          <p>Approve Hazard Submission with Edits.</p>
          {/* <Textarea
          name="editReason"
          label="Reason for edits"
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          /> */}
          {loading && !error && (
                  <div className="button-group">
                      <BouncingDotsLoader />
                  </div>
                  )}
          {!loading && (
                  <div className="button-group">
                      <Button
                          action="primary"
                          className="button--alert"
                          onClick={handleAcceptHazard}
                          >
                          Approve with Edits
                      </Button>
                  </div>
                  )}

          {error && !loading && (
                  <div className="form-error">
                      <span className="input__error">{error}</span>
                  </div>
                  )}
      </div>
      </>
    );
  } else if (approveStatus === "rejected") {
    approveSubmit = (
      <div>
        {/* <p>Reject Hazard Submission.</p>
        <Textarea
          name="rejectReason"
          label="Reason for rejection"
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
        /> */}
        {loading && !error && (
          <div className="button-group">
            <BouncingDotsLoader />
          </div>
        )}
        {!loading && (
          <div className="button-group">
            <Button
              action="primary"
              className="button--warning"
              onClick={handleRejectHazard}
            >
              Reject
            </Button>
          </div>
        )}
        {error && !loading && (
          <div className="form-error">
            <span className="input__error">{error}</span>
          </div>
        )}
      </div>
    );
  } else if (approveStatus === "approved") {
    approveSubmit = (
        <>
        {displayReviewDate}
          <div>
            <p>Approve Hazard Submission.</p>
            {loading && !error && (
                <div className="button-group">
                  <BouncingDotsLoader />
                </div>
            )}
            {!loading && (
                <div className="button-group">
                  <Button
                      action="primary"
                      className="button--success"
                      onClick={handleAcceptHazard}
                  >
                    Approve
                  </Button>
                </div>
            )}
            {error && !loading && (
                <div className="form-error">
                  <span className="input__error">{error}</span>
                </div>
            )}
          </div>
        </>
    );
  } else if (approveStatus === "propose-delete" && props.actionType === "delete") {
    approveSubmit = (
      <div>
        <p>Approve Hazard Deletion.</p>
        {loading && !error && (
          <div className="button-group">
            <BouncingDotsLoader />
          </div>
        )}
        {!loading && (
          <div className="button-group">
            <Button
              action="primary"
              className="button--success"
              onClick={handleDeleteHazard}
            >
              Approve Delete Hazard
            </Button>
            <div className="button-group">
              <Button
                action="primary"
                className="button--warning"
                onClick={handleProposeDeleteReject}
              >
                Reject Propose Delete Hazard
              </Button>
              <Input
                ref={proposedDeleteRejectReasonInput}
                name="proposedDeleteRejectReason"
                label="Reason for Rejection"
                value={values.proposedDeleteRejectReason}
                required={true}
                onChange={handleInputChange}
              />
            </div>
          </div>
        )}
        {error && !loading && (
          <div className="form-error">
            <span className="input__error">{error}</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="create-form__wrap">
      {props.hazardHistory?.length > 0 && (
        <div className="filter-form">
          <div className="filter-form__controller">
            <h2 className="filter-form__title inline-block">Hazard History</h2>

            {openFilters ? (
              <FontAwesomeIcon
                className="inline-block filter-icon"
                icon={faAngleUp}
                onClick={() => {
                  setOpenFilters(!openFilters);
                }}
                title="Collapse"
              />
            ) : (
              <FontAwesomeIcon
                className="inline-block filter-icon"
                icon={faAngleDown}
                onClick={() => setOpenFilters(!openFilters)}
                title="Expand"
              />
            )}
          </div>

          {openFilters && (
            <div>
              <ReactTable
                tableData={props.hazardHistory}
                hideColumns={["_id", "whats_changed", "rejected_data"]}
                renderRowSubComponent={renderRowSubComponent}
                extensible={true}
              ></ReactTable>
            </div>
          )}
        </div>
      )}
      <div
        className={
          approving ? "create-form create-form--approving" : "create-form"
        }
      >
        <div className="create-form__section">
          <h3>Location</h3>
          {approving ? (
            <p>
              Please use the <b>Hazard Approval Workflow</b> to approve, edit,
              or reject the information provided. Form fields will be made
              editable when you click <b>Edit</b>{" "}
              <FontAwesomeIcon
                icon={faEdit}
                className="create-form__approve-pane__icon"
              />{" "}
              on the relevant Workflow item.
            </p>
          ) : (
            <p>Give as much information as possible</p>
          )}

          <Input
            ref={localNameSelectInput}
            required={true}
            name="local_name"
            label="Local Name"
            value={values.local_name}
            onChange={handleInputChange}
            className={
              metaStatus.local_name !== ""
                ? "input__" + metaStatus.local_name
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.local_name === "edited" || approving === false
                ? false
                : true
            }
          />
          <div className="input__wrap input_route_du_elr">
            {routes && (
              <ReactSelect
                ref={routeSelectInput}
                fieldsErrors={fieldsErrors}
                name="route"
                label="Route"
                placeholder="Select Route"
                value={values.route}
                valueLabel={["zonedescription"]}
                data={routes}
                onChange={handleInputChangeG}
                required={true}
                className={
                  metaStatus.route !== "" ? "input__" + metaStatus.route : ""
                }
                isDisabled={
                  ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                    props.actionType
                  )
                    ? true
                    : metaStatus.route === "edited" || approving === false
                    ? false
                    : true
                }
              />
            )}

            {props.data.elrs && (
              <ReactSelect
                ref={elrSelectInput}
                fieldsErrors={fieldsErrors}
                name="elr"
                label="ELR"
                placeholder="Select ELR"
                valueLabel={["elr", "description"]}
                data={filteredElr}
                loading={filteredElr && filteredElr[0] === "loading..."}
                required={true}
                onChange={handleInputChangeG}
                value={values.elr}
                className={
                  metaStatus.elr !== "" ? "input__" + metaStatus.elr : ""
                }
                isDisabled={
                  ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                    props.actionType
                  )
                    ? true
                    : metaStatus.elr === "edited" || approving === false
                    ? false
                    : true
                }
              />
            )}

            {props.data.mduList && (
              <ReactSelect
                ref={duSelectInput}
                fieldsErrors={fieldsErrors}
                name="mdu"
                label="DU"
                placeholder="Select DU"
                valueLabel={["subzone", "subzone_name"]}
                required={true}
                data={filteredMdu}
                loading={filteredMdu && filteredMdu[0] === "loading..."}
                onChange={handleInputChangeG}
                value={values.mdu}
                className={
                  metaStatus.mdu !== "" ? "input__" + metaStatus.mdu : ""
                }
                isDisabled={
                  ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                    props.actionType
                  )
                    ? true
                    : metaStatus.mdu === "edited" || approving === false
                    ? false
                    : true
                }
              />
            )}
          </div>

          <Input
              ref={locationInput}
              name="location"
              label="Location"
              value={values.location}
              hint="Max of 100 characters"
              maxLength={100}
              onChange={handleInputChange}
              className={
                metaStatus.location !== "" ? "input__" + metaStatus.location : ""
              }
              disabled={
                ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                    props.actionType
                )
                    ? true
                    : metaStatus.location === "edited" || approving === false
                        ? false
                        : true
              }
          />
          {props.data.trackIdLookup && (
            <ReactSelect
              ref={trackIdSeclect}
              name="track_id"
              label="Track ID"
              placeholder="Select Track ID"
              valueLabel={["trackid", "description"]}
              required={true}
              data={props.data.trackIdLookup}
              value={values.track_id}
              onChange={handleInputChangeG}
              className={
                metaStatus.track_id !== ""
                  ? "input__" + metaStatus.track_id
                  : ""
              }
              isDisabled={
                ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                  props.actionType
                )
                  ? true
                  : metaStatus.track_id === "edited" || approving === false
                  ? false
                  : true
              }
            />
          )}
          <Input
            ref={postcodeInput}
            // required={true}
            name="postcode"
            label="Postcode"
            value={values.postcode}
            onChange={handleInputChange}
            pattern="^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$"
            className={
              metaStatus.postcode !== "" ? "input__" + metaStatus.postcode : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.postcode === "edited" || approving === false
                ? false
                : true
            }
          />
        <Input
            ref={purposeUsageInput}
            name="purpose_usage"
            label="Purpose/Usage"
            value={values.purpose_usage}
            hint="Max of 100 characters"
            maxLength={100}
            onChange={handleInputChange}
            className={
              metaStatus.purpose_usage !== "" ? "input__" + metaStatus.purpose_usage : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                  props.actionType
              )
                  ? true
                  : metaStatus.purpose_usage === "edited" || approving === false
                      ? false
                      : true
            }
        />
      </div>

        <div className="create-form__section">
          <h3>OS Grid</h3>
          <Input
            ref={eastingInput}
            fieldsErrors={fieldsErrors}
            type="number"
            name="easting"
            label="East"
            hint="E.g. 322796.4"
            value={values.easting}
            required={
              values.latitude === "" || values.longitude === "" ? true : false
            }
            clearErrors={
              values.latitude === "" || values.longitude === "" ? false : true
            }
            onChange={handleInputChange}
            className={
              metaStatus.easting !== "" ? "input__" + metaStatus.easting : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.easting === "edited" || approving === false
                ? false
                : true
            }
          />
          <Input
            ref={northingInput}
            fieldsErrors={fieldsErrors}
            type="number"
            name="northing"
            label="North"
            hint="E.g. 672137.3"
            required={
              values.latitude === "" || values.longitude === "" ? true : false
            }
            clearErrors={
              values.latitude === "" || values.longitude === "" ? false : true
            }
            value={values.northing}
            onChange={handleInputChange}
            className={
              metaStatus.northing !== "" ? "input__" + metaStatus.northing : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.northing === "edited" || approving === false
                ? false
                : true
            }
          />
        </div>

        <div className="create-form__section">
          <h3>GPS</h3>
          <Input
            ref={latitudeInput}
            fieldsErrors={fieldsErrors}
            type="number"
            name="latitude"
            label="Lat"
            hint="E.g. 54.118392"
            value={values.latitude}
            onChange={handleInputChange}
            className={
              metaStatus.latitude !== "" ? "input__" + metaStatus.latitude : ""
            }
            required={
              values.easting === "" || values.northing === "" ? true : false
            }
            clearErrors={
              values.easting === "" || values.northing === "" ? false : true
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.latitude === "edited" || approving === false
                ? false
                : true
            }
          />
          <Input
            ref={longitudeInput}
            fieldsErrors={fieldsErrors}
            type="number"
            name="longitude"
            label="Long"
            hint="E.g. -3.2249393"
            value={values.longitude}
            onChange={handleInputChange}
            required={
              values.easting === "" || values.northing === "" ? true : false
            }
            clearErrors={
              values.easting === "" || values.northing === "" ? false : true
            }
            className={
              metaStatus.longitude !== ""
                ? "input__" + metaStatus.longitude
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.longitude === "edited" || approving === false
                ? false
                : true
            }
          />
        </div>

        <div className="create-form__section">
          <h3>ELR</h3>
          <Input
            ref={startMileageSelectInput}
            type="number"
            name="start_mileage"
            label="Start Miles"
            value={values.start_mileage}
            min={values.mdu?.startmiles}
            max={values.mdu?.endmiles}
            step="0.0001"
            onChange={handleInputChange}
            onBlur={(e) => { validateMileages(e.target.value, e.target.name) }}
            required={true}
            className={
              metaStatus.startMiles !== ""
                ? "no-increment input__" + metaStatus.start_mileage
                : "no-increment input__"
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.start_mileage === "edited" || approving === false
                ? false
                : true
            }
            fieldsErrors={fieldsErrors}
          />
          <Input
            ref={endMileageSelectInput}
            type="number"
            name="end_mileage"
            label="End Miles"
            min={values.mdu?.startmiles}
            max={values.mdu?.endmiles}
            step="0.0001"
            value={values.end_mileage}
            onChange={handleInputChange}
            onBlur={(e) => { validateMileages(e.target.value, e.target.name) }}
            required={true}
            className={
              metaStatus.end_mileage !== ""
                ? "no-increment input__" + metaStatus.end_mileage
                : "no-increment input__"
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.end_mileage === "edited" || approving === false
                ? false
                : true
            }
            fieldsErrors={fieldsErrors}
          />
        </div>

        <div className="create-form__section">
          <h3>Hazard details</h3>

          <ReactSelect
            name="hazardType"
            label="Hazard Type"
            data={Object.values(props.data?.hazardTypes || {})}
            value={values.hazardType || "All"}
            onChange={handleInputChangeG}
            className={
              metaStatus.hazardType !== ""
                ? "input__" + metaStatus.hazardType
                : ""
            }
            isDisabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.hazardType === "edited" || approving === false
                ? false
                : true
            }
          />

          <ReactSelect
            ref={hazardCodeSelectInput}
            required={true}
            name="hazard_code"
            label="Hazard Code"
            placeholder="Select Hazard Code"
            valueLabel={["hazardCode", "description"]}
            data={hazardCodes || initialVals.hazardCodes}
            onChange={handleInputChangeG}
            value={values.hazard_code}
            className={
              metaStatus.hazard_code !== ""
                ? "input__" + metaStatus.hazard_code
                : ""
            }
            isDisabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.hazard_code === "edited" || approving === false
                ? false
                : true
            }
          />
        </div>
        <div className="create-form__section">
          <ReactSelect
            ref={operationalSelectInput}
            name="status_code"
            label="Operational Status"
            placeholder="Select Operational Status Code"
            valueLabel={["status_code", "description"]}
            data={statusCodes || initialVals.statusCodes}
            onChange={handleInputChangeG}
            value={values.status_code}
            className={
              metaStatus.status_code !== ""
                  ? "input__" + metaStatus.status_code
                  : ""
            }
            isDisabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                  props.actionType
              )
                  ? true
                  : metaStatus.status_code === "edited" || approving === false
                      ? false
                      : true
            }
            required={approving}
          />
        </div>

        <div className="create-form__section fields-wrapper">
          <div style={{ gridColumn: "1/-1" }}>
            <h3>Hazard Category</h3>
          </div>
          <Checkbox
            name="category"
            value="confinedSpace"
            label="Confined space"
            onChange={handleInputChange}
            checked={values.category === "confinedSpace"}
            className={
              metaStatus.category !== ""
                ? "checkbox__" + metaStatus.category
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.category === "edited" || approving === false
                ? false
                : true
            }
          />
          <Checkbox
            name="category"
            value="accessPoints"
            label="Access points"
            onChange={handleInputChange}
            checked={values.category === "accessPoints"}
            className={
              metaStatus.category !== ""
                ? "checkbox__" + metaStatus.category
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete","myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.category === "edited" || approving === false
                ? false
                : true
            }
          />
          <Checkbox
            name="category"
            value="pipelines"
            label="Pipelines"
            onChange={handleInputChange}
            checked={values.category === "pipelines"}
            className={
              metaStatus.category !== ""
                ? "checkbox__" + metaStatus.category
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate" ].includes(
                props.actionType
              )
                ? true
                : metaStatus.category === "edited" || approving === false
                ? false
                : true
            }
          />
          <Checkbox
            name="category"
            value="contaminatedArea"
            label="Contaminated area"
            onChange={handleInputChange}
            checked={values.category === "contaminatedArea"}
            className={
              metaStatus.category !== ""
                ? "checkbox__" + metaStatus.category
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.category === "edited" || approving === false
                ? false
                : true
            }
          />
          <Checkbox
            name="category"
            value="walkingRoutes"
            label="Walking routes"
            onChange={handleInputChange}
            checked={values.category === "walkingRoutes"}
            className={
              metaStatus.category !== ""
                ? "checkbox__" + metaStatus.category
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.category === "edited" || approving === false
                ? false
                : true
            }
          />
          <Checkbox
            name="category"
            value="cables"
            label="Cables"
            onChange={handleInputChange}
            checked={values.category === "cables"}
            className={
              metaStatus.category !== ""
                ? "checkbox__" + metaStatus.category
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.category === "edited" || approving === false
                ? false
                : true
            }
          />
          <Checkbox
            name="category"
            value="environmental"
            label="Environmental"
            onChange={handleInputChange}
            checked={values.category === "environmental"}
            className={
              metaStatus.category !== ""
                ? "checkbox__" + metaStatus.category
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.category === "edited" || approving === false
                ? false
                : true
            }
          />
          <Checkbox
            name="category"
            value="restrictedAccess"
            label="Restricted access"
            onChange={handleInputChange}
            checked={values.category === "restrictedAccess"}
            className={
              metaStatus.category !== ""
                ? "checkbox__" + metaStatus.category
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.category === "edited" || approving === false
                ? false
                : true
            }
          />
          <Checkbox
            name="category"
            value="subSurface"
            label="Sub surface"
            onChange={handleInputChange}
            checked={values.category === "subSurface"}
            className={
              metaStatus.category !== ""
                ? "checkbox__" + metaStatus.category
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.category === "edited" || approving === false
                ? false
                : true
            }
          />
          <Checkbox
            name="category"
            value="weightRestriction"
            label="Weight restriction"
            onChange={handleInputChange}
            checked={values.category === "weightRestriction"}
            className={
              metaStatus.category !== ""
                ? "checkbox__" + metaStatus.category
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.category === "edited" || approving === false
                ? false
                : true
            }
          />
          <Checkbox
            name="category"
            value="workingPractices"
            label="Working practices"
            onChange={handleInputChange}
            checked={values.category === "workingPractices"}
            className={
              metaStatus.category !== ""
                ? "checkbox__" + metaStatus.category
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.category === "edited" || approving === false
                ? false
                : true
            }
          />
        </div>

        <div className="create-form__section">
          <Textarea
            name="free_text"
            value={values.free_text}
            onChange={handleInputChange}
            label="Free Text"
            className={
              metaStatus.free_text !== ""
                ? "textarea__" + metaStatus.free_text
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.free_text === "edited" || approving === false
                ? false
                : true
            }
          />
        </div>
        <div className="create-form__section">
          <h3>Please Upload Evidence (Images)</h3>

          <Upload
            name="uploadImage"
            label="Upload Image"
            accept="image/*"
            multiple
            setFiles={setFiles}
            files={files}
            onClick={(e) => (e.target.value = null)}
            documents={props.documents}
            className={
              metaStatus.uploadImage !== ""
                ? "upload__" + metaStatus.uploadImage
                : ""
            }
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : metaStatus.uploadImage === "edited" || approving === false
                ? false
                : true
            }
          />
          <Upload
            camera="true"
            name="takePicture"
            label="Take Picture"
            accept="image/*"
            multiple
            setFiles={setFiles}
            files={files}
            onClick={(e) => (e.target.value = null)}
            documents={props.documents}
            disabled={
              ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                props.actionType
              )
                ? true
                : approving
            }
          />
        </div>
        <div className="create-form__section">
          <h4>Previews</h4>
          <div className="gallery">
            {files?.map((file) => {
              if (file.type.includes("image")) {
                return (
                  <div className="image__wrap">
                    <Button
                      action="tertiary"
                      onClick={(e) => removeFile(e, file.name)}
                      disabled={
                        ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                          props.actionType
                        )
                          ? true
                          : metaStatus.uploadImage === "edited" ||
                            approving === false
                          ? false
                          : true
                      }
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                    <div>{`${file.name} - ${file.size / 1000}KB`}</div>
                    <img src={file.preview} alt="" className="image" />
                  </div>
                );
              } else {
                return (
                  <div className="image__wrap">
                    <Button
                      action="tertiary"
                      onClick={(e) => removeFile(e, file.name)}
                      disabled={
                        ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                          props.actionType
                        )
                          ? true
                          : metaStatus.uploadImage === "edited" ||
                            approving === false
                          ? false
                          : true
                      }
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                    <div>{`${file.name} - ${file.size}KB`}</div>
                    <FontAwesomeIcon icon={faFile} />
                  </div>
                );
              }
            })}
            {props.documents
              ?.filter((document) => !filesToRemove.includes(document.id))
              .map((document) => {
                if (document.type.includes("image")) {
                  return (
                    <div className="image__wrap" key={document.id}>
                      <Button
                        action="tertiary"
                        onClick={(e) =>
                          setFilesToRemove((old) => [...old, document.id])
                        }
                        disabled={
                          ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                            props.actionType
                          )
                            ? true
                            : metaStatus.uploadImage === "edited" ||
                              approving === false
                            ? false
                            : true
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                      {/* <div>{`${file.name} - ${file.size}KB`}</div> */}
                      <img src={document.preview} alt="" className="image" />
                    </div>
                  );
                } else {
                  return (
                    <div className="image__wrap">
                      <Button
                        action="tertiary"
                        onClick={(e) =>
                          setFilesToRemove((old) => [...old, document.id])
                        }
                        disabled={
                          ["delete", "viewhazard", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                            props.actionType
                          )
                            ? true
                            : metaStatus.uploadImage === "edited" ||
                              approving === false
                            ? false
                            : true
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                      <div
                        style={{
                          height: "200px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div>{`${document.type}`}</div>
                        <FontAwesomeIcon
                          style={{ fontSize: "10em" }}
                          icon={faFile}
                        />
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
        <div className="create-form__section">
          <h3>Submission Details</h3>

          <div className="create-form__sub-section">
            <h4>Submission From</h4>
            <Input
              name="fromSignature"
              label="Signature"
              value={values.fromSignature}
              onChange={handleInputChange}
              required={true}
              disabled={true}
            />
            <div className="input__wrap">
              <label className="input__label">Date:</label>
              <SingleDatePicker
                date={values.fromDate}
                onDateChange={(date) => handleInputChange(date, "fromDate")}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                id="fromDate"
                numberOfMonths={1}
                block={true}
                showDefaultInputIcon={true}
                displayFormat={() => "DD MMMM YYYY"}
                isOutsideRange={falseFunc}
                disabled={
                  ["delete", "viewhazard", "propose amend", "propose delete", "myhazards-propose-delete", "re-instate"].includes(
                    props.actionType
                  )
                    ? true
                    : metaStatus.fromDate === "edited" || approving === false
                    ? false
                    : true
                }
              />
            </div>
          </div>
          <div className="create-form__sub-section">
            <h4>Submission To</h4>
            <ReactSelect
                ref={sendToAddressSelectInput}
                fieldsErrors={fieldsErrors}
                name="sendToAddress"
                label="Select from Address Book"
                placeholder="Select from Address Book"
                data={sendToAddresses || props.initialVals?.sendToAddresses}
                value={values?.sendToAddress}
                valueLabel={["name"]}
                onChange={handleInputChangeG}
                isDisabled={
                  ["delete", "viewhazard"].includes(
                      props.actionType
                  )
                      ? true : approving !== false
                }
                required={props.actionType === "propose delete" || props.actionType === "myhazards-propose-delete" || props.actionType === "re-instate" || props.actionType === "myhazards"}
            />
            <Input
              name="name"
              label="Name"
              value={values.name === undefined || values.name === "" ? props.initialVals?.userDetails?.firstName === undefined ? "" : props.initialVals?.userDetails?.firstName + " " + props.initialVals?.userDetails?.surname : values.name}
              onChange={handleInputChange}
              disabled={true}
            />
            <Input
              name="company"
              label="Company"
              value={values.company === undefined || values.company === "" ? props.initialVals?.userDetails?.organisation : values.company}
              onChange={handleInputChange}
              disabled={true}
            />
            <Input
              name="buildingName"
              label="Building Name"
              value={values.buildingName === undefined || values.buildingName === "" ? props.initialVals?.userDetails?.address?.buildingName : values.buildingName}
              onChange={handleInputChange}
              disabled={true}
            />
            <Input
              name="address1"
              label="Address 1"
              value={values.address1 === undefined || values.address1 === "" ? props.initialVals?.userDetails?.address?.address1 : values.address1}
              onChange={handleInputChange}
              disabled={true}
            />
            <Input
              name="address2"
              label="Address 2"
              value={values.address2 === undefined || values.address2 === "" ? props.initialVals?.userDetails?.address?.address2 : values.address2}
              onChange={handleInputChange}
              disabled={true}
            />
            <Input
              name="address3"
              label="Address 3"
              value={values.address3 === undefined || values.address3 === "" ? props.initialVals?.userDetails?.address?.address3 : values.address3}
              onChange={handleInputChange}
              disabled={true}
            />
            <Input
              name="phone"
              label="Phone"
              value={values.phone || props.initialVals?.userDetails?.telephoneNumber}
              onChange={handleInputChange}
              disabled={true}
            />
            <Input
              name="email"
              label="Email"
              value={values.email || props.initialVals?.email}
              onChange={handleInputChange}
              disabled={true}
            />

            <Input
              name="toSignature"
              label="Signature"
              value={values.toSignature || props.initialVals?.toSignature}
              onChange={handleInputChange}
              disabled={true}
            />
            <div className="input__wrap">
              <label className="input__label">Date:</label>
              <SingleDatePicker
                date={values.toDate}
                onDateChange={(date) => handleInputChange(date, "toDate")}
                focused={tfocused}
                onFocusChange={({ focused }) => settFocused(focused)}
                id="toDate"
                numberOfMonths={1}
                block={true}
                showDefaultInputIcon={true}
                openDirection="up"
                displayFormat={() => "DD MMMM YYYY"}
                isOutsideRange={falseFunc}
                disabled={true}
              />
            </div>
          </div>
        </div>
        {approving ? (
          ""
        ) : (
          <>
            <div className="button-group create-form__submit">
              {loading && (
                <BouncingDotsLoader style={{ width: "15px", height: "15px" }} />
              )}
              {!loading && !values.submission_status && props.actionType === "new" && (
                <>
                  <Button
                    action="secondary"
                    label="Reset"
                    onClick={handleReset}
                  />
                  <Button
                    action="primary"
                    label="Propose New"
                    onClick={handleSubmit}
                  />
                </>
              )}

              {!loading &&
                (props.actionType === "myhazards" || props.actionType === "myhazards-propose-delete") &&  (
                    <>
                      {props.actionType === "myhazards-propose-delete" && (
                          <Input
                              ref={proposeDeleteReason}
                              required={true}
                              name="propose_delete_reason"
                              label="Reason"
                              value={values.propose_delete_reason}
                              onChange={handleInputChange}
                              className={
                                metaStatus.propose_delete_reason !== ""
                                    ? "input__" + metaStatus.propose_delete_reason
                                    : ""
                              }
                              disabled={false}
                          />
                      )}
                      <Button
                        action="primary"
                        label="Submit"
                        onClick={handleEditHazard}
                      />
                    </>
                )}
              {!loading &&
                props.actionType === "propose amend" && (
                  <Button
                    action="primary"
                    label="Propose Amendment"
                    onClick={handleAmendHazard}
                  />
                )}
              {!loading &&
                props.actionType === "propose delete" &&
                props.actionType !== "myhazards" &&
                props.initialVals?.submission_status !==
                  "Proposed Deletion" && (
                  <>
                    <Input
                      ref={proposeDeleteReason}
                      required={true}
                      name="propose_delete_reason"
                      label="Reason"
                      value={values.propose_delete_reason}
                      onChange={handleInputChange}
                      className={
                        metaStatus.propose_delete_reason !== ""
                          ? "input__" + metaStatus.propose_delete_reason
                          : ""
                      }
                      disabled={false}
                    />
                    <Button
                      action="primary"
                      label="Propose Delete"
                      onClick={handleProposeDeleteHazard}
                    />
                  </>
                )}
              {!loading &&
                  props.actionType === "re-instate" &&
                  props.actionType !== "myhazards" &&
                  props.initialVals?.submission_status !==
                  "Proposed Deletion" && (
                      <>
                        <Input
                            ref={proposeDeleteReason}
                            name="propose_delete_reason"
                            label="Reason"
                            value={values.propose_delete_reason}
                            onChange={handleInputChange}
                            className={
                              metaStatus.propose_delete_reason !== ""
                                  ? "input__" + metaStatus.propose_delete_reason
                                  : ""
                            }
                            disabled={true}
                        />
                        <Input
                          ref={reinstateReason}
                          name="reinstate_reason"
                          label="Re-instate Reason"
                          value={values.reinstate_reason}
                          onChange={handleInputChange}
                          className={
                            metaStatus.reinstate_reason !== ""
                                ? "input__" + metaStatus.reinstate_reason
                                : ""
                          }
                          required={true}
                        />
                        <Button
                            action="primary"
                            label="Re-instate Hazard"
                            onClick={handleProposeReinstateHazard}
                        />
                      </>
                  )}
              {!loading &&
                props.actionType === "delete" &&
                props.actionType !== "myhazards" &&
                props.initialVals?.submission_status &&
                props.initialVals?.submission_status ===
                  "Proposed Deletion" && (
                  <Button
                    action="primary"
                    label="Delete"
                    onClick={openModal}
                  />
                )}
              {error && !loading && (
                <div className="form-error">
                  <span className="input__error">{error}</span>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {approving ? (
        <div className="create-form__approve-pane approve-pane">
          {props.actionType !== "delete" && (
            <>
              <h3>Hazard Approval Workflow</h3>
              <p>
                For each of the fields please indicate either <b>Approve</b>{" "}
                <FontAwesomeIcon
                  icon={faCheck}
                  className="approve-pane__icon"
                />{" "}
                <b>Edit</b>{" "}
                <FontAwesomeIcon
                  icon={faEdit}
                  className="create-form__approve-pane__icon"
                />{" "}
                or <b>Reject</b>{" "}
                <FontAwesomeIcon
                  icon={faTimes}
                  className="create-form__approve-pane__icon"
                />
                . Rejecting any field will mark the form as rejected because all
                records in the Hazard Directory must be accurate and approved.
              </p>

              <p style={{fontWeight: "bold"}}><FontAwesomeIcon icon={faCircleInfo} className="approve-pane__icon"/>{" "} If any of the Location values Route, ELR and DU are change the ELR
                Start Miles and End Miles will need to be updated. These will be marked
                as requiring to edit and the validation for the mileage will be highlighted.</p>

              <div className="button-group" style={{ margin: "0 0 0px" }}>
                <Button
                  action="primary"
                  className="button--success"
                  onClick={(e) => {
                    Object.keys(metaStatus).forEach(function (key, index) {
                      if (key === "status_code") {
                        if (values.status_code !== undefined && values.status_code !== "") {
                          setMetaStatus((metaStatus) => ({
                            ...metaStatus,
                            [key]: "approved",
                          }));
                        }
                      }
                      if (metaStatus[key] === "" && key !== "status_code" ) {
                        setMetaStatus((metaStatus) => ({
                          ...metaStatus,
                          [key]: "approved",
                        }));
                      }
                    });
                  }}
                  disabled={approveStatus === "approved"}
                >
                  {approveStatus === "approved"
                    ? "All fields approved"
                    : "Approve all fields"}
                </Button>
              </div>

              {props.initialVals?.submission_status !== "Proposed Deletion" ? (
                <>
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="Local Name"
                    value={values?.local_name}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.local_name}
                    target="local_name"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.local_name}
                    disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="Route"
                    value={values?.route?.zonedescription}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.route}
                    target="route"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.route?.zonedescription}
                    disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="ELR"
                    value={values?.elr?.elr}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.elr}
                    target="elr"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.elr.elr}
                    disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label=" DU"
                    value={values?.mdu?.subzone + " - " + values?.mdu?.subzone_name}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.mdu}
                    target="mdu"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.mdu?.subzone + " - " + props.initialVals?.mdu?.subzone_name}
                    disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                      handleSetRejectedReasion={handleSetRejectedReason}
                      rejectedReason={rejectedReason}
                      label="Location"
                      value={values?.location}
                      handleApprove={handleApprove}
                      handleEdit={handleEdit}
                      handleReject={handleReject}
                      handleUndo={handleUndo}
                      metaStatus={metaStatus?.location}
                      target="location"
                      userName={
                          props.data.userDetails.userDetails.firstName +
                          " " +
                          props.data.userDetails.userDetails.surname
                      }
                      initialValue={props.initialVals?.location}
                      disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="Track ID/ Position"
                    value={values?.track_id?.trackid}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.track_id}
                    target="track_id"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.track_id?.trackid}
                    disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="Postcode"
                    value={values?.postcode}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.postcode}
                    target="postcode"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.postcode}
                    disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                      handleSetRejectedReasion={handleSetRejectedReason}
                      rejectedReason={rejectedReason}
                      label="Purpose/Usage"
                      value={values?.purpose_usage}
                      handleApprove={handleApprove}
                      handleEdit={handleEdit}
                      handleReject={handleReject}
                      handleUndo={handleUndo}
                      metaStatus={metaStatus?.purpose_usage}
                      target="purpose_usage"
                      userName={
                          props.data.userDetails.userDetails.firstName +
                          " " +
                          props.data.userDetails.userDetails.surname
                      }
                      initialValue={props.initialVals?.purpose_usage}
                      disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="Easting"
                    value={values?.easting}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.easting}
                    target="easting"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.easting}
                    disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="Northing"
                    value={values?.northing}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.northing}
                    target="northing"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.northing}
                    disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="latitude"
                    value={values?.latitude}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.latitude}
                    target="latitude"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.latitude}
                    disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="Longitude"
                    value={values?.longitude}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.longitude}
                    target="longitude"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.longitude}
                    disabled={showSendToSSMForm}
                  />
                  {elrRangeValid && !duChange ? (
                      <>
                        <ApprovePaneEntry
                            handleSetRejectedReasion={handleSetRejectedReason}
                            rejectedReason={rejectedReason}
                            label="Start Miles"
                            value={values?.start_mileage}
                            handleApprove={handleApprove}
                            handleEdit={handleEdit}
                            handleReject={handleReject}
                            handleUndo={handleUndo}
                            metaStatus={metaStatus?.start_mileage}
                            target="start_mileage"
                            userName={
                                props.data.userDetails.userDetails.firstName +
                                " " +
                                props.data.userDetails.userDetails.surname
                            }
                            initialValue={props.initialVals?.start_mileage}
                            disabled={showSendToSSMForm}
                        />
                      </>
                  ) : (
                      <>
                        <ApprovePaneEntry
                            handleSetRejectedReasion={handleSetRejectedReason}
                            rejectedReason={rejectedReason}
                            label="Start Miles"
                            value={values?.start_mileage}
                            // handleApprove={handleApprove}
                            handleEdit={handleEdit}
                            handleReject={handleReject}
                            handleUndo={handleUndo}
                            metaStatus={metaStatus?.start_mileage}
                            target="start_mileage"
                            userName={
                                props.data.userDetails.userDetails.firstName +
                                " " +
                                props.data.userDetails.userDetails.surname
                            }
                            initialValue={props.initialVals?.start_mileage}
                            disabled={showSendToSSMForm}
                            required={true}
                        />
                      </>
                  )}
                  {elrRangeValid && !duChange ? (
                      <>
                        <ApprovePaneEntry
                            handleSetRejectedReasion={handleSetRejectedReason}
                            rejectedReason={rejectedReason}
                            label="End Miles"
                            value={values?.end_mileage}
                            handleApprove={handleApprove}
                            handleEdit={handleEdit}
                            handleReject={handleReject}
                            handleUndo={handleUndo}
                            metaStatus={metaStatus?.end_mileage}
                            target="end_mileage"
                            userName={
                                props.data.userDetails.userDetails.firstName +
                                " " +
                                props.data.userDetails.userDetails.surname
                            }
                            initialValue={props.initialVals?.end_mileage}
                            disabled={showSendToSSMForm}
                        />
                      </>
                  ) : (
                      <ApprovePaneEntry
                          handleSetRejectedReasion={handleSetRejectedReason}
                          rejectedReason={rejectedReason}
                          label="End Miles"
                          value={values?.end_mileage}
                          // handleApprove={handleApprove}
                          handleEdit={handleEdit}
                          handleReject={handleReject}
                          handleUndo={handleUndo}
                          metaStatus={metaStatus?.end_mileage}
                          target="end_mileage"
                          userName={
                              props.data.userDetails.userDetails.firstName +
                              " " +
                              props.data.userDetails.userDetails.surname
                          }
                          initialValue={props.initialVals?.end_mileage}
                          disabled={showSendToSSMForm}
                          required={true}
                      />
                  )}
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="Hazard Code"
                    value={values?.hazard_code?.hazardCode}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.hazard_code}
                    target="hazard_code"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.hazard_code?.hazardCode}
                    disabled={showSendToSSMForm}
                  />

                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="Hazard Category"
                    value={values?.category}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.category}
                    target="category"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.category}
                    disabled={showSendToSSMForm}
                  />

                  {props.initialVals.status_code === undefined || props.initialVals.status_code === "" ? (
                      <>
                        <ApprovePaneEntry
                            ref={approveOperationalStatus}
                            handleSetRejectedReasion={handleSetRejectedReason}
                            label="Operational Status Code"
                            value={values.status_code === "" || values.status_code === undefined ? undefined : values.status_code?.status_code + " - " + values.status_code?.description}
                            handleEdit={handleEdit}
                            handleUndo={handleUndo}
                            metaStatus={metaStatus?.status_code}
                            target="status_code"
                            userName={
                                props.data.userDetails.userDetails.firstName +
                                " " +
                                props.data.userDetails.userDetails.surname
                            }
                            initialValue={""}
                            disabled={showSendToSSMForm}
                            required={true}
                        />
                      </>
                      ) : (
                          <>
                            <ApprovePaneEntry
                                handleSetRejectedReasion={handleSetRejectedReason}
                                rejectedReason={rejectedReason}
                                label="Operational Status Code"
                                value={values?.status_code?.status_code + " - " + values?.status_code?.description}
                                handleApprove={handleApprove}
                                handleEdit={handleEdit}
                                handleReject={handleReject}
                                handleUndo={handleUndo}
                                metaStatus={metaStatus?.status_code}
                                target="status_code"
                                userName={
                                    props.data.userDetails.userDetails.firstName +
                                    " " +
                                    props.data.userDetails.userDetails.surname
                                }
                                initialValue={props.initialVals?.status_code?.status_code + " - " + props.initialVals?.status_code?.description}
                                disabled={showSendToSSMForm}
                            />
                          </>
                  )}

                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="Free Text"
                    value={values?.free_text}
                    handleApprove={handleApprove}
                    handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.free_text}
                    target="free_text"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.free_text}
                    disabled={showSendToSSMForm}
                  />
                  <ApprovePaneEntry
                    handleSetRejectedReasion={handleSetRejectedReason}
                    rejectedReason={rejectedReason}
                    label="Upload Image"
                    value={values?.uploadImage}
                    handleApprove={handleApprove}
                    // handleEdit={handleEdit}
                    handleReject={handleReject}
                    handleUndo={handleUndo}
                    metaStatus={metaStatus?.uploadImage}
                    target="uploadImage"
                    userName={
                      props.data.userDetails.userDetails.firstName +
                      " " +
                      props.data.userDetails.userDetails.surname
                    }
                    initialValue={props.initialVals?.uploadImage}
                    disabled={showSendToSSMForm}
                  />
                </>
              ) : (
                ""
              )}
            </>
          )}
          <div className="approve-pane__submission">
            {!showSendToSSMForm && (
              <>
                {(props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("whsea-group") ||
                  props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("whsea-admin-group")) &&
                    values.asset_request && (approveStatus === "edited" || approveStatus === "approved") && (
                    <>
                      <ReactSelect
                        ref={routeSelectInput}
                        name="priority"
                        label="Priority"
                        placeholder="Select Priority"
                        value={values.priority}
                        data={["Normal", "High"]}
                        onChange={handleInputChangeG}
                        required={true}
                        // className={
                        //   metaStatus.route !== "" ? "input__" + metaStatus.route : ""
                        // }
                        // isDisabled={
                        //   ["delete", "viewhazard", "propose delete"].includes(props.actionType)
                        //     ? true
                        //     : metaStatus.route === "edited" || approving === false
                        //     ? false
                        //     : true
                        // }
                      ></ReactSelect>

                      <Input
                        name="fms"
                        label="FMS Reference"
                        value={values.fms}
                        onChange={handleInputChange}
                      ></Input>
                    </>

                  )}

                <h3>Finalise Hazard Approval</h3>
                {approveSubmit}
              </>
            )}
            {(props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("whsea-group") ||
              props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("whsea-admin-group")) &&
              props.initialVals?.asset_request &&
              showSendToSSMForm && (
                <>
                  <ReactSelect
                    ref={sendToSSMSelectInput}
                    name="sendToSSM"
                    label="Send to"
                    placeholder="Select SSM"
                    value={values.sendToSSM}
                    valueLabel={["name", "email"]}
                    data={sendToSSMLookup}
                    onChange={handleInputChangeG}
                  ></ReactSelect>
                </>
              )}
            {values.sendToSSM && !ssmSentLoading && (
              <div className="button-group">
                <Button
                  action="primary"
                  className="button--success"
                  onClick={handleSendToSSM}
                >
                  Send to SSM
                </Button>
              </div>
            )}
            {ssmSentLoading === true && (
                <div className="button-group">
                  <BouncingDotsLoader/>
                </div>
            )}
            {!props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("whsea") &&
              showSendToSSMForm
            }
          </div>
        </div>
      ) : (
        ""
      )}
      {showModal ? (
        <Modal
          setShowModal={setShowModal}
          handleDeleteHazard={handleDeleteHazard}
        />
      ) : null}
    </div>
  );
}

export default CreateHazardForm;
