import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChartLine,
    faCog,
    faDatabase,
    faExclamationTriangle,
    faInfoCircle,
    faSearch,
    faUsersCog
} from "@fortawesome/free-solid-svg-icons";

import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react";

function Nav(props) {
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams();

  const { user, route } = useAuthenticator((context) => [
    context.route,
    context.user,
  ]);

  const adminsRolesArray = ["ontrac-admin-group", "ssm-admin-group", "whsea-admin-group"];

  const SSMRolesArray = ["ontrac-admin-group", "SSM", "ssm-admin-group"];

  const WHSEARolesArray = ["ontrac-admin-group", "whsea-group", "whsea-admin-group"];

  const isAdmin =
    route === "authenticated" &&
    adminsRolesArray.some((ele) =>
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(ele)
    );

  const isSMM =
    route === "authenticated" &&
    SSMRolesArray.some((ele) =>
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(ele)
    );

  const isWHSEA =
    route === "authenticated" &&
    WHSEARolesArray.some((ele) =>
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(ele)
    );

  const adminMenu = isAdmin ? (
    <li className="nav__li">
      <button
        className="nav__button"
        onClick={() => {
          navigate("/admindash");
        }}
      >
        <FontAwesomeIcon icon={faUsersCog} className="nav__icon" />
        <span className="nav__title">Admin Section</span>
      </button>
    </li>
  ) : (
    ""
  );

  return (
    <nav className={isAdmin ? "nav nav--admin" : "nav"}>
      <ul className="nav__ul">
        {isSMM && (
          <li className="nav__li">
            <button
              className="nav__button"
              onClick={() => {
                navigate("/smmdashboard");
              }}
            >
              <FontAwesomeIcon icon={faChartLine} className="nav__icon" />
              <span className="nav__title">SSM Dashboard</span>
            </button>
          </li>
        )}
        {isWHSEA && (
          <li className="nav__li">
            <button
              className="nav__button"
              onClick={() => {
                navigate("/whesadashboard");
              }}
            >
              <FontAwesomeIcon icon={faChartLine} className="nav__icon" />
              <span className="nav__title">WHSEA Dashboard</span>
            </button>
          </li>
        )}
        <li className="nav__li">
          <button
            className="nav__button"
            onClick={() => {
              navigate("/");
            }}
          >
            <FontAwesomeIcon icon={faSearch} className="nav__icon" />
            <span className="nav__title">Search NHD</span>
          </button>
        </li>
        <li className="nav__li">
          <button
            className="nav__button"
            onClick={() => {
              navigate("/notify");
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="nav__icon"
            />
            <span className="nav__title">Hazard Notification</span>
          </button>
        </li>
        <li className="nav__li">
          <button
            className="nav__button"
            onClick={() => {
              navigate("/register");
            }}
          >
            <FontAwesomeIcon icon={faDatabase} className="nav__icon" />
            <span className="nav__title">Submission Register</span>
          </button>
        </li>
        <li className="nav__li">
          <button
            className="nav__button"
            onClick={() => {
              navigate("/generalinfo");
            }}
          >
            <FontAwesomeIcon icon={faInfoCircle} className="nav__icon" />
            <span className="nav__title">General Information</span>
          </button>
        </li>
        {adminMenu}
        <li className="nav__li nav__open-menu">
          <button
            className="nav__button"
            onClick={() => props.setOpen(!props.open)}
            onBlur={() => props.setOpen(false)}
            onMouseOut={() => props.setOpen(false)}
            onTouchCancel={() => props.setOpen(false)}
            onTouchEnd={() => props.setOpen(false)}
          >
            <FontAwesomeIcon icon={faCog} className="nav__icon" />
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
