import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";

function UsefulLinksPage(props) {
  return (
    <div className="page" id="usefulLinksPage">
      <Header title="Useful Links" />
      <BackButton navigateUrl="/generalinfo" />

      <div className="useful-link__wrap">
        <div className="useful-link">
          <h3 className="useful-link__h3">Network Rail</h3>
          <p className="useful-link__p">
            Network Rail owns, operates and develops Britain's railway
            infrastructure.
          </p>
          <p className="useful-link__p">
            <a
              href="https://www.networkrail.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              NetworkRail
            </a>
          </p>
        </div>
        <div className="useful-link">
          <h3 className="useful-link__h3">National Rail Enquiries</h3>
          <p className="useful-link__p">
            The definitive source of customer information for all passenger rail
            services on the National Rail network in England, Wales and
            Scotland.
          </p>
          <p className="useful-link__p">
            <a
              href="https://www.nationalrail.co.uk/stations_destinations/default.aspx"
              target="_blank"
              rel="noreferrer"
            >
              National Rail Enquiries
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default UsefulLinksPage;
