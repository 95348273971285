import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import BackButton from "../../components/BackButton";
import ReactTable from "../../components/ReactTable/ReactTable";
import ReactSelect from "../../components/Select/ReactSelect";
import {HazardsInHoldingReportService} from "../../services/hazardsInHolding.service";
import Button from "../../components/Button";
import BouncingDotsLoader from "../../components/Loader/BouncingDotsLoader";
import GeneralService from "../../services/general.service";
import {toast} from "react-toastify";
import downloadFile from "../../_helpers/downloadFile";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import generalService from "../../services/general.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus";

function HazardReviewRejection(props) {

    const [hazards, setHazards] = useState();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const initialVals = {
        route: "",
        mdu: "",
    };
    const [values, setValues] = useState(initialVals);
    const [filteredMdu, setFilteredMdu] = useState();
    const [routes, setRoutes] = useState();
    const [sortOptions, setSortOptions] = useState({accessor: '', direction: ''});

    function getHazardsInHolding(pageNumber, pageSize) {
        setLoading(true);
        HazardsInHoldingReportService.getHazardsInHolding(values, pageSize, pageNumber, sortOptions)
            .then((response) => {
                setHazards(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        generalService.getRoutes()
            .then((response) => {
                setRoutes(response.data);
            })
            .catch((error) => {
                // setRoutes("error");
                console.log(error);
            });
        setLoading(true);
        HazardsInHoldingReportService.getHazardsInHolding()
            .then((response) => {
                setHazards(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (hazards?.results.length > 0) {
            setValues((values) => ({
                ...values,
                sortBy: sortOptions?.accessor,
                sortOrder: sortOptions?.direction,
            }));
            handleSubmit(undefined, undefined, sortOptions);
        }
    }, [sortOptions])

    const refresh = () => {
        getHazardsInHolding();
    }

    const handleInputChange = (e, name) => {
        if (name === "route") {
            if (e !== undefined) {
                setValues((values) => ({
                    ...values,
                    [name]: e ? e : "",
                }));
                GeneralService.getZonecodeMDUSubzonecode({
                    zonecode: e.zonecode,
                })
                .then((response) => {
                    setFilteredMdu(response.data);
                })
                .catch((error) => {
                    setFilteredMdu();
                });
            } else {
                setValues((values) => ({
                    ...values,
                    route: "",
                    mdu: "",
                }));
                setFilteredMdu();
            }
        } else {
            if (name === "mduHr") {
                name = "mdu";
            }
            setValues((values) => ({
                ...values,
                [name]: e ? e : "",
            }));
        }
    };

    const handlePageSize = (val) => {
        setPage(page);
        setPageSize(val);
    };

    const handleTableSort = (accessor) => {
        let direction = sortOptions?.direction;

        if (!direction) {
            direction = 'dsc';
        } else if (direction === 'dsc') {
            direction = 'asc';
        } else {
            direction = '';
        }

        setSortOptions({
            accessor : accessor,
            direction : direction
        });
    };

    function handleReset() {
        setValues({...initialVals});
        // props.handleReset({ ...initialVals });
    }

    function handleSearch(pageNumber, pageSizeNumber) {
        getHazardsInHolding(pageNumber, pageSizeNumber);
    }

    function handleSubmit(pageVal, pageSizeVal) {
        let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
        let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
        setPage(pageNumber);
        setPageSize(pageSizeNumber);
        handleSearch(pageNumber, pageSizeNumber);
    }

    const handleEdit = (e, row) => {
        let data = row.row?.original;
        HazardsInHoldingReportService.notifySubmitter(data.hazard_id)
        .then((respose) => {
            console.log(respose);
            toast.success(`Email Sent to Submitter.`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch((error) => {
            console.log(error);
            toast.error(`Error Sending Email to Submitter`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    };

    function handleExport(pageSize, page) {
        HazardsInHoldingReportService.exportHazardsHolding(values, pageSize, page)
        .then((response) => {
            downloadFile(response);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <div className="page" id="deletedHazardsPage">
            <Header title="Hazards waiting Review/Rejection"/>
            <BackButton
                setPage={props.setPage}
                target={["adminDashPage", "backward"]}
            />

            <h2>Hazards waiting for Review or have been rejected.</h2>

            <div className="report-form">
                <div className="report-form__section">
                    <h2 className="admin-report-filter__title">
                        Search Filters
                    </h2>
                    <p>Plese select at leat one of the below values. You can select both and search for a result.</p>
                </div>
                <div className="report-form__section">
                    <ReactSelect
                        name="route"
                        label="Route"
                        placeholder="Select Route"
                        valueLabel={["zonedescription"]}
                        data={routes}
                        onChange={handleInputChange}
                        value={values.route}
                    />
                    <ReactSelect
                        name="mduHr"
                        label="MDU"
                        placeholder="Select MDU"
                        valueLabel={["subzonecode", "description"]}
                        data={filteredMdu || props.mduList}
                        loading={filteredMdu && filteredMdu[0] === "loading..."}
                        onChange={handleInputChange}
                        value={values.mdu}
                    />
                </div>
                <div className="button-group report-form__submit">
                    {loading && <BouncingDotsLoader></BouncingDotsLoader>}
                    {!loading && (
                        <Button action="secondary" label="Reset" onClick={handleReset}/>
                    )}
                    {!loading && (
                        <Button action="primary" label="Search" onClick={() => handleSearch(0, pageSize)}/>
                    )}

                </div>
            </div>
            {!loading && (
                <>
                    {hazards?.results && hazards?.results?.length > 0 ? (
                        <div>
                            <div className="button-group">
                                <Button
                                    action="primary"
                                    label="Export"
                                    onClick={() => handleExport(undefined, 0)}
                                />
                                <Button
                                    action="secondary"
                                    label="Refresh"
                                    onClick={refresh}
                                />
                            </div>
                            <ReactTable
                                tableData={hazards?.results}
                                showColumns={hazards?.headers}
                                customHeader={true}
                                customSearch={true}
                                currentPage={hazards?.pageNumber}
                                totalPages={hazards?.totalPages}
                                pageSize={hazards?.pageSize}
                                totalElements={hazards?.totalElements}
                                editHeader={"Notify Submitter"}
                                editAction={(e, row) => {
                                    handleEdit(e, row);
                                }}
                                editIcon={faEnvelope}
                                setPage={(val) => {
                                    setPage(val);
                                    handleSubmit(val, undefined);
                                }}
                                setPageSize={(val) => {
                                    handlePageSize(val);
                                    handleSubmit(0, val);
                                }}
                                sortOptions={sortOptions}
                                setSortOptions={setSortOptions}
                                handleTableSort={handleTableSort}
                            />
                        </div>
                    ) : <div className="loadingCircle">
                        No Results Based On Your Search Criteria
                        <span style={{marginLeft: "10px"}}> <FontAwesomeIcon icon={faMagnifyingGlassMinus}/> </span>
                    </div>}
                </>)
            }
        </div>
    );

}

export default HazardReviewRejection;