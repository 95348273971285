import axios from "../_helpers/axiosinstance";

const getUserSavedReports = async () => {
  return await axios.get(`${process.env.REACT_APP_nhd_hazard_search_ingress}/nhd/api/hazard/saved-reports`);
};

const getCustomReportById = async (id) => {
  // const response = await axios.get("/reports", {});
  // // const data = handleResponse(response);
  // // return data;
  // return response;
  return await axios.get(`${process.env.REACT_APP_nhd_hazard_search_ingress}/nhd/api/hazard/saved-reports/${id}`);
};

const MyReportsService = {
  getUserSavedReports,
  getCustomReportById,
};

export default MyReportsService;
