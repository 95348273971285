import React, {useEffect, useState} from "react";
import Header from "../components/Header.js";
import Button from "../components/Button.js";
import Checkbox from "../components/Checkbox.js";
import Input from "../components/Input.js";
import {useLocation, useNavigate} from "react-router-dom";
import ReactTable from "../components/ReactTable/ReactTable.js";
import ReactSelect from "../components/Select/ReactSelect.jsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faCircleNotch, faExclamationTriangle,} from "@fortawesome/free-solid-svg-icons";

import HazardManagementService from "../services/hazard-management.service.js";
import BackButton from "../components/BackButton.js";
import generalService from "../services/general.service";
import GeneralService from "../services/general.service";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus";

function RegisterPage(props) {
  const [routes, setRoutes] = useState();
  const [hazardCodes, setHazardCodes] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [submissionRegisterSelected, setSubmissionRegisterSelected] = useState();
  const [originalSubmissionRegisterSelected, setOriginalSubmissionRegisterSelected] = useState();
  const [sortOptions, setSortOptions] = useState({accessor: '', direction: ''});

  const [openFilters, setOpenFilters] = useState(false);

  const [showModal, setShowModal] = useState(false);

  let navigate = useNavigate();

  const { user } = useAuthenticator((context) => [context.user]);

  const { state } = useLocation();

  const approvingRolesArray = [
    "admin",
    "whsea-group",
    "whsea-admin-group",
    "SSM",
    "ssm-admin-group",
    "ontrac-admin-group",
  ];
  // const approvingRolesArray = ["admin", "whsea-group", "SSM"];
  const approving = approvingRolesArray.some((ele) =>
    props.user.signInUserSession.accessToken.payload["cognito:groups"].includes(
      ele
    )
  )
    ? true
    : false;

  useEffect(() => {
    if (routes === undefined) {
      GeneralService.getRoutes(true)
          .then((response) => {
            setRoutes(response.data);
          })
          .catch((error) => {
            // setRoutes("error");
            console.log(error);
          });
    }
    if (hazardCodes === undefined) {
      GeneralService.getHazardCodesAll(true)
        .then((response) => {
          setHazardCodes(response.data);
        })
        .catch((error) => {
          // setRoutes("error");
          console.log(error);
        });
    }
  }, [routes, hazardCodes]);

  useEffect(() => {
    if (items?.results?.length > 0) {
      setValues((values) => ({
        ...values,
        sortBy: sortOptions?.accessor,
        sortOrder: sortOptions?.direction,
      }));
      getSubmissionsRegister(0, pageSize);
    }
  }, [sortOptions])

  const setDefaultNewHazard = () => {
    setSubmissionRegisterSelected("New Hazard");
    setOriginalSubmissionRegisterSelected("New Hazard");
  }

  const setDefaultMyHazards = () => {
    setSubmissionRegisterSelected("My Hazards");
    setOriginalSubmissionRegisterSelected("My Hazards");
  }

  useEffect(() => {
    switch (state?.type) {
      case "New Hazard":
        setDefaultNewHazard();
        break;
      case "Amended Hazard":
        setSubmissionRegisterSelected("Amended Hazard");
        setOriginalSubmissionRegisterSelected("Amended Hazard");
        break;
      case "Hazard Deletion":
        setSubmissionRegisterSelected("Hazard Deletion");
        setOriginalSubmissionRegisterSelected("Hazard Deletion");
        break;
      case "My Hazards":
        setDefaultMyHazards()
        break;
      default:
        approving
          ? setDefaultNewHazard()
          : setDefaultMyHazards()
    }
  }, [state]);

  useEffect(() => {
    if (submissionRegisterSelected) getSubmissionsRegister();
  }, [submissionRegisterSelected]);

  const getSubmissionsRegister = (pageVal, pageSizeVal) => {
    console.log(submissionRegisterSelected);
    switch (submissionRegisterSelected) {
      case "New Hazard":
        getNewSubmissionsRegister(pageVal, pageSizeVal);
        break;
      case "Amended Hazard":
        getAmendSubmissionsRegister(pageVal, pageSizeVal);
        break;
      case "Hazard Deletion":
        getDeleteSubmissionsRegister(pageVal, pageSizeVal);
        break;
      case "My Hazards":
        getMySubmissionsRegister(pageVal, pageSizeVal);
        break;
      case "Filter":
        handleSubmit(values, pageVal, pageSizeVal);
        break;
      default:
        approving ? getNewSubmissionsRegister() : getMySubmissionsRegister();
    }
  };

  const getMySubmissionsRegister = (pageVal, pageSizeVal) => {
    setLoading(true);
    HazardManagementService.getMySubmissionsRegister({
      page: pageVal,
      pageSize: pageSizeVal,
      sortBy: sortOptions?.accessor,
      sortOrder: sortOptions?.direction
    })
      .then((response) => {
        setLoading(false);
        setItems(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const getNewSubmissionsRegister = (pageVal, pageSizeVal) => {
    setLoading(true);
    HazardManagementService.getNewSubmissionsRegister({
      page: pageVal,
      pageSize: pageSizeVal,
      sortBy: sortOptions?.accessor,
      sortOrder: sortOptions?.direction
    })
      .then((response) => {
        setLoading(false);
        setItems(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const getAmendSubmissionsRegister = (pageVal, pageSizeVal) => {
    setLoading(true);
    HazardManagementService.getAmendSubmissionsRegister({
      page: pageVal,
      pageSize: pageSizeVal,
      sortBy: sortOptions?.accessor,
      sortOrder: sortOptions?.direction
    })
      .then((response) => {
        setLoading(false);
        setItems(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const getDeleteSubmissionsRegister = (pageVal, pageSizeVal) => {
    setLoading(true);
    HazardManagementService.getDeleteSubmissionsRegister({
      page: pageVal,
      pageSize: pageSizeVal,
      sortBy: sortOptions?.accessor,
      sortOrder: sortOptions?.direction
    })
      .then((response) => {
        setLoading(false);
        setItems(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  const initialVals = {
    elr: "",
    startMiles: "",
    endMiles: "",
    lat: "",
    long: "",
    hazardType: "",
    hazardTypeCheck: "",
    route: "",
    mdu: "",
    status: "",
  };

  const [values, setValues] = useState(initialVals);
  const [filteredElr, setFilteredElr] = useState();
  const [filteredMdu, setFilteredMdu] = useState();

  function handleInputChange(e) {
    setValues((values) => ({ ...values, [e.target.name]: e.target.value }));
  }

  function handleSubmit(e, pageVal, pageSizeVal) {
    console.log("handleSubmit");
    setLoading(true);
    let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
    let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;

    console.log(values);
    console.log(pageNumber);
    console.log(pageSizeNumber);
    HazardManagementService.search(values, pageNumber, pageSizeNumber, sortOptions)
      .then((response) => {
        setLoading(false);
        setSubmissionRegisterSelected("Filter");
        setItems(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }

  function handleReset() {
    setValues({ ...initialVals });
    setSubmissionRegisterSelected(originalSubmissionRegisterSelected);
  }

  const handleInputChangeG = (e, name) => {
    if (name === "mduSR") {
      name = "mdu";
    }
    if (name === "route") {
      if (e !== undefined) {
        setFilteredElr(["loading..."]);
        setValues((values) => ({
          ...values,
          [name]: e ? e : "",
        }));
        generalService
          .getElrsZonecode(e.zonecode)
          .then((response) => {
            setFilteredElr(response.data);
          })
          .catch((error) => {
            setFilteredElr();
          });
      } else {
        setValues((values) => ({
          ...values,
          elr: "",
          mdu: "",
        }));
        setFilteredElr();
      }
    }
    if (name === "elrSR") {
      name = "elr";
      if (e !== undefined) {
        setFilteredMdu(["loading..."]);
        setValues((values) => ({
          ...values,
          mdu: "",
        }));
        generalService
          .getMDUSubzonecode({
            zonecode: e.zonecode,
            elr: e.elr,
          })
          .then((response) => {
            setFilteredMdu(response.data);
          })
          .catch((error) => {
            setFilteredMdu();
          });
      } else {
        setValues((values) => ({
          ...values,
          mdu: "",
        }));
        setFilteredMdu();
      }
    }

    setValues((values) => ({
      ...values,
      [name]: e ? e : "",
    }));
  };

  const handleclick = (submissionRegisterType) => {
    setSubmissionRegisterSelected(submissionRegisterType);
  };

  const handleEdit = (e, row) => {
    console.log(submissionRegisterSelected);
    switch (submissionRegisterSelected) {
      case "New Hazard":
        navigate(`/newhazard/${row.row.original.hazard_id}`, {
          state: { data: row.row?.original, pageTitle: "New Hazard" },
        });
        break;
      case "Amended Hazard":
        navigate(`/amendhazard/${row.row.original.hazard_id}`, {
          state: { data: row.row?.original, pageTitle: "Amend Hazard" },
        });
        break;
      case "Hazard Deletion":
        navigate(`/deletehazard/${row.row.original.hazard_id}`, {
          state: { data: row.row?.original, pageTitle: "Hazard Deletion" },
        });
        break;
      case "My Hazards":
        if (row.row?.original.submissionType === "Proposed Deletion") {
          navigate(`/myhazards/${row.row.original.hazard_id}`, {
            state: {data: row.row?.original, pageTitle: "Edit Hazard", actionType: "myhazards-propose-delete"}
          })
        } else {
          navigate(`/myhazards/${row.row.original.hazard_id}`, {
            state: { data: row.row?.original, pageTitle: "Edit Submission" },
          });
        }
        break;
      case "Filter":
        if (
          user.signInUserSession.accessToken.payload["cognito:groups"].includes(
            "standard-users"
          )
        ) {
          navigate(`/myhazards/${row.row.original.hazard_id}`, {
            state: { data: row.row?.original, pageTitle: "Edit Submission" },
          });
        } else if (row.row.original.submission_status === "New Submission") {
          navigate(`/newhazard/${row.row.original.hazard_id}`, {
            state: { data: row.row?.original, pageTitle: "New Submission" },
          });
        } else if (row.row.original.submission_status === "Pending Request") {
          navigate(`/amendhazard/${row.row.original.hazard_id}`, {
            state: { data: row.row?.original, pageTitle: "Amend Submission Request" },
          });
        } else if (row.row.original.submission_status === "Proposed Deletion") {
          navigate(`/deletehazard/${row.row.original.hazard_id}`, {
            state: { data: row.row?.original, pageTitle: "Proposed Delete Request" },
          });
        } else {
          navigate(`/viewhazard/${row.row.original.hazard_id}`, {
            state: { data: row.row?.original, actionType: "edit", pageTitle: "Edit Submission" },
          });
        }
      default:
        break;
    }
  };

  const handlePageSize = (val) => {
    setPage(0);
    setPageSize(val);
  };

  const handleUpdateSubmit = (pageVal, pageSizeVal) => {
    let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
    let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
    console.log(submissionRegisterSelected);
    if (submissionRegisterSelected)
      getSubmissionsRegister(pageNumber, pageSizeNumber);
  };

  const handleTableSort = (accessor) => {
    let direction = sortOptions?.direction;

    if (!direction) {
      direction = 'dsc';
    } else if (direction === 'dsc') {
      direction = 'asc';
    } else {
      direction = '';
    }

    setSortOptions({
      accessor : accessor,
      direction : direction
    });
  };

  return (
    <div className="page" id="registerPage">
      <Header title="Hazard Register" />
      <BackButton
        navigateUrl={state?.previousPage ? state.previousPage : "/"}
      />
      <div className="filter-form">
        <div className="filter-form__controller">
          <h2 className="filter-form__title inline-block">
            Submission Search Filters
          </h2>

          {openFilters ? (
            <FontAwesomeIcon
              className="inline-block filter-icon"
              icon={faAngleUp}
              onClick={() => setOpenFilters(!openFilters)}
              title="Collapse"
            />
          ) : (
            <FontAwesomeIcon
              className="inline-block filter-icon"
              icon={faAngleDown}
              onClick={() => setOpenFilters(!openFilters)}
              title="Expand"
            />
          )}
        </div>

        {openFilters && (
          <div>
            <div className="filter-form__group fields-wrapper">
              <ReactSelect
                name="route"
                label="Route"
                placeholder="Select Route"
                valueLabel={["zonedescription"]}
                data={routes}
                onChange={handleInputChangeG}
                value={values.route}
              />
              <ReactSelect
                name="elrSR"
                label="ELR"
                placeholder="Select ELR"
                valueLabel={["elr", "description"]}
                data={filteredElr || props.elrs}
                loading={filteredElr && filteredElr[0] === "loading..."}
                onChange={handleInputChangeG}
                value={values.elr}
              />
              <Input
                name="startMiles"
                label="Mileage From"
                value={values.startMiles}
                onChange={handleInputChange}
              />
              <Input
                name="endMiles"
                label="Mileage To"
                value={values.endMiles}
                onChange={handleInputChange}
              />

              <ReactSelect
                name="hazardCode"
                label="Hazard Code"
                placeholder="Select Hazard Code"
                valueLabel={["hazardCode", "description"]}
                data={hazardCodes}
                value={values.hazardCode}
                onChange={handleInputChangeG}
              />

              <ReactSelect
                name="mduSR"
                label="MDU"
                placeholder="Select MDU"
                valueLabel={["subzonecode", "description"]}
                data={filteredMdu || props.mduList}
                loading={filteredMdu && filteredMdu[0] === "loading..."}
                onChange={handleInputChangeG}
                value={values.mdu}
              />
              <ReactSelect
                name="submissionStatus"
                label="Submission Status"
                placeholder="Select Submission Status"
                valueLabel={["submission_state"]}
                data={props.submissionStatus}
                value={values.submissionStatus}
                // onChange={handleInputChange}
                onChange={handleInputChangeG}
              />
            </div>
            <div className="filter-form__group fields-wrapper">
              <Checkbox
                name="hazardTypeCheck"
                value="confinedSpace"
                label="Confined space"
                onChange={handleInputChange}
                checked={values.hazardTypeCheck === "confinedSpace"}
              />
              <Checkbox
                name="hazardTypeCheck"
                value="accessPoints"
                label="Access points"
                onChange={handleInputChange}
                checked={values.hazardTypeCheck === "accessPoints"}
              />
              <Checkbox
                name="hazardTypeCheck"
                value="pipelines"
                label="Pipelines"
                onChange={handleInputChange}
                checked={values.hazardTypeCheck === "pipelines"}
              />
              <Checkbox
                name="hazardTypeCheck"
                value="containmentArea"
                label="Containment area"
                onChange={handleInputChange}
                checked={values.hazardTypeCheck === "containmentArea"}
              />
              <Checkbox
                name="hazardTypeCheck"
                value="walkingRoutes"
                label="Walking routes"
                onChange={handleInputChange}
                checked={values.hazardTypeCheck === "walkingRoutes"}
              />
              <Checkbox
                name="hazardTypeCheck"
                value="cables"
                label="Cables"
                onChange={handleInputChange}
                checked={values.hazardTypeCheck === "cables"}
              />
              <Checkbox
                name="hazardTypeCheck"
                value="environmental"
                label="Environmental"
                onChange={handleInputChange}
                checked={values.hazardTypeCheck === "environmental"}
              />
              <Checkbox
                name="hazardTypeCheck"
                value="restrictedAccess"
                label="Restricted access"
                onChange={handleInputChange}
                checked={values.hazardTypeCheck === "restrictedAccess"}
              />
              <Checkbox
                name="hazardTypeCheck"
                value="subSurface"
                label="Sub surface"
                onChange={handleInputChange}
                checked={values.hazardTypeCheck === "subSurface"}
              />
              <Checkbox
                name="hazardTypeCheck"
                value="weightRestriction"
                label="Weight restriction"
                onChange={handleInputChange}
                checked={values.hazardTypeCheck === "weightRestriction"}
              />
              <Checkbox
                name="hazardTypeCheck"
                value="workingPractices"
                label="Working practices"
                onChange={handleInputChange}
                checked={values.hazardTypeCheck === "workingPractices"}
              />
            </div>
            <div className="button-group filter-form__submit">
              <Button action="secondary" label="Reset" onClick={handleReset} />
              <Button
                action="primary"
                label="Filter"
                onClick={(e) => handleSubmit(e, undefined, undefined)}
              />
            </div>
          </div>
        )}
      </div>

      <h2>Submission Register</h2>
      <div className="submission_register-group">
        {approving && (
          <div className="button-group button-group--bar">
            <Button
              action="secondary"
              className={
                submissionRegisterSelected &&
                submissionRegisterSelected !== "New Hazard"
                  ? ""
                  : `button--active`
              }
              label="New Hazard"
              onClick={(e) => handleclick("New Hazard")}
            />
            <Button
              action="secondary"
              className={
                submissionRegisterSelected === "Amended Hazard"
                  ? `button--active`
                  : ""
              }
              label="Amended Hazard"
              onClick={(e) => handleclick("Amended Hazard")}
            />
            <Button
              action="secondary"
              className={
                submissionRegisterSelected === "Hazard Deletion"
                  ? `button--active`
                  : ""
              }
              label="Hazard Deletion"
              onClick={(e) => handleclick("Hazard Deletion")}
            />
          </div>
        )}
        <div className="button-group button-group--bar">
          <Button
            action="secondary"
            className={
              submissionRegisterSelected &&
              submissionRegisterSelected !== "My Hazards"
                ? ""
                : `button--active`
            }
            label="My Hazards"
            onClick={(e) => handleclick("My Hazards")}
          />
        </div>
      </div>

      <div>
        <h2>Hazard Submissions</h2>
        {loading && (
          <div className="loadingCircle">
            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
          </div>
        )}
        {!loading && items?.results?.length > 0 ? (
          <ReactTable
            key={JSON.stringify(submissionRegisterSelected)}
            tableData={items?.results}
            customHeader={true}
            customSearch={true}
            showColumns={items?.headers}
            currentPage={items?.pageNumber}
            totalPages={items?.totalPages}
            pageSize={items?.pageSize}
            totalElements={items?.totalElements}
            setPage={(val) => {
              console.log("setPage");
              console.log(val);
              setPage(val);
              handleUpdateSubmit(val, undefined);
            }}
            setPageSize={(val) => {
              console.log("setPageSize");
              console.log(val);
              handlePageSize(val);
              handleUpdateSubmit(0, val);
            }}
            editAction={(e, row) => {
              handleEdit(e, row);
            }}
            sortOptions={sortOptions}
            setSortOptions={setSortOptions}
            handleTableSort={handleTableSort}
          ></ReactTable>
        ) : <div className="loadingCircle">
          No Results Based On Your Search Criteria
          <span style={{marginLeft: "10px"}}> <FontAwesomeIcon icon={faMagnifyingGlassMinus}/> </span>
        </div>

        }
      </div>
    </div>
  );
}

export default RegisterPage;
