import React from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import CreateHazardTypeForm from "../../components/CreateHazardTypeForm.js";
import {useLocation} from "react-router-dom";

function AdminAddHazardType(props) {
    const { state } = useLocation();

  return (
    <div className="page" id="adminAddHazardType">
      <Header title={state.pageTitle} />
      <BackButton />

      <CreateHazardTypeForm data={props} />
    </div>
  );
}

export default AdminAddHazardType;
