import React from "react";
import Header from "../../components/Header.js";
import Button from "../../components/Button.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {faFileImport} from "@fortawesome/free-solid-svg-icons/faFileImport";
import {faBarcode} from "@fortawesome/free-solid-svg-icons/faBarcode";
import BackButton from "../../components/BackButton";

function DataManagementDashPage(props) {
  const { user } = useAuthenticator((context) => [context.user]);

  let navigate = useNavigate();
  const { state } = useLocation();

  return (
    <div className="page" id="dataManDashPage">
      <Header title="NHD Data Management" />
      <BackButton
          navigateUrl={state?.previousPage ? state.previousPage : "/admindash"}
      />
      <div>
        <div className="admin-dash--card_wrap">
          <div className="card admin-dash--card">
            <FontAwesomeIcon icon={faBarcode} className="card__icon" />
            <h3 className="card__title">Hazard Code Management</h3>
            <p className="card__description">
              Manage hazard categories and hazard codes.
            </p>
            <Button
                action="round"
                label="Hazard Code"
                onClick={() => navigate("/hazardcodemanagement")}
            >
              <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
            </Button>
          </div>
          <div className="card admin-dash--card">
            <FontAwesomeIcon icon={faFolderOpen} className="card__icon" />
            <h3 className="card__title">Route/Region Management</h3>
            <p className="card__description">
              Manage Regions.
            </p>
            <Button
                action="round"
                label="Route/Region"
                onClick={() => navigate("/regionmnagement")}
            >
              <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
            </Button>
          </div>
          <div className="card admin-dash--card">
            <FontAwesomeIcon icon={faFileImport} className="card__icon"/>
            <h3 className="card__title">Import Boundary Data Update</h3>
            <p className="card__description">
              Import an ELR Boundary Data
            </p>
            <Button
                action="round"
                label="Import Boundary Data Update"
                onClick={() => navigate("/boundaryImport")}
            >
              <FontAwesomeIcon icon={faArrowRight} className="button__icon"/>
            </Button>
          </div>
          <div className="card admin-dash--card">
            <FontAwesomeIcon icon={faFileImport} className="card__icon"/>
            <h3 className="card__title">Import Hazards</h3>
            <p className="card__description">
              Import and update Hazards
            </p>
            <Button
                action="round"
                label="Import Hazards"
                onClick={() => navigate("/bulkhazardsImport")}
            >
              <FontAwesomeIcon icon={faArrowRight} className="button__icon"/>
            </Button>
          </div>
        </div>
      </div>


    </div>
  );
}

export default DataManagementDashPage;
