import React from "react";

import {useAuthenticator} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import {useLocation, useNavigate} from "react-router";

const Unauthorized = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  // let from = location.state?.from?.pathname || "/";
  // useEffect(() => {
  //   if (route === "authenticated") {
  //     navigate(from, { replace: true });
  //   }
  // }, [route, navigate, from]);
  return <h1>Unauthorized</h1>;
};

export default Unauthorized;
