import React, {useEffect, useState} from "react";
import HazardManagementService from "../services/hazard-management.service.js";
import {useLocation, useParams} from "react-router-dom";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import CreateHazardForm from "../components/CreateHazardForm.js";

import {useAuthenticator} from "@aws-amplify/ui-react";
import GeneralService from "../services/general.service";
import {UserService as userService} from "../services/user.service";

const HazardDetails = (props) => {
  const [values, setValues] = useState();
  const [initialValues, setInitialValues] = useState();
  const [hazardHistory, setHazardHistory] = useState();
  const [documents, setDocuments] = useState();
  const [routes, setRoutes] = useState();
  const [hazardCodes, setHazardCodes] = useState();
  const [statusCodes, setStatusCodes] = useState();
  const [dataRequest, setDataRequest] = useState(false);
  const [liveRequest, setLiveRequest] = useState(false);

  let { id } = useParams();
  const { state } = useLocation();
  const { user } = useAuthenticator((context) => [context.route]);

    useEffect(async () => {
        if (routes === undefined) {
            await GeneralService.getRoutes()
                .then((response) => {
                    setRoutes(response.data);
                })
                .catch((error) => {
                    // setRoutes("error");
                    console.log(error);
                });
        }
        if (hazardCodes === undefined) {
            await GeneralService.getHazardCodesAll(true)
                .then((response) => {
                    setHazardCodes(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        if (statusCodes === undefined) {
            await GeneralService.getOperationStatusAll()
                .then((response) => {
                    setStatusCodes(response.data);
                }).catch((error) => {
                    console.log(error);
                })
        }

    }, [routes, hazardCodes, statusCodes]);

    useEffect(async () => {
        if (id && routes && props.elrs && props.mduList && hazardCodes && statusCodes) {
            if (state.hazardLive) {
                if (!liveRequest) {
                    setLiveRequest(true)
                    HazardManagementService.getHazardById(id)
                        .then(async (response) => {
                            response.data.route = reactSelectValueRoute(
                                response.data.zonecode,
                                routes
                            );
                            response.data.elr = reactSelectValueElr(
                                response.data.elr,
                                response.data.zonecode,
                                props.elrs
                            );
                            let mdus = [];
                            await GeneralService.getSubzoneLookup(
                                response.data.elr,
                                response.data.zonecode
                            ).then((response) => {
                                mdus = response.data;
                            }).catch((error) => {
                                console.log(error);
                            });
                            response.data.mdu = reactSelectValueMDU(
                                response.data.subzone,
                                mdus
                            );
                            response.data.hazard_code = reactSelectValueHazardCode(
                                response.data.hazard_code,
                                hazardCodes
                            );

                            response.data.track_id = reactSelectValueTrackId(
                                response.data.track_id,
                                props.trackIdLookup
                            );

                            response.data.status_code = reactSelectStatusCode(
                                response.data.status_code,
                                statusCodes
                            );

                            response.data.sendToAddresses = await userService
                                .getUserSubmissionLookupWhsea({
                                    route: response.data.route,
                                    mdu: [response.data.subzone],
                                })
                                .then((response) => {
                                    return response.data;
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            if (state.actionType === "propose amend") {
                                response.data.reviewer = "";
                            }

                            setValues(JSON.parse(JSON.stringify(response.data)));
                            setInitialValues(JSON.parse(JSON.stringify(response.data)));

                            // setsingleHazard(response.data);
                        })
                        .catch((error) => {
                            // setsingleHazardLoaded(true);
                            // setsingleHazardError(error);
                        });

                    HazardManagementService.getHazardHistoryById(id)
                        .then((response) => {
                            setHazardHistory(response.data);

                        })
                        .catch((error) => console.log(error));
                }
            }
            if (!state.hazardLive && !state.hazardInactive) {
                HazardManagementService.getHoldingHazardById(id)
                    .then(async (response) => {
                        response.data.route = reactSelectValueRoute(
                            response.data.zonecode,
                            routes
                        );
                        response.data.elr = reactSelectValueElr(
                            response.data.elr,
                            response.data.zonecode,
                            props.elrs
                        );
                        let mdus = [];
                        await GeneralService.getSubzoneLookup({
                            zonecode: response.data.zonecode,
                            elr: response.data.elr.elr,
                        }).then((response) => {
                            mdus = response.data;
                        });
                        response.data.mdu = reactSelectValueMDU(
                            response.data.subzone,
                            mdus
                        );
                        response.data.hazard_code = reactSelectValueHazardCode(
                            response.data.hazard_code,
                            hazardCodes
                        );
                        response.data.track_id = reactSelectValueTrackId(
                            response.data.track_id,
                            props.trackIdLookup
                        );
                        response.data.status_code = reactSelectStatusCode(
                            response.data.status_code,
                            statusCodes
                        );

                        let userDetails = undefined;
                        let email = "";
                        if (response.data.reviewer) {
                            await userService.getUserFromUserId(response.data.reviewer)
                                .then((response1) => {
                                    userDetails = response1.data.userDetails;
                                    email = response1.data.email;
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        }

                        response.data.sendToAddresses = await userService
                            .getUserSubmissionLookupWhsea({
                                route: response.data.route,
                                mdu: [response.data.subzone],
                            })
                            .then((response) => {
                                return response.data;
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        response.data.userDetails = userDetails;
                        response.data.email = email;

                        let propose_delete_reason = "";
                        await HazardManagementService.getHoldingHazardHistoryById(id)
                            .then((response) => {
                                setHazardHistory(response.data);
                                propose_delete_reason = getProposeDeleteReason(response.data);
                            })
                            .catch((error) => console.log(error));
                        response.data.propose_delete_reason = propose_delete_reason;

                        setValues(JSON.parse(JSON.stringify(response.data)));
                        setInitialValues(JSON.parse(JSON.stringify(response.data)));

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            if (!state.hazardLive && state.hazardInactive) {
                //get hazard from inactive
                await HazardManagementService.getInactiveHazard(id)
                    .then(async (response) => {
                        response.data.route = reactSelectValueRoute(
                            response.data.zonecode,
                            routes
                        );
                        response.data.elr = reactSelectValueElr(
                            response.data.elr,
                            response.data.zonecode,
                            props.elrs
                        );
                        let mdus = [];
                        await GeneralService.getSubzoneLookup({
                            zonecode: response.data.zonecode,
                            elr: response.data.elr.elr,
                        }).then((response) => {
                            mdus = response.data;
                        });
                        response.data.mdu = reactSelectValueMDU(
                            response.data.subzone,
                            mdus
                        );
                        response.data.hazard_code = reactSelectValueHazardCode(
                            response.data.hazard_code,
                            hazardCodes
                        );
                        response.data.track_id = reactSelectValueTrackId(
                            response.data.track_id,
                            props.trackIdLookup
                        );
                        response.data.status_code = reactSelectStatusCode(
                            response.data.status_code,
                            statusCodes
                        );

                        let userDetails = undefined;
                        let email = "";

                        response.data.sendToAddresses = await userService
                            .getUserSubmissionLookupWhsea({
                                route: response.data.route,
                                mdu: [response.data.subzone],
                            })
                            .then((response) => {
                                return response.data;
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        response.data.userDetails = userDetails;
                        response.data.email = email;

                        let propose_delete_reason = "";
                        await HazardManagementService.getHazardHistoryById(id)
                            .then((response) => {
                                setHazardHistory(response.data);
                                propose_delete_reason = getProposeDeleteReason(response.data);
                            })
                            .catch((error) => console.log(error));
                        response.data.propose_delete_reason = propose_delete_reason;

                        setValues(JSON.parse(JSON.stringify(response.data)));
                        setInitialValues(JSON.parse(JSON.stringify(response.data)));

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }, [id, routes, props.elrs, props.mduList, hazardCodes, state.hazardLive, props.trackIdLookup, statusCodes, state.hazardInactive]);

  useEffect(() => {
    if (initialValues?.documents) {
      let map = new Map();
      initialValues.documents.forEach((element) => {
        HazardManagementService.getFilesUrlById(element).then((response) => {
          map.set(
            element,
            Object.assign(
              {},
              {
                preview: response.data.s3Url,
                id: element,
                type: response.data.fileType,
              }
            )
          );

          //   initialValues?.documents?.forEach((item) => map.set(item, item));

          let mergedArr = Array.from(map.values());
          setDocuments(mergedArr);
        });
      });
    }
  }, [initialValues?.documents]);

  useEffect(() => {
    document.body.scrollTo(0, 0);
    window.scrollTo(0, 0);
    document.getElementById("scroller").scroll(0, 0);
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
  }, []);

  const removeDocument = (e, id) => {
    HazardManagementService.deleteFileById(id)
      .then((response) => {
        setDocuments(documents.filter((document) => document.id != id));
      })
      .catch((error) => console.log(error));
  };

  const reactSelectValueRoute = (details, propsList) => {
    let valueObj = [];

    if (details) {
      if (Array.isArray(details)) {
        valueObj = propsList?.filter((ele) => {
          return details.map((det) => det?.trim()).includes(ele.zonecode);
        });
        return valueObj;
      } else {
        valueObj = propsList.filter((ele) => {
          return details === ele.zonecode;
        });
        return valueObj[0];
      }
    }

    return undefined;
  };

  const reactSelectValueElr = (elr, zonecode, propsList) => {
    let valueObj = [];
    if (elr) {
      if (Array.isArray(elr)) {
        valueObj = propsList?.filter((ele) => {
          return elr.map((det) => det?.trim()).includes(ele.elr);
        });
        return valueObj;
      } else {
        valueObj = propsList.filter((ele) => {
          return elr === ele.elr && zonecode === ele.zonecode;
        });
          if (valueObj.length > 0) {
              return valueObj[0];
          } else {
              return elr;
          }
      }
    }
    return undefined;
  };

  const reactSelectValueMDU = (details, propsList) => {
    let valueObj = [];

    if (details) {
      if (Array.isArray(details)) {
        valueObj = propsList?.filter((ele) => {
          return details.map((det) => det?.trim()).includes(ele.subzone);
        });
        return valueObj;
      } else {
        valueObj = propsList.filter((ele) => {
          return details === ele.subzone;
        });
        return valueObj[0];
      }
    }

    return undefined;
  };

  const reactSelectValueHazardCode = (details, propsList) => {
    let valueObj = [];

    if (details) {
      if (Array.isArray(details)) {
        valueObj = propsList?.filter((ele) => {
          return details.map((det) => det?.trim()).includes(ele.hazardCode);
        });
        return valueObj;
      } else {
        valueObj = propsList.filter((ele) => {
          return details === ele.hazardCode;
        });
        return valueObj[0];
      }
    }

    return undefined;
  };

  const reactSelectValueTrackId = (details, propsList) => {
    let valueObj = [];
    if (details || details === 0) {
      if (Array.isArray(details)) {
        valueObj = propsList?.filter((ele) => {
          return details.map((det) => det?.trim()).includes(ele.trackid);
        });
        return valueObj;
      } else {
        valueObj = propsList.filter((ele) => {
          return details === ele.trackid;
        });

        return valueObj[0];
      }
    }

    return undefined;
  };

  const reactSelectStatusCode = (details, propsList) => {
      let valueObj = [];

      if (details) {
          if (Array.isArray(details)) {
              valueObj = propsList?.filter((ele) => {
                  return details.map((det) => det?.trim()).includes(ele.status_code);
              });
              return valueObj;
          } else {
              valueObj = propsList.filter((ele) => {
                  return details === ele.status_code;
              });
              return valueObj[0];
          }
      }

      return undefined;
  }

const getProposeDeleteReason = (data) => {
    let value = [];
    data.forEach(history => {
        if (value.length === 0 || value[0] === undefined) {
            let changes = history.whats_changed;
            let keys = changes ? Object.keys(changes) : [];
            value = keys?.map((key, index) => {
                if (key === "Reason for Deletion") {
                    return changes[key]["before"]
                }
            });
        }
    })

    return value.length > 0 ? value[0] : ""
}

  return (
    <div className="page" id="editHazardPage">
      <Header title={state.pageTitle} />
      <BackButton />

      <CreateHazardForm
        data={props}
        initialVals={initialValues}
        hazardHistory={hazardHistory}
        documents={documents}
        removeDocument={removeDocument}
        actionType={state.actionType ? state.actionType : props.actionType}
        user={user}
      />
      {/* {showForm} */}
    </div>
  );
};

export default HazardDetails;
