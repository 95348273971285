import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";

function SignalWireDegPage(props) {
  return (
    <div className="page" id="signalWireDegPage">
      <Header title="Signal Wire Degradation" />
      <BackButton navigateUrl="/generalinfo" />

      <div className="signal-wire-deg__wrap">
        <h3 className="signal-wire-deg__h3">
          Generic Signal Wire Degradation Instruction
        </h3>

        <p className="signal-wire-deg__p">
          <b>Working in Signaling Equipment Rooms & Apparatus Cases</b>
        </p>

        <p className="signal-wire-deg__p">
          Hazards may exist with regard to the condition of cables and wiring at
          signalling installations, which may pose a risk to the safety of the
          infrastructure or personnel if left uncontrolled. Precautionary
          measures may, therefore, be imposed from time to time, which could
          include restricting access and/or adoption of special working
          arrangements.
        </p>

        <p className="signal-wire-deg__p">
          The Regional Signal Engineer (RSE) maintains an up to date directory
          of sites with wiring/cable degradation and any special controls that
          are necessary. Advice and permission shall be obtained from the RSE at
          such installations.
        </p>
      </div>
    </div>
  );
}

export default SignalWireDegPage;
