import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";

function GreenZonePage(props) {
  return (
    <div className="page" id="greenZonePage">
      <Header title="Green Zone" />
      <BackButton navigateUrl="/generalinfo" />

      <div className="green-zone__wrap">
        <h2 className="green-zone__h2">Green Zone Availability Information</h2>
        <div className="green-zone">
          <h3 className="green-zone__h3">Green Zone Guide</h3>
          <p className="green-zone__p">
            Information on Green Zone working opportunities is contained in the
            Green Zone Guide
          </p>
          <p className="green-zone__p">
            <a
              href="http://gzam.networkrail.co.uk/GreenZoneGuide.htm"
              target="_blank"
              rel="noreferrer"
            >
              Networkrail Green Zone Guide
            </a>
          </p>
        </div>
        <div className="green-zone">
          <h3 className="green-zone__h3">Green Zone Planner</h3>
          <p className="green-zone__p">Green zone planner homepage</p>
          <p className="green-zone__p">
            <a
              href="http://gzam.networkrail.co.uk/"
              target="_blank"
              rel="noreferrer"
            >
              GZAM Networkrail
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default GreenZonePage;
