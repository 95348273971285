import React from "react";
import Button from "../components/Button.js";
import Header from "../components/Header.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useLocation, useNavigate, useParams} from "react-router-dom";

function NotifyPage(props) {
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams();

  return (
    <div className="page" id="notifyPage">
      <Header title="Hazard Action" />
      <div className="card__wrap">
        <div className="card card--width33">
          <video
            className="card__video"
            loop={false}
            autoPlay
            muted
            controls={false}
            playsInline
          >
            <source src="./video/NHD_New_Hazard_1_5.mp4" type="video/mp4" />
            Your browser does not support the video.
          </video>
          <h3 className="card__title">New Hazard</h3>
          <p className="card__description">
           Submit a request for a new Hazard.
          </p>
          <Button
            action="round"
            label="New Hazard"
            onClick={() =>
              navigate("/newhazard", {
                state: {
                  previousPage: "/notify",
                  pageTitle: "New Hazard"
                },
              })
            }
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width33">
          <video
            className="card__video"
            loop={false}
            autoPlay
            muted
            controls={false}
            playsInline
          >
            <source src="./video/NHD_Edit_Hazard1_4.mp4" type="video/mp4" />
            Your browser does not support the video.
          </video>
          <h3 className="card__title">Amendment of Existing Hazard</h3>
          <p className="card__description">
            Suggest updates and amendments to existing hazards in the Directory.
          </p>
          <Button
            action="round"
            label="Edit Hazard"
            onClick={() =>
              navigate("/customsearch", {
                state: {
                  tableAction: "Propose Amended Hazard",
                  previousPage: "/notify",
                  pageTitle: "Propose Amendment"
                },
              })
            }
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width33">
          <video
            className="card__video"
            loop={false}
            autoPlay
            muted
            controls={false}
            playsInline
          >
            <source src="./video/NHD_Delete_Hazard1_7.mp4" type="video/mp4" />
            Your browser does not support the video.
          </video>
          <h3 className="card__title">Deletion of Existing Hazard</h3>
          <p className="card__description">
            Request that a hazard record be removed if it is out of date or no
            longer needed.
          </p>
          <Button
            action="round"
            label="Delete Hazard"
            onClick={() =>
              navigate("/customsearch", {
                state: {
                  tableAction: "Propose Hazard Deletion",
                  previousPage: "/notify",
                  pageTitle: "Propose Deletion"
                },
              })
            }
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NotifyPage;
