import React, {useEffect, useState} from "react";
import Nav from "./components/Nav.js";
import UserNav from "./components/UserNav.js";

import SearchPage from "./pages/SearchPage.js";
import NotifyPage from "./pages/NotifyPage.js";
import RegisterPage from "./pages/RegisterPage.js";
import GeneralInfoPage from "./pages/GeneralInfoPage.js";

import CustomSearchPage from "./pages/CustomSearchPage.js";
import StandardReportsPage from "./pages/StandardReportsPage.js";
import MyReportsPage from "./pages/MyReportsPage.js";

import NewHazardPage from "./pages/NewHazardPage.js";
import DeleteHazardPage from "./pages/DeleteHazardPage.js";

import AccessPointsPage from "./pages/AccessPointsPage.js";
import AppendixAPage from "./pages/AppendixAPage.js";
import ExtractWorkSitePage from "./pages/ExtractWorkSitePage.js";
import ExtractElrPage from "./pages/ExtractElrPage.js";
import RedZonePage from "./pages/RedZonePage.js";
import SectionMduPage from "./pages/SectionMduPage.js";

import StationDirectoryPage from "./pages/StationDirectoryPage.js";
import GenericHazardsPage from "./pages/GenericHazardsPage.js";
import SignalWireDegPage from "./pages/SignalWireDegPage.js";
import SafetyHazardCodesPage from "./pages/SafetyHazardCodesPage.js";
import EnvironmentalHazardCodesPage from "./pages/EnvironmentalHazardCodesPage.js";
import GreenZonePage from "./pages/GreenZonePage.js";
import UsefulLinksPage from "./pages/UsefulLinksPage.js";
import BuriedServicesPage from "./pages/BuriedServicesPage.js";
import MobilePhoneCoveragePage from "./pages/MobilePhoneCoveragePage.js";
import HazardNotificationFormPage from "./pages/HazardNotificationFormPage.js";

import LogoutPage from "./pages/LogoutPage.js";
import UserGuidePage from "./pages/UserGuidePage.js";
import AgreementPage from "./pages/AgreementPage.js";
import PrivacyPage from "./pages/PrivacyPage.js";

import AdminDashPage from "./pages/admin/AdminDashPage.js";
import AdminReportsPage from "./pages/admin/AdminReportsPage.js";
import HazardCodeManagementPage from "./pages/data-management/HazardCodeManagementPage.js";
import AdminAddUser from "./pages/admin/AdminAddUser.js";
import AdminAddHazardType from "./pages/admin/AdminAddHazardType.js";

import {Route, Routes, useLocation, useNavigate} from "react-router-dom";

import GeneralService from "./services/general.service";

import data from "./datahc/data";
import LoginPage from "./pages/Unauthorized.jsx";
import {RequireAuth} from "./components/RequireAuth.jsx";
import WHSEADashboardPage from "./pages/Dashboards/WHSEA/WHSEADashboardPage.jsx";
import SMMDashboardPage from "./pages/Dashboards/SSM/SSMDashboardPage.jsx";
import OnboardingRequestDetails from "./pages/Details/OnboardingRequestDetails.jsx";
import UserDetails from "./pages/Details/UserDetails.jsx";
import HazardDetails from "./pages/HazardDetails.jsx";
import ThemeContextProvider from "./contexts/ThemeContextProvider.jsx";
import {useAuthenticator} from "@aws-amplify/ui-react";
import "react-toastify/dist/ReactToastify.css";
import CrimeHotspots from "./pages/CrimeHotspots.js";

import Modal from "react-modal";
import TermnsAndConditions from "./components/TermnsAndConditions/TermnsAndConditions.jsx";
import {UserService} from "./services/user.service.js";
import UserPermissionRequests from "./pages/UserPermissionRequests";
import V3ExportPage from "./pages/admin/V3ExportPage";
import InactiveHazards from "./pages/admin/InactiveHazards";
import HazardsReviewRejection from "./pages/admin/HazardsReviewRejection";
import OrgManagementPage from "./pages/admin/OrgManagementPage";
import OrganisationDetails from "./pages/Details/OrganisationDetails";
import DataManagementDashPage from "./pages/data-management/DataManagementDashPage";
import RegionManagementPage from "./pages/data-management/RegionManagementPage";
import DataAddZone from "./pages/data-management/DataAddZone";
import ImportBoundaryDataPage from "./pages/data-management/ImportBoundaryDataPage";
import HazardsImportDataPage from "./pages/data-management/HazardsImportDataPage";
import UserManagementDash from "./pages/admin/UserManagementDash";
import UserManagementPage from "./pages/admin/UserManagement";
import OnboardingPage from "./pages/admin/OnboardingPage";
import UserPermissionRequestsManagement from "./pages/admin/UserPermissionRequests";

function Main(props) {
  const [hazardTypes, setHazardTypes] = useState();
  const [hazardCodes, setHazardCodes] = useState();
  // const [recipientList, setRecipientList] = useState();
  const [mduList, setMduList] = useState();
  const [routes, setRoutes] = useState();
  const [elrs, setElrs] = useState();
  const [types, setTypes] = useState();
  const [submissionStatus, setSubmissionStatus] = useState();
  const [trackIdLookup, setTrackIdLookup] = useState();

  const [modalIsOpen, setIsOpen] = useState(true);

  const { user } = useAuthenticator((context) => [context.user]);
  const [userDetails, setUserDetails] = useState();

  const { route } = useAuthenticator((context) => [context.route]);
  const {state} = useLocation();

  useEffect(() => {
    if (route === "authenticated") {
      props.setLoggedIn(true);
    }
    // else {
    //   props.setLoggedIn(false);
    // }
  }, [route]);

  useEffect(() => {
    if (user) {
      UserService.getUserFromCognitoUsername(user.username)
        .then((response) => {
          setUserDetails(response.data);
          if (response.data.userDetails.newProfile === true) {
            navigate("/profile", {
              state: { title: "User Profile", formType: "profile" },
            })
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user]);

  const [open, setOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const navigate = useNavigate();

  function doResTable() {
    let resTables = document.querySelectorAll(".res-table");
    resTables.forEach(function (resTable) {
      let ths = resTable.querySelectorAll("th");
      ths.forEach(function (th, i) {
        let headerText;
        let columnNo;
        if (th.querySelectorAll("label").length !== 0) {
          headerText = th.children("label").textContent.trim();
          columnNo = i + 1;
        } else {
          headerText = th.textContent.trim();
          columnNo = i + 1;
        }
        headerText = headerText.length > 0 ? (headerText += ": ") : headerText;
        let thisTable = th.closest("table");
        let targetTds = thisTable.querySelectorAll(
          "td:nth-child(" + columnNo + ")"
        );
        targetTds.forEach(function (targetTd) {
          targetTd.setAttribute("data-before", headerText);
        });
      });
    });
  }
  doResTable();

  const isAdmin = user.userRole === "admin" ? true : false;

  useEffect(async () => {
    navigate("/");
    setHazardTypes(data.hazardTypesHC);
    // setHazardCodes(data.hazardCodesHC.results);
    // setRecipientList(data.recipientListHC);
    // setMduList(data.mduListHC.results);
    // setRoutes(data.routesHC.results);
    // setElrs(data.elrsHC.results);
    setTypes(data.typesHC);

    // // setRoutes("loading");
    // GeneralService.getRoutes()
    //   .then((response) => {
    //     setRoutes(response.data);
    //   })
    //   .catch((error) => {
    //     // setRoutes("error");
    //     console.log(error);
    //   });

    GeneralService.getElrs()
      .then((response) => {
        setElrs(response.data);
      })
      .catch((error) => {
        // setRoutes("error");
        console.log(error);
      });

    GeneralService.getMDU()
      .then((response) => {
        setMduList(response.data);
      })
      .catch((error) => {
        // setRoutes("error");
        console.log(error);
      });

    // GeneralService.getHazardCodesAll(true)
    //   .then((response) => {
    //     setHazardCodes(response.data);
    //   })
    //   .catch((error) => {
    //     // setRoutes("error");
    //     console.log(error);
    //   });

    GeneralService.getSubmissionStatus()
      .then((response) => {
        setSubmissionStatus(response.data);
      })
      .catch((error) => {
        // setRoutes("error");
        console.log(error);
      });

    GeneralService.getAllTrackId()
      .then((response) => {
        setTrackIdLookup(response.data);
      })
      .catch((error) => {
        setTrackIdLookup({});
        console.log(error);
      });
  }, []);

  let subtitle;

  function closeModal() {
    window.localStorage.setItem("termsandconditions", true);
    setIsOpen(false);
  }

  useEffect(() => {
    let termsandconditions = window.localStorage.getItem("termsandconditions");
    if (termsandconditions) {
      setIsOpen(false);
    }
  }, []);

  return (
    <div className={darkMode ? "App dark" : "App"}>
      <div className="wrap">
        <ThemeContextProvider>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            className="Modal"
            overlayClassName="Overlay"
            contentLabel="Example Modal"
          >
            <TermnsAndConditions closeModal={closeModal} />
          </Modal>
          <Nav setOpen={setOpen} open={open} />
          <div className="page__wrap" id="scroller">
            <Routes>
              <Route
                path="/smmdashboard"
                element={
                  <RequireAuth
                    allowedRoles={[
                      "ontrac-admin-group",
                      "SSM",
                      "ssm-admin-group",
                    ]}
                  >
                    <SMMDashboardPage user={user}/>
                  </RequireAuth>
                }
              />
              <Route
                path="/whesadashboard"
                // allowedRoles={["ontrac-admin-group", "whsea-group"]}
                element={
                  <RequireAuth
                    allowedRoles={[
                      "ontrac-admin-group",
                      "whsea-group",
                      "whsea-admin-group",
                    ]}
                  >
                    <WHSEADashboardPage elrs={elrs} mduList={mduList}/>
                  </RequireAuth>
                }
              />
              <Route path="/" element={<SearchPage />} />
              <Route path="notify" element={<NotifyPage />} />
              <Route
                path="register"
                element={
                  <RegisterPage
                    // routes={routes}
                    elrs={elrs}
                    mduList={mduList}
                    // hazardCode={hazardCodes}
                    submissionStatus={submissionStatus}
                    user={user}
                    userName={user.username}
                    // userDetails={userDetails}
                  />
                }
              />
              <Route
                path="customsearch"
                element={
                  <CustomSearchPage
                    // routes={routes}
                    elrs={elrs}
                    mduList={mduList}
                    // hazardCodes={hazardCodes}
                  />
                }
              />
              <Route
                path="customsearch/:id"
                element={
                  <CustomSearchPage
                    routes={routes}
                    elrs={elrs}
                    // mduList={mduList}
                    hazardCodes={hazardCodes}
                  />
                }
              />
              <Route path="standardreports" element={<StandardReportsPage />} />
              <Route path="myreports" element={<MyReportsPage />} />
              <Route path="generalinfo" element={<GeneralInfoPage />} />
              <Route
                path="newhazard"
                element={
                  <NewHazardPage
                    hazardTypes={hazardTypes}
                    // hazardCodes={hazardCodes}
                    trackIdLookup={trackIdLookup}
                    // recipientList={recipientList}
                    // routes={routes}
                    elrs={elrs}
                    mduList={mduList}
                    userName={user.username}
                  />
                }
              />
              <Route
                path="viewhazard/:id"
                element={
                  <HazardDetails
                    hazardTypes={hazardTypes}
                    hazardCodes={hazardCodes}
                    trackIdLookup={trackIdLookup}
                    // recipientList={recipientList}
                    userRole={user.userRole}
                    userName={user.username}
                    userDetails={userDetails}
                    routes={routes}
                    elrs={elrs}
                    mduList={mduList}
                    actionType="viewhazard"
                  />
                }
              />
              <Route
                path="myhazards/:id"
                element={
                  <HazardDetails
                    hazardTypes={hazardTypes}
                    hazardCodes={hazardCodes}
                    trackIdLookup={trackIdLookup}
                    // recipientList={recipientList}
                    userRole={user.userRole}
                    userName={user.username}
                    userDetails={userDetails}
                    routes={routes}
                    elrs={elrs}
                    mduList={mduList}
                    actionType={state?.actionType === "myhazards-propose-delete" ? "myhazards-propose-delete" : "myhazards" }
                  />
                }
              />
              <Route
                path="amendhazard/:id"
                element={
                  <HazardDetails
                    hazardTypes={hazardTypes}
                    hazardCodes={hazardCodes}
                    trackIdLookup={trackIdLookup}
                    // recipientList={recipientList}
                    userRole={user.userRole}
                    userName={user.username}
                    userDetails={userDetails}
                    elrs={elrs}
                    mduList={mduList}
                    actionType="amend"
                  />
                }
              />
              <Route
                path="newhazard/:id"
                element={
                  <HazardDetails
                    hazardTypes={hazardTypes}
                    trackIdLookup={trackIdLookup}
                    userRole={user.userRole}
                    userName={user.username}
                    userDetails={userDetails}
                    elrs={elrs}
                    mduList={mduList}
                    actionType="new"
                  />
                }
              />
              <Route
                path="deletehazard/:id"
                element={
                  <HazardDetails
                    hazardTypes={hazardTypes}
                    hazardCodes={hazardCodes}
                    trackIdLookup={trackIdLookup}
                    // recipientList={recipientList}
                    userRole={user.userRole}
                    userName={user.username}
                    userDetails={userDetails}
                    routes={routes}
                    elrs={elrs}
                    mduList={mduList}
                    actionType="delete"
                  />
                }
              />
              <Route
                path="reinstate-hazard/:id"
                element={
                  <HazardDetails
                      hazardTypes={hazardTypes}
                      trackIdLookup={trackIdLookup}
                      userRole={user.userRole}
                      userName={user.username}
                      userDetails={userDetails}
                      actionType="re-instate"
                  />
                }
              />
              <Route path="deletehazard" element={<DeleteHazardPage />} />
              <Route
                path="accesspoints"
                element={<AccessPointsPage types={types} />}
              />
              <Route
                path="appendixa"
                element={<AppendixAPage routes={routes} />}
              />
              <Route
                path="extractworksite"
                element={<ExtractWorkSitePage routes={routes} elrs={elrs} />}
              />
              <Route
                path="extractelr"
                element={<ExtractElrPage routes={routes} elrs={elrs} />}
              />
              <Route
                path="redzone"
                element={<RedZonePage routes={routes} mduList={mduList} />}
              />
              <Route
                path="sectionmdu"
                element={<SectionMduPage mduList={mduList} />}
              />
              <Route
                path="stationdirectory"
                element={<StationDirectoryPage />}
              />
              <Route path="generichazards" element={<GenericHazardsPage />} />
              <Route path="signalwiredeg" element={<SignalWireDegPage />} />
              <Route
                path="safetyhazardcodes"
                element={<SafetyHazardCodesPage />}
              />
              <Route
                path="environmentalhazardcodes"
                element={<EnvironmentalHazardCodesPage />}
              />
              <Route path="greenzone" element={<GreenZonePage />} />
              <Route path="usefullinks" element={<UsefulLinksPage />} />
              <Route path="buriedservices" element={<BuriedServicesPage />} />
              <Route path="crimehotspots" element={<CrimeHotspots />} />
              <Route
                path="mobilephonecoverage"
                element={<MobilePhoneCoveragePage />}
              />
              <Route
                path="hazardnotificationform"
                element={<HazardNotificationFormPage />}
              />
              <Route
                path="unauthorized"
                element={<LoginPage signOut={props.signOut} />}
              />
              <Route
                path="logout"
                element={<LogoutPage signOut={props.signOut} />}
              />
              <Route path="userguide" element={<UserGuidePage />} />
              <Route path="agreement" element={<AgreementPage />} />
              <Route path="privacy" element={<PrivacyPage />} />

              <Route
                path="admindash"
                element={
                  <RequireAuth
                    allowedRoles={[
                      "ontrac-admin-group",
                      "whsea-admin-group",
                      "ssm-admin-group",
                    ]}
                  >
                    <AdminDashPage />
                  </RequireAuth>
                }
              />
              <Route
                path="adminreports"
                element={<AdminReportsPage/>}
              />

              <Route path="dataManagementDash" element={<DataManagementDashPage/>}/>

              <Route path="hazardcodemanagement" element={<HazardCodeManagementPage />} />

              <Route path="regionmnagement" element={<RegionManagementPage/>}/>

              <Route path="usermanagementDash" element={<UserManagementDash/>}/>

              <Route path="usermanagement" element={<UserManagementPage/>}/>

              <Route path="onboarding" element={<OnboardingPage/>}/>

              <Route path="userpermission-management" element={<UserPermissionRequestsManagement/>}/>

              <Route path="orgmanagement" element={<OrgManagementPage/>}/>  

              <Route path="v3Export" element={<V3ExportPage/>} />

              <Route path="inactiveHazards" element={<InactiveHazards/>}/>

                <Route path="hazardsReviewRejection" element={<HazardsReviewRejection mduList={mduList} />}/>

              <Route
                path="adminadduser"
                element={
                  <AdminAddUser mduList={mduList} user={user} />
                }
              />

              <Route
                path="adminaddhazardtype"
                element={<AdminAddHazardType />}
              />

              <Route
                path="adminedithazardtype"
                element={<AdminAddHazardType/>}
              />

              <Route
                path="onboardingdetails"
                element={<OnboardingRequestDetails />}
              />
              <Route
                path="userdetails"
                element={<UserDetails routes={routes} mduList={mduList} user={user} />}
              />

              <Route path="organisationdetails" element={<OrganisationDetails/>}/>

              <Route path="adminAddOrganisation" element={<OrganisationDetails/>}/>  

              <Route
                path="userpermissionrequests"
                element={<UserPermissionRequests />}
              />

              <Route
                path="profile"
                element={<UserDetails mduList={mduList} user={user}/>}
              />
              <Route path="addZone" element={<DataAddZone/>}/>

              <Route path="editZone" element={<DataAddZone/>}/>

              <Route path="boundaryImport" element={<ImportBoundaryDataPage/>}/>

              <Route path="bulkhazardsImport" element={<HazardsImportDataPage/>}/>
            </Routes>

            {/* {adminPages}  */}
          </div>

          <UserNav open={open} setDarkMode={setDarkMode} darkMode={darkMode} />
        </ThemeContextProvider>
      </div>
    </div>
  );
}

export default Main;
