import React, {Fragment, useContext, useEffect, useState} from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faDownload, faFile, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Input from "../../components/Input";
import ReactSelect from "../../components/Select/ReactSelect";
import Button from "../../components/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {ThemeContext} from "../../contexts/ThemeContextProvider";
import {useAuthenticator} from "@aws-amplify/ui-react";
import useWindowSize from "../../hooks/useWindowSize";
import {UserService as userService} from "../../services/user.service";
import {OrganisationService} from "../../services/organisation.service";
import ContentLoader from "react-content-loader";
import ReactTable from "../../components/ReactTable/ReactTable";
import Upload from "../../components/Upload";
import downloadFile from "../../_helpers/downloadFile";
import BulkUploadService from "../../services/bulk-upload.service";
import {toast} from "react-toastify";

export default function UserManagementPage(props) {

    const [values, setValues] = useState();
    const [openFilters, setOpenFilters] = useState(false);
    const [users, setUsers] = useState();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [organisations, setOrganisations] = useState();
    const [searching, setSearching] = useState(false);
    const [sortOptions, setSortOptions] = useState({accessor: '', direction: ''});
    const [userFile, setUserFile] = useState([]);
    const [sorting, setSorting] = useState(false);

    const {state} = useLocation();

    const context = useContext(ThemeContext);
    const {user} = useAuthenticator((context) => [context.user]);

    let navigate = useNavigate();

    const userStatuses = [
        {value: "CONFIRMED", label: "Confirmed"},
        {value: "FORCE_CHANGE_PASSWORD", label: "Force change Password"},
    ];

    const userEnabledValues = [
        {value: "Enabled", label: "Enabled"},
        {value: "Disabled", label: "Disabled"},
    ];

    function getUsers(csv, pageNumber, pageSize) {
        userService
            .getUsers(csv, pageSize, pageNumber, sortOptions)
            .then((response) => {
                setUsers(response.data);
                // setUsers(usersData);
                setLoadingUsers(false);
            })
            .catch((error) => {
                setUsers({});
                setLoadingUsers(false);
                console.log(error);
            });
    }

    function getOrganisations() {
        if (user.signInUserSession.accessToken.payload["cognito:groups"].includes("ontrac-admin-group")) {
            OrganisationService.getAllOrganisations().then((response) => {
                setOrganisations(response.data);
            }).catch((error) => {
                setOrganisations({});
                console.log(error);
            })
        }
    }

    useEffect(() => {
        getUsers()
        getOrganisations()
    }, []);

    useEffect(() => {
        if (organisations?.length > 0) {
            handleSubmit(undefined, 10, sortOptions);
        }
    }, [sortOptions])

    function handleInputChange(e) {
        setValues((values) => ({...values, [e.target.name]: e.target.value}));
    }

    const handleInputChangeG = (e, name) => {
        setValues((values) => ({
            ...values,
            [name]: e ? e : "",
        }));
    };

    function handleSubmit(pageVal, pageSizeVal) {
        let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
        let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
        setPage(pageNumber);
        setPageSize(pageSizeNumber);
        if (!searching) {
            getUsers(false, pageNumber, pageSizeNumber);
        } else {
            handleSearch(pageNumber, pageSizeNumber);
        }
    }

    function handleSearch(pageNumber, pageSizeNumber) {
        setLoadingUsers(true);
        setSearching(true);
        userService.userSearch(values, false, pageNumber, pageSizeNumber, sortOptions)
            .then((response) => {
                setUsers(response.data);
                setLoadingUsers(false);
            })
            .catch((error) => {
                console.log(error);
                setUsers({error});
                setLoadingUsers(false);
            })
    }

    const handleExportUsers = () => {
        if (values) {
            userService
                .userSearch(values, true)
                .then((response) => {
                    downloadFile(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            userService.getUsers(true)
                .then((response) => {
                    downloadFile(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    };

    const usersTemplate = () => {
        BulkUploadService.usersTemplate()
            .then((response) => {
                const url = response.data.s3url;
                const link = document.createElement('a');
                const filename = response.data.filename;
                link.href = url;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => console.log(error));
    };

    const uploadUsers = () => {
        let file = userFile[0];
        let params = {};
        params.file = file;
        BulkUploadService.users(params)
            .then((response) => {
                toast.success(`Successfuly uploaded ${file.name}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                removeUserFile(undefined, file.name);
            })
            .catch((error) => console.log(error));
    };

    function handleReset() {
        setValues(null);
        setSearching(false);
        setPage(0);
        setPageSize(10);
        userService
            .getUsers(false, 10, 0)
            .then((response) => {
                setUsers(response.data);
                setLoadingUsers(false);
            })
            .catch((error) => {
                setUsers({});
                setLoadingUsers(false);
                console.log(error);
            });
    }

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        return () => {
            reoveUserFileURL();
        };
    }, []);

    const reoveUserFileURL = () => {
        userFile.forEach((file) => {
            URL.revokeObjectURL(file.preview);
        });
    };

    const removeUserFile = (e, fileName) => {
        e && e.stopPropagation();

        let arr = [];
        userFile.forEach((file) => {
            if (file.name === fileName) {
                URL.revokeObjectURL(file.preview);
            } else arr.push(file);
        });

        setUserFile(arr);
    };

    const handlePageSize = (val) => {
        setPage(page);
        setPageSize(val);
    };

    const handleTableSort = (accessor) => {
        let direction = sortOptions?.direction;

        if (!direction) {
            direction = 'dsc';
            setSorting(true);
        } else if (direction === 'dsc') {
            direction = 'asc';
            setSorting(true);
        } else {
            direction = '';
        }

        setSortOptions({
            accessor: accessor,
            direction: direction
        });
    }

    const handleOpenCurrentUserDetails = (e, row) => {
        navigate("/userdetails", {
            state: { data: row.row?.original, title: "User Details", formType: "userdetails" },
        });
    };

    return (
        <div className="page" id="userManagementPage">
            <Header title="User Management"/>
            <BackButton
                setPage={props.setPage}
                target={["usermanagementDash", "backward"]}
                navigateUrl="/usermanagementDash"
            />

            <div className="filter-form">
                <div className="filter-form__controller">
                    <h2 className="filter-form__title inline-block">
                        Users Search Filters
                    </h2>

                    {openFilters ? (
                        <FontAwesomeIcon
                            className="inline-block filter-icon"
                            icon={faAngleUp}
                            onClick={() => setOpenFilters(!openFilters)}
                            title="Collapse"
                        />
                    ) : (
                        <FontAwesomeIcon
                            className="inline-block filter-icon"
                            icon={faAngleDown}
                            onClick={() => setOpenFilters(!openFilters)}
                            title="Expand"
                        />
                    )}
                </div>

                {openFilters && (
                    <div>
                        <div className="filter-form__group fields-wrapper">
                            <Input
                                name="firstName"
                                label="First Name"
                                value={values?.firstName}
                                onChange={handleInputChange}
                            />
                            <Input
                                name="surname"
                                label="Surname"
                                value={values?.surname}
                                onChange={handleInputChange}
                            />
                            <Input
                                name="email"
                                label="Email"
                                value={values?.email}
                                onChange={handleInputChange}
                            />
                            {user.signInUserSession.accessToken.payload["cognito:groups"].includes("ontrac-admin-group") && (
                                <ReactSelect
                                    name="organisation"
                                    label="Organisation"
                                    placeholder="Select an Organisation"
                                    data={organisations}
                                    valueLabel={["organisation_name"]}
                                    value={values?.organisation}
                                    onChange={handleInputChangeG}
                                />
                            )}
                            {user.signInUserSession.accessToken.payload["cognito:groups"].includes("ontrac-admin-group") && (
                                <ReactSelect
                                    name="userStatus"
                                    label="User Status"
                                    placeholder="Select User Status"
                                    data={userStatuses}
                                    valueLabel={["label"]}
                                    value={values?.userStatus}
                                    onChange={handleInputChangeG}
                                />
                            )}
                            <ReactSelect
                                name="enabled"
                                label="User Enabled Status"
                                placeholder="Select Enabled Status"
                                data={userEnabledValues}
                                valueLabel={["label"]}
                                value={values?.enabled}
                                onChange={handleInputChangeG}
                            />
                        </div>

                        <div className="button-group filter-form__submit">
                            <Button action="secondary" label="Reset" onClick={handleReset}/>
                            <Button action="primary" label="Filter" onClick={() => handleSearch(page, pageSize)}/>
                        </div>
                    </div>
                )}
            </div>

            {!loadingUsers && users !== "error" && users?.results?.length > 0 && (
                <>
                    <h2>Current Users</h2>
                    <ReactTable
                        tableData={users?.results}
                        showColumns={users?.headers}
                        canOpen={true}
                        handleOpen={handleOpenCurrentUserDetails}
                        customHeader={true}
                        customSearch={true}
                        currentPage={users?.pageNumber}
                        totalPages={users?.totalPages}
                        pageSize={users?.pageSize}
                        totalElements={users?.totalElements}
                        setPage={(val) => {
                            setPage(val);
                            handleSubmit(val, undefined);
                        }}
                        setPageSize={(val) => {
                            handlePageSize(val);
                            handleSubmit(0, val);
                        }}
                        sortOptions={sortOptions}
                        setSortOptions={setSortOptions}
                        handleTableSort={handleTableSort}
                    ></ReactTable>
                    <div className="button-group">
                        <Button action="secondary" label="Export Users" onClick={handleExportUsers}/>
                        {user.signInUserSession.accessToken.payload["cognito:groups"].includes(
                            "ontrac-admin-group"
                        ) && <Button
                            action="primary"
                            label="Add User"
                            onClick={() => navigate("/adminAddUser")}
                        />}
                    </div>
                </>
            )}
            {!loadingUsers && Array.isArray(users) && users?.length == 0 && (
                <>
                    <h2>There are no users</h2>
                </>
            )}
            {!loadingUsers && users == "error" && (
                <>
                    <h2>Unable to fetch users</h2>
                </>
            )}
            {user.signInUserSession.accessToken.payload["cognito:groups"].includes("ontrac-admin-group") &&
                <div className="bulk-upload">
                    <Button
                        action="secondary"
                        label="Download Users Bulk Template"
                        onClick={usersTemplate}
                    >
                        <FontAwesomeIcon icon={faDownload} className="button__icon"/>
                    </Button>
                    {userFile.length > 0 ? (
                        <Button
                            action="primary"
                            label="Confirm and upload"
                            onClick={uploadUsers}
                        ></Button>
                    ) : (
                        <Upload
                            name="uploadUsers"
                            label="Users Bulk Upload"
                            setFiles={setUserFile}
                            files={userFile}
                            disabled={false}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                    )}

                    {userFile.length > 0 && (
                        <>
                            <div className="create-form__section">
                                <h4>Previews</h4>
                                <div className="gallery">
                                    {userFile?.map((file) => {
                                        if (file.type.includes("image")) {
                                            return (
                                                <div className="image__wrap">
                                                    <Button
                                                        action="tertiary"
                                                        onClick={(e) => removeUserFile(e, file.name)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                                    </Button>
                                                    <div>{`${file.name} - ${file.size / 1000}KB`}</div>
                                                    <img src={file.preview} alt="" className="image"/>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className="image__wrap">
                                                    <Button
                                                        action="tertiary"
                                                        onClick={(e) => removeUserFile(e, file.name)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                                    </Button>
                                                    <div
                                                        style={{maxWidth: "100%", wordBreak: "break-word"}}
                                                    >{`${file.name} - ${file.size}KB`}</div>
                                                    <FontAwesomeIcon icon={faFile}/>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        </>
                    )}
                </div>}
        </div>
    );
}