import axios from "../_helpers/axiosinstance";

const getOrganisations = async (pageNumber, pageSize, csv, sortOptions) => {
    let url = `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/organisations`;
    let sortBy = undefined;
    let sortOrder = undefined;
    let config = {
        requiresIdentityId: true,
    }

    if (sortOptions?.accessor) {
        sortBy = sortOptions.accessor;
    }

    if (sortOptions?.direction) {
        sortOrder = sortOptions.direction;
    }

    if (csv) {
        url = `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/organisations/export`;
        config = {
            requiresIdentityId: true,
            responseType: "blob"
        }
    }

    const response = await axios.post(
        url, {
            page: pageNumber === 0 ? 0 : pageNumber || 0,
            pageSize: pageSize === 0 ? 0 : pageSize || 10,
            sortBy: sortBy || undefined,
            sortOrder: sortOrder || undefined,
        }, config
    );
    return response;
};

const getAllOrganisations = async () => {
    return axios.get(
        `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/organisations`, {
            requiresIdentityId: true
        });
}

const updateOrganisation = async (values) => {
    const response = await axios.post(
        `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/organisations/${values.id}`,
        values, {
            requiresIdentityId: true
        });
    return response;
}

const createOrganisation = async (values) => {
    const response = await axios.post(`${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/organisations/create`,
    values, {
        requiresIdentityId: true
    });
    return response;
}

const searchByOrgName = async (orgName, pageNumber, pageSize, sortOptions) => {
    let sortBy = undefined;
    let sortOrder = undefined;

    if (sortOptions?.accessor) {
        sortBy = sortOptions.accessor;
    }

    if (sortOptions?.direction) {
        sortOrder = sortOptions.direction;
    }
    const response = await axios.post(
            `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/organisations/searchByName/${orgName}`,
            {
                page: pageNumber === 0 ? 0 : pageNumber || 0,
                pageSize: pageSize === 0 ? 0 : pageSize || 10,
                sortBy: sortBy || undefined,
                sortOrder: sortOrder || undefined,
            },
            {
                requiresIdentityId: true
            }
    );
    return response;
}

const exportOrganisations = async () => {
    const response = await axios.get(
            `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/organisations/export`,
            {
                requiresIdentityId: true,
                responseType: "blob"
            }
    );
    return response;
}

export const OrganisationService = {
    getOrganisations,
    getAllOrganisations,
    updateOrganisation,
    createOrganisation,
    searchByOrgName,
    exportOrganisations
}
