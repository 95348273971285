const downloadFile = async (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition.substring(
        contentDisposition.indexOf("=") + 1
    );
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export default downloadFile;