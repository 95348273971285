import React from "react";
import Header from "../components/Header.js";
import Button from "../components/Button.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useLocation, useNavigate, useParams} from "react-router-dom";

function SearchPage(props) {
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams();
  return (
    <div className="page page--show" id="searchPage">
      <Header title="Hazard Search" />
      <div className="card__wrap">
        <div className="card card--width33">
          <video
            className="card__video"
            loop={false}
            autoPlay
            muted
            controls={false}
            playsInline
          >
            <source src="./video/Custom_Reports_NHD_7.mp4" type="video/mp4" />
            Your browser does not support the video.
          </video>

          <h3 className="card__title">Custom Search</h3>
          <p className="card__description">
            Search and filter the National Hazard Directory. Create and save custom reports.
          </p>
          <Button
            action="round"
            label="Custom Search"
            onClick={() => {
              navigate("/customsearch", {
                state: {
                  pageTitle: "Custom Search"
                }
              });
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width33">
          <video
            className="card__video"
            loop={false}
            autoPlay
            muted
            controls={false}
            playsInline
          >
            <source src="./video/NHD_General_Report_8.mp4" type="video/mp4" />
            Your browser does not support the video.
          </video>
          <h3 className="card__title">Standard Reports</h3>
          <p className="card__description">
            Generate pre-defined reports.
          </p>
          <Button
            action="round"
            label="Standard Reports"
            onClick={() => {
              navigate("/standardreports");
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width33">
          <video
            className="card__video"
            loop={false}
            autoPlay
            muted
            controls={false}
            playsInline
          >
            <source src="./video/NHD_My_Reports_2.mp4" type="video/mp4" />
            Your browser does not support the video.
          </video>
          <h3 className="card__title">My Reports</h3>
          <p className="card__description">
            Manage your saved custom reports.
          </p>
          <Button
            action="round"
            label="My Reports"
            onClick={() => {
              navigate("/myreports");
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SearchPage;
