import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

function HazardNotificationFormPage(props) {
  return (
    <div className="page" id="hazardNotificationFormPage">
      <Header title="Hazard Notification Form" />
      <BackButton navigateUrl="/generalinfo" />

      <div className="hazard-form-pdf__wrap">
        <div className="hazard-form-pdf">
          <h2 className="hazard-form-pdf__h2">
            Download Hazard Notification Form
          </h2>

          <p className="hazard-form-pdf__p">
            Here you can download a copy of the Safety and Environmental Hazard
            Notification Form. Downloaded copies are uncontrolled and we cannot
            guarantee the accuracy, safe processing, tracking, or legitimacy of
            any content provided via downloaded forms. Please use the online
            system provided wherever possible and only download forms in
            exceptional circumstances.
          </p>

          <p className="hazard-form-pdf__p">
            <a
              className="hazard-form-pdf__download button button--primary"
              href=".\downloads\NHD-HazardNotification-From.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faDownload} className="button__icon" />{" "}
              <span className="button__text">
                Download Hazard Notification PDF
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default HazardNotificationFormPage;
