import React, {Fragment, useContext, useEffect, useState} from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import {useLocation, useNavigate} from "react-router-dom";
import {ThemeContext} from "../../contexts/ThemeContextProvider";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {UserService as userService} from "../../services/user.service";
import ReactTable from "../../components/ReactTable/ReactTable";

export default function UserPermissionRequestsManagement(props) {
    const [userPermissionRequests, setUserPermissionRequests] = useState();
    const [loadingPermissionRequests, setLoadingPermissionRequests] = useState(false);
    const {state} = useLocation();

    const context = useContext(ThemeContext);
    const {user} = useAuthenticator((context) => [context.user]);
    let navigate = useNavigate();

    useEffect(() => {
        setLoadingPermissionRequests(true);
        userService.getPermissionRequests()
            .then((response) => {
                setUserPermissionRequests(response.data);
                // setUsers(usersData);
                setLoadingPermissionRequests(false);
            })
            .catch((error) => {
                setUserPermissionRequests({});
                setLoadingPermissionRequests(false);
                console.log(error);
            });
    }, []);

    const handleOpenPermissionRequest = (e, row) => {
        navigate("/userpermissionrequests", {
            state: {
                data: row.row?.original,
            },
        });
    };

    return (
        <div className="page" id="dataManDashPage">
            <Header title="User Permission Request Management" />
            <BackButton
                setPage={props.setPage}
                target={["usermanagementDash", "backward"]}
                navigateUrl="/usermanagementDash"
            />
            {!loadingPermissionRequests && userPermissionRequests !== "error" && userPermissionRequests?.length > 0 && (
                <>
                    <h2>User Permission Requests</h2>
                    <ReactTable
                        tableData={userPermissionRequests}
                        canOpen={true}
                        handleOpen={handleOpenPermissionRequest}
                        showColumns={[
                            "name",
                            "email",
                            "requested_permission",
                        ]}
                    ></ReactTable>
                </>
            )}
            {!loadingPermissionRequests && Array.isArray(userPermissionRequests) && userPermissionRequests?.length === 0 && (
                <>
                    <h2>There are no user permission requests</h2>
                </>
            )}
            {!loadingPermissionRequests && userPermissionRequests === "error" && (
                <>
                    <h2>Unable to fetch user permission requests</h2>
                </>
            )}
        </div>
    )
}