import React from "react";
import _uniqueId from "lodash/uniqueId";

function Checkbox(props) {
  const id = _uniqueId(props.name + "__" + props.value);

  let classProps = ["checkbox__wrap"];
  if (props.disabled) {
    classProps.push("checkbox__wrap--disabled");
  }
  if (props.className) {
    classProps.push(props.className);
  }

  return (
    <div className={classProps.join(" ")}>
      <input
        className="checkbox"
        type="checkbox"
        name={props.name}
        value={props.value}
        id={id}
        checked={props.checked}
        onChange={(e) => {
            props.onChange(e, props.name);
        }}
        disabled={props.disabled}
        readOnly={props.readOnly}
      />
      <label className="checkbox__label" htmlFor={id}>
        {props.label ? props.label : props.value}
      </label>
    </div>
  );
}

export default Checkbox;
