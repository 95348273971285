import React, {useEffect, useRef, useState} from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import StandardReportsForm from "../components/StandardReportsForm.js";
import StandardReportsService from "../services/standardreports.sevice.js";
import ReactTable from "../components/ReactTable/ReactTable.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faExclamationTriangle,} from "@fortawesome/free-solid-svg-icons";
import GeneralService from "../services/general.service.js";
import {toast} from "react-toastify";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus";

function RedZonePage(props) {
  let reportTitle = "Red Zone Working";
  let reportInfo =
    "Filter Red Zone Working Prohibitions by Route or MDU.";
  let fields = [
    { name: "route", required: false },
    { name: "mdu", required: false },
  ];

  const [values, setValues] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [customSearchItems, setCustomSearchItems] = useState();
  // const [filteredElr, setFilteredElr] = useState();
  const [filteredMdu, setFilteredMdu] = useState();
  const routeSelectInput = useRef(null);
  const mduSelectInput = useRef(null);
  const [reset, setReset] = useState(false);

  useEffect(() => {

  }, [values]);

  //TODO form validation
  //iterate over fields, see which are mandatory and check them against values, if they are null/empty/"" set a error message to inputs fields to show a error message

  //fetch MDU based on ELR
  useEffect(() => {
    if (values?.route) {
      setFilteredMdu(["loading..."]);
      setValues((values) => ({
        ...values,
        mdu: "",
      }));
      GeneralService.getZonecodeMDUSubzonecode({
        zonecode: values?.route?.zonecode,
      })
        .then((response) => {
          setFilteredMdu(response.data);
        })
        .catch((error) => {
          setFilteredMdu();
        });
    } else {
      setFilteredMdu();
    }
  }, [values?.route]);

  const handlePageSize = (val) => {
    setPage(0);
    setPageSize(val);
  };

  const handleReset = () => {
    setReset(true);
    setCustomSearchItems({
      status: {
        loading: false,
        error: false,
      },
    });
  }

  function handleSubmit(pageVal, pageSizeVal) {
    setReset(false);
    let validRoute = routeSelectInput.current.validateField();
    let validMdu = mduSelectInput.current.validateField();
    // let validType = elrSelectInput.current.validateField();
    if (validRoute && validMdu) {
      setCustomSearchItems({
        status: {
          loading: true,
          error: false,
        },
      });
      let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
      let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
      StandardReportsService.hazardsRed(pageNumber, pageSizeNumber, values)
        .then((response) => {
          setCustomSearchItems(response.data);
        })
        .catch((error) => {
          setCustomSearchItems({
            status: {
              loading: false,
              error: true,
            },
          });
        });
    } else {
      setCustomSearchItems({
        status: {
          loading: false,
          error: "Please check the form is complete and required values are selected.",
        },
      });
    }
  }

  const handleExport = () => {
    StandardReportsService.hazardsRedExport(page, pageSize, values)
      .then((response) => {
        toast.success(`Your Export has been sent and you will receive an email shortly with the attached report.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {});
  };

  return (
    <div className="page" id="redZonePage">
      <Header title="Red Zone Working Pohibitions" />
      <BackButton
        setPage={props.setPage}
        target={["standardReportsPage", "backward"]}
      />

      <StandardReportsForm
        data={props}
        reportTitle={reportTitle}
        reportInfo={reportInfo}
        fields={fields}
        filteredMdu={filteredMdu}
        handleSubmit={() => handleSubmit(0, undefined)}
        handleReset={() => handleReset()}
        values={values}
        setValues={setValues}
        routeSelectInput={routeSelectInput}
        mduSelectInput={mduSelectInput}
      />
      <div>
        {customSearchItems?.status?.loading === true && (
          <div className="loadingCircle">
            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
          </div>
        )}
        {customSearchItems?.status?.error === true && (
          <div className="loadingCircle">
            Error fetching data
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
        )}
        {customSearchItems?.results && customSearchItems?.results?.length > 0 && (
          <ReactTable
            hideColumns={["id"]}
            tableData={customSearchItems.results}
            currentPage={customSearchItems?.pageNumber}
            totalPages={customSearchItems?.totalPages}
            pageSize={customSearchItems?.pageSize}
            setPage={(val) => {
              setPage(val);
              handleSubmit(val, undefined);
            }}
            setPageSize={(val) => {
              handlePageSize(val);
              handleSubmit(0, val);
            }}
            exportCsv={{ name: "redZone", action: handleExport }}
          ></ReactTable>
        )}
        {customSearchItems?.results && customSearchItems?.results.length === 0 && !reset && (
        <div className="loadingCircle">
          No Results Based On Your Search Criteria
          <span style={{marginLeft: "10px"}}> <FontAwesomeIcon icon={faMagnifyingGlassMinus}/> </span>
        </div>
        )}
      </div>
    </div>
  );
}

export default RedZonePage;
