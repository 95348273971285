import axios from "../_helpers/axiosinstance";

const customSearch = async (params, pageSize, pageNumber, csv, save, sortOptions, totalElements) => {
    let url = `${process.env.REACT_APP_nhd_hazard_search_ingress}/nhd/api/hazard/custom-search`;
    let config = {};
    if (csv) {
        url = `${process.env.REACT_APP_nhd_hazard_search_ingress}/nhd/api/hazard/custom-search/export`;
    }
    if (save) {
        url = `${process.env.REACT_APP_nhd_hazard_search_ingress}/nhd/api/hazard/custom-search/save`;
    }

    let sortBy = undefined;
    let sortOrder = undefined;

    if (sortOptions?.accessor) {
      sortBy = sortOptions.accessor;
    } else if (params?.sortBy) {
      sortBy = params.sortBy;
    }

    if (sortOptions?.direction) {
      sortOrder = sortOptions.direction;
    } else if (params?.sortOrder) {
      sortOrder = params.sortOrder;
    }

  const response = await axios.post(
    url,
    {
        totalElements: totalElements || undefined,
      page: pageNumber === 0 ? 0 : pageNumber || params?.pageNumber || 0,
      pageSize: pageSize === 0 ? 0 : pageSize || params?.pageSize || 10,
      route: params?.route || undefined,
      sortBy: sortBy || undefined,
      sortOrder: sortOrder || undefined,
      elr: params?.elrCS || undefined,
      startMileage: params?.startMileage || undefined,
      endMileage: params?.endMileage || undefined,
      subzone: params?.mdu?.subzonecode || undefined,
      hazard_code: params?.hazardCode || undefined,
      hazard_id: params?.hazard_id || undefined,
      local_name: params?.localName || undefined,
      selectedFields: params?.selectedFields || {
        custom: false,
        fields: [],
      },
      report_name: params?.report_name || undefined
    },
    config
  );
  return response;
};

const HazardSearchService = {
  customSearch,
};

export default HazardSearchService;
