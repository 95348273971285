import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";

function GenericHazardsPage(props) {
  return (
    <div className="page" id="genericHazardsPage">
      <Header title="Generic Hazards" />
      <BackButton navigateUrl="/generalinfo" />

      <div className="generic-hazards">
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">
            INJURY FROM BEING HIT BY MOVING RAIL VEHICLES
          </h3>

          <p className="generic-hazards__p">
            While working on or near the line, moving rail traffic presents a
            significant hazard both to the individuals working and to train
            safety. Control measures documented in the Network Rail Rule Book
            and other operational safety documentation must be strictly applied.
            In addition only authorised persons are allowed on or about the
            line. Where these persons are not Network Rail employees, safety
            must be assured through compliance with the Network Rail. Rule Book
            and in the case of Network Rail Contractors, by compliance with the
            conditions detailed in the contract.
          </p>

          <p className="generic-hazards__p">
            A hazard of injury through being swept into the side of a passing
            train due to air turbulence exists. The Network Rail Rule Book
            defines minimum specific distances for places of safety from lines
            open to rail traffic.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">
            SLIP AND TRIP HAZARDS ON THE LINESIDE
          </h3>

          <p className="generic-hazards__p">
            When on or about the line care should be taken of trackside
            "furniture" to ensure that trips do not occur, examples of such
            "furniture" include; sleepers, point rodding, cable ducting, rails
            and sleepers. Slip hazards can arise through the presence of ice or
            water making the surface slippery. Particular care must be taken
            when these are covered by snow or during the hours of darkness when
            they are more difficult to see.
          </p>

          <p className="generic-hazards__p">
            Underfoot conditions can become slippery in adverse weather, leading
            to possible slips, trips and falls. Walking routes and platforms
            will be maintained so far as is reasonably practicable by those
            organisations responsible for them, but care should always be taken.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">LEAD PAINT ON STRUCTURES</h3>

          <p className="generic-hazards__p">
            Due to the historical nature of many structures they have in the
            past been painted with lead based paints. Care should be taken to
            ensure work on structures is carried out in a manner giving due
            consideration to such a hazard.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">HEALTH AND HYGIENE HAZARDS</h3>

          <p className="generic-hazards__p">
            On Network Rail's infrastructure health and hygiene hazards result
            from a number of sources these include; water, sewers and culverts.
            Care must be taken to ensure personal hygiene arrangements are
            adequate in such circumstances. In addition rats pose a risk of
            Wiel's Disease (Leptospirosis) in static or polluted water and
            infected waste. The irresponsible disposal of syringes by drug
            addicts introduces the risk of both AIDS and Hepatitis and
            arrangements should be made to dispose of these responsibly.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">
            CONTACT WITH ELECTRIC TRACTION POWER SUPPLY
          </h3>

          <p className="generic-hazards__p">
            Electrocution, burning and shock hazards exist whilst working on
            Network Rail infrastructure when traction current supplied to
            traction units, by either a conductor rail or overhead line
            equipment has not been isolated and earthed. All persons working in
            such areas must comply with the appropriate rules published in the
            Network Rail.
          </p>

          <p className="generic-hazards__p">
            Rule Book and relevant working instructions. Specific detail
            regarding the presence of conductor rail and overhead line power
            supplies are provided in this Directory.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">
            CONTACT WITH HAZARDOUS SUBSTANCES
          </h3>

          <p className="generic-hazards__p">
            Potentially hazardous chemicals can be conveyed on freight trains.
            If these become spilled during an accident avoid contact with the
            substance, refer to the Hazchem labels and advise the appropriate
            Zone Control Centre.
          </p>

          <p className="generic-hazards__p">
            Traces of asbestos may be present in vehicles and could be disturbed
            following an accident. Such vehicles are marked by a blue triangle
            at sole bar level reference should be made to the appropriate zonal
            control centre for further guidance in the event of an accident.
          </p>

          <p className="generic-hazards__p">
            The track ballast may become contaminated with oil, material fallen
            off trains or organic matter. Hands should be washed before eating
            or smoking following contact with it. Other particular substances to
            be aware of are:
          </p>

          <p className="generic-hazards__p">
            Polychlorinated Biphenyls (PCBs) exist in some FDM transformers. All
            equipment is labelled
          </p>

          <p className="generic-hazards__p">
            Di-iso-nonyl Phthalate Ester. This chemical is used as a plasticiser
            in PVC wiring from which it may leach. The chemical will not give
            any ill effects providing it is not ingested (eaten) and care is
            taken avoid contact with the skin.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">WORKING OVER WATER</h3>

          <p className="generic-hazards__p">
            A hazard from drowning exists unless suitable and sufficient
            controls exist to prevent falling from the structure whilst working
            upon it. Work method statements must take account of this hazard.
            Whilst walking over bridges walking routes must be observed and
            proper account taken of any local instructions, details of which
            will be found or referenced in this Directory.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">FALLING FROM HEIGHT</h3>

          <p className="generic-hazards__p">
            A risk of serious or fatal injury could result from falling from
            height unless suitable control measures exist. Handrails on exposed
            and infrequently used structures such as signalbox verandas,
            walkways on roofs of buildings, bridges etc. should be tested before
            reliance is placed upon them.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">MOBILE PHONES</h3>

          <p className="generic-hazards__p">
            Mobile phones are not guaranteed to work in any location. Users
            should make tests prior to work commencing before relying on a
            mobile phone as their principle means of communication.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">BURIED SERVICES</h3>

          <p className="generic-hazards__p">
            The presence of buried services represents a hazard from
            electrocution, fire, and explosion. No Excavation work should start
            without first checking the appropriate section of this Directory and
            relevant plans. A survey using a 'cat' detector (approved by Network
            Rail) should always be carried out prior to excavation work. In
            addition trial holes should be dug by hand prior to any mechanical
            excavation commencing. When in doubt about the presence of buried
            services the appropriate public utility should be contacted direct
            for further advice.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">CABLES</h3>

          <p className="generic-hazards__p">
            Site-specific details available at the time of publication are
            shown. These will be further augmented in future issues.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">PIPELINES</h3>

          <p className="generic-hazards__p">
            Site specific details available at the time of publication, are
            shown. These will be further augmented in future issue.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">WILD ANIMALS</h3>

          <p className="generic-hazards__p">
            In both urban and rural areas there is a possibility that wild
            animals may be encountered.
          </p>

          <p className="generic-hazards__p">
            SNAKES - Venomous snakes may be particularly encountered on heath
            land and in such areas appropriate protection should be adopted.
            Even reptiles that appear to be dead should not be touched.
          </p>

          <p className="generic-hazards__p">
            BADGERS- Badger setts have been identified on Network Rail property
            in some rural areas. Although the presence of badgers does not pose
            any danger to human activity there is a legal requirement that they,
            and their setts, must not be harmed or disturbed without an
            appropriate license.
          </p>

          <p className="generic-hazards__p">
            BATS - These animals may roost in structures owned by Network Rail.
            Although the presence of bats does not pose any danger to human
            activity, there is a legal requirement that bats must not be harmed
            or their roosts obstructed.
          </p>

          <p className="generic-hazards__p">
            RATS and MICE - These animals pose little danger to human activity
            although it should be remembered that rats can carry Wiel's Disease
            (Leptospirosis) in areas of stagnant water and infected waste.
            Infestations of these animals must be reported to zone control
            centers in order that they may be professionally exterminated.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">DANGEROUS PLANTS</h3>

          <p className="generic-hazards__p">
            Giant Hogweed plants may colonise Network Rail property in the
            vicinity of watercourses. The stems, and edges & undersides of
            leaves are coated with poisonous sap that can cause blistering and
            severe irritation. Do not touch Giant Hogweed plants without wearing
            appropriate protective equipment (NB: hairs from the plant may
            penetrate some types of clothing). Further advice may be obtained
            from Zone Environment Managers.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">ASBESTOS</h3>

          <p className="generic-hazards__p">
            A comprehensive survey of manned lineside locations has been
            undertaken by Network Rail to identify the presence of asbestos at
            those locations. This detailed information may be obtained from the
            zonal Contract Managers.
          </p>

          <p className="generic-hazards__p">
            Asbestos may be present elsewhere on the network (such as in some
            cable troughing) and if doubt exists as to its presence no work
            should be undertaken which may cause disturbance and the release of
            asbestos particles. Site activity should only commence when the
            material being worked with is identified and appropriate protective
            precautions applied.
          </p>

          <p className="generic-hazards__p">
            When working with asbestos existing legislation must be complied
            with.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">ACCESS TO BUILDING ROOFS</h3>

          <p className="generic-hazards__p">
            When undertaking work or inspections on station, depot and lineside
            buildings a risk assessment must be carried out and suitable
            arrangements made to ensure that a safe place of work is
            established. This may require the need for independent access
            equipment to be provided. Notice should always been taken of the
            potential fragile nature of roofs and instructions appertaining to
            individual roofs should always be followed where they are posted.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">TRESPASS AND VANDALISM</h3>

          <p className="generic-hazards__p">
            Some sites across the network are prone to trespass and vandalism.
            Care should be taken to identify these and where necessary take
            appropriate action to protect staff and/or Network Rail or Train
            Operating Company assets. Detailed information about such sites can
            be obtained from the zonal Contract Managers.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">FAULTY MANHOLE COVERS</h3>

          <p className="generic-hazards__p">
            Some old manhole covers are faulty and very susceptible to fractures
            when stepped upon. Staff must avoid stepping on manhole covers when
            walking along the cess etc. Consideration for this should also be
            taken into account by the COSS when selecting places of safety and
            walking routes to and from a site of work.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">ACCESS POINTS</h3>

          <p className="generic-hazards__p">
            There is only limited information to date. This will be augmented in
            future issues. More detailed information can be obtained from zonal
            production organisations or project managers.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">COMAH SITES</h3>

          <p className="generic-hazards__p">
            Certain industrial sites are designated under the Control of Major
            Accidental Hazards (COMAH) Regulations 1999. These Regulations
            require that the Local Authority covering the area of the site
            prepare an off-site plan detailing the arrangements for a 'major
            accident' at the location. Copies of the off site plans for COMAH
            sites can be obtained from zonal organizations.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">WALKING ROUTES</h3>

          <p className="generic-hazards__p">
            The routes described in this document are strictly for use by
            Network Rail employees, Railway Group personnel, contractors, and
            approved visitors to Network Rail infrastructure. They are not
            available for public use except where a public right of way is
            traversed. For access to locations not shown in this document
            reference should be made to zonal emergency plans.
          </p>

          <p className="generic-hazards__p">
            Cable troughing routes are not designated as authorised walking
            routes. However it is appreciated that the troughing route is often
            a location of safety. When in this place of staff must be extra
            vigilant and beware of missing, rocking or broken troughing covers.
          </p>

          <p className="generic-hazards__p">
            In some areas a continuous cess safety path has been provided on one
            or both sides of the line. At certain locations there is
            insufficient space in which to construct the cess path. At such
            places "No Safe Cess" signs are positioned. It acts as a warning to
            staff working in these areas that additional protection may be
            required.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">HARD HAT AREAS</h3>

          <p className="generic-hazards__p">
            Employees and other personnel must wear an approved helmet in a
            designated Hard Hat Area. These areas are clearly identified by
            signs at road/pedestrian access points, but not when access is from
            the running line.
          </p>

          <p className="generic-hazards__p">
            The access to and conditions under mechanical signal boxes are often
            restricted and cramped. In the more spacious boxes there is still a
            risk from lever tails, weights and confined access to locking trays.
            The under-portions of mechanical signal boxes are designated hardhat
            areas. Employees and other personnel must comply with this
            designation.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">SUB SURFACE STATIONS</h3>

          <p className="generic-hazards__p">
            No personnel must undertake work at sub surface stations unless they
            have attended Network Rail Fire Training Modules A & G and have
            received familiarisation training on site. Access to the station
            will be restricted to personnel whose names appear on the definitive
            list held within the Station Control Room.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">ENVIRONMENTAL HAZARDS</h3>

          <p className="generic-hazards__p">
            Various Railway sites have environmental hazards e.g. Contaminated
            Land. Before carrying out work at any location, local Safety
            Instructions must be checked or Network Rail Environmental Managers
            contacted to establish if any special precautions are required.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">FLOODS</h3>

          <p className="generic-hazards__p">
            The Zone Structures Engineers have identified areas liable to
            flooding where Network Rail Controlled Infrastructure may be
            affected, including specific bridges and other structures known to
            be at particular risk. When there is a likelihood of flooding,
            warnings will be given by the appropriate regional offices of the
            Environment Agency to the Network Rail Zone Control for those areas
            likely to be effected. That warning will then be disseminated to
            appropriate parties by the Zone Control organisation. The specific
            procedure for managing flood alerts is set out in Zone Emergency
            Plans.
          </p>

          <p className="generic-hazards__p">
            There are three stages of flood warning alerts issued by the
            Environment Agency for a particular river catchment area and/or
            tidal location along the coast. The flood warnings are categorised
            according to the risk of flooding and anticipated timescale before
            reaching a particular area, as noted below:
          </p>

          <p className="generic-hazards__p">
            Flood Watch – Flooding is possible. Be aware! Be prepared! Watch
            Out!
          </p>

          <p className="generic-hazards__p">
            Flood Warning – Flooding of homes, businesses and main roads is
            expected. Act now!
          </p>

          <p className="generic-hazards__p">
            Severe Flood Warning – Severe Flooding is expected. Imminent danger
            to life and property. Act now!
          </p>

          <p className="generic-hazards__p">
            All Clear – All clear is issued when flood watches or warnings are
            no longer in force. Flood water levels receding. Check all is safe
            to return. Seek advice.
          </p>

          <p className="generic-hazards__p">
            The Zone Control organisation will advise the appropriate personnel
            in accordance with the Zone Emergency Plan.
          </p>

          <p className="generic-hazards__p">
            The Infrastructure Maintenance Contractor shall arrange patrolling
            of the parts of the railway likely to be affected by flooding.
            Decisions and line closures shall be made in accordance with the
            guidance notes for observing bridges at risk and requirements given
            in Zone Procedures.
          </p>
        </div>
        <div className="generic-hazards__hazard">
          <h3 className="generic-hazards__h3">LOCATION CASES</h3>

          <p className="generic-hazards__p">
            The doors of certain location cases when open are foul of the
            structure gauge i.e. less than 1.642m from the inside edge of the
            running rail. When standing in front of certain location cases it is
            not possible to maintain the 4-ft clearance for a position of
            safety. The guard rails at certain locations, although not foul of
            the structure gauge being 3 ft or less in height, are placed such
            that standing behind them does not constitute a place of safety. All
            such locations are labelled with special limited clearance and
            warning signs. Employees and other personnel must never assume that
            by standing in front of a location case they are in a position of
            safety.
          </p>
        </div>
      </div>
    </div>
  );
}

export default GenericHazardsPage;
