import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";

function AgreementPage(props) {
  return (
    <div className="page" id="agreementPage">
      <Header title="User Agreement" />
      <BackButton navigateUrl="/" />

      <div className="agreement__wrap">
        <div className="agreement">
          <h2 className="agreement__h2">Agreement</h2>
          <p className="agreement__p">Lorem ipsum</p>
        </div>
      </div>
    </div>
  );
}

export default AgreementPage;
