import React, {useEffect, useState} from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import Input from "../components/Input.js";
import Button from "../components/Button.js";
import GeneralService from "../services/general.service.js";
import ReactTable from "../components/ReactTable/ReactTable.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faExclamationTriangle,} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

function StationDirectoryPage(props) {
  const initialVals = {
    search: "",
  };

  const [values, setValues] = useState(initialVals);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [customSearchItems, setCustomSearchItems] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function handleInputChange(e) {
    setValues((values) => ({ ...values, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    setLoading(true);
    GeneralService.getStations(page, pageSize, undefined)
      .then((response) => {
        setLoading(false);
        setCustomSearchItems(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  function handleSubmit(pageVal, pageSizeVal) {
    let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
    let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
    if (values.search && values.search !== "") {
      setCustomSearchItems({
        status: {
          loading: true,
          error: false,
        },
      });
      GeneralService.stationsSearch(pageNumber, pageSizeNumber, {
        stationName: values.search,
      })
        .then((response) => {
          console.log(response);
          setCustomSearchItems(response.data);
        })
        .catch((error) => console.log(error));
    } else {
      setCustomSearchItems({
        status: {
          loading: true,
          error: false,
        },
      });
      GeneralService.getStations(pageNumber, pageSizeNumber, undefined)
        .then((response) => {
          console.log(response);
          setCustomSearchItems(response.data);
        })
        .catch((error) => console.log(error));
    }
  }

  function handleReset() {
    setValues({ ...initialVals });
  }

  const handlePageSize = (val) => {
    setPage(0);
    setPageSize(val);
  };

  return (
    <div className="page" id="stationDirectoryPage">
      <Header title="Station Directory" />
      <BackButton navigateUrl="/generalinfo" />

      <div className="search-form">
        <div className="search-form__section">
          <h3>Search Station Directory</h3>
          <Input
            name="search"
            label="Search"
            value={values.search}
            onChange={handleInputChange}
          />
        </div>
        {customSearchItems?.status?.loading === true && (
          <div className="loadingCircle">
            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
          </div>
        )}
        {customSearchItems?.status?.error === true && (
          <div className="loadingCircle">
            Error fetching data
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
        )}
        {loading && (
          <div className="loadingCircle">
            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
          </div>
        )}
        {!error && customSearchItems?.results && (
          <ReactTable
            hideColumns={["_id"]}
            tableData={customSearchItems?.results}
            currentPage={customSearchItems?.pageNumber}
            totalPages={customSearchItems?.totalPages}
            pageSize={customSearchItems?.pageSize}
            totalElements={customSearchItems?.totalElements}
            setPage={(val) => {
              setPage(val);
              handleSubmit(val, undefined);
            }}
            setPageSize={(val) => {
              handlePageSize(val);
              handleSubmit(0, val);
            }}
            // exportCsv={{ name: "accesspoints", action: handleExport }}
            // printFriendly={{ action: handlePrintFriendly }}
          ></ReactTable>
        )}
        <div className="button-group search-form__submit">
          <Button action="secondary" label="Reset" onClick={handleReset} />
          <Button
            action="primary"
            label="Search"
            onClick={(e) => handleSubmit(undefined, undefined)}
          />
        </div>
      </div>
    </div>
  );
}

export default StationDirectoryPage;
