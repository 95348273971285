import React from "react";
import Button from "../Button";
import "./TermnsAndConditions.css";

const TermnsAndConditions = (props) => {
  return (
    <div
      className="content-area"
      id="content-area-container"
      style={{
        width: "100%",
        background: "white",
        boxShadow: "rgb(136, 136, 136) 0px 0px 8px 3px",
      }}
    >
      <div id="content-area">
        <div className="content_layout">
          <header className="header">Terms and Conditions</header>

          <div className="static-cms">
            <p className="indent">
              The purpose of this website is to alert users to the typical
              hazards they may come across whilst working on Network Rail’s
              Infrastructure. The National Hazard Directory is maintained by
              Network Rail to provide its employees and contractors with
              information on known hazards present on the infrastructure in
              order to assist in the identification of the associated risks
              working ‘on or near the line’.
            </p>
            <p className="indent">
              The records are updated regularly and therefore Network Rail
              believe that the contents are reasonably accurate at the time of
              issue, but some of the information can vary in age and accuracy so
              for that reason Network Rail will give no warranty as to the
              suitability of its use. It is recommended that all searches (in
              particular for buried services) should be conducted together with
              a site specific risk assessment/site visit, taking into account
              the requirements of the appropriate track safety rules, rule
              books/industry standards and so on. Network Rail will accept no
              liability in respect of the content or subsequent use of the
              National Hazard Directory or any of the information contained
              within.
            </p>

            <header className="header">Utilities and buried Services</header>
            <p className="indent">
              The information relating to buried services and local utilities
              contained within the National Hazard Directory does not replace
              the legal requirement to conduct a full buried services survey
              when penetrating the ground. Please contact your local Network
              Rail representative or line Manager before commencing work
              involving buried services.
            </p>

            <Button
              action="primary"
              label="Accept"
              onClick={props.closeModal}
              className="indent"
            />
            <p className="indent"></p>
            <p className="indent">
              OnTrac Ltd does not warrant the use of the Network Rail National
              Hazard Directory or any of the information contained within and no
              representations or warranties are made as to completeness or
              accuracy of the data. The data should be used for reference
              purposes only. Accordingly, OnTrac Ltd will accept no
              responsibility for loss of profit or for any indirect, incidental
              or consequential damages.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermnsAndConditions;
