import React from "react";
import Header from "../components/Header.js";
import Button from "../components/Button.js";
import BackButton from "../components/BackButton.js";
import {useNavigate} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faBan, faPersonDigging, faSubway, faTableList} from "@fortawesome/free-solid-svg-icons";

function StandardReportsPage(props) {
  let navigate = useNavigate();

  return (
    <div className="page" id="standardReportsPage">
      <Header title="Standard Reports" />
      <BackButton navigateUrl="/" />
      <div className="card__wrap">
        <div className="card card--width20">
          <FontAwesomeIcon icon={faSubway} className="card__icon" />
          <h3 className="card__title">Access Points </h3>

          <Button
            action="round"
            label="Access Points"
            onClick={() => navigate("/accessPoints")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faTableList} className="card__icon" />
          <h3 className="card__title">Appendix A - ELRs</h3>

          <Button
            action="round"
            label="Appendix A - ELRs"
            onClick={() => navigate("/appendixa")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faPersonDigging} className="card__icon" />
          <h3 className="card__title">Extract for Site of Work</h3>

          <Button
            action="round"
            label="Extract for Site of Work"
            onClick={() => navigate("/extractworksite")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faTableList} className="card__icon" />
          <h3 className="card__title">Extract for ELR</h3>

          <Button
            action="round"
            label="Extract for ELR"
            onClick={() => navigate("/extractelr")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faBan} className="card__icon" />
          <h3 className="card__title">Red Zone Working Prohibitions</h3>

          <Button
            action="round"
            label="Red Zone Working Prohibitions"
            onClick={() => navigate("/redzone")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>
        <div className="card card--width20">
          <FontAwesomeIcon icon={faTableList} className="card__icon" />
          <h3 className="card__title">Section by MDU</h3>

          <Button
            action="round"
            label="Section by MDU"
            onClick={() => navigate("/sectionmdu")}
          >
            <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
          </Button>
        </div>

        <span className="card--width20"></span>
        <span className="card--width20"></span>
      </div>
    </div>
  );
}

export default StandardReportsPage;
