import React, {useEffect, useState} from "react";
import {Amplify, Auth, Hub} from "aws-amplify";
import awsExports from "./aws-exports";
import Main from "./Main";
import {
    Authenticator,
    Button,
    Divider,
    Flex,
    Heading,
    Text,
    useAuthenticator,
    useTheme,
    View,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {addAuthorizationHeader} from "./_helpers/axiosinstance";
import "./App.css";
import OnboardingService from "./services/onboarding.service";
import {toast, ToastContainer} from "react-toastify";
import BouncingDotsLoader from "./components/Loader/BouncingDotsLoader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

Amplify.configure(awsExports);

const initialValues = {
  firstname: "",
  surname: "",
  email: "",
  organisation: "",
  telNo: "",
};

export default function App(props) {
  const { tokens } = useTheme();
  const [selectedTab, setSelectedTab] = useState("signin");
  const [loggedIn, setLoggedIn] = useState(true);
  const [requestAccessValues, setRequestAccessValues] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [env, setEnv] = useState("");

  useEffect(() => {
    console.log(loggedIn);
    setEnv(process.env.REACT_APP_ENV);
  }, [loggedIn]);

    Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
            case 'signIn':
                console.log('sign in', event, data)
                // this.setState({ user: data})
                break
            case 'signOut':
                console.log('sign out')
                // this.setState({ user: null })
                break
            default:
                console.log('Hub Message: ', event);
        }
    })

  function handleInputChange(e) {
    setRequestAccessValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
    setErrorMessage();
  }

  const handleRequestAccess = () => {
    if (
      requestAccessValues.firstname &&
      requestAccessValues.surname &&
      requestAccessValues.email &&
      requestAccessValues.organisation &&
      requestAccessValues.telNo
    ) {
      setLoading(true);
      OnboardingService.onboarding(requestAccessValues)
        .then((response) => {
          setRequestAccessValues(initialValues);
          toast.success(
            `Your request has been submitted. It is currently being reviewed by admin. Once your request has been reviewed, you will receive an email.`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setLoading(false);
        })
        .catch((error) => console.log(error));
    } else {
      setErrorMessage("Please fill in all fields");
    }
  };

  const logout = (cb) => {
    // setLoggedIn(false);
    cb();
  };

  const services = {
    async handleSignIn(formData) {
      let { username, password } = formData;
      // custom username
      return Auth.signIn({
        username,
        password,
      }).then((response) => {
        setLoggedIn("true");
        console.log(response);
        return response;
      });
    },
  };

  const components = {
    Footer() {
      const { tokens } = useTheme();
      if (loggedIn !== "true") {
        setLoggedIn(false);
      }
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            © Copyright Ontrac 2024. All rights reserved.
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();
          return (
             <div>
                 {env !== "DEV" && (
                     <Flex direction="column" justifyContent="space-between">
                         <Divider orientation="horizontal"/>
                         <Flex direction="row" justifyContent="center">
                             <Button
                                 fontWeight="normal"
                                 onClick={() => window.location.href = `https://${awsExports.oauth.domain}/login?client_id=${awsExports.aws_user_pools_web_client_id}&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${awsExports.oauth.redirectSignIn}`}
                                 variation="primary"
                                 textAlign="center"
                             >Login Using NR Active Directory</Button>
                         </Flex>
                         <Text textAlign="center">OR</Text>
                     </Flex>
                 )}
             </div>
          )
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
              className="resetPassword"
            >
              Reset Password
            </Button>
            <Text
              color={tokens.colors.neutral[80]}
              width="90%"
              fontSize="9px"
              textAlign={"center"}
              margin={"5px auto 10px auto"}
            >
              <FontAwesomeIcon icon={faInfoCircle} className="nav__icon" />
              The Password reset function is for anyone who does not have a
              Network Rail email address. Network Rail password reset will be
              handled via Azure Active Directory.
            </Text>
          </View>
        );
      },
    },

    SignUp: {
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    SetupTOTP: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
  };

  return (
    <div>
      <div
        style={{
          display: loggedIn ? "none" : "flex",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        <img
          className="formWidth"
          alt="Network Rail logo"
          src="/img/network-rail-logo.svg"
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          className="formWidth"
          style={{
            display: loggedIn ? "none" : "flex",

            placeSelf: "center",
            border: "1px solid hsl(210, 8%, 55%)",
            borderBottom: "none",
          }}
        >
          <span
            style={{
              flex: "1",
              textAlign: "center",
              fontSize: "1rem",
              fontWeight: "700",
              padding: "0.75rem",
              borderTop: selectedTab === "signin" ? "1px solid red" : "",
            }}
            onClick={(e) => setSelectedTab("signin")}
          >
            Sign in
          </span>
          <span
            style={{
              flex: "1",
              textAlign: "center",
              fontSize: "1rem",
              fontWeight: "700",
              padding: "0.75rem",
              borderTop: selectedTab !== "signin" ? "1px solid red" : "",
            }}
            onClick={(e) => setSelectedTab("requestaccess")}
          >
            Request Access
          </span>
        </div>
        {selectedTab === "signin" ? (
            <div>

          <Authenticator
            components={components}
            services={services}
            hideSignUp={true}
          >
            {({ signOut, user }) => {
              addAuthorizationHeader(user);
              return (
                <Main
                  setLoggedIn={setLoggedIn}
                  signOut={() => logout(signOut)}
                  user={user}
                  navigateTo="/smmdashboard"
                />
              );
            }}
          </Authenticator>
            </div>
        ) : (
          <div
            className="formWidth"
            style={{
              display: "flex",

              placeSelf: "center",
              border: "1px solid hsl(210, 8%, 55%)",
              borderTop: "none",
              padding: "2.0rem",
              boxSizing: "border-box",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <input
              className="amplify-input"
              type="text"
              placeholder="First Name"
              name="firstname"
              onChange={handleInputChange}
              value={requestAccessValues.firstname}
            />
            <input
              className="amplify-input"
              type="text"
              placeholder="Surname"
              name="surname"
              onChange={handleInputChange}
              value={requestAccessValues.surname}
            />
            <input
              className="amplify-input"
              type="text"
              placeholder="Email"
              name="email"
              onChange={handleInputChange}
              value={requestAccessValues.email}
            />
            <input
              className="amplify-input"
              type="text"
              placeholder="Organisation"
              name="organisation"
              onChange={handleInputChange}
              value={requestAccessValues.organisation}
            />
            <input
              className="amplify-input"
              type="text"
              placeholder="Telephone Number"
              name="telNo"
              onChange={handleInputChange}
              value={requestAccessValues.telNo}
            />
            {!loading ? (
              <button
                className="amplify-button amplify-field-group__control amplify-button--primary amplify-button--fullwidth"
                type="submit"
                onClick={handleRequestAccess}
              >
                Request Access
              </button>
            ) : (
              <BouncingDotsLoader style={{ marginTop: "15px" }} />
            )}
            <div className="form-error">
              {errorMessage && (
                <div className="form-error">
                  <span className="input__error">{errorMessage}</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
}
