import axios from "axios";
import {Auth} from "aws-amplify";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  // headers: {
  //   Authorization: `Bearer ${Auth.user
  //     ?.getSignInUserSession()
  //     .getIdToken()
  //     .getJwtToken()}`,

  //   "X-Custom-Header": "foobar",
  // },
  //timeout: 10000,
});

export default instance;

export const addAuthorizationHeader = (user) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${user
    .getSignInUserSession()
    .getIdToken()
    .getJwtToken()}`;
};

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    response.status = {
      loading: false,
      error: false,
    };
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(async (config) => {
  if (config.jwtToken) {
    config.headers["Authorization"] = `Bearer ${config.jwtToken}`;
  }
  if (config.requiresIdentityId) {
    try {
      let data = await Auth.currentUserCredentials();
      config.headers["x-identityId"] = data.identityId;
      return config;
    } catch (error) {
      console.log("error fetching identityId");
    }
  } else {
    return config;
  }
});
