import React, {forwardRef, useEffect, useImperativeHandle, useState,} from "react";

const Input = forwardRef((props, ref) => {
  const [error, setError] = useState();
  const [errorMsg, setErrorMsg] = useState();

  useImperativeHandle(ref, () => ({
    validateField() {
      return validate();
    },
  }));

  useEffect(() => {
    setError();
    setErrorMsg();

    if (props.fieldsErrors?.[props.name]) {
      setError(true);
      setErrorMsg("Invalid value");
    } else {
      setError(false);
      setErrorMsg();
    }
  }, [props.fieldsErrors?.[props.name]]);

  let classProps = ["input__wrap"];
  if (props.disabled) {
    classProps.push("input__wrap--disabled");
  }
  if (props.className) {
    classProps.push(props.className);
  }
  if (error) {
    classProps.push("input__wrap--error");
  }

  let inputProps = {
    type: props.type ? props.type : "text",
    className: "input",
    id: props.id ? props.id : props.name,
    name: props.name,
  };
  inputProps.value =
    props.value === 0 ? props.value : props.value ? props.value : "";
  if (props.min) {
    inputProps.min = props.min;
  }
  if (props.max) {
    inputProps.max = props.max;
  }
  if (props.step) {
    inputProps.step = props.step;
  }
  if (props.disabled) {
    inputProps.disabled = props.disabled;
  }
  if (props.readOnly) {
    inputProps.readOnly = props.readOnly;
    classProps.push("readOnly");
  }
  if (props.required) {
    inputProps.required = props.required;
    inputProps.onBlur = () => validate();
  }
  if (props.minlength) {
    inputProps.minlength = props.minlength;
  }
  if (props.maxLength) {
    inputProps.maxLength = props.maxLength;
  }
  if (props.placeholder) {
    inputProps.placeholder = props.placeholder;
  }
  if (props.accept) {
    inputProps.accept = props.accept;
  }
  if (props.onChange) {
    inputProps.onChange = props.onChange;
  }
  if (props.onBlur) {
    inputProps.onBlur = props.onBlur;
  }
  if (props.checked) {
    inputProps.checked = props.checked;
  }
  if (props.onClick) {
    inputProps.onClick = props.onClick;
  }
  if (props.pattern) {
    inputProps.pattern = props.pattern;
  }
  if (props.min) {
    inputProps.min = props.min;
  }
  if (props.focus) {
    inputProps.autoFocus = props.focus;
  }

  useEffect(() => {
    if (props.clearErrors) {
      setError(false);
      setErrorMsg();
    }
  }, [props.clearErrors]);

  function validate() {
    if (props.value != "0" && (!props.value || props.value === "") && props.required) {
      console.log("setting error required for" + props.name);
      setError(true);
      setErrorMsg("This field is required");
      return false;
    } else if (props.fieldsErrors?.[props.name]) {
      setError(true);
      setErrorMsg("Invalid value");
      return false;
    } else {
      console.log("removing error for" + props.name);
      setError(false);
      setErrorMsg();
      return true;
    }
  }

  return (
    <div
      className={classProps.join(" ")}
      key={props.keyprops ? props.keyprops + props.name : props.name}
    >
      <label
        className="input__label"
        htmlFor={props.id ? props.id : props.name}
      >
        {props.label ? `${props.label}: ` : props.name ? `${props.name}: ` : ""}
        {inputProps.required ? <span className="small">[Required]</span> : ""}
      </label>
      <div style={{ display: "flex", gap: "1em" }}>
        {props.custom &&
          props.custom.map((ele, index) => {
            return <input {...ele} style={{ flex: "1" }} />;
          })}
      </div>
      <div>
        {!props.custom && <input {...inputProps} style={props.style}></input>}
        {props.children && props.children}
      </div>
      {props.hint ? <span className="input__label">{props.hint}</span> : "" }
      {error ? <span className="input__error">{errorMsg}</span> : ""}
    </div>
  );
});

export default Input;
