import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";

function CrimeHotspots(props) {
  return (
    <div className="page " id="crimeHotspotsPage">
      <Header title="Crime Hotspots" />
      <BackButton navigateUrl="/generalinfo" />

      <div className="buried-services__wrap">
        <div className="buried-services">
          <h2 className="buried-services__h2">Hotspot Route Crime</h2>

          <table>
            <tbody>
              <tr style={{ height: "23px" }}>
                <th style={{ height: "23px" }}>&nbsp;Location</th>
                <th style={{ height: "23px" }}>Line Reference</th>
                <th style={{ height: "23px" }}>Mileage &amp; Chains</th>
              </tr>
              <tr style={{ height: "23px" }}>
                <td style={{ height: "23px" }}>
                  &nbsp;Kirkby Summit to Mansfield Woodhouse
                </td>
                <td style={{ height: "23px" }}>&nbsp;PBS2/3</td>
                <td style={{ height: "23px" }}>
                  &nbsp;137M -11CH to 142M- 17CH
                </td>
              </tr>
              <tr style={{ height: "23px" }}>
                <td style={{ height: "23px" }}>
                  &nbsp;Knighton Junction to Wigston South Jnc
                </td>
                <td style={{ height: "23px" }}>&nbsp;SPC4</td>
                <td style={{ height: "23px" }}>&nbsp;95M -37CH to 97M -45CH</td>
              </tr>
              <tr style={{ height: "23px" }}>
                <td style={{ height: "23px" }}>
                  &nbsp;Bedford North to Bedforth South
                </td>
                <td style={{ height: "23px" }}>&nbsp;SPC1/2</td>
                <td style={{ height: "23px" }}>&nbsp;48M-60CH to 50M-35CH</td>
              </tr>
              <tr style={{ height: "23.5px" }}>
                <td style={{ height: "23.5px" }}>&nbsp;Wellingborough</td>
                <td style={{ height: "23.5px" }}>&nbsp;SPC3</td>
                <td style={{ height: "23.5px" }}>&nbsp;65M-11CH</td>
              </tr>
              <tr style={{ height: "23px" }}>
                <td style={{ height: "23px" }}>
                  &nbsp;Trowell Jnuction to Horns Bridge
                </td>
                <td style={{ height: "23px" }}>&nbsp;TCC/SPC9</td>
                <td style={{ height: "23px" }}>&nbsp;125M-09CH to 144M-68CH</td>
              </tr>
              <tr style={{ height: "23px" }}>
                <td style={{ height: "23px" }}>&nbsp;Derby to Peartree</td>
                <td style={{ height: "23px" }}>&nbsp;DBP1</td>
                <td style={{ height: "23px" }}>&nbsp;0-00M to1M-16CH</td>
              </tr>
              <tr style={{ height: "23px" }}>
                <td style={{ height: "23px" }}>
                  &nbsp;Elstree to Radlett&nbsp;
                </td>
                <td style={{ height: "23px" }}>&nbsp;SPC1</td>
                <td style={{ height: "23px" }}>&nbsp;12M-35CH to 14M-33CH</td>
              </tr>
              <tr style={{ height: "23px" }}>
                <td style={{ height: "23px" }}>
                  &nbsp;Loughborough Sth To Loughborough Nth
                </td>
                <td style={{ height: "23px" }}>&nbsp;SPC5</td>
                <td style={{ height: "23px" }}>&nbsp;111M-22CH to 112M-06CH</td>
              </tr>
              <tr style={{ height: "23px" }}>
                <td style={{ height: "23px" }}>
                  &nbsp;Knighton to kirby Muxloe
                </td>
                <td style={{ height: "23px" }}>&nbsp;KSL</td>
                <td style={{ height: "23px" }}>&nbsp;97M-45CH to 102M-36CH</td>
              </tr>
              <tr style={{ height: "23px" }}>
                <td style={{ height: "23px" }}>&nbsp;Hinckley</td>
                <td style={{ height: "23px" }}>&nbsp;WSN</td>
                <td style={{ height: "23px" }}>&nbsp;4-00M</td>
              </tr>
              <tr style={{ height: "23px" }}>
                <td style={{ height: "23px" }}>&nbsp;Bulwell</td>
                <td style={{ height: "23px" }}>&nbsp;RAC</td>
                <td style={{ height: "23px" }}>&nbsp;128M-76CH</td>
              </tr>
              <tr style={{ height: "23px" }}>
                <td style={{ height: "23px" }}>Corby</td>
                <td style={{ height: "23px" }}>GSM1</td>
                <td style={{ height: "23px" }}>79M-32CH</td>
              </tr>
            </tbody>
          </table>
          <br />

          <h5>Definition of a Route Crime Hotspot</h5>
          <p className="buried-services__p">
            <b>
              A Route Crime Hotspot is defined as a location with:
              <br />
              a) very high levels of repeat trespass and vandalism with
              <br />
              b) potential high consequence leading to
              <br />
              c) significant risk from train derailment.
              <br />
            </b>
          </p>
        </div>
      </div>
    </div>
  );
}

export default CrimeHotspots;
