const typesHC = [
  {
    value: "Any",
    description: "Any",
  },
  {
    value: "MAV",
    description: "Vehicle",
  },
  {
    value: "MAP",
    description: "Pedestrian",
  },
  {
    value: "MAR",
    description: "Road-Rail",
  },
];

const hazardTypesHC = {
  all: "All",
  ah: "Access Points",
  eh: "Environmental Hazards",
  sa: "Health & Safety Codes",
  oh: "Other Hazards",
};

const data = {
  hazardTypesHC,
  typesHC,
};

export default data;
