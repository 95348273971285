import React, {forwardRef, useMemo, useRef, useState} from "react";
import Button from "../Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faEdit,
    faExternalLinkAlt,
    faSort,
    faSortDown,
    faSortUp,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {usePagination, useTable} from "react-table";
import "./ReactTable.scss";
import Checkbox from "../Checkbox";
import {useExpanded} from "react-table/dist/react-table.development";
import useWindowSize from "../../hooks/useWindowSize";

const ReactTable = forwardRef((props, ref) => {
  const {
    tableData,
    pageSize,
    currentPage,
    totalPages,
    setPage,
    setPageSize,
    exportCsv,
    totalElements,
    renderRowSubComponent,
    sortOptions,
    setSortOptions,
    handleTableSort
  } = props;

  const tableRef = useRef(null);


  const [goToPage, setGoToPage] = useState();
  const windowSize = useWindowSize();
  const totalPagesCalculated =
    totalElements % pageSize === 0 ? totalPages - 1 : totalPages;
  const editHeader = props?.editHeader || "Edit";
  const editIcon = props?.editIcon || faEdit;

  const columns = React.useMemo(() => {
    let dataColumnsArray;
    let columnsArray = [];
    let nestedColumns = [];
    let utilsColumns = [];
    let extensibleCoolumn = [];
      if (!props.customSearch) {
          tableData?.[0]
              ? (dataColumnsArray = Object.keys(tableData[0]).map((key, i) => {
                  if (!props.hideColumns?.includes(key)) {
                      if (
                          typeof tableData[0][key] === "object" &&
                          !Array.isArray(tableData[0][key]) &&
                          tableData[0][key] !== null
                      ) {
                          nestedColumns = Object.keys(tableData[0][key]).map(
                              (nestedKey, i) => {
                                  let nestColumn = {
                                      Header: nestedKey
                                          .replace(/([A-Z])/g, " $1")
                                          .replace(/^./, function (str) {
                                              return str.toUpperCase();
                                          })
                                          .replace(/_[a-z]/g, function (str) {
                                              return str.toUpperCase();
                                          })
                                          .replace(/_/g, " ")
                                          .replace(/\bI D\b/g, "ID")
                                          .replace(/\bElr\b/g, "ELR")
                                          .replace(/\bSubzone\b/g, "MDU")
                                          .replace(/\bMdu\b/g, "MDU")
                                          .replace(/\bId\b/g, "ID")
                                          .replace(/\bSubmittedby/g, "Submitter")
                                          .replace(/\bLastupdate/g, "Last Update"),

                                      accessor: `${key}.${nestedKey}`,
                                      disableSortBy: true,
                                      sortByKey: `${key}.${nestedKey}`
                                  };
                                  // headerObj.columns.push(nestColumn);
                                  return nestColumn;
                              }
                          );
                      } else {
                          return {
                              Header: key
                                  .replace(/([A-Z])/g, " $1")
                                  .replace(/^./, function (str) {
                                      return str.toUpperCase();
                                  })
                                  .replace(/_[a-z]/g, function (str) {
                                      return str.toUpperCase();
                                  })
                                  .replace(/_/g, " ")
                                  .replace(/\bI D\b/g, "ID")
                                  .replace(/\bElr\b/g, "ELR")
                                  .replace(/\bSubzone\b/g, "MDU")
                                  .replace(/\bMdu\b/g, "MDU")
                                  .replace(/\bId\b/g, "ID")
                                  .replace(/\bSubmittedby/g, "Submitter")
                                  .replace(/\bLastupdate/g, "Last Update"),
                              accessor: key,
                              disableSortBy: true,
                              sortByKey: key
                          };
                      }
                  }
              }))
              : (dataColumnsArray = []);
      } else {

          dataColumnsArray = Object.entries(props.showColumns).map(entry => {
              return {
                  Header: entry[1].header
                      // .replace(/([A-Z])/g, " $1")
                      // .replace(/^./, function (str) {
                      //     return str.toUpperCase();
                      // })
                      // .replace(/_[a-z]/g, function (str) {
                      //     return str.toUpperCase();
                      // })
                      .replace(/_/g, " ")
                      .replace(/\bI D\b/g, "ID")
                      .replace(/\bElr\b/g, "ELR")
                      .replace(/\bSubzone\b/g, "MDU")
                      .replace(/\bMdu\b/g, "MDU")
                      .replace(/\bId\b/g, "ID")
                      .replace(/\bSubmittedby/g, "Submitter")
                      .replace(/\bLastupdate/g, "Last Update"),
                  accessor: entry[1].accessor,
                  disableSortBy: !entry[1].sort,
                  sortByKey: entry[1].databaseField ? entry[1].databaseField : entry[1].accessor
              };
          });
      }

    if (props.editAction) {
      utilsColumns.push({
          Header: editHeader,
        Cell: (row) => {
          return useMemo(() => {
            return (
              <Button
                action="tertiary"
                onClick={(e) => {
                  props.editAction(e, row);
                }}
              >
                <FontAwesomeIcon icon={editIcon} />
              </Button>
            );
          }, [props.editAction]);
        },
      });
    }
    if (props.deleteAction) {
      utilsColumns.push({
        Header: "Delete",
        Cell: (row) => {
          return (
            <Button
              action="tertiary"
              onClick={(e) => props.deleteAction(e, row)}
              disabled={
                row.row.original.submission_status !== "Proposed Deletion"
              }
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          );
        },
      });
    }


    columnsArray = columnsArray.concat(utilsColumns);
    dataColumnsArray = dataColumnsArray.filter((ele) => ele !== undefined);
    dataColumnsArray = dataColumnsArray.concat(nestedColumns);

    if (props.showColumns) {
      let showColumnsNormalized;

        if (!props.customHeader) {
            showColumnsNormalized = props.showColumns.map((ele) => {
                return ele
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                        return str.toUpperCase();
                    })
                    .replace(/_/g, " ")
                    .replace(/\bI D\b/g, "ID")
                    .replace(/\bElr\b/g, "ELR")
                    .replace(/\bSubzone\b/g, "MDU")
                    .replace(/\bId\b/g, "ID")
                    .replace(/\bname\b/g, "Name");
            });
        } else {
            showColumnsNormalized = props.showColumns.map((ele) => {
                return ele.header.replace(/^./, function (str) {
                    return str.toUpperCase();
                })
            });
        }
        if (!props.customSearch) {
            dataColumnsArray = dataColumnsArray.filter((ele) => {
                return showColumnsNormalized.includes(ele.Header);
            });
        }
        // if (!props.customHeader) {
            dataColumnsArray.sort((a, b) => {
                return (
                    showColumnsNormalized.indexOf(a.Header) -
                    showColumnsNormalized.indexOf(b.Header)
                );
            });
        // }
    }
    if (props.hideColumns) {
      let showColumnsNormalized = props.hideColumns.map((ele) => {
        return ele
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, function (str) {
            return str.toUpperCase();
          })
          .replace(/_/g, " ")
          .replace(/\bI D\b/g, "ID")
          .replace(/\bElr\b/g, "ELR")
          .replace(/\bSubzone\b/g, "MDU")
          .replace(/\bId\b/g, "ID");
      });
      dataColumnsArray = dataColumnsArray.filter((ele) => {
        return !showColumnsNormalized.includes(ele.Header);
      });
    }

    if (props.extensible) {
      columnsArray.unshift(
        {
          // Make an expander cell
          Header: () => null, // No header
          id: "expander", // It needs an ID
          Cell: ({ row }) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <FontAwesomeIcon
                  className="inline-block filter-icon"
                  icon={faAngleUp}
                  title="Collapse"
                />
              ) : (
                <FontAwesomeIcon
                  className="inline-block filter-icon"
                  icon={faAngleDown}
                  title="Expand"
                />
              )}
            </span>
          ),
        }
      );
    }

    columnsArray = columnsArray.concat(dataColumnsArray);
    if (props.canOpen) {
      utilsColumns.push({
        Header: editHeader,
        Cell: (row) => (
            <Button
                action="tertiary"
                onClick={(e) => {
                  props.handleOpen(e, row);
                }}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </Button>
        ),
        disableSortBy: true,
      });
      columnsArray = columnsArray.concat(utilsColumns);
    }

    return columnsArray;
  }, [tableData]);
  const data = React.useMemo(() => (tableData ? tableData : []), [tableData]);

  let classProps = ["res-table"];
  if (props.disabled) {
    classProps.push("res-table--disabled");
  }
  if (props.className) {
    classProps.push(props.className);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,

    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    visibleColumns,
    state: { expanded },
  } = useTable({ columns, data }, useExpanded, usePagination);

  return (
    <>
      {exportCsv && (
        <div className="button-group report-form__submit">
          <Button
            action="secondary"
            label="Export"
            onClick={props.exportCsv.action}
          />
          {/*<Button*/}
          {/*  action="secondary"*/}
          {/*  label="Print Friendly"*/}
          {/*  onClick={props.printFriendly?.action}*/}
          {/*  // onClick={() => {*/}
          {/*  //   let divToPrint = tableRef.current;*/}
          {/*  //   let newWin = window.open("");*/}
          {/*  //   newWin.document.write(divToPrint.outerHTML);*/}
          {/*  //   newWin.print();*/}
          {/*  //   newWin.close();*/}
          {/*  // }}*/}
          {/*/>*/}
          {/* <ReactToPrint
            trigger={() => (
              <Button
                action="primary"
                label="Print Friendly"
                onClick={props.printFriendly.handlePrintFriendly}
                // onClick={() => {
                //   let divToPrint = tableRef.current;
                //   let newWin = window.open("");
                //   newWin.document.write(divToPrint.outerHTML);
                //   newWin.print();
                //   newWin.close();
                // }}
              />
            )}
            content={() => tableRef.current}
          /> */}
        </div>
      )}
      <div className="res-table__wrap" style={{ overflowX: "scroll" }}>
        <table
          ref={tableRef}
          {...getTableProps()}
          className={classProps.join(" ")}
        >
          <thead className="res-table__thead">
            {headerGroups.map((headerGroup, headerIndex) => (
              <tr
                className="res-table__tr"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      className="res-table__th"
                      {...column.getHeaderProps()}
                      onClick={() => !column?.disableSortBy ? handleTableSort(column?.sortByKey) : undefined}
                      style={{cursor : column?.disableSortBy ? 'default' : 'pointer' }}
                    >
                      {column.render("Header")}
                      {!["Open", editHeader].includes(column["Header"]) && (
                        <span>
                          {" "}
                          {!column.disableSortBy && column?.sortByKey === sortOptions?.accessor && <>
                            {sortOptions?.direction === 'dsc' && <FontAwesomeIcon icon={faSortDown} />}
                            {sortOptions?.direction === 'asc' && <FontAwesomeIcon icon={faSortUp} />}
                            {!sortOptions?.direction && <FontAwesomeIcon icon={faSort} />}
                          </>}
                          {!column.disableSortBy && column?.sortByKey !== sortOptions?.accessor && <FontAwesomeIcon icon={faSort} />}
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="res-table__tbody" {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={"row-" + i}>
                  <tr className="res-table__tr" {...row.getRowProps()}>
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          className={`res-table__td `}
                          {...cell.getCellProps()}
                          data-before={cell.column.Header}
                          
                        >
                          {/* <span style={{ marginRight: "10px" }}>
                            {" "}
                            {cell.column.isSorted ? (
                              cell.column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faSortUp} />
                              ) : (
                                <FontAwesomeIcon icon={faSortDown} />
                              )
                            ) : cell.column.originalId?.includes(
                                "_placeholder"
                              ) || cell.column.columns ? (
                              ""
                            ) : (
                              <FontAwesomeIcon icon={faSort} />
                            )}
                          </span> */}
                          {typeof cell.value == "boolean" ? (
                            cell.value === true ? (
                              <Checkbox checked={true} readOnly></Checkbox>
                            ) : (
                              <Checkbox checked={false} readOnly></Checkbox>
                            )
                          ) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr className="res-table__tr">
                      <td
                        className="fields-changes"
                        colSpan={visibleColumns.length}
                      >
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        {setPage && (
          <div className="pagination">
            <span className="pagination__page-no">
              Page{" "}
              <b>
                {currentPage + 1} of {totalPages}
              </b>
            </span>
            <div className="pagination__buttons">
              <Button
                action="tertiary"
                onClick={() => setPage(0)}
                disabled={currentPage <= 0}
              >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
              </Button>
              <Button
                action="tertiary"
                onClick={() => setPage(currentPage - 1)}
                disabled={currentPage <= 0}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </Button>
              <Button
                action="tertiary"
                onClick={() => setPage(currentPage + 1)}
                disabled={currentPage >= totalPages - 1}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </Button>
              <Button
                action="tertiary"
                onClick={() => setPage(totalPages - 1)}
                disabled={currentPage >= totalPages - 1}
              >
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              </Button>
            </div>

            <div className="pagination__controls">
              <label
                className="pagination__label"
                onClick={(e) =>
                  goToPage ? setPage(goToPage) : setPage(currentPage)
                }
              >
                Go to page:
              </label>
              <input
                key={"pagination__input" + currentPage}
                className="input pagination__input"
                type="number"
                defaultValue={isNaN(currentPage) ? 1 : currentPage + 1}
                min="1"
                max={totalPages}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;

                  if (!e.target.checkValidity()) {
                    e.target.reportValidity();
                    return;
                  }
                  setGoToPage(page);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    console.log(goToPage);
                    goToPage || goToPage === 0
                      ? setPage(goToPage)
                      : setPage(currentPage);
                  }
                }}
              />
              <select
                className="select pagination__select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </>
  );
});

export default ReactTable;
