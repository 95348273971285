import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {ThemeContext} from "../../contexts/ThemeContextProvider";
import {useAuthenticator} from "@aws-amplify/ui-react";
import useWindowSize from "../../hooks/useWindowSize";
import Header from "../../components/Header";
import BackButton from "../../components/BackButton";
import ReactTable from "../../components/ReactTable/ReactTable";
import {OrganisationService} from "../../services/organisation.service";
import Input from "../../components/Input";
import BouncingDotsLoader from "../../components/Loader/BouncingDotsLoader";
import Button from "../../components/Button";
import downloadFile from "../../_helpers/downloadFile";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus";

export default function OrgManagementPage(props) {

    const [loading, setLoading] = useState(false);
    const [organisations, setOrganisations] = useState();
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchOrgName, setSearchOrgName] = useState();

    const {state} = useLocation();
    let navigate = useNavigate();
    const context = useContext(ThemeContext);
    const {user} = useAuthenticator((context) => [context.user]);
    const size = useWindowSize();
    const [sortOptions, setSortOptions] = useState({accessor: '', direction: ''});
    const [searching, setSearching] = useState(false);

    function getOrganisations(pageNumber, pageSize) {
        setLoading(true);
        setSearching(false);
        OrganisationService.getOrganisations(pageNumber, pageSize, false, sortOptions).then((response) => {
            setOrganisations(response.data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        getOrganisations()
    }, []);

    useEffect(() => {
        if (organisations?.results.length > 0) {
            handleSubmit(undefined, 10, sortOptions);
        }
    }, [sortOptions])

    const handlePageSize = (val) => {
        setPage(0);
        setPageSize(val);
    };

    function handleSubmit(pageVal, pageSizeVal) {
        let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
        let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
        if (!searching) {
            getOrganisations(pageNumber, pageSizeNumber);
        } else {
            handleSearch();
        }
    }

    const handleOpenOrganisationDetails = (e, row) => {
        navigate("/organisationdetails", {
            state: { data: row.row?.original, title: "Organisation Details"},
        });
    }

    function handleReset() {
        setSearchOrgName(null);
        getOrganisations(page, pageSize);
    }

    const handleInputChange = (e) => {
        if (e.target.name === "orgname") {
            if (e.target.value !== undefined) {
                setSearchOrgName(e.target.value);
            } else {
                setSearchOrgName(null);
            }
        }
    }

    function handleSearch() {
        setLoading(true);
        setSearching(true);
        OrganisationService.searchByOrgName(searchOrgName, page, pageSize, sortOptions).then((response) => {
            setOrganisations(response.data);
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
            setSearching(false);
        });
    }

    const handleExportOrganisations = () => {
        OrganisationService.exportOrganisations()
        .then((response) => {
            downloadFile(response);
        }).catch((error) => {
            console.log(error);
        });
    };

    const handleTableSort = (accessor) => {
        let direction = sortOptions?.direction;

        if (!direction) {
            direction = 'dsc';
        } else if (direction === 'dsc') {
            direction = 'asc';
        } else {
            direction = '';
        }

        setSortOptions({
            accessor : accessor,
            direction : direction
        });
    };

    return (
        <div className="page" id="orgManagementPage">
            <Header title="Organisation Management"/>
            <BackButton
                setPage={props.setPage}
                target={["adminDashPage", "backward"]}
                navigateUrl="/admindash"
            />
            <div>
                <h2>Organisations</h2>
                <div className="report-form">
                    <div className="report-form__section">
                        <h2 className="admin-report-filter__title">
                            Search Filters
                        </h2>
                        <p>Search Organisation by name. The search is case insensitive.</p>
                    </div>
                    <div className="report-form__section">
                        <Input
                            name="orgname"
                            label="Organisation Name"
                            onChange={(e) => handleInputChange(e)}
                            value={searchOrgName}
                        />
                    </div>
                    <div className="button-group report-form__submit">
                        {loading && <BouncingDotsLoader></BouncingDotsLoader>}
                        {!loading && (
                                <Button action="secondary" label="Reset" onClick={handleReset}/>
                                )}
                        {!loading && (
                                <Button action="primary" label="Search" onClick={handleSearch}/>
                                )}

                    </div>
                </div>
                {organisations?.results && organisations?.results?.length > 0 ? (
                    <div>
                        <ReactTable
                            tableData={organisations?.results}
                            showColumns={organisations?.headers}
                            customHeader={true}
                            customSearch={true}
                            currentPage={organisations?.pageNumber}
                            totalPages={organisations?.totalPages}
                            pageSize={organisations?.pageSize}
                            totalElements={organisations?.totalElements}
                            canOpen={true}
                            handleOpen={handleOpenOrganisationDetails}
                            setPage={(val) => {
                                setPage(val);
                                handleSubmit(val, undefined);
                            }}
                            setPageSize={(val) => {
                                handlePageSize(val);
                                handleSubmit(0, val);
                            }}
                            sortOptions={sortOptions}
                            setSortOptions={setSortOptions}
                            handleTableSort={handleTableSort}
                        />
                        <div className="button-group">
                            <Button action="secondary" label="Export Organisations" onClick={handleExportOrganisations}/>
                            <Button
                                action="primary"
                                label="Add Organisation"
                                onClick={() => navigate("/adminAddOrganisation", {
                                    state: { type: 'add' }
                                })}
                            />
                        </div>
                    </div>
                ) : <div className="loadingCircle">
                    No Results Based On Your Search Criteria
                    <span style={{marginLeft: "10px"}}> <FontAwesomeIcon icon={faMagnifyingGlassMinus}/> </span>
                </div>
                }
            </div>
        </div>
    );
}