import React from "react";
import "./Tile.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import BouncingDotsLoader from "../Loader/BouncingDotsLoader";
import {useNavigate} from "react-router-dom";
import Button from "../Button";

const Tile = (props) => {
  let navigate = useNavigate();
  return (
    <div className="cardNumber">
      <h3 className="card__title">{props.title}</h3>
      {props.loading && (
        <BouncingDotsLoader style={{ width: "30px", height: "30px" }} />
      )}
      {!props.loading && <p className="data">{props.data}</p>}
      {props.desc && <p className="desc">{props.desc}</p>}
      <Button action="round" label="Custom Search" onClick={props.onClick}>
        <FontAwesomeIcon icon={faArrowRight} className="button__icon" />
      </Button>
    </div>
  );
};

export default Tile;
