import axios from "../_helpers/axiosinstance";

const users = async (params) => {
    let data = new FormData();
    data.append("file", params.file || undefined);
    const response = await axios.post(
        `${process.env.REACT_APP_nhd_bulk_import_service_ingress}/nhd/api/bulk-upload/users`,
        data,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            requiresIdentityId: true,
        }
    );
    // const data = handleResponse(response);
    // return data;
    return response;
};

const usersTemplate = async (params) => {
    const response = await axios.get(
        `${process.env.REACT_APP_nhd_bulk_import_service_ingress}/nhd/api/bulk-upload/users-template`,
        {
            requiresIdentityId: true,
        },
    );
    // const data = handleResponse(response);
    // return data;
    return response;
};

const hazards = async (params) => {
    let data = new FormData();
    data.append("file", params.file || undefined);
    const response = await axios.post(
        `${process.env.REACT_APP_nhd_bulk_import_service_ingress}/nhd/api/bulk-upload/hazards`,
        data,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            requiresIdentityId: true,
        }
    );
    // const data = handleResponse(response);
    // return data;
    return response;
};

const hazardsTemplate = async () => {
    const response = await axios.get(
        `${process.env.REACT_APP_nhd_bulk_import_service_ingress}/nhd/api/bulk-upload/hazard-template`,
        {
            requiresIdentityId: true,
        }
    );
    // const data = handleResponse(response);
    // return data;
    return response;
};

const boundaryTemplate = async () => {
    const response = await axios.get(
        `${process.env.REACT_APP_nhd_bulk_import_service_ingress}/nhd/api/bulk-upload/elrRouteUpdateTemplate`,
        {
            requiresIdentityId: true,
        }
    );
    return response;
}

const uploadBoundaryData = async (params) => {
    let data = new FormData();
    data.append("file", params.file || undefined);
    const response = await axios.post(
        `${process.env.REACT_APP_nhd_bulk_import_service_ingress}/nhd/api/bulk-upload/elrRouteUpdate`,
        data,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            requiresIdentityId: true,
        }
    );
    return response;
};

const getSSMHazards = async (params) => {
    if (params?.elrs?.filterElrs) {
        let elrsData = {}
        elrsData.filterElrs = params.elrs.filterElrs;
        elrsData.elrs = Object.fromEntries(params.elrs.elrs);
        if (params?.hazardCodes?.hazardCodes) {
            await axios.post(
                `${process.env.REACT_APP_nhd_bulk_import_service_ingress}/nhd/api/bulk-upload/export/ssm-hazards`,
                {
                    hazardCodes: params?.hazardCodes,
                    elrs: elrsData
                },
                {
                    requiresIdentityId: true,
                }
            );
        } else {
            await axios.post(
                `${process.env.REACT_APP_nhd_bulk_import_service_ingress}/nhd/api/bulk-upload/export/ssm-hazards`,
                {
                    elrs: elrsData
                },
                {
                    requiresIdentityId: true,
                }
            );
        }
    } else {
        if (params?.hazardCodes?.hazardCodes) {
            await axios.post(
                `${process.env.REACT_APP_nhd_bulk_import_service_ingress}/nhd/api/bulk-upload/export/ssm-hazards`,
                {
                    hazardCodes: params?.hazardCodes
                },
                {
                    requiresIdentityId: true,
                }
            );
        } else {
            await axios.post(
                `${process.env.REACT_APP_nhd_bulk_import_service_ingress}/nhd/api/bulk-upload/export/ssm-hazards`,
                {},
                {
                    requiresIdentityId: true,
                }
            );
        }
    }

}

const BulkUploadService = {
    users,
    usersTemplate,
    hazards,
    hazardsTemplate,
    boundaryTemplate,
    uploadBoundaryData,
    getSSMHazards
};

export default BulkUploadService;
