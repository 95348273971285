import React, {useEffect, useRef, useState} from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import Button from "../components/Button.js";
import Checkbox from "../components/Checkbox.js";
import Input from "../components/Input.js";
import HazardSearchService from "../services/hazard-search.service";
import GeneralService from "../services/general.service";
import ReactTable from "../components/ReactTable/ReactTable.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faExclamationTriangle,} from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "../components/Select/ReactSelect.jsx";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import MyReportsService from "../services/myreports.service.js";
import BouncingDotsLoader from "../components/Loader/BouncingDotsLoader.jsx";
import {toast} from "react-toastify";
import downloadFile from "../_helpers/downloadFile";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus";

function CustomSearchPage(props) {
  const initialVals = {
    page: 0,
    pageSize: 10,
    mduList: props.mduList,
    route: "",
    elrCS: "",
    startMileage: "",
    endMileage: "",
    status: {
      loading: false,
      error: false,
    },
    selectedFields: {
      custom: false,
      fields: [],
    },
  };

  const [values, setValues] = useState(initialVals);
  const [initialValues, setInitialValues] = useState();
  const [filteredElr, setFilteredElr] = useState();
  const [selectedElrs, setSelectedElrs] = useState(new Map());
  const [filteredMdu, setFilteredMdu] = useState();
  const [openAdvanced, setOpenAdvanced] = useState(false);
  const [customSearchItems, setCustomSearchItems] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [routes, setRoutes] = useState();
  const [hazardCodes, setHazardCodes] = useState();
  const [sortOptions, setSortOptions] = useState({accessor: '', direction: ''});

  const routeSelectInput = useRef(null);
  const elrSelectInput = useRef(null);
  const duSelectInput = useRef(null);
  const startMileageSelectInput = useRef(null);
  const endMileageSelectInput = useRef(null);
  const hazardIdSelectInput = useRef(null);
  const [fieldsErrors, setFieldsErrors] = useState();
  const [inStorage, setInStorage] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [allowed, setAllowed] = useState();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [tableAction, setTableAction] = useState();
  let { id } = useParams();

  function checkStorage() {
    const values = JSON.parse(window.sessionStorage.getItem("custom-search-back"));
    if (values !== null) {
      setValues(values);
      setInitialValues(values);
      setInStorage(true);
      var checkboxes = document.getElementsByName("reportColumns");
      if (values.selectedFields.custom === true && checkboxes.length === values.selectedFields.fields.length) {
        setSelectAll(true);
      }
      if (values.mdu !== "" || values.hazardCode !== undefined || values.selectedFields.custom === true) {
        setOpenAdvanced(true);
      }
    }
  }

  useEffect(async () => {
    if (routes === undefined) {
      await GeneralService.getRoutes(true)
          .then((response) => {
            setRoutes(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    }
    if (filteredElr === undefined) {
      await GeneralService.getElrs()
        .then((response) => {
          setFilteredElr(response.data);
        }).catch((error) => {
          console.log(error);
        });
    }
    if (hazardCodes === undefined) {
      await GeneralService.getHazardCodesAll(true)
          .then((response) => {
            setHazardCodes(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }, [routes, hazardCodes, filteredElr]);

  useEffect(() => {
    switch (state?.tableAction) {
      case "Amended Hazard":
        setTableAction("Amended Hazard");
        checkStorage();
        break;
      case "Hazard Deletion":
        setTableAction("Hazard Deletion");
        checkStorage();
        break;
      case "Propose Amended Hazard":
        setTableAction("Propose Amended Hazard");
        checkStorage();
        break;
      case "Propose Hazard Deletion":
        setTableAction("Propose Hazard Deletion");
        checkStorage();
        break;

      default:
        break;
    }
  }, [state]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      MyReportsService.getCustomReportById(id)
        .then((response) => {
          setValues(response.data.search_data.fields);
          setInitialValues(response.data.search_data.fields);
          setLoading(false);
          if (response.data.search_data.fields.advancedOptions) {
            setOpenAdvanced(true);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    //fetch customSearch by id
  }, [id]);

  function handleInputChange(e) {
    let isJson;
    try {
      isJson = JSON.parse(e.target.value);
    } catch (e) {
      isJson = false;
    }

    if (e.target.name === "startMileage") {
      if (!e.target.checkValidity()) {
        e.target.reportValidity();
        setFieldsErrors((old) => ({ ...old, [e.target.name]: "Error" }));
      } else {
        setFieldsErrors((old) => ({ ...old, [e.target.name]: undefined }));
      }
    }
    if (e.target.name === "endMileage") {
      if (!e.target.checkValidity()) {
        e.target.reportValidity();
        setFieldsErrors((old) => ({ ...old, [e.target.name]: "Error" }));
      } else {
        setFieldsErrors((old) => ({ ...old, [e.target.name]: undefined }));
      }
    }

    isJson
      ? setValues((values) => ({
          ...values,

          [e.target.name]: JSON.parse(e.target.value),
        }))
      : setValues((values) => ({
          ...values,

          [e.target.name]: e.target.value,
        }));

    setCustomSearchItems((customSearchItems) => ({
      ...customSearchItems,
      status: {
        loading: false,
        error: false,
      },
    }));
  }

  const handleInputChangeG = (value, name, actionType) => {
    if (actionType?.action === "remove-value") {
      if (name !== "mduCs" && name !== "hazardCode") {
        var route = routes.filter((r) => {
          if (r.zonecode === actionType.removedValue.value.zonecode) {
            return true;
          }
        });
        var key = route[0].zonecode + ", " + route[0].zonedescription;

        if (selectedElrs.has(key)) {
          if (name === "elrCS") {
            let keyValue = selectedElrs.get(key);
            if (keyValue !== null) {
              keyValue = keyValue.filter((v) => {
                if (v._id !== actionType.removedValue.value._id) {
                  return true;
                }
              });
              setValues((values) => ({
                ...values,
                [name]: value ? value : "",
              }));
              selectedElrs.set(key, keyValue);
            }
          } else {
            let elrs = [];
            selectedElrs.delete(key);
            selectedElrs.forEach((element) => {
              for (let i = 0; i < element?.length; i++) {
                elrs.push(element[i]);
              }
            });
            setValues((values) => ({
              ...values,
              [name]: value, elrCS: elrs
            }));
          }
        } else {
          setValues((values) => ({
            ...values,
            [name]: value ? value : "",
          }));
        }
      } else {
        setValues((values) => ({
          ...values,
          [name]: value ? value : "",
        }));
      }
    }

    if (actionType?.action === "clear") {
      if (name !== "hazardCode") {
        if (name === "route") {
          selectedElrs.clear();
          setValues((values) => ({
            ...values,
            [name]: value, elrCS: []
          }));
          GeneralService.getElrs()
        .then((response) => {
          setFilteredElr(response.data);
        }).catch((error) => {
          console.log(error);
        });
        } if (name === "elrCS") {
          selectedElrs.clear();
          setValues((values) => ({
            ...values,
            [name]: value
          }));
        } if (name === "mduCs") {
          name = "mdu";
          setValues((values) => ({
            ...values,
            [name]: value
          }));
        }
      } else {
        setValues((values) => ({
          ...values,
          [name]: value ? value : "",
        }));
      }
    }

    if (actionType?.action !== "clear" && actionType?.action !== "remove-value") {
      if (name !== "mduCs") {
        setValues((values) => ({
          ...values,
          startMileage: "",
          endMileage: "",
          [name]: value ? value : "",
        }));
        if (name === "elrCS") {
          var route1 = routes.filter((r) => {
            if (r.zonecode === actionType.option.value.zonecode) {
              return true;
            }
          });
          var key1 = route1[0].zonecode+ ", " + route1[0].zonedescription;
          if (selectedElrs?.has(key1)) {
            let value = selectedElrs.get(key1);
            if (value !== null) {
              value.push(actionType.option.value);
              selectedElrs.set(key1, value);
            } else {
              selectedElrs.set(key1, [actionType.option.value]);
            }
          } else {
            selectedElrs.set(key1, [actionType.option.value])
          }

          let zonecodes = [];
          let elrs = [];

          selectedElrs.forEach((element) => {
              for (let i = 0; i < element?.length; i++) {
                elrs.push(element[i].elr);
                if (!zonecodes.includes(element[i].zonecode)) {
                  zonecodes.push(element[i].zonecode);
                }
              }
          });

          GeneralService
          .getMDUSubzonecode({
            zonecode: zonecodes,
            elr: elrs,
          })
          .then((response) => {
            setFilteredMdu(response.data);
          })
          .catch((error) => {
            setFilteredMdu();
          });
        }

      } else {
        if (name === "mduCs") {
          name = "mdu";
        }
        setValues((values) => ({
          ...values,
          [name]: value ? value : "",
        }));
      }

      setCustomSearchItems((customSearchItems) => ({
        ...customSearchItems,
        status: {
          loading: false,
          error: false,
        },
      }));
    }
  };

  function handleCheckboxChange(e, name) {
    console.log(name);

    if (name !== "selectAll") {
      let updatedList = [...values.selectedFields.fields];
      if (e.target.checked) {
        updatedList = [...values.selectedFields.fields, e.target.value];
      } else {
        updatedList.splice(
            values.selectedFields.fields.indexOf(e.target.value),
            1
        );
      }
      setValues((oldValues) => {
        return {
          ...oldValues,
          selectedFields: {
            custom: updatedList.length > 0,
            fields: updatedList,
          },
        };
      });
      var checkboxes = document.getElementsByName("reportColumns");
      if (updatedList.length === checkboxes.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    } if (name === "selectAll" && e.target.checked) {
      let selectedFields = [];
      let checkboxes = document.getElementsByName("reportColumns");
      checkboxes.forEach(checkbox => {
        selectedFields.push(checkbox.value);
      });
      setValues((oldValues) => {
        return {
          ...oldValues,
          selectedFields: {
            custom: selectedFields.length > 0,
            fields: selectedFields,
          },
        };
      });
      setSelectAll(true);
    } if (name === "selectAll" && !e.target.checked) {
      setValues((oldValues) => {
        return {
          ...oldValues,
          selectedFields: {
            custom: false,
            fields: [],
          },
        };
      });
      setSelectAll(false);
    }
  }

  //fetch ElRs based on route
  useEffect(() => {
    if (values.route && values.route !== "") {
      let zoneCode = [];
      for (let i = 0; i < values.route.length; i++) {
        zoneCode[i] = values.route[i].zonecode;
      }

      if (id || inStorage) {
        if (initialValues) {
          if (allowed) {
            setFilteredElr(["loading..."]);
            setValues((values) => ({
              ...values,
            }));
            if (zoneCode) {
              GeneralService.getElrsZonecode(zoneCode)
                  .then((response) => {
                    setFilteredElr(response.data);
                  })
                  .catch((error) => {
                    setFilteredElr({});
                  });
              GeneralService.getZonecodeMDUSubzonecode({
                zonecode: zoneCode,
              })
                  .then((response) => {
                    setFilteredMdu(response.data);
                  })
                  .catch((error) => {
                    setFilteredMdu();
                  });
            }
          } else {
            setAllowed(true);
          }
        }
      } else {
        setFilteredElr(["loading..."]);
        setValues((values) => ({
          ...values,
        }));
        GeneralService.getElrsZonecode(zoneCode)
          .then((response) => {
            setFilteredElr(response.data);
          })
          .catch((error) => {
            setFilteredElr({});
          });
        GeneralService.getZonecodeMDUSubzonecode({
          zonecode: zoneCode,
        })
            .then((response) => {
              setFilteredMdu(response.data);
            })
            .catch((error) => {
              setFilteredMdu();
            });
      }
    } else {
      if (id || inStorage) {
        if (initialValues) {
          if (allowed) {
            setValues((values) => ({
              ...values,
            }));
            setFilteredElr({});
          } else {
            setAllowed(true);
          }
        }
      } else {
        setValues((values) => ({
          ...values,
          mdu: "",
        }));
        setFilteredElr({});
        setFilteredMdu(initialVals.mduList);
      }
    }
  }, [values?.route, initialValues?.route, id, allowed, initialValues]);

  useEffect(() => {
    if (values.elrCS) {
      if (id || inStorage) {
        if (initialValues) {
          if (allowed) {
            setFilteredMdu(["loading..."]);
            setValues((values) => ({
              ...values,
            }));
          } else {
            setAllowed(true);
          }
        }
      } else {
        setFilteredMdu(["loading..."]);
        setValues((values) => ({
          ...values,
          mdu: "",
        }));
        GeneralService.getZonecodeMDUSubzonecode({
          zonecode: values?.route?.zonecode,
        })
            .then((response) => {
              setFilteredMdu(response.data);
            })
            .catch((error) => {
              setFilteredMdu();
            });
      }
    } else {
      if (id || inStorage) {
        if (initialValues) {
          if (allowed) {
            setValues((values) => ({
              ...values,
            }));
            setFilteredMdu();
          } else {
            setAllowed(true);
          }
        }
      } else {
        setValues((values) => ({
          ...values,
          mdu: "",
        }));
        if (values?.route?.zonecode) {
          GeneralService.getZonecodeMDUSubzonecode({
            zonecode: values?.route?.zonecode,
          })
              .then((response) => {
                setFilteredMdu(response.data);
              })
              .catch((error) => {
                setFilteredMdu();
              });
        }
      }
    }
  }, [values.elr, initialValues?.elr, id, allowed, initialValues]);

  const setPageSize = (pageSize) => {
    setValues((values) => ({
      ...values,
      pageNumber: 0,
      pageSize: pageSize,
    }));
    setCustomSearchItems((customSearchItems) => ({
      ...customSearchItems,
      pageNumber: 0,
      pageSize: pageSize,
    }));
  };

  const setPage = (page) => {
    setValues((values) => ({
      ...values,
      pageNumber: page,
    }));
    setCustomSearchItems((customSearchItems) => ({
      ...customSearchItems,
      pageNumber: page,
    }));
  };

  useEffect(() => {
    setError();
  }, [customSearchItems, values]);

  const validform = () => {
    let valid = false;

    if (values.route !== "" || values.elrCS !== "") {
      let validRoute = routeSelectInput.current?.validateField();
      let validElr = elrSelectInput.current?.validateField();
      let validStartMileage = startMileageSelectInput.current?.validateField();
      let validEndMileage = endMileageSelectInput.current?.validateField();
      if (validRoute && validElr && validStartMileage && validEndMileage) {
        valid = true;
      }
    }

    if (values.hazard_id != null) {
      let validHazardID = hazardIdSelectInput.current.validateField();
      if (validHazardID) {
        valid = true;
      }
    }

    return valid;
  };

  function handleSubmit(pageSize, pageNumber, sortOptions) {
    setError();
    let valid = validform();

    if (valid) {
      setCustomSearchItems({
        status: {
          loading: true,
          error: false,
        },
      });
      let totalElements = 0;
      if (pageNumber != 0) {
        totalElements = customSearchItems?.totalElements
      }


      HazardSearchService.customSearch(values, pageSize, pageNumber, false, false, sortOptions, totalElements)
        .then((response) => {
          setCustomSearchItems(response.data);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response?.status === 401) {
            setError("Error Unauthorized");
          } else {
            setError("An error occurred please contact a supervisor");
          }

          setCustomSearchItems({
            status: {
              loading: false,
              error: true,
            },
          });
        });
    } else {
      setLoading(false);

      setCustomSearchItems({
        status: {
          loading: false,
          error: "Please Provide either a Route, ELR or Hazard ID.",
        },
      });
    }
  }

  function handleSaveSearch() {
    HazardSearchService.customSearch(values, 10, 0, false, true)
      .then((response) => {
        toast.success(`Search saved in My Reports`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setValues((values) => ({
          ...values,

          "report_name": null,
        }));
      })
      .catch((error) => {
        toast.error(`Attempt to save Search Failed.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  function handleReset() {
    setValues({ ...initialVals });
    setCustomSearchItems();
    setSelectAll(false);
  }

  const handleExport = () => {
    HazardSearchService.customSearch(values, 10, 0, true, false)
      .then((response) => {
        downloadFile(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTableSort = (accessor) => {
    let direction = sortOptions?.direction;

    if (!direction) {
      direction = 'dsc';
    } else if (direction === 'dsc') {
      direction = 'asc';
    } else {
      direction = '';
    }

    setSortOptions({
      accessor : accessor,
      direction : direction
    });
  };

  useEffect(() => {
    if (customSearchItems?.results.length > 0) {
      setValues((values) => ({
        ...values,
        sortBy: sortOptions?.accessor,
        sortOrder: sortOptions?.direction,
      }));
      setCustomSearchItems((customSearchItems) => ({
        ...customSearchItems,
        sortBy: sortOptions?.accessor,
        sortOrder: sortOptions?.direction,
      }));
      handleSubmit(undefined, 0, sortOptions);
    }
  }, [sortOptions])

  return (
    <div className="page" id="customSearchPage">
      <Header title={state.pageTitle} />
      <BackButton
        navigateUrl={state?.previousPage ? state.previousPage : "/"}
      />

      {loading && <BouncingDotsLoader />}
      {!loading && (
        <div className="search-form">
          <div className="search-form__section">
            <h3>Custom Search</h3>
            <p>
              Search by either Route, ELR and or Hazard ID.
            </p>

            <ReactSelect
              name="route"
              label="Route"
              placeholder="Select Route"
              valueLabel={["zonedescription"]}
              data={routes}
              onChange={handleInputChangeG}
              value={values.route}
              ref={routeSelectInput}
              isMulti={true}
            />
            <ReactSelect
              ref={elrSelectInput}
              // required={!values.hazard_id}
              name="elrCS"
              label="ELR"
              placeholder="Select ELR"
              valueLabel={["elr", "description"]}
              data={filteredElr || props.elrs}
              loading={filteredElr && filteredElr[0] === "loading..."}
              onChange={handleInputChangeG}
              value={values.elrCS}
              isMulti={true}
            />

            <Input
              ref={startMileageSelectInput}
              name="startMileage"
              label="From Miles"
              type="number"
              min={values.elr?.startmiles}
              max={values.elr?.endmiles}
              step="0.0001"
              value={values.startMileage}
              onChange={handleInputChange}
              fieldsErrors={fieldsErrors}
            />
            <Input
              ref={endMileageSelectInput}
              name="endMileage"
              label="To Miles"
              step="0.0001"
              type="number"
              min={values.elr?.startmiles}
              max={values.elr?.endmiles}
              value={values.endMileage}
              onChange={handleInputChange}
              fieldsErrors={fieldsErrors}
            />
            <Input
                ref={hazardIdSelectInput}
                name="hazard_id"
                label="Hazard ID"
                value={values.hazard_id}
                onChange={handleInputChange}
                // required={!values.route && !values.elr}
            />
          </div>

          <div className="search-form__section">
            <h3>Advanced Custom Report (optional)</h3>
            <p>
              Further customise your report by clicking the Advanced Options Button.
            </p>
            <div className="button-group">
              <Button
                action={openAdvanced ? "tertiary" : "secondary"}
                label="Advanced Options"
                onClick={() => {setOpenAdvanced(!openAdvanced);}}
              />
            </div>
          </div>
          <div
            className={openAdvanced
                ? "search-form__section"
                : "search-form__section search-form__section--closed"
            }
          >
            <h3>Advanced Search</h3>

            <ReactSelect
              ref={duSelectInput}
              name="mduCs"
              label="MDU"
              placeholder="Select MDU"
              valueLabel={["subzonecode", "description"]}
              data={filteredMdu || initialVals.mduList}
              loading={filteredMdu && filteredMdu[0] === "loading..."}
              onChange={handleInputChangeG}
              value={values.mdu}
            />
            <ReactSelect
              name="hazardCode"
              label="Hazard Code"
              placeholder="Select Hazard Code"
              isMulti={true}
              valueLabel={["hazardCode", "description"]}
              data={hazardCodes}
              onChange={handleInputChangeG}
              value={values.hazardCode}
              defaultValue={values.hazardCode}
            />
            <Input
              name="localName"
              label="Local Name"
              value={values.localName}
              onChange={handleInputChange}
            />
          </div>

          <div
            className={openAdvanced
                ? "search-form__section"
                : "search-form__section search-form__section--closed"
            }
          >
            <h3>Custom Report Columns</h3>
            <p>
              Select the information you wish to display in your report.
            </p>
            <div className={"search-form__section__checkbox"}>
              <Checkbox
                name="selectAll"
                value="selectAll"
                label="Select All"
                onChange={handleCheckboxChange}
                checked={selectAll}
              />
            </div>

            <div className={"search-form__section__checkbox"}>
              <Checkbox
                onChange={handleCheckboxChange}
                name="reportColumns"
                value="elr"
                label="ELR"
                checked={values.selectedFields?.fields?.includes("elr")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="elrName"
                  label="ELR Name"
                  checked={values.selectedFields?.fields?.includes("elrName")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="startMileage"
                  label="Start Mileage"
                  checked={values.selectedFields?.fields?.includes("startMileage")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="endMileage"
                  label="End Mileage"
                  checked={values.selectedFields?.fields?.includes("endMileage")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="hazardCode"
                  label="Hazard Code"
                  checked={values.selectedFields?.fields?.includes("hazardCode")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="hazardDescription"
                  label="Hazard Description"
                  checked={values.selectedFields?.fields?.includes(
                      "hazardDescription"
                  )}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="localName"
                  label="Local Name"
                  checked={values.selectedFields?.fields?.includes("localName")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="mdu"
                  label="MDU"
                  checked={values.selectedFields?.fields?.includes("mdu")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="trackID"
                  label="track ID"
                  checked={values.selectedFields?.fields?.includes("trackID")}
              />
              <Checkbox
                onChange={handleCheckboxChange}
                name="reportColumns"
                value="statusCode"
                label="Operational Status Code"
                checked={values.selectedFields?.fields?.includes("statusCode")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="freeText"
                  label="Free Text"
                  checked={values.selectedFields?.fields?.includes("freeText")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="easting"
                  label="Easting"
                  checked={values.selectedFields?.fields?.includes("easting")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="northing"
                  label="Northing"
                  checked={values.selectedFields?.fields?.includes("northing")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="maintainer"
                  label="Maintainer"
                  checked={values.selectedFields?.fields?.includes("maintainer")}
              />
              <Checkbox
                  onChange={handleCheckboxChange}
                  name="reportColumns"
                  value="lastUpdated"
                  label="Last Updated"
                  checked={values.selectedFields?.fields?.includes("lastUpdated")}
              />
            </div>
          </div>
          <div className="button-group search-form__submit">
            <Button action="secondary" label="Cancel" onClick={handleReset} />
            <Button
              action="primary"
              label="Run Report"
              onClick={(e) => handleSubmit(undefined, 0)}
            />
          </div>
          {customSearchItems?.status?.error && !loading && (
            <div className="form-error">
              <span className="input__error">
                {customSearchItems.status.error}
              </span>
            </div>
          )}
        </div>
      )}

      <div>
        {customSearchItems?.status?.loading === true && (
          <div className="loadingCircle">
            <span style={{marginLeft: "10px"}}><FontAwesomeIcon icon={faCircleNotch} spin={true}/></span>
          </div>
        )}
        {customSearchItems?.status?.error === true && (
          <div className="loadingCircle">
            Error fetching data
            <span style={{marginLeft: "10px"}}><FontAwesomeIcon icon={faExclamationTriangle}/></span>
          </div>
        )}

        {customSearchItems?.results && customSearchItems?.results.length > 0 ? (
          <>
            <div className="button-group report-form__submit">
              <Input
                name="report_name"
                label="Report Name"
                value={values.report_name}
                onChange={handleInputChange}
              />
              <Button
                action="secondary"
                label="Save Search"
                onClick={(e) => handleSaveSearch()}
              />
            </div>

            <div className="button-group">
              <Button action="secondary" label="Export CSV" onClick={handleExport} />
            </div>

            <ReactTable
              customSearch={true}
              tableData={customSearchItems?.results}
              // hideColumns={["_id"]}
              showColumns={customSearchItems?.headers}
              customHeader={true}
              currentPage={customSearchItems?.pageNumber}
              totalPages={customSearchItems?.totalPages}
              pageSize={customSearchItems?.pageSize}
              totalElements={customSearchItems?.totalElements}
              setPage={(val) => {
                setPage(val);
                handleSubmit(undefined, val);
              }}
              setPageSize={(val) => {
                setPageSize(val);
                handleSubmit(val, 0);
              }}
              sortOptions={sortOptions}
              setSortOptions={setSortOptions}
              handleTableSort={handleTableSort}
              editAction={
                tableAction
                  ? (e, row) => {
                      if (tableAction === "Amended Hazard") {
                        window.sessionStorage.setItem("custom-search-back", JSON.stringify(values))
                        navigate(`/amendhazard/${row.row.original.hazardID}`, {
                          state: { hazardLive: true, actionType: "amend" },
                        });
                      }
                      if (tableAction === "Hazard Deletion") {
                        window.sessionStorage.setItem("custom-search-back", JSON.stringify(values))
                        navigate(`/deletehazard/${row.row.original.hazardID}`, {
                          state: { hazardLive: true, actionType: "delete" },
                        });
                      }
                      if (tableAction === "Propose Hazard Deletion") {
                        window.sessionStorage.setItem("custom-search-back", JSON.stringify(values))
                        navigate(`/deletehazard/${row.row.original.hazardID}`, {
                          state: {
                            hazardLive: true,
                            actionType: "propose delete",
                            pageTitle: "Propose Deletion"
                          },
                        });
                      }
                      if (tableAction === "Propose Amended Hazard") {
                        window.sessionStorage.setItem("custom-search-back", JSON.stringify(values))
                        navigate(`/amendhazard/${row.row.original.hazardID}`, {
                          state: {
                            hazardLive: true,
                            actionType: "propose amend",
                            pageTitle: "Propose Amendment"
                          },
                        });
                      }
                    }
                  : null
              }
            ></ReactTable>
          </>
        ) : <div className="loadingCircle">
          No Results Based On Your Search Criteria
          <span style={{marginLeft: "10px"}}> <FontAwesomeIcon icon={faMagnifyingGlassMinus}/> </span>
        </div>
        }
      </div>
    </div>
  );
}

export default CustomSearchPage;
