import React, {useEffect, useRef, useState} from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import StandardReportsForm from "../components/StandardReportsForm.js";
import StandardReportsService from "../services/standardreports.sevice.js";
import ReactTable from "../components/ReactTable/ReactTable.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faExclamationTriangle,} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus";

function SectionMduPage(props) {
  let reportTitle = "Section by MDU";
  let reportInfo = "Filter the Hazard list by MDU.";
  let fields = [{name: "mdu", required: true}];

  const [values, setValues] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [customSearchItems, setCustomSearchItems] = useState();
  const [reset, setReset] = useState(false);
  const mduSelectInput = useRef(null);

  useEffect(() => {

  }, [values]);

  const handlePageSize = (val) => {
    setPage(0);
    setPageSize(val);
  };

  function handleSubmit(pageVal, pageSizeVal) {
    setReset(false);
    let validMdu = mduSelectInput.current.validateField();
    // let validType = elrSelectInput.current.validateField();
    if (validMdu) {
      setCustomSearchItems({
        status: {
          loading: true,
          error: false,
        },
      });
      let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
      let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
      StandardReportsService.searchMDU(pageNumber, pageSizeNumber, values)
        .then((response) => {
          setCustomSearchItems(response.data);
        })
        .catch((error) => {
          setCustomSearchItems({
            status: {
              loading: false,
              error: true,
            },
          });
        });
    } else {
      setCustomSearchItems({
        status: {
          loading: false,
          error: "Please check the form is complete and required values are selected.",
        },
      });
    }
  }

  const handleReset = () => {
    setReset(true);
    setCustomSearchItems({
      status: {
        loading: false,
        error: false,
      },
    });
  }

  const handleExport = () => {
    StandardReportsService.searchMDUExport(page, pageSize, values)
      .then((response) => {
        toast.success(`Your Export has been sent and you will receive an email shortly with the attached report.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {});
  };

  return (
    <div className="page" id="sectionMduPage">
      <Header title="Section by MDU" />
      <BackButton
        setPage={props.setPage}
        target={["standardReportsPage", "backward"]}
      />

      <StandardReportsForm
        data={props}
        reportTitle={reportTitle}
        reportInfo={reportInfo}
        fields={fields}
        handleSubmit={() => handleSubmit(0, undefined)}
        handleReset={() => handleReset()}
        values={values}
        setValues={setValues}
        mduSelectInput={mduSelectInput}
      />
      <div>
        {customSearchItems?.status?.loading === true && (
          <div className="loadingCircle">
            <FontAwesomeIcon icon={faCircleNotch} spin={true} />
          </div>
        )}
        {customSearchItems?.status?.error === true && (
          <div className="loadingCircle">
            Error fetching data
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
        )}
        {customSearchItems?.results && customSearchItems?.results?.length > 0 && (
          <ReactTable
            hideColumns={["id"]}
            tableData={customSearchItems.results}
            currentPage={customSearchItems?.pageNumber}
            totalPages={customSearchItems?.totalPages}
            pageSize={customSearchItems?.pageSize}
            totalElements={customSearchItems?.totalElements}
            setPage={(val) => {
              setPage(val);
              handleSubmit(val, undefined);
            }}
            setPageSize={(val) => {
              handlePageSize(val);
              handleSubmit(0, val);
            }}
            exportCsv={{ name: "sectionMDU", action: handleExport }}
          ></ReactTable>
        )}
        {customSearchItems?.results && customSearchItems?.results.length === 0 && !reset && (
        <div className="loadingCircle">
          No Results Based On Your Search Criteria
          <span style={{marginLeft: "10px"}}> <FontAwesomeIcon icon={faMagnifyingGlassMinus}/> </span>
        </div>
        )}
      </div>
    </div>
  );
}

export default SectionMduPage;
