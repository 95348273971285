import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import BackButton from "../../components/BackButton";
import Button from "../../components/Button";
import Header from "../../components/Header";
import generateFields from "../../_helpers/generateDetailFields";
import {UserService as userService} from "../../services/user.service";
import BouncingDotsLoader from "../../components/Loader/BouncingDotsLoader";
import Input from "../../components/Input";
import ReactSelect from "../../components/Select/ReactSelect";
import {OrganisationService} from "../../services/organisation.service";

const OnboardingRequestDetails = () => {
  const { state } = useLocation();
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const organisationInputRef = useRef(null);
  const [organisations, setOrganisations] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    if (state.data) {
      setDetails(state.data);
    }
  }, [state]);

  useEffect(() => {
    OrganisationService.getOrganisations(0, 500).then((response) => {
      setOrganisations(response.data.results)
    }).catch((error) =>{
      console.log(error);
    });
  }, []);

  const handleAccept = () => {
    setLoading(true);
    userService
      .onboardingAccept(details)
      .then((response) => {
        setLoading(false);
        navigate("/usermanagement", {
          replace: true,
          state: {
            onboardingRequest: {
              message: `Onboarding request accepted for ${details.email}`,
              status: "success",
            },
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.apierror.message);
      });
  };

  const handleReject = () => {
    userService
      .onboardingReject(details.id)
      .then((response) => {
        setLoading(false);
        navigate("/usermanagement", {
          replace: true,
          state: {
            onboardingRequest: {
              message: `Onboarding request rejected for ${details.email}`,
              status: "success",
            },
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        navigate("/usermanagement", {
          replace: true,
          state: {
            onboardingRequest: {
              message: `Error rejecting onboarding request for ${details.email}`,
              status: "error",
            },
          },
        });
      });
  };

  const handleInputChangeG = (e, name) => {
    setDetails((values) => ({
      ...values,
      [name]: e.organisation_name ? e.organisation_name : "",
    }));
  };

  return (
    <div className="page" id={`${state?.title?.toLowerCase || ""} `}>
      <Header title={`Onboarding Request Details`} />
      <BackButton />
      {details && (
        <div className="filter-form">
          <div className="filter-form__controller">
            <h2 className="filter-form__title inline-block">Details</h2>
          </div>
          <div>
            <div className="filter-form__group fields-wrapper">
              <Input
                  key={`firstName`}
                  name={`firstName`}
                  label={`FirstName`}
                  value={details.firstName}
                  readOnly={true}
              />
              <Input
                  key={`surname`}
                  name={`surname`}
                  label={`Surname`}
                  value={details.surname}
                  readOnly={true}
              />
              <Input
                  key={`email`}
                  name={`email`}
                  label={`Email`}
                  value={details.email}
                  readOnly={true}
              />
              <ReactSelect
                  name="organisation"
                  label="Organisation"
                  placeholder="Select Organisation"
                  valueLabel={["organisation_name"]}
                  data={organisations}
                  // loading={organisations && organisations[0] === "loading..."}
                  value={details.organisation}
                  isDisabled={!error}
                  onChange={handleInputChangeG}
                  // required={true}
              />
              {/*<Input*/}
              {/*    ref={organisationInputRef}*/}
              {/*    key={`organisation`}*/}
              {/*    name={`organisation`}*/}
              {/*    label={`Organisation`}*/}
              {/*    value={details.organisation}*/}
              {/*    // readOnly={true}*/}
              {/*    disabled={true}*/}
              {/*/>*/}
              <Input
                  key={`telephoneNumber`}
                  name={`telephoneNumber`}
                  label={`Telephone Number`}
                  value={details.telephoneNumber}
                  readOnly={true}
              />
            </div>
            <div className="button-group filter-form__submit">
              {!loading && (
                <>
                  <Button
                    action="secondary"
                    label="Accept"
                    onClick={handleAccept}
                  />
                  <Button
                    action="primary"
                    label="Reject"
                    onClick={handleReject}
                  />
                </>
              )}
              {loading && (
                <>
                  <Button
                    action="primary"
                    label="Reject"
                    onClick={() => {}}
                    className={"hiden"}
                  />{" "}
                  <BouncingDotsLoader></BouncingDotsLoader>
                </>
              )}
              {error && (
                <div className="form-error">
                    <span className="input__error">{error}</span>
                </div>
               )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnboardingRequestDetails;
