import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";

function PrivacyPage(props) {
  return (
    <div className="page" id="privacyPage">
      <Header title="Privacy" />
      <BackButton navigateUrl="/" />

      <div className="privacy__wrap">
        <div className="privacy">
          <h2 className="privacy__h2">Privacy</h2>

          <p className="privacy__p">
            Ontrac Limited ("We") are committed to protecting and respecting
            your privacy.
          </p>

          <p className="privacy__p">
            This policy sets out the basis on which any personal data we collect
            from you, or that you provide to us, will be processed by us. It
            also sets out your responsibilities for your processing of personal
            data when using our Service (as defined below). Please read the
            following carefully to understand our views and practices regarding
            your personal data, how we will treat it and what your data
            protection obligations are in respect of your use of the Service.
          </p>

          <h3 className="privacy__h3">Information We collect from you</h3>

          <p className="privacy__p">
            If you decide to register with and use www.nationalhazards.co.uk,
            you will be asked to provide certain information about yourself
            including your name and contact details. By submitting your personal
            information, you consent to the collection, use and transfer of your
            information in accordance with the terms of this privacy policy.
          </p>

          <h3 className="privacy__h3">Use of your personal information</h3>

          <p className="privacy__p">
            We will only use your personal information for the following
            purposes:
          </p>

          <ul className="privacy__ul">
            <li className="privacy__li">
              to enable us to provide you with access to the Service, to use the
              Service, to enable you to download information and materials from
              the Service;
            </li>
            <li className="privacy__li">
              to make you aware of other products or services offered by us from
              time to time;
            </li>
            <li className="privacy__li">
              to contact you for your views on the Service and to notify you
              occasionally about important changes or developments to the
              Service; and
            </li>
            <li className="privacy__li">
              to administer, support, improve and develop the Service.
            </li>
          </ul>

          <p className="privacy__p">
            If you change your mind about being contacted in the future, please
            let us know and we will remove you from our mailing list.
          </p>

          <p className="privacy__p">
            We reserve the right to use the name and/or logo of the company you
            work for in publicity material, advertising or marketing collateral,
            unless you specifically tell us otherwise. Your name, address
            details and all other personal information will remain confidential
            at all times.
          </p>

          <h3 className="privacy__h3">
            You can opt-out of any email communications
          </h3>

          <p className="privacy__p">
            We send product information, service updates and regular customer
            newsletters to registered users via email.
          </p>

          <p className="privacy__p">
            Where appropriate email communication will contain clear and obvious
            instructions describing how the user can opt to be removed from the
            mailing list. We will promptly remove any user upon request.
          </p>

          <h3 className="privacy__h3">Disclosure of your information</h3>

          <p className="privacy__p">
            We follow strict guidelines in the storage and disclosure of
            information which you have given us, to prevent unauthorised access.
            We comply with the laws of the countries from which we operate.
          </p>

          <p className="privacy__p">
            We do not disclose your personal data to any third party (in the
            UK), except to the extent that we may disclose your personal data
            for the purposes of providing Services from us. They supply the
            hardware infrastructure, storage and associated services necessary
            for us to provide the Service.
          </p>

          <p className="privacy__p">
            We may also disclose your personal information to third parties:
          </p>

          <ul className="privacy__ul">
            <li className="privacy__li">
              In the event that we sell or buy any business or assets, in which
              case we may disclose your personal data to the prospective seller
              or buyer of such business or assets.
            </li>
            <li className="privacy__li">
              If Ontrac Limited or substantially all of its assets are acquired
              by a third party, in which case personal data held by it about its
              customers will be one of the transferred assets.
            </li>
            <li className="privacy__li">
              If we are under a duty to disclose or share your personal data in
              order to comply with any legal obligation, or in order to enforce
              or apply the terms of use and other agreements; or to protect the
              rights, property, or safety of Ontrac Limited, our customers, or
              others. This includes exchanging information with other companies
              and organisations for the purposes of fraud protection and credit
              risk reduction.
            </li>
          </ul>

          <p className="privacy__p">
            By using the Service, you consent to our third party service
            partners having access to your personal data. We always ensure that
            your data is only transferred in full accordance with UK data
            protection laws. By submitting your personal data, you agree to this
            transfer, storing or processing. We will take all steps reasonably
            necessary to ensure that your data is treated securely and in
            accordance with this privacy policy. You agree to keep confidential
            and not to disclose to any other individual or individuals for all
            time the user name and password related to your account.
          </p>
        </div>
        <div className="privacy">
          <h3 className="privacy__h3">About Cookies</h3>

          <p className="privacy__p">
            A cookie is a text-only string of information that is stored by your
            browser when you navigate around a website. Cookies are required to
            use the service and are used in the following ways:
          </p>

          <ul className="privacy__ul">
            <li className="privacy__li">
              To keep a "session" open after a user logs in.
            </li>
            <li className="privacy__li">
              To compile anonymous, aggregated statistics that allow us to
              understand how users use our site and to help us improve the
              structure of our website. We cannot identify you personally in
              this way.
            </li>
            <li className="privacy__li">
              When a user has enabled "Remember Me on This Computer" a cookie
              allows the user to access their account without logging in each
              time. This cookie is removed when the user explicitly logs out.
            </li>
          </ul>

          <p className="privacy__p">
            These cookies contain no personal information, but clearly users
            shouldn't enable "Remember Me" when using a publicly-accessible
            computing device or terminal.
          </p>

          <h3 className="privacy__h3">You own your data</h3>

          <p className="privacy__p">
            The Service will only store information that is entered by the users
            of your organisation, or automatically imported at a user's
            instruction. The data entered, or imported on instruction, by the
            users of an organisation remains the property of you and we will not
            use nor make available for use any of this information without
            permission by you.
          </p>

          <h3 className="privacy__h3">
            You control who has access to your data
          </h3>

          <p className="privacy__p">
            It is the user's responsibility to keep their passwords safe. It is
            the Administrator's responsibility to ensure that any users that are
            invited to use the Service have permission to view the information
            stored in the Service. Neither our staff nor our third party service
            partners have access to any user passwords and are therefore unable
            to access the users account.
          </p>

          <p className="privacy__p">
            You represent, warrant and undertake that when using the Service,
            you will at all times comply with all applicable local and national
            laws; including but not limited to, those relating to privacy and
            the processing of personal data and other information. You agree to
            promptly comply with any request from us requiring you to amend,
            transfer and/or delete any information recorded by you on the
            Service. You also agree to provide us with full co-operation and
            assistance in relation to any complaint, notice or communication
            which relates directly or indirectly to the processing of personal
            data or other information or to your compliance with any applicable
            local and national laws.
          </p>

          <p className="privacy__p">
            You agree to indemnify us and keep us indemnified and defend us at
            your own expense against all costs, claims, damages or expenses
            incurred by us or for which the we may become liable due to any
            failure by you (or your employees or agents (where applicable)) to
            comply with your obligations under this Privacy Policy.
          </p>

          <h3 className="privacy__h3">
            You are responsible for checking the privacy policy of any
            third-party websites we link to
          </h3>

          <p className="privacy__p">
            The Service may contain links to third-party websites. We take no
            responsibility for the privacy practices or content of these
            websites.
          </p>

          <h3 className="privacy__h3">
            This policy may be updated from time to time
          </h3>

          <p className="privacy__p">
            We reserve the right to change this policy at any time and any
            amended policy will be posted on our Website.
          </p>

          <p className="privacy__p">
            For the purpose of the Data Protection Act 1998, the data controller
            is Ontrac Limited, a limited company registered in England with
            number 06662069 whose registered office is at Leeds Innovation
            Centre, 103 Clarendon Road, Leeds, West Yorkshire, England, LS2 9DF.
          </p>

          <p className="privacy__p">
            Questions, comments and requests regarding this privacy policy are
            welcomed and should be forwarded by email to enquiries@on-trac.co.uk
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPage;
