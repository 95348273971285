import React from 'react';
import Button from './Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit, faExternalLinkAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons'

function ResTable(props) {

  let headers = props.headers;
  let tableData =props.tableData;

  if (Array.isArray(headers)) {
    headers = headers.map( (opt, i) => <th className="res-table__th" key={i} >{opt}</th> );
  } else {
    headers =  Object.entries(headers).map( (optval, k) =>  <th className="res-table__th" key={k} value={optval[0]}>{optval[1]}</th> );  
  }

  function addButtons(value, k) {
    switch (value[0]) {
      case "canEdit" :
        if(value[1]) {
          return (<td className="res-table__td--input" key={k}>
            <Button action="tertiary" onClick={props.editAction}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </td>);
        } else {
          return (<td className="res-table__td--input" key={k}>
            <Button action="tertiary button--disabled"><FontAwesomeIcon icon={faEdit} /></Button>
          </td>);
        }
      case "canDelete":
        if(value[1]) {
          return (<td className="res-table__td--input" key={k}>
              <Button action="tertiary" onClick={props.deleteAction}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </td>);
        } else {
          return (<td className="res-table__td--input" key={k}>
            <Button action="tertiary button--disabled"><FontAwesomeIcon icon={faTrashAlt} /></Button>
          </td>);
        } 
      case "canOpen":
        return (<td className="res-table__td--input" key={k}>
            <Button action="tertiary"><FontAwesomeIcon icon={faExternalLinkAlt} /></Button>
          </td>);
      default:
        return <td className="res-table__td" key={k}>{value[1]}</td>;
    }
  }

  if (Array.isArray(tableData)) {
    tableData = tableData.map( (row, i) => <tr key={i} className="res-table__tr">{Object.entries(row).map( addButtons )}</tr> );
  }

  let classProps = ['res-table'];
  if (props.disabled) {classProps.push('res-table--disabled')}
  if (props.className) {classProps.push(props.className)}

  return (
    <div className="res-table__wrap">
      <table className={classProps.join(' ')}>
        <thead className="res-table__thead">
          <tr className="res-table__tr">
            {headers}
          </tr>
        </thead>
        <tbody className="res-table__tbody">
          {tableData}
        </tbody>
      </table>
    </div>
  );
};

export default ResTable;