import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import CreateHazardForm from "../components/CreateHazardForm.js";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {useLocation} from "react-router-dom";

function NewHazardPage(props) {
  const { user } = useAuthenticator((context) => [context.route]);
  const { state } = useLocation();
  return (
    <div className="page" id="newHazardPage">
      <Header title="New Hazard" />
      <BackButton
        navigateUrl={state?.previousPage ? state.previousPage : "/"}
      />

      <CreateHazardForm data={props} actionType="new" user={user} />
    </div>
  );
}

export default NewHazardPage;
