import Header from "../../components/Header";
import BackButton from "../../components/BackButton";
import Button from "../../components/Button";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import V3Service from "../../services/v3.service";
import ReactTablePagingFE from "../../components/ReactTable/ReactTablePagingFE";
import {toast} from "react-toastify";

function V3ExportPage(props) {

    const [exports, setExports] = useState();
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();

    function getFies() {
        setLoading(true);
        V3Service.getFiles()
            .then((response) => {
                setExports(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        getFies();
    }, []);

    const requestExport = () => {
        V3Service.requestExport().then((response) => {
            toast.success(`Successfully Requested an Export for V3. Please wait for the Email Before downloading`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch((error) => {
            toast.error(`Request for export Failed`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    const refresh = () => {
      getFies();
    }

    function downloadExport(row) {
        V3Service.getExport(row).then((response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            const contentDisposition = response.headers["content-disposition"];
            const filename = contentDisposition.substring(
                contentDisposition.indexOf("=") + 1
            );
            link.href = url;
            link.setAttribute("download", filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <div className="page" id="v3exportPage">
            <Header title="V3 Export Page" />
            <BackButton
                setPage={props.setPage}
                target={["adminDashPage", "backward"]}
            />

            <h2>V3 Exports</h2>
            {!loading && (
                <>
                <ReactTablePagingFE
                    tableData={exports?.results}
                    showColumns={[
                        "fileName",
                        "date",
                    ]}
                    canDownload={true}
                    handleDownload={(e, row) => {
                        console.log(row);
                        downloadExport(row.row.original);
                    }}
                />
                </>)
            }

            <div className="button-group">
                <Button
                    action="primary"
                    label="Request Export"
                    onClick={requestExport}
                />
                <Button
                    action="secondary"
                    label="Refresh"
                    onClick={refresh}
                />
            </div>
        </div>
    );
}


export default V3ExportPage;