import React, {useEffect, useState} from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import Button from "../../components/Button.js";
import ReactTable from "../../components/ReactTable/ReactTable.js";
import GeneralService from "../../services/general.service.js";
import {useNavigate} from "react-router-dom";

function RegionManagementPage(props) {
  const [regions, setRegions] = useState();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  let navigate = useNavigate();
  const [sortOptions, setSortOptions] = useState({accessor: '', direction: ''});

  useEffect(() => {
    GeneralService.getRoutesPaged({ page: 0, pageSize: 10 })
      .then((response) => {
        setRegions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

    useEffect(() => {
        if (regions?.results.length > 0) {
            handleSubmit(undefined, undefined, sortOptions);
        }
    }, [sortOptions])

  const handlePageSize = (val) => {
    setPage(0);
    setPageSize(val);
  };

  function handleSubmit(pageVal, pageSizeVal) {
    let params = {};
    let pageNumber = pageVal === 0 ? 0 : pageVal || page || 0;
    let pageSizeNumber = pageSizeVal === 0 ? 0 : pageSizeVal || pageSize || 0;
    params.page = pageNumber;
    params.pageSize = pageSizeNumber;
    GeneralService.getRoutesPaged(params, sortOptions)
      .then((response) => {
        setRegions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleEdit = (e, row) => {
      navigate("/editZone", {
          state: { data: row.row?.original, edit: true },
      });
  };

    const handleTableSort = (accessor) => {
        let direction = sortOptions?.direction;

        if (!direction) {
            direction = 'dsc';
        } else if (direction === 'dsc') {
            direction = 'asc';
        } else {
            direction = '';
        }

        setSortOptions({
            accessor : accessor,
            direction : direction
        });
    };

  return (
    <div className="page" id="regionManagementPage">
      <Header title="Region Management" />
      <BackButton
        setPage={props.setPage}
        target={["adminDashPage", "backward"]}
      />

      <h2>Region/Zones</h2>
      {regions !== null &&regions?.results?.length > 0 &&
      <ReactTable
        tableData={regions?.results}
        currentPage={regions?.pageNumber}
        totalPages={regions?.totalPages}
        pageSize={regions?.pageSize}
        totalElements={regions?.totalElements}
        showColumns={regions?.headers}
        customHeader={true}
        customSearch={true}
        setPage={(val) => {
          setPage(val);
          handleSubmit(val, undefined);
        }}
        setPageSize={(val) => {
          handlePageSize(val);
          handleSubmit(0, val);
        }}
        editAction={(e, row) => {
          handleEdit(e, row);
        }}
        sortOptions={sortOptions}
        setSortOptions={setSortOptions}
        handleTableSort={handleTableSort}
      >
    </ReactTable>}

      <div className="button-group">
        <Button
          action="primary"
          label="Add Region"
          onClick={() => navigate("/addZone")}
        />
      </div>
    </div>
  );
}

export default RegionManagementPage;
