import React from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import Button from "../components/Button.js";

function LogoutPage(props) {
  return (
    <div className="page" id="logoutPage">
      <Header title="Logout" />
      <BackButton navigateUrl="/" />

      <div className="logout__wrap">
        <div className="logout">
          <p className="logout__p">
            <Button action="primary" label="Logout" onClick={props.signOut} />
          </p>
          <p className="logout__p">Log out of the National Hazard Directory.</p>
        </div>
      </div>
    </div>
  );
}

export default LogoutPage;
