import React from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import CreateUserForm from "../../components/CreateUserForm.js";

function AdminAddUser(props) {
  return (
    <div className="page" id="adminAddUser">
      <Header title="Add New User" />
      <BackButton
        setPage={props.setPage}
        target={["userManagementPage", "backward"]}
      />

      <CreateUserForm data={props} />
    </div>
  );
}

export default AdminAddUser;
