import React, {useEffect, useState} from "react";
import Button from "./Button.js";
import Input from "./Input.js";
import BouncingDotsLoader from "./Loader/BouncingDotsLoader.jsx";
import ReactSelect from "./Select/ReactSelect.jsx";
import generalService from "../services/general.service";
import GeneralService from "../services/general.service";

function StandardReportsForm(props) {
  const {
    values,
    setValues,
    handleInputChange,
    // filteredElr,
    filteredMdu,
    routeSelectInput,
    elrSelectInput,
    typeSelectInput,
    mduSelectInput,
    startMileageSelectInput,
    endMileageSelectInput,
    setFieldsErrors,
    fieldsErrors,
    loading,
    error
  } = props;
  let fields = props.fields
    ? props.fields
    : ["route", "elr", "type", "mileage"];

  const initialVals = {
    route: "",
    elr: "",
    mdu: "",
    accessType: "Any",
    startMileage: "",
    endMileage: "",
  };

  // const [values, setValues] = useState(initialVals);
  const [filteredElr, setFilteredElr] = useState();
  // const [filteredMdu, setFilteredMdu] = useState();
  const [routes, setRoutes] = useState();

  useEffect(() => {
    setValues(initialVals);
  }, []);

  useEffect(() => {
    if (routes === undefined) {
      GeneralService.getRoutes(true)
          .then((response) => {
            setRoutes(response.data);
          })
          .catch((error) => {
            // setRoutes("error");
            console.log(error);
          });
    }
  }, [routes]);
  function handleSubmit() {
    props.handleSubmit(values);
  }

  function handleReset() {
    setValues({ ...initialVals });
    props.handleReset();
  }
  const handleInputChangeG = (e, name) => {
    if (name === "route") {
      if (e !== undefined) {
        setFilteredElr(["loading..."]);
        setValues((values) => ({
          ...values,
          [name]: e ? e : "",
        }));
        generalService
          .getElrsZonecode(e.zonecode)
          .then((response) => {
            setFilteredElr(response.data);
          })
          .catch((error) => {
            setFilteredElr();
          });
      } else {
        setValues((values) => ({
          ...values,
          elr: "",
          mdu: "",
        }));
        setFilteredElr();
      }
    }

    if (name == "mduSf") {
      name = "mdu";
    }

    setValues((values) => ({
      ...values,
      startMileage: "",
      endMileage: "",
      [name]: e ? e : "",
    }));
  };

  const generateFields = () => {
    let generatedFields = [];
    fields.map((field) => {
      if (
        typeof field === "object" &&
        !Array.isArray(field) &&
        field !== null
      ) {
        if (field.name !== "mileage")
          generatedFields.push(getField(field.name, field.required));
      } else if (field !== "mileage") {
        generatedFields.push(getField(field, false));
      }
    });
    return generatedFields;
  };

  const generateMileage = () => {
    let generatedMileage = [];
    fields.map((field) => {
      if (
        typeof field === "object" &&
        !Array.isArray(field) &&
        field !== null
      ) {
        if (field.name === "mileage")
          generatedMileage.push(getField(field.name, field.required));
      } else if (field === "mileage") {
        generatedMileage.push(getField(field, false));
      }
    });
    return generatedMileage;
  };

  const getField = (name, required) => {
    switch (name) {
      case "route":
        return (
          <ReactSelect
            ref={routeSelectInput}
            key="route"
            keyprops="routekeyprops"
            name="route"
            label="Route"
            placeholder="Select Route"
            valueLabel={["zonedescription"]}
            data={routes}
            onChange={handleInputChangeG}
            value={values?.route}
            required={required}
          />
        );

      case "type":
        return (
          <ReactSelect
            ref={typeSelectInput}
            key="accessType"
            keyprops="accessTypekeyprops"
            name="accessType"
            label="Type"
            placeholder="Select Type"
            valueLabel={["description"]}
            data={props.data.types}
            value={values?.accessType}
            onChange={handleInputChangeG}
            required={required}
          />
        );

      case "elr":
        return (
          <ReactSelect
            ref={elrSelectInput}
            key="elr"
            keyprops="elrkeyprops"
            name="elr"
            label="ELR"
            placeholder="Select ELR"
            valueLabel={["elr", "description"]}
            data={filteredElr}
            loading={filteredElr && filteredElr[0] === "loading..."}
            onChange={handleInputChangeG}
            value={values?.elr}
            required={required}
          />
        );

      case "mileage":
        return (
          <div
            className="report-form__section"
            key="standardReportsMileageForm"
          >
            <Input
              key="startMiles"
              type="number"
              keyprops="startMileskeyprops"
              name="startMileage"
              label="Start Miles"
              min={values?.elr?.startmiles}
              max={values?.elr?.endmiles}
              step="0.0001"
              value={values?.startMileage}
              onChange={handleInputChange}
              required={required}
              ref={startMileageSelectInput}
              fieldsErrors={fieldsErrors}
            />
            <Input
              key="endMiles"
              type="number"
              keyprops="endMileskeyprops"
              name="endMileage"
              label="End Miles"
              min={values?.elr?.startmiles}
              max={values?.elr?.endmiles}
              step="0.0001"
              value={values?.endMileage}
              onChange={handleInputChange}
              required={required}
              ref={endMileageSelectInput}
              fieldsErrors={fieldsErrors}
            />
          </div>
        );

      case "mdu":
        return (
          <ReactSelect
            ref={mduSelectInput}
            key="mdu"
            keyprops="mdukeyprops"
            name="mduSf"
            label="MDU"
            placeholder="Select MDU"
            valueLabel={["subzonecode", "description"]}
            data={filteredMdu || props.data.mduList}
            loading={filteredMdu && filteredMdu[0] === "loading..."}
            onChange={handleInputChangeG}
            value={values?.mdu}
            required={required}
          />
        );

      default:
        return;
    }
  };

  return (
    <div className="report-form">
      <div className="report-form__section">
        <h2>{props.reportTitle}</h2>
        <p>{props.reportInfo}</p>
      </div>

      <div className="report-form__section">{generateFields()}</div>
      {generateMileage()}

      <div className="button-group report-form__submit">
        {loading && <BouncingDotsLoader></BouncingDotsLoader>}
        {!loading && (
          <Button action="secondary" label="Reset" onClick={handleReset} />
        )}
        {!loading && (
          <Button action="primary" label="Search" onClick={handleSubmit} />
        )}
        {error && !loading && (
          <div className="form-error">
            <span className="input__error">{error}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default StandardReportsForm;
