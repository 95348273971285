import React, {forwardRef, useMemo, useRef} from "react";
import Button from "../Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faDownload,
    faEdit,
    faExternalLinkAlt,
    faSort,
    faSortDown,
    faSortUp,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {usePagination, useSortBy, useTable} from "react-table";
import "./ReactTable.scss";
import Checkbox from "../Checkbox";
import {useExpanded} from "react-table/dist/react-table.development";

const ReactTablePagingFE = forwardRef((props, ref) => {
  const {
    tableData,
    exportCsv,
    totalElements,
    renderRowSubComponent,
  } = props;

  const tableRef = useRef(null);

  const columns = React.useMemo(() => {
    let dataColumnsArray;
    let columnsArray = [];
    let nestedColumns = [];
    let utilsColumns = [];
    let extensibleCoolumn = [];
    tableData?.[0]
      ? (dataColumnsArray = Object.keys(tableData[0]).map((key, i) => {
          if (!props.hideColumns?.includes(key)) {
            if (
              typeof tableData[0][key] === "object" &&
              !Array.isArray(tableData[0][key]) &&
              tableData[0][key] !== null
            ) {
              // let headerObj = {};
              // headerObj.Header = key
              //   .replace(/([A-Z])/g, " $1")
              //   .replace(/^./, function (str) {
              //     return str.toUpperCase();
              //   })
              //   .replace(/\bI D\b/g, "ID");
              // headerObj.columns = [];
              // console.log(headerObj);
              nestedColumns = Object.keys(tableData[0][key]).map(
                (nestedKey, i) => {
                  let nestColumn = {
                    Header: nestedKey
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })
                      .replace(/_/g, " ")
                      .replace(/\bI D\b/g, "ID"),
                    accessor: `${key}.${nestedKey}`,
                  };
                  // headerObj.columns.push(nestColumn);
                  return nestColumn;
                }
              );
            } else {
              return {
                Header: key
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, function (str) {
                    return str.toUpperCase();
                  })
                    .replace(/\W*(Elr)\W*/g, function (str) {
                      return str.toUpperCase();
                    })
                  .replace(/_/g, " ")
                  .replace(/\bI D\b/g, "ID"),
                accessor: key,
              };
            }
          }
        }))
      : (dataColumnsArray = []);

    if (props.editAction) {
      utilsColumns.push({
        Header: "Edit",
        Cell: (row) => {
          return useMemo(() => {
            return (
              <Button
                action="tertiary"
                onClick={(e) => {
                  props.editAction(e, row);
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            );
          }, [props.editAction]);
        },
      });
    }
    if (props.deleteAction) {
      utilsColumns.push({
        Header: "Delete",
        Cell: (row) => {
          return (
            <Button
              action="tertiary"
              onClick={(e) => props.deleteAction(e, row)}
              disabled={
                row.row.original.submission_status !== "Proposed Deletion"
              }
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          );
        },
      });
    }
    if (props.canOpen) {
      utilsColumns.push({
        Header: "Open",
        Cell: (row) => (
          <Button
            action="tertiary"
            onClick={(e) => {
              props.handleOpen(e, row);
            }}
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </Button>
        ),
      });
    }
    if (props.canDownload) {
      utilsColumns.push({
        Header: "Download",
        Cell: (row) => (
            <Button
                action="tertiary"
                onClick={(e) => {
                  props.handleDownload(e, row);
                }}
            >
              <FontAwesomeIcon icon={faDownload}/>
            </Button>
        ),
      });
    }

    columnsArray = columnsArray.concat(utilsColumns);
    dataColumnsArray = dataColumnsArray.filter((ele) => ele !== undefined);
    dataColumnsArray = dataColumnsArray.concat(nestedColumns);

    if (props.showColumns) {
      let showColumnsNormalized = props.showColumns.map((ele) => {
        return ele
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, function (str) {
            return str.toUpperCase();
          })
          .replace(/_/g, " ")
          .replace(/\bI D\b/g, "ID");
      });
      dataColumnsArray = dataColumnsArray.filter((ele) => {
        return showColumnsNormalized.includes(ele.Header);
      });
    }
    if (props.hideColumns) {
      let showColumnsNormalized = props.hideColumns.map((ele) => {
        return ele
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, function (str) {
            return str.toUpperCase();
          })
          .replace(/_/g, " ")
          .replace(/\bI D\b/g, "ID");
      });
      dataColumnsArray = dataColumnsArray.filter((ele) => {
        return !showColumnsNormalized.includes(ele.Header);
      });
    }

    if (props.extensible) {
      columnsArray.unshift(
        {
          // Make an expander cell
          Header: () => null, // No header
          id: "expander", // It needs an ID
          Cell: ({ row }) => (
            // Use Cell to render an expander for each row.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander.
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <FontAwesomeIcon
                  className="inline-block filter-icon"
                  icon={faAngleUp}
                  title="Collapse"
                />
              ) : (
                <FontAwesomeIcon
                  className="inline-block filter-icon"
                  icon={faAngleDown}
                  title="Expand"
                />
              )}
            </span>
          ),
        }
      );
    }

    return columnsArray.concat(dataColumnsArray);
  }, [tableData]);
  const data = React.useMemo(() => (tableData ? tableData : []), [tableData]);

  let classProps = ["res-table"];
  if (props.disabled) {
    classProps.push("res-table--disabled");
  }
  if (props.className) {
    classProps.push(props.className);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { expanded, pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useSortBy,
    useExpanded,
    usePagination,
    usePagination
  );

  return (
    <>
      {exportCsv && (
        <div className="button-group report-form__submit">
          <Button
            action="primary"
            label="Export"
            onClick={props.exportCsv.action}
          />
          <Button
            action="primary"
            label="Print Friendly"
            onClick={props.printFriendly?.action}
            // onClick={() => {
            //   let divToPrint = tableRef.current;
            //   let newWin = window.open("");
            //   newWin.document.write(divToPrint.outerHTML);
            //   newWin.print();
            //   newWin.close();
            // }}
          />
          {/* <ReactToPrint
            trigger={() => (
              <Button
                action="primary"
                label="Print Friendly"
                onClick={props.printFriendly.handlePrintFriendly}
                // onClick={() => {
                //   let divToPrint = tableRef.current;
                //   let newWin = window.open("");
                //   newWin.document.write(divToPrint.outerHTML);
                //   newWin.print();
                //   newWin.close();
                // }}
              />
            )}
            content={() => tableRef.current}
          /> */}
        </div>
      )}
      <div className="res-table__wrap" style={{ overflowX: "scroll" }}>
        <table
          ref={tableRef}
          {...getTableProps()}
          className={classProps.join(" ")}
        >
          <thead className="res-table__thead">
            {headerGroups.map((headerGroup, headerIndex) => (
              <tr
                className="res-table__tr"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      className="res-table__th"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {!["Open", "Edit"].includes(column["Header"]) && (
                          <span>
                            {column.isSorted ? (
                                column.isSortedDesc ? (
                                    <FontAwesomeIcon icon={faSortUp} />
                                ) : (
                                    <FontAwesomeIcon icon={faSortDown} />
                                )
                            ) : column.originalId?.includes("_placeholder") ||
                            column.columns ? (
                                ""
                            ) : (
                                <FontAwesomeIcon icon={faSort} />
                            )}
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="res-table__tbody" {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={"row-" + i}>
                  <tr className="res-table__tr" {...row.getRowProps()}>
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          className={`res-table__td `}
                          {...cell.getCellProps()}
                        >
                          {typeof cell.value == "boolean" ? (
                            cell.value === true ? (
                              <Checkbox checked={true} readOnly></Checkbox>
                            ) : (
                              <Checkbox checked={false} readOnly></Checkbox>
                            )
                          ) : (
                            // : cell.column["Header"] === "Free Text" ? (
                            //   <Tooltip text={cell.render("Cell")}>
                            //     {cell.render("Cell")}
                            //   </Tooltip>
                            // )
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr className="res-table__tr">
                      <td
                        className="fields-changes"
                        colSpan={visibleColumns.length}
                      >
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>

        <div className="pagination">
          <span className="pagination__page-no">
            Page{" "}
            <b>
              {pageIndex + 1} of {pageOptions.length}
            </b>
          </span>
          <div className="pagination__buttons">
            <Button
              action="tertiary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </Button>
            <Button
              action="tertiary"
              onClick={(e) => {
                gotoPage(pageIndex - 1);
              }}
              disabled={!canPreviousPage}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </Button>
            <Button
              action="tertiary"
              onClick={(e) => {
                gotoPage(pageIndex + 1);
              }}
              disabled={!canNextPage}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </Button>
            <Button
              action="tertiary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </Button>
          </div>

          <div className="pagination__controls">
            <label
              className="pagination__label"
              onClick={(e) => gotoPage(page)}
            >
              Go to page:
            </label>
            <input
              key={"pagination__input" + pageIndex}
              className="input pagination__input"
              type="number"
              defaultValue={isNaN(pageIndex) ? 1 : pageIndex + 1}
              min="1"
              max={pageOptions.length}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
            <select
              className="select pagination__select"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
});

export default ReactTablePagingFE;
