import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import BackButton from "../../components/BackButton";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Input from "../../components/Input";
import {UserService as userService} from "../../services/user.service";
import Checkbox from "../../components/Checkbox";
import ReactSelect from "../../components/Select/ReactSelect";
import GeneralService from "../../services/general.service";
import {toast} from "react-toastify";
import {OrganisationService} from "../../services/organisation.service";

const UserDetails = (props) => {
  const { state } = useLocation();
  const { pathname } = useLocation();
  const [clearData, setClearData] = useState(false);
  const [routes, setRoutes] = useState();
  const [details, setDetails] = useState();
  const [detailsEdited, setDetailsEdited] = useState();
  const [nonFilteredMdu, setNonFilteredMdu] = useState();
  const [filteredMdu, setFilteredMdu] = useState();
  const [editable, setEditable] = useState(false);
  const [elrMdus, setElrMdus] = useState(new Map());
  const roleSelectInput = useRef(null);
  const [roles, setRoles] = useState();
  const [requestedRole, setRequestedRole] = useState();
  const rolePermissionSelectInput = useRef(null);
  const [delegates, setDelegates] = useState();
  const [selectedDelegate, setSelectedDelegate] = useState();
  const delegateSelectInput = useRef(false);
  const firstNameInput = useRef(null);
  const surnameInput = useRef(null);
  const telephoneNumberInput = useRef(null);
  const routeSelectInput = useRef(null);
  const mduSelectInput = useRef(null);
  const [organisations, setOrganisations] = useState();
  const organisationSelectInput = useRef(null);
  const [getuser, setGetUser] = useState(false);
  const [error, setError] = useState();
  const [redirectUrl, setRedirectUrl] = useState(null);

  const navigate = useNavigate();

  const isOntracAdmin = props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("ontrac-admin-group") ? true : false;
  const isNetworkRail = details?.userDetails?.organisation?.organisation_name === "Network Rail";

  async function getUserDetails() {
      await userService.getUserFromCognitoUsername(props.user.username)
          .then(async (response) => {
              var route = reactSelectValueRoute(
                  response.data.userDetails.route,
                  routes
              );
              var mdu = reactSelectValueMDU(
                  response.data.userDetails.mdus,
                  props.mduList
              );
              var organisation = reactSelectOrganisation(
                  response.data.userDetails.organisation,
                  organisations
              );
              setDetails(response.data);
              setDetailsEdited(response.data);
              filterMDUFromRoute(route);
              if (roles === undefined) {
                  await userService
                      .getUserGroups()
                      .then((response1) => {
                          setRoles(response1.data);
                          var group = reactSelectRole(response.data.userDetails.cognitoGroup, response1.data);
                          setDetails((values) => ({
                              ...values,
                              userDetails: {
                                  ...values.userDetails,
                                  route: route,
                                  mdu: mdu,
                                  organisation: organisation,
                                  cognito_group: group,
                                  cognitoGroup: group[0]
                              }
                          }));
                          setDetailsEdited((values) => ({
                              ...values,
                              userDetails: {
                                  ...values.userDetails,
                                  route: route,
                                  mdu: mdu,
                                  organisation: organisation,
                                  cognito_group: group,
                                  cognitoGroup: group[0]
                              }
                          }));
                          setRequestedRole(group);
                      })
                      .catch((error) => {
                          setRoles(error);
                      });
              } else {
                  var group = reactSelectRole(response.data.userDetails.cognitoGroup, roles);
                  setDetailsEdited((values) => ({
                      ...values,
                      userDetails: {
                          ...values.userDetails,
                          route: route,
                          mdu: mdu,
                          organisation: organisation,
                          cognito_group: group,
                          cognitoGroup: group[0]
                      }
                  }));
              }

          });
  }
  
  useEffect( async () => {
      if (state.formType === "profile" && state.previousPath === "/userdetails" && !clearData) {
          setRedirectUrl('/usermanagement');
          setClearData(true);
          await getUserDetails();
      }
  }, [state, details, detailsEdited, pathname, clearData, getUserDetails])

    useEffect(async () => {
        if (routes === undefined) {
            await GeneralService.getRoutes(true)
                .then((response) => {
                    setRoutes(response.data);
                })
                .catch((error) => {
                    // setRoutes("error");
                    console.log(error);
                });
        }
        if (organisations === undefined) {
            await OrganisationService.getAllOrganisations()
                .then((response) => {
                    setOrganisations(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [routes, organisations]);

    useEffect(() => {
        async function fetchDelegates() {
            if (!delegateSelectInput.current) {
                delegateSelectInput.current = true;
                if (props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("whsea-admin-group") ||
                    props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("whsea-group")) {
                    let role = "";
                    if (process.env.REACT_APP_ENV !== "DEV" && props.user.signInUserSession.accessToken.payload["cognito:groups"].length > 1 && details.userDetails.organisation === "Network Rail") {
                        role = props.user.signInUserSession.accessToken.payload["cognito:groups"][1]
                    } else {
                        role = props.user.signInUserSession.accessToken.payload["cognito:groups"][0]
                    }
                    await userService
                        .getAllWhseaUsers(details.userDetails.route, details.userDetails.mdus, role)
                        .then((response) => {
                            setDelegates(response.data);
                            var delegate = reactSelectDelegate(details.userDetails.delegateUserId, response.data);
                            setDetails((values) => ({
                                ...values,
                                userDetails: {...values.userDetails, delegate: delegate}
                            }));
                            setDetailsEdited((values) => ({
                                ...values,
                                userDetails: {...values.userDetails, delegate: delegate}
                            }));
                        })
                        .catch((error) => {
                            setDelegates(error);
                        });
                } else if (props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("ssm-admin-group")
                    || props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("SSM")) {
                    let role = "";
                    if (process.env.REACT_APP_ENV !== "DEV" && props.user.signInUserSession.accessToken.payload["cognito:groups"].length > 1 && details.userDetails.organisation === "Network Rail") {
                        role = props.user.signInUserSession.accessToken.payload["cognito:groups"][1]
                    } else {
                        role = props.user.signInUserSession.accessToken.payload["cognito:groups"][0]
                    }
                    await userService
                        .getAllSSMUsers(details.userDetails.route, details.userDetails.mdus, role)
                        .then((response) => {
                            setDelegates(response.data);
                            var delegate = reactSelectDelegate(details.userDetails.delegateUserId, response.data);
                            setDetails((values) => ({
                                ...values,
                                userDetails: {...values.userDetails, delegate: delegate}
                            }));
                            setDetailsEdited((values) => ({
                                ...values,
                                userDetails: {...values.userDetails, delegate: delegate}
                            }));
                        })
                        .catch((error) => {
                            setDelegates(error);
                        });
                }
            }
        }
        if (delegates === undefined && details?.userDetails?.organisation === "Network Rail") {
            if (!delegateSelectInput.current) {
                fetchDelegates();
            }
        }
    }, [delegates, details])

  useEffect(async () => {
      if (routes && organisations && props.mduList && details === undefined) {
          if (state?.data) {
              setDetails(state.data);
              setDetailsEdited(state.data);
              var route = reactSelectValueRoute(
                  state.data.userDetails.route,
                  routes
              );
              var mdu = reactSelectValueMDU(
                  state.data.userDetails.mdus,
                  props.mduList
              );
              var organisation = reactSelectOrganisation(
                  state.data.userDetails.organisation,
                  organisations
              );
              setDetailsEdited((values) => ({
                  ...values,
                  userDetails: {...values.userDetails, route: route, mdu: mdu, organisation: organisation}
              }));
              filterMDUFromRoute(route);
              userService
                  .getUserGroups()
                  .then((response) => {
                      setRoles(response.data);
                      var group = reactSelectRole(state.data.userDetails.cognitoGroup, response.data);
                      setDetails((values) => ({
                          ...values,
                          userDetails: {...values.userDetails, cognito_group: group, cognitoGroup: group[0]}
                      }));
                      setDetailsEdited((values) => ({
                          ...values,
                          userDetails: {...values.userDetails, cognito_group: group, cognitoGroup: group[0] }
                      }));
                      setRequestedRole(group);
                  })
                  .catch((error) => {
                      setRoles(error);
                  });
          } else {
              if (!getuser) {
                  setGetUser(true);
                  await getUserDetails();
              }
          }
      }
  }, [state, routes, organisations, props.mduList, props.user.username]);

  const handleOnchange = (e, setUserDetails, address) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (editable) {
      if (setUserDetails) {
        if (!address) {
          setDetailsEdited((values) => ({
            ...values,
            userDetails: {...values.userDetails, [e.target.name]: value},
          }));
        } else {
          setDetailsEdited((values) => ({
            ...values,
            userDetails: {...values.userDetails, address: {...values.userDetails.address,[e.target.name]: value}},
          }));
        }
      } else {
        setDetailsEdited((values) => ({
          ...values,
          [e.target.name]: value,
        }));
      }
    }
  };

    function filterMDUFromRoute(value) {
        console.log('filter');
        setFilteredMdu(["loading..."]);
        setDetailsEdited((values) => ({
            ...values,
        }));
        let zoneCode = [];
        if (value) {
            for (let i = 0; i < value.length; i++) {
                zoneCode[i] = value[i].zonecode;
            }
        }
        if (zoneCode?.length > 0) {
            GeneralService.getZonecodeMDUSubzonecode({
                zonecode: zoneCode,
            })
                .then((response) => {
                    console.log('filter response')
                    const notFiltered = [...response.data];
                    setNonFilteredMdu(notFiltered);
                    let mdus = response.data;
                    if (elrMdus.size !== 0) {
                        var elrMduKeys = Array.from(elrMdus.keys());
                        for (let i = 0; i < elrMduKeys.length; i++) {
                            var entry = elrMdus.get(elrMduKeys[i]);
                            if (entry != null && entry.length > 0) {
                                for (let j = 0; j < entry.length; j++) {
                                    for (let k = 0; k < mdus.length; k++) {
                                        if (entry[j]._id === mdus[k]._id) {
                                            mdus.splice(k, 1);
                                        }
                                    }
                                }
                            }
                        }
                        // mdus = response.data;
                    }
                    console.log('here');
                    setFilteredMdu(mdus);
                })
                .catch((error) => {
                    setFilteredMdu();
                });
        } else {
            setFilteredMdu();
        }
    }

    const handleReactSelectChange = (value, name, actionType) => {
    console.log(value, name, actionType);
    if (actionType?.action === "remove-value") {
      var route = routes.filter((r) => {
        if (r.zonecode === actionType.removedValue.value.zonecode) {
          return true;
        }
      });
      var key = route[0].zonecode + ", " + route[0].zonedescription;

      if (elrMdus.has(key)) {
        if (name === "mduPr") {
          let keyValue = elrMdus.get(key);
          if (keyValue !== null) {
            keyValue = keyValue.filter((v) => {
              if (v.subzonecode !== actionType.removedValue.value.subzonecode) {
                return true;
              }
            });
            setDetailsEdited((values) => ({
              ...values,
              userDetails: { ...values.userDetails, ["mdu"]: value},
            }));
            elrMdus.set(key, keyValue);
            if (!filteredMdu.includes(actionType.removedValue.value)) {
                // // setFilteredMdu(nonFilteredMdu);
                var mdus = [...filteredMdu];
                mdus.push(actionType.removedValue.value);
                mdus = mdus.sort((a, b) => {
                    return a.zonecode.localeCompare(b.zonecode);
                });
                setFilteredMdu(mdus);
            }

          }

        } else {
          let mdus = [];
          elrMdus.delete(key);
          elrMdus.forEach((element) => {
            for (let i = 0; i < element?.length; i++) {
              mdus.push(element[i]);
            }
          });

          setDetailsEdited((values) => ({
            ...values,
            userDetails: { ...values.userDetails, [name]: value, mdu: mdus},
          }));
          if (name === "route") {
              filterMDUFromRoute(value);
          }
        }
      } else {
          setDetailsEdited((values) => ({
              ...values,
              userDetails: { ...values.userDetails, [name]: value},
          }));
          filterMDUFromRoute(value);
      }
    } if (actionType?.action === "clear") {
      if (name === "mduPr") {
        setDetailsEdited((values) => ({
          ...values,
          userDetails: { ...values.userDetails, ["mdu"]: value},
        }));
        elrMdus.clear();
        setFilteredMdu(nonFilteredMdu);
      } else {
        setDetailsEdited((values) => ({
          ...values,
          userDetails: { ...values.userDetails, [name]: value, mdu: ""},
        }));
        elrMdus.clear();
        setFilteredMdu();
        setNonFilteredMdu();
      }
    } if (actionType?.action !== "clear" && actionType?.action !== "remove-value") {
        if(name === "mduPr") {
            name = "mdu";
            if (detailsEdited.userDetails.mdu?.length > 0) {
                var mdus = [...detailsEdited.userDetails.mdu];
                value.forEach(v => {
                    if (!mdus.includes(v)) {
                        mdus.push(v)
                    }
                });

                value = mdus.sort((a, b) => {
                    return a.zonecode.localeCompare(b.zonecode);
                });
            }
        }
      setDetailsEdited((values) => ({
        ...values,
        userDetails: { ...values.userDetails, [name]: value },
      }));

        if (name === "route") {
            filterMDUFromRoute(value);
        }

      if (name !== "cognito_group" && name !== "delegate" && name !== "organisation" && name === "mdu") {
        if (actionType.option.value === "*") {
            elrMdus.clear();
            routes.forEach(route => {
                var key2 = route.zonecode + ", " + route.zonedescription;
                value.forEach(mdu => {
                    if (mdu.zonecode === route.zonecode) {
                        if (elrMdus.has(key2)) {
                            let value = elrMdus.get(key2);
                            if (value !== null) {
                                value.push(mdu);
                                elrMdus.set(key2, value);
                            } else {
                                elrMdus.set(key2, [mdu]);
                            }
                        } else {
                            elrMdus.set(key2, [mdu])
                        }
                    }
                });
            });
        } else {
            var route1 = routes.filter((r) => {
                if (r.zonecode === actionType.option.value.zonecode) {
                    return true;
                }
            });
            var key1 = route1[0].zonecode + ", " + route1[0].zonedescription;
            if (elrMdus.has(key1)) {
                let value = elrMdus.get(key1);
                if (value !== null) {
                    value.push(actionType.option.value);
                    elrMdus.set(key1, value);
                } else {
                    elrMdus.set(key1, [actionType.option.value]);
                }
            } else {
                elrMdus.set(key1, [actionType.option.value]);
            }
        }
      }
    }
  };

    const validform = () => {
        let valid = false;

        let validFirstName = firstNameInput.current?.validateField();
        let validSurname = surnameInput.current?.validateField();
        let validTelNo = telephoneNumberInput.current?.validateField();
        let validRoute = routeSelectInput.current?.validateField();
        let validMDU = mduSelectInput.current?.validateField();
        if (validFirstName && validSurname && validTelNo && validRoute && validMDU) {
            valid = true;
        }

        return valid;
    };

  const handleUpdate = () => {
      if (validform()) {
          userService
              .updateUser(detailsEdited)
              .then((response) => {
                  //toast
                  toast.success(
                      `Your request has been submitted. And the user details should have been updated.`,
                      {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                      }
                  );
                  //navigate user mangement
                  if (state.formType === "userdetails") {
                      navigate("/usermanagement");
                  } else {
                      navigate("/");
                  }
              })
              .catch((error) => {
                  if (!error.response.data?.apierror?.message) {
                      toast.error(
                          `There was an error submitting the update. Please contact support@on-trac.co.uk`,
                          {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                          }
                      );
                  } else {
                      toast.error(
                          `${error.response.data?.apierror?.message}`,
                          {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                          }
                      );
                  }
                  console.log(error);
                  setError(error.response.data?.apierror?.message);
              });
      } else {
          setError("Please check fields or errors highlighted in red, and amend the information to continue.");
      }
  };

  function setElrMdusRoutes(value, routes) {
    let valueObj = [];
      if (value.length === routes.length) {
          valueObj = routes;
      } else {
          valueObj = routes?.filter((ele) => {
              return value.map((det) => det).includes(ele.zonedescription);
          });
      }
      for (let i = 0; i < valueObj.length; i++) {
          elrMdus.set(valueObj[i].zonecode + ", " + valueObj[i].zonedescription, null);
      }
      return valueObj;
  }

  function updateElrMdus(value, propsList) {
    var valueObj = propsList?.filter((ele) => {
      return value.map((det) => det).includes(ele.subzonecode);
    });
    if (valueObj?.length > 0) {
        let keys = [];
        routes.forEach(route => {
            valueObj.forEach(v => {
                if (route.zonecode === v.zonecode) {
                    var key = route.zonecode + ", " + route.zonedescription;
                    if (!keys.includes(key)) {
                        keys.push(route.zonecode + ", " + route.zonedescription);
                    }
                }
            });
        });
        keys.forEach(key => {
            if (elrMdus.has(key)) {
                let value1 = elrMdus.get(key);
                if (value1 !== null) {
                    valueObj.forEach(v => {
                        if (v.zonecode === key.split(",")[0]) {
                            value1.push(v);
                        }
                    });
                    elrMdus.set(key, value1);
                } else {
                    let value2 = [];
                    valueObj.forEach(v => {
                        if (v.zonecode === key.split(",")[0]) {
                            value2.push(v);
                        }
                    });
                    elrMdus.set(key, value2);
                }
            }
        });
    }
    return valueObj;
  }

  const toggleEdit = () => {
    if (editable) {
      elrMdus.clear();
      setDetailsEdited(details);
      var route;
      if (details.userDetails.route != null && details.userDetails.route.length > 1) {
        var routeNames = [];
        details.userDetails.route.forEach(route => {
            routeNames.push(route.zonedescription);
        });
        route = reactSelectValueRoute(routeNames, routes)
      } else {
          route = reactSelectValueRoute([details.userDetails.route[0]], routes);
      }

      let mdu = reactSelectValueMDU(details.userDetails.mdus, props.mduList);
      let delegate = reactSelectDelegate(details.userDetails.delegate, delegates);
      let organisation;
      let cognitoGroup = details.userDetails.cognitoGroup;
      if (state.formType === "userdetails") {
          organisation = reactSelectOrganisation(details.userDetails.organisation, organisations);
      } else {
          organisation = details.userDetails.organisation;
      }
      setDetailsEdited((values) => ({
        ...values,
        userDetails: {...values.userDetails, route: route, mdu: mdu, delegate: delegate, cognitoGroup: cognitoGroup, organisation: organisation}
      }))
      setSelectedDelegate(delegate);
      filterMDUFromRoute(route);
    }
    setEditable(!editable);
    setError(null);
  };

  const reactSelectValueRoute = (value, propsList) => {
    let valueObj = [];

    if (value) {
      if (Array.isArray(value) && value.length > 0) {
        valueObj = setElrMdusRoutes(value, propsList);
        return valueObj;
      } else {
        valueObj = propsList.filter((ele) => {
          return value === ele.zonedescription;
        });
        return valueObj;
      }
    }
    return undefined;
  };

  const reactSelectValueMDU = (value, propsList) => {
    let valueObj = [];

    if (value) {
      if (Array.isArray(value)) {
        valueObj = updateElrMdus(value, propsList);
        return valueObj;
      } else {
        valueObj = propsList.filter((ele) => {
          return value === ele.subzonecode;
        });
        return valueObj;
      }
    }

    return undefined;
  };

  const reactSelectRole = (value, roles) => {
    let valueObj = [];

    if (value) {
        valueObj = roles.filter((ele) => {
          return value === ele.group_name;
        });
        return valueObj;
      }
  }

  const reactSelectDelegate = (value, delegates) => {
    let valueObj = [];

    if (value) {
      valueObj = delegates.filter((ele) => {
        return value === ele.userid;
      });
      return valueObj[0];
    }
  }

  const reactSelectOrganisation = (value, organisations) => {
      let valueObj;

      if (value) {
          valueObj = organisations.filter((element) => {
              return value === element.organisation_name;
          });
          return valueObj[0];
      }
  }

  const handleRoleSelect = (value)  => {
    setRequestedRole(value);
  }

  const handleDelegateChange = (value) => {
    setSelectedDelegate(value);
  }

  const handlePermissionRequest = () => {
    userService.requestPermission(requestedRole)
        .then((response) => {
            toast.success(
                `Your request has been submitted.`,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        }).catch((error) => {
        if (error.response.status === 409) {
            toast.error(
                'You already have a request open to change your role. Please contact your NR representative.',
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        } else {
            console.log(error);
        }
    })
  };

  const temporaryPassword = () => {
      userService.setTemporaryPassword(details.username).then((response) => {
          toast.success(
              `Temporary Password set for ${details.userDetails.firstName}`,
              {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
              }
          );
      }).catch((error) => {
          toast.error(
              `Error setting temporary Password for ${details.userDetails.firstName}`,
              {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
              }
          );
      })
  };

  const resendInvitation = () => {
      userService.resendInvitation(details.username).then((response) => {
          toast.success(
              `Invitation Resent for ${details.userDetails.firstName}`,
              {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
              }
          );
      }).catch((error) => {
          toast.error(
              `Error sending login password`,
              {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
              }
          );
      })
  };

  return (
    <div className="page" id={`${state?.title?.toLowerCase || ""} `}>
      <Header title={`${state?.title || ""} `} />
      <BackButton 
        navigateUrl={redirectUrl}
      />
      {details && (
        <div className="filter-form">
          <div className="filter-form__controller">
            <h2 className="filter-form__title inline-block">User Details</h2>
          </div>
            {isNetworkRail && state.formType === "profile" && details.userDetails.newProfile && (
                <div>
                    <h3>Please Complete the Form below before proceeding to use NHD. </h3>
                </div>
            )}
          <div>
            <div className="filter-form__group fields-wrapper">
              <Input
                ref={firstNameInput}
                key={`firstName`}
                name={`firstName`}
                label={`FirstName`}
                value={detailsEdited?.userDetails.firstName}
                readOnly={editable ? false : true}
                required={editable ? true : false}
                onChange={(e) => handleOnchange(e, true)}
                className={`${editable === true ? "editable" : ""}`}
              />
              <Input
                ref={surnameInput}
                key={`surname`}
                name={`surname`}
                label={`Surname`}
                value={detailsEdited?.userDetails.surname}
                readOnly={editable ? false : true}
                required={editable ? true : false}
                onChange={(e) => handleOnchange(e, true)}
                className={`${editable === true ? "editable" : ""}`}
              />
              <Input
                ref={telephoneNumberInput}
                key={`telephoneNumber`}
                name={`telephoneNumber`}
                label={`Telephone Number`}
                value={detailsEdited?.userDetails.telephoneNumber}
                readOnly={editable ? false : true}
                required={editable ? true : false}
                onChange={(e) => handleOnchange(e, true)}
                className={`${editable === true ? "editable" : ""}`}
              />
              <Input
                key={`email`}
                name={`email`}
                label={`Email`}
                value={detailsEdited?.email}
                readOnly={true}
              />
                {state.formType === "userdetails" && !detailsEdited?.userDetails?.organisation?.organisation_name.startsWith("Network") ?
                    <ReactSelect
                        ref={organisationSelectInput}
                        key={`organisation`}
                        name={`organisation`}
                        label={`Organisation`}
                        placeholder="Select Organisation"
                        valueLabel={["organisation_name"]}
                        data={organisations}
                        value={detailsEdited?.userDetails.organisation}
                        required={true}
                        isDisabled={editable ? false : true}
                        onChange={handleReactSelectChange}
                        className={`${editable === true ? "editable" : ""}`}
                    /> :
                    <Input
                        ref={organisationSelectInput}
                        key={`organisation`}
                        name={`organisation`}
                        label={`Organisation`}
                        value={detailsEdited?.userDetails?.organisation?.organisation_name}
                        readOnly={true}
                    />
                }
              <Input
                key={`created`}
                name={`created`}
                label={`Created`}
                value={detailsEdited?.created}
                readOnly={true}
              />

              <Input
                key={`sentinelNumber`}
                name={`sentinelNumber`}
                label={`Sentinel Number`}
                value={parseInt(detailsEdited?.userDetails.sentinelNumber)}
                readOnly={editable ? false : true}
                required={editable ? true : false}
                onChange={(e) => handleOnchange(e, true)}
                className={`${editable === true ? "editable" : ""}`}
                type="number"
              />

              <Input
                key={'cognitoGroup'}
                name={'cognitoGroup'}
                label={'User Role'}
                value={detailsEdited?.userDetails?.cognitoGroup?.description}
                readOnly={true}
              />
            </div>
            {isOntracAdmin && state.formType === "userdetails" && (detailsEdited?.userDetails?.organisation?.organisation_name.startsWith("Network") || detailsEdited?.userDetails?.organisation?.organisation_name.startsWith("OnTrac")) && (
                <ReactSelect
                    ref={roleSelectInput}
                    name="cognito_group"
                    label="Role"
                    placeholder="Select Role"
                    valueLabel={["description"]}
                    data={roles}
                    loading={roles && roles[0] === "loading..."}
                    onChange={handleReactSelectChange}
                    value={detailsEdited?.userDetails?.cognito_group}
                    required={true}
                    isDisabled={editable ? false : true}
                    selectAll={false}
                />
            )}
            <div className="search-form__section">
              <ReactSelect
                ref={routeSelectInput}
                isMulti={true}
                name="route"
                label="Route"
                placeholder="Select Route"
                valueLabel={["zonedescription"]}
                data={routes}
                onChange={handleReactSelectChange}
                value={detailsEdited?.userDetails?.route}
                isDisabled={editable ? false : true}
                required={editable ? true : false}
                selectAll={true}
              />
              <ReactSelect
                ref={mduSelectInput}
                isMulti={true}
                name="mduPr"
                label="MDU"
                placeholder="Select MDU"
                valueLabel={["subzonecode", "description"]}
                data={filteredMdu}
                loading={filteredMdu && filteredMdu[0] === "loading..."}
                onChange={handleReactSelectChange}
                value={detailsEdited?.userDetails?.mdu}
                isDisabled={editable ? false : true}
                required={editable ? true : false}
                selectAll={true}
              />
            </div>
            {state.formType === "userdetails" && (
                <div className="create-form__section fields-wrapper">
                  <Checkbox
                      key="enabled"
                      name="enabled"
                      value="enabled"
                      label="Enabled"
                      onChange={(e) => handleOnchange(e)}
                      checked={detailsEdited?.enabled || false}
                      className={`userdetail-checkbox ${
                          editable === true ? "editable" : ""
                      }`}
                      disabled={editable ? false : true}
                  />
                </div>
            )}

            <div className="create-form__section">
              <h3>Address Details</h3>
              <Input
                  name="building_name"
                  label="Building Name"
                  value={detailsEdited?.userDetails?.address?.building_name}
                  onChange={(e) => handleOnchange(e, true, true)}
                  readOnly={editable ? false : true}
              />
              <Input
                  name="address1"
                  label="Address"
                  value={detailsEdited?.userDetails?.address?.address1}
                  onChange={(e) => handleOnchange(e, true, true)}
                  readOnly={editable ? false : true}
              />
              <Input
                  name="address2"
                  label="Address"
                  value={detailsEdited?.userDetails?.address?.address2}
                  onChange={(e) => handleOnchange(e, true, true)}
                  readOnly={editable ? false : true}
              />
              <Input
                  name="address3"
                  label="Address"
                  value={detailsEdited?.userDetails?.address?.address3}
                  onChange={(e) => handleOnchange(e, true, true)}
                  readOnly={editable ? false : true}
              />
              <Input
                  name="city"
                  label="City"
                  value={detailsEdited?.userDetails?.address?.city}
                  onChange={(e) => handleOnchange(e, true, true)}
                  readOnly={editable ? false : true}
              />
              <Input
                  name="county"
                  label="County"
                  value={detailsEdited?.userDetails?.address?.county}
                  onChange={(e) => handleOnchange(e, true, true)}
                  readOnly={editable ? false : true}
              />
              <Input
                  name="postcode"
                  label="Postcode"
                  value={detailsEdited?.userDetails?.address?.postcode}
                  onChange={(e) => handleOnchange(e, true, true)}
                  readOnly={editable ? false : true}
              />
            </div>
            {isNetworkRail && state.formType === "profile" &&
                (props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("SSM") ||
                props.user.signInUserSession.accessToken.payload["cognito:groups"].includes("ssm-admin-group")) && (
                <div className={"search-form__section"}>
                  <h3>Select your delegate reviewer</h3>
                    <ReactSelect
//                        ref={delegateSelectInput}
                        name="delegate"
                        label="Delegate"
                        placeholder="Select Delegate User"
                        valueLabel={["name", "email"]}
                        data={delegates}
                        loading={delegates && delegates[0] === "loading..."}
                        onChange={handleReactSelectChange}
                        value={detailsEdited?.userDetails?.delegate}
                        isDisabled={editable ? false : true}
                        selectAll={false}
                    />
                </div>
            )}
            <div className="button-group filter-form__submit">
              {editable && (
                <>
                  {" "}
                  <Button
                    action="primary"
                    label="Update"
                    onClick={handleUpdate}
                  />
                  <Button
                    action="primary"
                    label="Cancel"
                    onClick={toggleEdit}
                  />
                </>
              )}
              {!editable && (
                <Button action="primary" label="Edit" onClick={toggleEdit} />
              )}
                <div className="form-error">
                    <span className="input__error">{error}</span>
                </div>
            </div>
          </div>
            {isOntracAdmin && state.formType === "userdetails" && !isNetworkRail && (
                <div className="button-group filter-form__submit">
                    <Button
                        action="secondary"
                        label="Set Temporary Password"
                        onClick={temporaryPassword}
                        disabled={details.userStatus !== "CONFIRMED"}
                    />
                </div>
            )}
            {isOntracAdmin && state.formType === "userdetails" && details.userStatus === "FORCE_CHANGE_PASSWORD" && (
                <div className="button-group filter-form__submit">
                    <Button
                        action="secondary"
                        label="Resend Invitation Password"
                        onClick={resendInvitation}
                        // disabled={details.userStatus === "FORCE_CHANGE_PASSWORD"}
                    />
                </div>
            )}
          {isNetworkRail && details.userDetails?.newProfile === false  && state.formType === "profile" && (
              <>
              <div className={"search-form__section profile-request_permissions"}>
                <h3> Request Role Permissions</h3>

                <ReactSelect
                    ref={rolePermissionSelectInput}
                    name="rolesSelect"
                    label="Role"
                    placeholder="Select Requested Role"
                    valueLabel={["description"]}
                    data={roles}
                    onChange={handleRoleSelect}
                    value={requestedRole}
                    selectAll={false}
                />
                <div className="button-group filter-form__submit" style={{paddingTop: "20px"}}>
                  <>
                    {" "}
                    <Button action="secondary" label="Request Permission" onClick={handlePermissionRequest} />
                  </>
                </div>
              </div>
              </>
          )}
        </div>
      )}
    </div>
  );
};

export default UserDetails;
