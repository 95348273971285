import React, {createContext, useState} from "react";

export const ThemeContext = createContext();
const ThemeContextProvider = (props) => {
  const [context, setContext] = useState({ isDarkMode: false });

  const toggleTheme = () => {
    setContext((old) => ({ ...old, isDarkMode: !old.isDarkMode }));
  };

  return (
    <ThemeContext.Provider
      value={{ context: context, toggleTheme: toggleTheme }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
