import React from 'react';

function Textarea(props) {

  let classProps = ['textarea__wrap'];
  if (props.disabled) {classProps.push('textarea__wrap--disabled')}
  if (props.className) {classProps.push(props.className)}

  let inputProps = {
    className: "textarea",
    id: props.id ? props.id : props.name,
    name: props.name
  };

  inputProps.value = props.value ?  props.value : "";
  if (props.rows) {inputProps.rows = props.rows}
  if (props.cols) {inputProps.cols = props.cols}
  if (props.disabled) {inputProps.disabled = props.disabled}
  if (props.readonly) {inputProps.readonly = props.readonly}
  if (props.required) {inputProps.required = props.required}
  if (props.maxlength) {inputProps.maxlength = props.maxlength}
  if (props.placeholder) {inputProps.placeholder = props.placeholder}
  if (props.onChange) {inputProps.onChange = props.onChange}

  return (
  <div className={classProps.join(' ')} >
    <label className="textarea__label" htmlFor={props.id ? props.id : props.name}>{props.label ? props.label : props.name}:</label>
    <textarea 
      {...inputProps}
      />
  </div>
)};

export default Textarea;