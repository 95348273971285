import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEdit, faTimes, faUndo} from "@fortawesome/free-solid-svg-icons";
import Input from "./Input";

function ApprovePaneEntry(props) {
  let metaData = {
    target: props.target,
    status: props.metaStatus ? props.metaStatus : "",
    initialValue: props.initialValue === 0 ? 0 : props.initialValue || "",
    newValue: props.value === 0 ? 0 : props.value || "",
    approverName: props.userName,
    date: new Date(),
  };

  let classProps = ["approve-pane__entry"];
  if (props.className) {
    classProps.push(props.className);
  }
  if (props.metaStatus || props.required === true) {
    if (props.metaStatus !== "" || !props.required) {
      classProps.push("approve-pane__entry--" + props.metaStatus);
    } else {
      classProps.push("approve-pane__entry--required");
    }
  }

  let editRecord =
    metaData.newValue === metaData.initialValue || props.required ? (
      ""
    ) : (
      <span className="approve-pane__entry__editRecord">
        This field was changed from{" "}
        <span className="approve-pane__entry__edit-from">
          {metaData.initialValue}
        </span>{" "}
        to{" "}
        <span className="approve-pane__entry__edit-to">
          {metaData.newValue}
        </span>
      </span>
    );

  const metaDetails = function (status) {
    switch (status) {
      case "approved":
        return (
          <span className="approve-pane__entry__metaDetails">
            Approved on {metaData.date.toLocaleDateString("en-UK")} by{" "}
            {metaData.approverName}
          </span>
        );
      case "edited":
        return (
          <span className="approve-pane__entry__metaDetails">
            Edited on {metaData.date.toLocaleDateString("en-UK")} by{" "}
            {metaData.approverName}
          </span>
        );
      case "rejected":
        return (
          <span className="approve-pane__entry__metaDetails">
            Rejected on {metaData.date.toLocaleDateString("en-UK")} by{" "}
            {metaData.approverName}
          </span>
        );
      default:
        return "";
    }
  };

  return (
    <div className={classProps.join(" ")}>
      <div className="approve-pane__entry__details">
        <span className="approve-pane__entry__label">{props.label}: </span>
        <span className="approve-pane__entry__value">{props.value}</span>

        {metaDetails(metaData.status)}
        {editRecord}
      </div>

      <div className="approve-pane__entry__buttons">
        <button
          className="approve-pane__entry__button approve-pane__entry__button--undo"
          onClick={(e) => props.handleUndo(e, metaData)}
          disabled={props.disabled}
        >
          <FontAwesomeIcon icon={faUndo} className="approve-pane__icon" />
        </button>
        {props.handleApprove && <button
          className="approve-pane__entry__button approve-pane__entry__button--approve"
          onClick={(e) => props.handleApprove(e, metaData)}
        >
          <FontAwesomeIcon icon={faCheck} className="approve-pane__icon" />
        </button> }
        {props.handleEdit && <button
          className="approve-pane__entry__button approve-pane__entry__button--edit"
          onClick={(e) => props.handleEdit(e, metaData)}
        >
          <FontAwesomeIcon icon={faEdit} className="approve-pane__icon" />
        </button> }
        {props.handleReject && <button
          className="approve-pane__entry__button approve-pane__entry__button--reject"
          onClick={(e) => props.handleReject(e, metaData)}
        >
          <FontAwesomeIcon icon={faTimes} className="approve-pane__icon" />
        </button>
        }
      </div>
      {metaData.status === "rejected" && (
        <>
          <Input
            // name="reason"
            placeholder="Reason..."
            value={props.rejectedReason[metaData.target]}
            onChange={(e) => props.handleSetRejectedReasion(e, metaData)}
          />
        </>
      )}
    </div>
  );
}

export default ApprovePaneEntry;
