import React from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import CreateZoneForm from "../../components/CreateZoneForm";

function AdminAddHazardType(props) {
  return (
    <div className="page" id="dataAddZone">
      <Header title="Add New Zone/Region" />
      <BackButton />

      <CreateZoneForm data={props} />
    </div>
  );
}

export default AdminAddHazardType;
