import React, {Fragment, useContext, useEffect, useState} from "react";
import Header from "../../components/Header.js";
import BackButton from "../../components/BackButton.js";
import {useLocation, useNavigate} from "react-router-dom";
import {ThemeContext} from "../../contexts/ThemeContextProvider";
import {useAuthenticator} from "@aws-amplify/ui-react";
import ReactTable from "../../components/ReactTable/ReactTable";
import {UserService as userService} from "../../services/user.service";

export default function OnboardingPage(props) {

    const [onBoardingRequests, setOnBoardingRequests] = useState();
    const [onBoardingRequestMessage, setOnBoardingRequestMessage] = useState();
    const [loadingOnBoardingRequests, setLoadingOnBoardingRequests] = useState(true);

    const {state} = useLocation();

    const context = useContext(ThemeContext);
    const {user} = useAuthenticator((context) => [context.user]);
    let navigate = useNavigate();

    useEffect(() => {
        if (state?.onboardingRequest) {
            setOnBoardingRequestMessage(state?.onboardingRequest);
            setTimeout(() => setOnBoardingRequestMessage(), 5000);
        }
    }, [state]);

    useEffect(() => {
        setLoadingOnBoardingRequests(true);
        if (user.signInUserSession.accessToken.payload["cognito:groups"].includes(
            "ontrac-admin-group"
        )) {
            userService
                .getOnboardingRequests()
                .then((response) => {
                    //setOnBoardingRequests(onBoardingData);
                    setOnBoardingRequests(response.data);
                    setLoadingOnBoardingRequests(false);
                })
                .catch((error) => {
                    setOnBoardingRequests({});
                    // setOnBoardingRequests("error");
                    setLoadingOnBoardingRequests(false);
                    console.log(error);
                });
        } else {
            setLoadingOnBoardingRequests(false);
            setOnBoardingRequests({});
        }
    }, [user.signInUserSession.accessToken.payload]);

    const handleOpenOnboardingDetails = (e, row) => {
        navigate("/onboardingdetails", {
            state: {
                data: row.row?.original,
            },
        });
    };

    return (
        <div className="page" id="dataManDashPage">
            <Header title="Onboarding User Management" />
            <BackButton
                setPage={props.setPage}
                target={["usermanagementDash", "backward"]}
                navigateUrl="/usermanagementDash"
            />
            {onBoardingRequestMessage &&
                onBoardingRequestMessage.status === "success" && (
                    <div className="alert alert-success">
                        {onBoardingRequestMessage.message}
                    </div>
                )}
            {onBoardingRequestMessage &&
                onBoardingRequestMessage.status === "error" && (
                    <div className="alert alert-danger">
                        {onBoardingRequestMessage.message}
                    </div>
                )}
            {!loadingOnBoardingRequests &&
                onBoardingRequests !== "error" &&
                onBoardingRequests.length > 0 &&
                user.signInUserSession.accessToken.payload["cognito:groups"].includes(
                    "ontrac-admin-group"
                ) && (
                    <>
                        <h2>Onboarding requests</h2>
                        <ReactTable
                            tableData={onBoardingRequests}
                            canOpen={true}
                            handleOpen={handleOpenOnboardingDetails}
                            showColumns={[
                                "email",
                                "firstName",
                                "surname",
                                "organisation",
                                "telephoneNumber",
                            ]}
                        ></ReactTable>
                    </>
                )}
            {!loadingOnBoardingRequests && onBoardingRequests.length == 0 && (
                <h2>There are no onboarding requests</h2>
            )}
            {!loadingOnBoardingRequests && onBoardingRequests == "error" && (
                <h2>Unable to onboarding requests</h2>
            )}
        </div>
    )
}