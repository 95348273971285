import axios from "../_helpers/axiosinstance";

const getUsers = async (csv, pageSize, pageNumber, sortOptions) => {
  let sortBy = undefined;
  let sortOrder = undefined;

  if (sortOptions?.accessor) {
      sortBy = sortOptions.accessor;
  }

  if (sortOptions?.direction) {
      sortOrder = sortOptions.direction;
  }

  let url = `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/all`;
  let config = {
      requiresIdentityId: true,
  }

  if (csv) {
      url = `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/all/export`;
      config = {
          requiresIdentityId: true,
          responseType: "blob"
      }
  }

  const response = await axios.post(
    url,
    {
      page: pageNumber === 0 ? 0 : pageNumber || 0,
      pageSize: pageSize === 0 ? 0 : pageSize || 10,
      sortBy: sortBy || undefined,
      sortOrder: sortOrder || undefined,
    },
    config
  );
  return response;
};

const getUserFromCognitoUsername = async (username) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/cognito-user/${username}`,
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const getUserElrsFromCognitoUsername = async (username) => {
    const response = await axios.get(
        `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/elrs-cognito-user/${username}`,
        {
            requiresIdentityId: true,
        }
    );
    return response;
}

const getUserFromUserId = async (userId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/user/${userId}`,
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const userSearch = async (params, csv, page, pageSize, sortOptions) => {
  let sortBy = undefined;
  let sortOrder = undefined;
  let url = `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/search`;

  if (sortOptions?.accessor) {
      sortBy = sortOptions.accessor;
  }

  if (sortOptions?.direction) {
      sortOrder = sortOptions.direction;

  }

  let  config = {
      requiresIdentityId: true,
  }

  if (csv) {
      url = `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/search/export`;
      config = {
          requiresIdentityId: true,
          responseType: "blob"
      }
  }

  const response = await axios.post(
    url,
    {
      first_name: params?.firstName || undefined,
      surname: params?.surname || undefined,
      email: params?.email || undefined,
      status: params?.userStatus?.value || undefined,
      enabled: params?.enabled?.value || undefined,
      organisation_name: params?.organisation?.organisation_name || undefined,
      page: page === 0 ? 0 : page || 0,
      pageSize: pageSize === 0 ? 0 : pageSize || 10,
      sortBy: sortBy || undefined,
      sortOrder: sortOrder || undefined,
    },
    config
  );
  return response;
};

const getOnboardingRequests = async (params) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/onboarding/requests`,
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const onboardingReject = async (id, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/onboarding/reject-request/${id}`,
    {},
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const onboardingAccept = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/onboarding/accept`,
    {
      id: params.id || "",
      firstName: params.firstName || "",
      surname: params.surname || "",
      email: params.email || "",
      organisation: params.organisation || "",
      telephoneNumber: params.telephoneNumber || "",
      sentinelNumber: params.sentinelNumber || undefined,
    },
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const userEnable = async (username) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/enable/${username}`,
    {},
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const userDisable = async (username) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/disable/${username}`,
    {},
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const userDelete = async (username) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/delete/${username}`,
    {},
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const userVerifyEmail = async (username) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/onboarding/verify-email/${username}`,
    {},
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const createUser = async (params) => {
  let mdusValues = [];
  let routeValues = [];
  if (params.mdu) {
    for (var i = 0; i < params.mdu.length; i++) {
      mdusValues.push(params.mdu[i].subzonecode);
    }
  }
    if (params.route) {
        for (let i = 0; i < params.route.length; i++) {
            routeValues.push(params.route[i].zonedescription);
        }
    }
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/create`,
    {
      firstName: params.firstName || "",
      surname: params.surname || "",
      role: params.role.group_name || "standard-users",
      email: params.email || "",
      organisation: params.organisation.organisation_name || "",
      telephoneNumber: params.telephone || undefined,
      sentinelNumber: params.sentinelNumber || undefined,
      route: routeValues || "",
      mdus: mdusValues || "",
      address: {
        building_name: params.buildingName || undefined,
        address1: params.address1 || undefined,
        address2: params.address2 || undefined,
        address3: params.address3 || undefined,
        city: params.city || undefined,
        county: params.county || undefined,
        postcode: params.postcode || undefined,
      },
    },
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const updateUser = async (params) => {
    let mdusValues = [];
    let routeValues = [];
    if (params.userDetails.mdu) {
        for (var i = 0; i < params.userDetails.mdu.length; i++) {
            mdusValues.push(params.userDetails.mdu[i].subzonecode);
        }
    }
    if (params.userDetails.route) {
        for (let i = 0; i < params.userDetails.route.length; i++) {
            var value = params.userDetails.route[i];
            if (typeof value === 'object') {
                routeValues.push(params.userDetails.route[i].zonedescription);
            }
        }
    }
    const response = await axios.post(
        `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/update-user`,
        {
            username: params.username,
            enabled: params.enabled,
            userDetails: {
                id: params.userDetails.id,
                firstName: params.userDetails.firstName,
                surname: params.userDetails.surname,
                organisation: params.userDetails.organisation ? params.userDetails.organisation.organisation_name : undefined,
                telephoneNumber: params.userDetails.telephoneNumber,
                sentinelNumber: params.userDetails.sentinelNumber,
                route: routeValues.length > 0 ?routeValues : params.userDetails.route,
                mdus: mdusValues,
                address: params.userDetails.address,
                cognitoGroup: params.userDetails.cognito_group ? params.userDetails?.cognito_group.group_name : undefined,
                delegateUserId: params.userDetails.delegate ? params.userDetails?.delegate.userid : undefined
            }
        },
        {
            requiresIdentityId: true,
        }
    );
    return response;
}

const userInactivityReport = async (params, csv) => {
  let routes = [];
  let mdusValues = [];
  let url = `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/reports/user-activity`;
  let config = {
      requiresIdentityId: true,
  }

  if (params.route) {
      params.route.forEach(route => {
          routes.push(route.zonedescription);
      });
  }

  if (params.mdu) {
    for (var i = 0; i < params.mdu.length; i++) {
      mdusValues.push(params.mdu[i].subzonecode);
    }
  }

  if (csv) {
      url = `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/reports/user-activity/export`
      config = {
          requiresIdentityId: true,
          responseType: "blob"
      }
  }


  const response = await axios.post(
    url,
    {
      routes: routes,
      mdus: mdusValues,
      date_range: {
        start_date: params.startDate,
        end_date: params.endDate,
      },
    },
    config
  );
  return response;
};

const userPermissionReport = async (params, csv) => {
  let routes = [];
  let mdusValues = [];
  let url = `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/reports/user-permissions`;
  let config = {
      requiresIdentityId: true,
  }

  if (params.route2) {
    params.route2.forEach(route => {
        routes.push(route.zonedescription);
    });
  }

  if (params.mdu2) {
    for (var i = 0; i < params.mdu2.length; i++) {
      mdusValues.push(params.mdu2[i].subzonecode);
    }
  }

  if (csv) {
      url = `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/reports/user-permissions/export`;
      config = {
          requiresIdentityId: true,
          responseType: "blob"
      }
  }

  const response = await axios.post(
    url,
    {
      routes: routes,
      mdus: mdusValues,
      role: params.role,
    },
    config
  );
  return response;
};

const getUserGroups = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/user-groups/all`,
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const getUserSubmissionLookupWhsea = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/submission-lookup/whsea`,
    {
      route: params.route.zonedescription,
      mdus: params.mdu,
    },
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const getUserSubmissionLookupSSM = async (params) => {
    const response = await axios.post(
        `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/submission-lookup/ssm`,
        {
            route: params.route.zonedescription,
            mdus: params.mdu,
        },
        {
            requiresIdentityId: true,
        }
    );
    return response;
}

const requestPermission = async (requestedPermission) => {
    const response = await axios.post(
        `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/permission-request`,
        {
            requested_permission: requestedPermission.group_name
        }
    );
    return response;
}

const getPermissionRequests = async () => {
    return await axios.get(`${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/permission-requests`,
        {
            requiresIdentityId: true,
        });
}

const userPermissionAccept = async (details) => {
    const response = axios.post(
        `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/permission-request/accept`,
        {
            _id: details._id,
            requested_permission: details.requested_permission,
            user_id: details.user_id,
            email: details.email,
        },
        {
            requiresIdentityId: true,
        }
    );
    return response;
};

const userPermissionReject = async (id) => {
    const response = axios.post(
        `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/permission-request/reject/${id}`,
        {},
        {
            requiresIdentityId: true,
        }
    );
    return response;
}

const downloadFullExport = async () => {
    const response = axios.get(
        `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/full-export`,
        {
            requiresIdentityId: true,
        });
    return response;
}

const getAllWhseaUsers = async (route, mdu, role) => {
    const response = axios.post(
        `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/all/whsea`,
        {
            routes: route,
            mdus: mdu,
            role: role
        },
        {
            requiresIdentityId: true,
        }
    );
    return response;
}

const getAllSSMUsers = async (route, mdu, role) => {
    const response = axios.post(
        `${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/all/ssm`,
        {
            routes: route,
            mdus: mdu,
            role: role
        },
        {
            requiresIdentityId: true,
        }
    );
    return response;
}

const setTemporaryPassword = async (username) => {
    const response = axios.post(`${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/temporary-pass/${username}`,
        {},
        {
            requiresIdentityId: true,
        }
        );
    return response;
}

const resendInvitation = async (username) => {
    const response = axios.post(`${process.env.REACT_APP_nhd_admin_service_ingress}/nhd/api/admin/users/resend-confirmation/${username}`,
        {},
        {
            requiresIdentityId: true,
        }
        );
}

export const UserService = {
        getOnboardingRequests,
        getUsers,
        getUserFromCognitoUsername,
        getUserElrsFromCognitoUsername,
        getUserFromUserId,
        userSearch,
        onboardingReject,
        onboardingAccept,
        userEnable,
        userDelete,
        userDisable,
        userVerifyEmail,
        createUser,
        updateUser,
        userInactivityReport,
        getUserGroups,
        userPermissionReport,
        getUserSubmissionLookupWhsea,
        getUserSubmissionLookupSSM,
        requestPermission,
        getPermissionRequests,
        userPermissionAccept,
        userPermissionReject,
        downloadFullExport,
        getAllWhseaUsers,
        getAllSSMUsers,
        setTemporaryPassword,
        resendInvitation,
    }
;
