import React, {useEffect, useState} from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import ResTable from "../components/ResTable.js";
import GeneralService from "../services/general.service.js";
import ReactTable from "../components/ReactTable/ReactTable.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch,} from "@fortawesome/free-solid-svg-icons";

function SafetyHazardCodesPage(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    setIsLoaded(false);
    GeneralService.getHazardCodesSafetyCodesPaged({
      page: page,
      pageSize: pageSize,
    })
      .then((response) => {
        console.log(response);
        setIsLoaded(true);
        setItems(response.data);
      })
      .catch((error) => {
        setIsLoaded(true);
        setError(error);
      });
  }, [page, pageSize]);

  let safetyHazards = "";
  if (error) {
    safetyHazards = <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    safetyHazards = <div>Loading...</div>;
  } else {
    let headers = ["Description", "Item Code"];
    safetyHazards = <ResTable headers={headers} tableData={items} />;
  }

  const handleExport = () => {};

  return (
    <div className="page" id="safetyHazardCodesPage">
      <Header title="Safety Hazard Codes" />
      <BackButton navigateUrl="/generalinfo" />

      {/* <div className="safety-hazards-codes">{safetyHazards}</div> */}

      {!isLoaded && (
        <div className="loadingCircle">
          <FontAwesomeIcon icon={faCircleNotch} spin={true} />
        </div>
      )}
      {!error && items && isLoaded && (
        <div className="safety-hazards-codes">
          <ReactTable
            tableData={items.results}
            currentPage={items?.pageNumber}
            totalPages={items?.totalPages}
            pageSize={items?.pageSize}
            totalElements={items?.totalElements}
            setPage={setPage}
            setPageSize={setPageSize}
            hideColumns={[
              "id",
            ]}
          ></ReactTable>
        </div>
      )}
    </div>
  );
}

export default SafetyHazardCodesPage;
