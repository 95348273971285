import React, {useEffect, useRef, useState} from "react";
import Button from "./Button.js";
import Input from "./Input.js";

import "react-dates/initialize";
import GeneralService from "../services/general.service";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import Checkbox from "./Checkbox";

function CreateZoneForm(props) {
    const [initialVals, setInitialVals] =  useState({
            zonedescription: "",
            zonecode: "",
            enabled: false
        });
    const {state} = useLocation();
    const [values, setValues] = useState(initialVals);

    let navigate = useNavigate();

    const zoneCodeInput = useRef(null);
    const zoneCodeDescriptionInput = useRef(null);

    useEffect(() => {
        if (state?.data) {
            setInitialVals(state.data);
            setValues(state.data);
        }
    }, [state]);

    function handleInputChange(e, name) {
        setValues((values) => ({...values, [e.target.name]: e.target.value}));
    }

    const validForm = () => {
        let validZoneCode = zoneCodeInput.current.validateField();
        let validDescription = zoneCodeDescriptionInput.current.validateField();

        return !!(validZoneCode && validDescription);
    }

    function handleSubmit() {
        if (validForm()) {
            if (values.id) {
                GeneralService.updateRoute(values)
                    .then((response) => {
                        toast.success(`Updated Zone with ${values.zonecode}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        navigate("/regionmnagement");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                GeneralService.createRoute(values)
                    .then((response) => {
                        toast.success(`Created Hazard Code with ${values.zonecode}`, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        navigate("/regionmnagement");
                    }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    function handleReset() {
        setValues({...initialVals});
    }

    function handleOnchange(e) {
        const value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setValues((values) => ({
            ...values,
            [e.target.name]: value,
        }));
    }

    return (
        <div className="create-form">
            <div className="create-form__section">
                <Input
                    ref={zoneCodeInput}
                    name="zonecode"
                    label="Zone Code"
                    value={values.zonecode}
                    onChange={handleInputChange}
                    disabled={state?.edit}
                    required={values?.zonecode === undefined}
                />
                <Input
                    ref={zoneCodeDescriptionInput}
                    name="zonedescription"
                    label="Zone Description"
                    value={values.zonedescription}
                    onChange={handleInputChange}
                    required={true}
                />
                <Checkbox
                    key="enabled"
                    name="enabled"
                    value="enabled"
                    label="Enabled"
                    onChange={(e) => handleOnchange(e)}
                    checked={values.enabled || false}
                />
            </div>

            <div className="button-group create-form__submit">
                <Button action="secondary" label="Reset" onClick={handleReset}/>
                <Button action="primary" label="Submit" onClick={handleSubmit}/>
            </div>
        </div>
    );
}

export default CreateZoneForm;
