import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Header from "../../components/Header";
import BackButton from "../../components/BackButton";
import generateFields from "../../_helpers/generateDetailFields";
import Button from "../../components/Button";
import BouncingDotsLoader from "../../components/Loader/BouncingDotsLoader";
import Input from "../../components/Input";
import {OrganisationService} from "../../services/organisation.service";
import {toast} from "react-toastify";

const OrganisationDetails = (props) => {

    const { state } = useLocation();
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        if (state.type === 'add') {
            setDetails(null);
        }
        else if (state?.data) {
            setDetails(state.data);
        }
    }, [state]);

    const handleOnchange = (e, address) => {
        if (!address) {
            setDetails((values) => ({
                ...values,
                [e.target.name]: e.target.value,
            }));
        } else {
            setDetails((values) => ({
                ...values,
//                userDetails: {...values.userDetails, [e.target.name]: value}
                address: {...values.address,[e.target.name]: e.target.value},
            }));
        }
    };

    const handleUpdate = () => {
        if (state.type === 'add') {
            OrganisationService.createOrganisation(details).then((response) => {
                toast.success(`Successfuly created Organisation ${details.organisation_name}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate("/orgmanagement")
            }).catch((error) => {
                toast.error(`${error.response.data.apierror.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        } else {
            OrganisationService.updateOrganisation(details).then((response) => {
                toast.success(`Successfuly updated Organisation ${details.organisation_name}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                navigate("/orgmanagement")
            }).catch((error) => {
                toast.error(`Failed to Update Organisation`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
    }

    return (
        <div className="page" id={`${state?.title?.toLowerCase || ""} `}>
            <Header title={`Organisation Details`} />
            <BackButton />
            <div className="filter-form">
                <div className="filter-form__controller">
                    <h2 className="filter-form__title inline-block">Details</h2>
                </div>
                <div>
                    <div className="filter-form__section">
                        <Input
                            name={`organisation_name`}
                            label={`Organisation Name`}
                            value={details?.organisation_name}
                            onChange={(e) => handleOnchange(e, false)}
                        />
                        <div className="input_wap input_route_du_elr">
                            <Input
                                name={`licenses_bought`}
                                label={`Licenses Bought`}
                                type="number"
                                value={details?.licenses_bought}
                                onChange={(e) => handleOnchange(e, false)}
                            />
                            <Input
                                name={`licenses_used`}
                                label={`Licenses Used`}
                                type="number"
                                value={details?.licenses_used || 0}
                                readOnly={true}
                            />
                        </div>

                    </div>
                    <div className="create-form__section">
                        <h3>Address Details</h3>
                        <Input
                            name="building_name"
                            label="Building Name"
                            value={details?.address?.building_name}
                            onChange={(e) => handleOnchange(e, true)}
                        />
                        <Input
                            name="address1"
                            label="Address"
                            value={details?.address?.address1}
                            onChange={(e) => handleOnchange(e, true)}
                        />
                        <Input
                            name="address2"
                            label="Address"
                            value={details?.address?.address2}
                            onChange={(e) => handleOnchange(e, true)}
                        />
                        <Input
                            name="address3"
                            label="Address"
                            value={details?.address?.address3}
                            onChange={(e) => handleOnchange(e, true)}
                        />
                        <Input
                            name="city"
                            label="City"
                            value={details?.address?.city}
                            onChange={(e) => handleOnchange(e, true)}
                        />
                        <Input
                            name="county"
                            label="County"
                            value={details?.address?.county}
                            onChange={(e) => handleOnchange(e, true)}
                        />
                        <Input
                            name="postcode"
                            label="Postcode"
                            value={details?.address?.postcode}
                            onChange={(e) => handleOnchange(e, true)}
                        />
                    </div>
                    <div className="button-group filter-form__submit">
                        {!loading && (
                                <>
                                {state?.type === 'add' ?
                                <Button
                                    action="primary"
                                    label="Create"
                                    onClick={handleUpdate}
                                /> :
                                <Button
                                    action="primary"
                                    label="Update"
                                    onClick={handleUpdate}
                                />
                                }
                                </>
                            )}
                        </div>
                </div>
            </div>
        </div>
    );

}

export default OrganisationDetails;