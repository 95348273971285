import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react";

export function RequireAuth({ children, allowedRoles }) {
  const location = useLocation();
  const { user, route } = useAuthenticator((context) => [context.route]);

  if (
    route !== "authenticated" ||
    !allowedRoles.some((ele) =>
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(ele)
    )
  ) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  } else {
    <Outlet />;
  }
  return children;
}
