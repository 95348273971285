import axios from "../_helpers/axiosinstance";
import {config} from "@fortawesome/fontawesome-svg-core";

const create = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/create`,
    {
      elr: params?.elr.elr || undefined,
      elr_name: params?.elr.description || undefined,
      start_mileage:
        params?.start_mileage === 0 ? 0 : params?.start_mileage || undefined,
      end_mileage:
        params?.end_mileage === 0 ? 0 : params?.end_mileage || undefined,
      zonecode: params?.elr.zonecode || undefined,
      subzone: params?.mdu.subzone || undefined,
      local_name: params?.local_name || undefined,
      location: params?.location || undefined,
      track_id: params?.track_id.trackid === 0 ? 0 : params?.track_id.trackid || undefined,
      hazard_code: params?.hazard_code.hazardCode || undefined,
      status_code: params?.status_code.status_code || undefined,
      easting: params?.easting || undefined,
      northing: params?.northing || undefined,
      postcode: params?.postcode || undefined,
      purpose_usage: params?.purpose_usage || undefined,
      os_gridref: params?.os_gridref || undefined,
      latitude: params?.latitude || undefined,
      longitude: params?.longitude || undefined,
      maintainer: params?.maintainer || undefined,
      asset_request: params?.asset_request || undefined,
      free_text: params?.free_text || undefined,
      reviewer: params?.reviewer || undefined,
      category: params?.category || undefined,
    },
    {
      requiresIdentityId: true,
    }
  );

  return response;
};

const acceptHazard = async (params, proposeEdit) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/accept`,
    params,
    { requiresIdentityId: true, params: { proposeEdit: proposeEdit || false } }
  );

  return response;
};

const editHazard = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/edit`,
    {
      holding_hazard: {
          _id: params?._id || undefined,
          hazard_id: params?.hazard_id || undefined,
          elr: params?.elr.elr || undefined,
          elr_name: params?.elr.description || undefined,
          start_mileage:
              params?.start_mileage === 0 ? 0 : params?.start_mileage || undefined,
          end_mileage:
              params?.end_mileage === 0 ? 0 : params?.end_mileage || undefined,
          zonecode: params?.elr.zonecode || undefined,
          subzone: params?.mdu.subzone || undefined,
          local_name: params?.local_name || undefined,
          location: params?.location || undefined,
          track_id: params?.track_id.trackid === 0 ? 0 : params?.track_id.trackid || undefined,
          hazard_code: params?.hazard_code.hazardCode || undefined,
          hazard_type: params?.hazard_type || undefined,
          status_code: params?.status_code?.status_code || undefined,
          easting: params?.easting || undefined,
          northing: params?.northing || undefined,
          postcode: params?.postcode || undefined,
          purpose_usage: params?.purpose_usage || undefined,
          os_gridref: params?.os_gridref || undefined,
          latitude: params?.latitude || undefined,
          longitude: params?.longitude || undefined,
          maintainer: params?.maintainer || undefined,
          hazard_status: params?.hazard_status || undefined,
          asset_request: params?.asset_request || undefined,
          free_text: params?.free_text,
          reviewer: params?.reviewer || undefined,
          category: params?.category || undefined,
      },
      delete_reason: params?.propose_delete_reason || undefined
    },
    { requiresIdentityId: true }
  );

  return response;
};

const filesUpload = async (params) => {
  let data = new FormData();
  data.append("file", params.file || undefined);

  //append fileInformation as json to formData
  const json = JSON.stringify(params.fileInformation);
  const blob = new Blob([json], {
    type: "application/json",
  });
  data.append("file_information", blob);

  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/files/upload`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      requiresIdentityId: true,
    }
  );

  return response;
};

const getHoldingHazardById = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/hazard/holding/${id}`
  );
};

const getHazardById = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/hazard/${id}`,
    {}
  );

  return response;
};

const getInactiveHazard = async (id) => {
    return await axios.get(`${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/hazard/inactive/${id}`);
}

const getAllSubmissionsRegister = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/submission-register/all`,
    {
      page: params?.page || 0,
      pageSize: params?.pageSize || 10,
    }
  );

  return response;
};

const getMySubmissionsRegister = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/submission-register/submissions`,
    {
      page: params?.page || 0,
      pageSize: params?.pageSize || 10,
      sortBy: params?.sortBy || undefined,
      sortOrder: params?.sortOrder || undefined
    }
  );

  return response;
};

const getNewSubmissionsRegister = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/submission-register/new-submission`,
    {
      page: params?.page || 0,
      pageSize: params?.pageSize || 10,
      sortBy: params?.sortBy || undefined,
      sortOrder: params?.sortOrder || undefined
    }
  );

  return response;
};

const getAmendSubmissionsRegister = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/submission-register/amend`,
    {
      page: params?.page || 0,
      pageSize: params?.pageSize || 10,
      sortBy: params?.sortBy || undefined,
      sortOrder: params?.sortOrder || undefined
    }
  );

  return response;
};

const getDeleteSubmissionsRegister = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/submission-register/proposed-deletion`,
    {
      page: params?.page || 0,
      pageSize: params?.pageSize || 10,
      sortBy: params?.sortBy || undefined,
      sortOrder: params?.sortOrder || undefined
    }
  );

  return response;
};

const getFilesUrlById = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/files/url/${id}`,
    { requiresIdentityId: true }
  );

  return response;
};

const deleteFileById = async (id) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/files/delete/${id}`,
    { requiresIdentityId: true }
  );

  return response;
};

const proposeEdit = async (params, rejected) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/propose-edit`,
    {
      _id: params?._id || undefined,
      hazard_id: params?.hazard_id || undefined,
      elr: params?.elr?.elr || undefined,
      elr_name: params?.elr?.description || undefined,
      start_mileage:
        params?.start_mileage === 0 ? 0 : params?.start_mileage || undefined,
      end_mileage:
        params?.end_mileage === 0 ? 0 : params?.end_mileage || undefined,
      zonecode: params?.elr?.zonecode || undefined,
      subzone: params?.mdu?.subzone || undefined,
      local_name: params?.local_name || undefined,
      location: params?.location || undefined,
      track_id: params?.track_id.trackid === 0 ? 0 : params?.track_id.trackid || undefined,
      hazard_code: params?.hazard_code?.hazardCode || undefined,
      hazard_type: params?.hazard_type || undefined,
      status_code: params?.status_code?.status_code || undefined,
      easting: params?.easting || undefined,
      northing: params?.northing || undefined,
      postcode: params?.postcode || undefined,
      purpose_usage: params?.purpose_usage || undefined,
      os_gridref: params?.os_gridref || undefined,
      latitude: params?.latitude || undefined,
      longitude: params?.longitude || undefined,
      maintainer: params?.maintainer || undefined,
      hazard_status: params?.hazard_status || undefined,
      asset_request: params?.asset_request || undefined,
      free_text: params?.free_text,
      reviewer: params?.reviewer || undefined,
      category: params?.category || undefined,
    },
    { requiresIdentityId: true, params: { rejected: rejected || false } }
  );

  return response;
};

const proposeDelete = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/propose-delete`,
    {
      hazard_id: params?.hazard_id || undefined,
      reviewer: params?.reviewer || undefined,
      reason: params?.propose_delete_reason || undefined,
    },
    { requiresIdentityId: true }
  );

  return response;
};

const proposeReinstateHazard = async (params) => {
    const response = axios.post(`${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/propose-reinstate`,
        {
            hazard_id: params?.hazard_id || undefined,
            reviewer: params?.reviewer || undefined,
            reason: params?.reinstate_reason || undefined,
        },
        { requiresIdentityId: true }
    );
    return response;
}

const deleteHazardById = async (id, ssmApproved) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/delete-hazard/${id}?ssmApproved=${ssmApproved}`,

    { requiresIdentityId: true }
  );

  return response;
};

const rejectHazard = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/reject-hazard`,
    params,
    { requiresIdentityId: true }
  );

  return response;
};

const rejectProposedDeleteHazard = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/reject-propose-delete`,
    params,
    {
      requiresIdentityId: true,
    }
  );
};

const getHazardHistoryById = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/hazard-history/${id}`,
    { requiresIdentityId: true }
  );

  return response;
};

const getHoldingHazardHistoryById = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/hazard-history/holding/${id}`,
    { requiresIdentityId: true }
  );

  return response;
};

const sendToSSMReview = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/ssm-review`,
    params,
    {
      requiresIdentityId: true,
    }
  );
  return response;
};

const search = async (params, page, pageSize, sortOptions) => {
  let sortBy = undefined;
  let sortOrder = undefined;

  if (sortOptions?.accessor) {
      sortBy = sortOptions.accessor;
  }

  if (sortOptions?.direction) {
      sortOrder = sortOptions.direction;
  }

  const response = await axios.post(
    `${process.env.REACT_APP_nhd_hazard_management_service_ingress}/nhd/api/hazard-management/submission-register/search`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      sortBy: sortBy || undefined,
      sortOrder: sortOrder || undefined,
      zonecode: params?.route?.zonecode || undefined,
      elr: params?.elr?.elr || undefined,
      start_mileage: params?.startMiles || undefined,
      end_mileage: params?.endMiles || undefined,
      hazard_code: params?.hazardCode?.hazardCode || undefined,
      subzone: params?.mdu?.subzonecode || undefined,
      submission_status:
        params?.submissionStatus?.submission_state || undefined,
      category: params?.hazardTypeCheck || undefined,
    }
  );
  return response;
};

const HazardManagementService = {
  create,
  filesUpload,
  getAllSubmissionsRegister,
  getMySubmissionsRegister,
  getNewSubmissionsRegister,
  getAmendSubmissionsRegister,
  getDeleteSubmissionsRegister,
  getHoldingHazardById,
  getHazardById,
  getFilesUrlById,
  deleteFileById,
  proposeEdit,
  proposeDelete,
  deleteHazardById,
  acceptHazard,
  rejectHazard,
  editHazard,
  getHazardHistoryById,
  getHoldingHazardHistoryById,
  rejectProposedDeleteHazard,
  sendToSSMReview,
  search,
  getInactiveHazard,
  proposeReinstateHazard
};

export default HazardManagementService;
