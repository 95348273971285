import React, {useEffect, useRef, useState} from "react";
import Button from "./Button.js";
import Input from "./Input.js";
import ReactSelect from "./Select/ReactSelect";
import GeneralService from "../services/general.service";
import {UserService as userService} from "../services/user.service";
import {useLocation, useNavigate} from "react-router-dom";
import {OrganisationService} from "../services/organisation.service";

function CreateUserForm(props) {
  const initialVals = props.initialVals
    ? props.initialVals
    : {
        firstName: "",
        surname: "",
        organisation: "",
        role: "",
        route: [],
        mdu: [],
        sentinelNumber: "",
        email: "",
        confirmEmail: "",
        telephone: "",
        buildingName: "",
        address1: "",
        address2: "",
        address3: "",
        city: "",
        county: "",
        postcode: "",
      };

  const [values, setValues] = useState(initialVals);
  const [routes, setRoutes] = useState();
  const [filteredMdu, setFilteredMdu] = useState();
  const [roles, setRoles] = useState();
  const [userCreateMessage, setUserCreateMessage] = useState();
  const [organisations, setOrganisations] = useState();
  const [error, setError] = useState(null);
  const { state } = useLocation();
  let navigate = useNavigate();

  const firstNameInput = useRef(null);
  const surnameInput = useRef(null);
  const organizationSelectInput = useRef(null);
  const routeSelectInput = useRef(null);
  const roleSelectInput = useRef(null);
  const duSelectInput = useRef(null);
  const emailInput = useRef(null);
  const confirmEmailInput = useRef(null);
  const [selectRole, setSelectRole] = useState(false);

  const isOntracAdmin = props.data.user.signInUserSession.accessToken.payload[
    "cognito:groups"
  ].includes("ontrac-admin-group")
    ? true
    : false;

  //fetch MDU based on Route
  useEffect(() => {
    if (routes === undefined) {
      GeneralService.getRoutes(true)
          .then((response) => {
              setRoutes(response.data);
          })
          .catch((error) => {
              console.log(error);
          });
    }
    setFilteredMdu(["loading..."]);
    let zoneCode = [];
    if (values.route.length > 0) {
      for (const item of values.route) {
        zoneCode.push(item.zonecode);
      }
    }
    if (zoneCode) {
      GeneralService.getZonecodeMDUSubzonecode({
        zonecode: zoneCode,
      })
        .then((response) => {
          setFilteredMdu(response.data);
        })
        .catch((error) => {
          setFilteredMdu();
        });
    } else {
      setFilteredMdu();
    }
  }, [values?.route]);

  //fetch roles
  useEffect(() => {
      OrganisationService.getOrganisations(0, 500).then((response) => {
          setOrganisations(response.data.results)
      }).catch((error) =>{
          console.log(error);
      });
    userService
      .getUserGroups()
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        setRoles(error);
      });
  }, [values?.role, values?.organisation]);

  function handleInputChange(e) {
    setValues((values) => ({ ...values, [e.target.name]: e.target.value }));
  }

  const handleInputChangeG = (e, name) => {
      if (name === "mduAS") {
          name = "mdu"
      }
      if (name === "organisation") {
          if (e.organisation_name.startsWith("OnTrac")) {
              setSelectRole(true);
          } else {
              setSelectRole(false);
          }
      }
    setValues((values) => ({
      ...values,
      [name]: e ? e : "",
    }));
  };

  const validform = () => {
    let validFirstName = firstNameInput.current.validateField();
    let validSurname = surnameInput.current.validateField();
    let validOrganization = organizationSelectInput.current.validateField();
    let validRole = true;
    let validRoute = routeSelectInput.current.validateField();
    let validDu = duSelectInput.current.validateField();
    let validEmail = emailInput.current.validateField();
    let validConfirmEmail = confirmEmailInput.current.validateField();

    if (values.role) {
        validRole = roleSelectInput.current.validateField();
    }

    if (
      validFirstName &&
      validSurname &&
      validOrganization &&
      validRoute &&
      validRole &&
      validDu &&
      validEmail &&
      validConfirmEmail
    ) {
      return true;
    } else {
      return false;
    }
  };

  function handleSubmit() {
    if (validform()) {
      userService
        .createUser(values)
        .then((response) => {
          navigate("/usermanagement", {
            replace: true,
            state: {
              onboardingRequest: {
                message: `Created user ${response.data.email}`,
                status: "success",
              },
            },
          });
        })
        .catch((error) => {
          console.log(error.message);
          setError(error.response.data.apierror.message);
        });
    } else {
      setError("Invalid form");
    }
  }

  useEffect(() => {
    if (state?.onboardingRequest) {
      setUserCreateMessage(state?.onboardingRequest);
      setTimeout(() => setUserCreateMessage(), 5000);
    }
  }, [state]);

  function handleReset() {
    setValues({ ...initialVals });
    setError(null);
  }

  return (
    <div className="create-form">
      {userCreateMessage && userCreateMessage.status === "success" && (
        <div className="alert alert-success">{userCreateMessage.message}</div>
      )}
      {userCreateMessage && userCreateMessage.status === "error" && (
        <div className="alert alert-danger">{userCreateMessage.message}</div>
      )}
      <div className="create-form__section">
        <h3>User Details</h3>
        <Input
          ref={firstNameInput}
          name="firstName"
          label="First name"
          value={values.firstName}
          onChange={handleInputChange}
          required={true}
          focus={"autofocus"}
        />
        <Input
          ref={surnameInput}
          name="surname"
          label="Surname"
          value={values.surname}
          onChange={handleInputChange}
          required={true}
        />
        <ReactSelect
          ref={organizationSelectInput}
          name="organisation"
          label="Organisation"
          placeholder="Select Organisation"
          valueLabel={["organisation_name"]}
          data={organisations}
          loading={organisations && organisations[0] === "loading..."}
          value={values?.organisation}
          onChange={handleInputChangeG}
          required={true}
        />
        {isOntracAdmin && selectRole && (
          <ReactSelect
            ref={roleSelectInput}
            name="role"
            label="Role"
            placeholder="Select Role"
            valueLabel={["description"]}
            data={roles}
            loading={roles && roles[0] === "loading..."}
            onChange={handleInputChangeG}
            value={values?.role}
            required={true}
          />
        )}
        <Input
          name="sentinelNumber"
          label="Sentinel Number"
          value={values.sentinelNumber}
          onChange={handleInputChange}
        />
        <ReactSelect
          ref={routeSelectInput}
          isMulti={true}
          name="route"
          label="Route"
          placeholder="Select Route(s)"
          valueLabel={["zonedescription"]}
          data={routes}
          onChange={handleInputChangeG}
          value={values.route}
          required={true}
        />
        <ReactSelect
          ref={duSelectInput}
          isMulti={true}
          name="mduAS"
          label="MDU"
          placeholder="Select MDU(s)"
          valueLabel={["subzonecode", "description"]}
          data={filteredMdu || props.mduList}
          loading={filteredMdu && filteredMdu[0] === "loading..."}
          onChange={handleInputChangeG}
          value={values?.mdu}
          required={true}
        />
      </div>

      <div className="create-form__section">
        <h3>Email</h3>
        <Input
          ref={emailInput}
          type="email"
          name="email"
          label="Email"
          value={values.email}
          onChange={handleInputChange}
          required={true}
        />
        <Input
          ref={confirmEmailInput}
          type="email"
          name="emailConfirm"
          label="Confirm Email"
          value={values.emailConfirm}
          onChange={handleInputChange}
          required={true}
        />
      </div>

      <div className="create-form__section">
        <h3>Phone</h3>
        <Input
          type="tel"
          name="telephone"
          label="Telephone Number"
          value={values.telephone}
          onChange={handleInputChange}
        />
      </div>

      <div className="create-form__section">
        <h3>Address</h3>
        <Input
          name="buildingName"
          label="Building Name"
          value={values.buildingName}
          onChange={handleInputChange}
        />
        <Input
          name="address1"
          label="Address"
          value={values.address1}
          onChange={handleInputChange}
        />
        <Input
          name="address2"
          label="Address"
          value={values.address2}
          onChange={handleInputChange}
        />
        <Input
          name="address3"
          label="Address"
          value={values.address3}
          onChange={handleInputChange}
        />
        <Input
          name="city"
          label="City"
          value={values.city}
          onChange={handleInputChange}
        />
        <Input
          name="county"
          label="County"
          value={values.county}
          onChange={handleInputChange}
        />
        <Input
          name="postcode"
          label="Postcode"
          value={values.postcode}
          onChange={handleInputChange}
        />
      </div>

      <div className="button-group create-form__submit">
        <Button action="secondary" label="Reset" onClick={handleReset} />
        <Button action="primary" label="Submit" onClick={handleSubmit} />
      </div>
      {error && (
        <div className="form-error">
          <span className="input__error">{error}</span>
        </div>
      )}
    </div>
  );
}

export default CreateUserForm;
