import React, {useEffect, useState} from "react";
import Header from "../components/Header.js";
import BackButton from "../components/BackButton.js";
import ReactTable from "../components/ReactTable/ReactTable.js";
import {useNavigate} from "react-router-dom";
import MyReportsService from "../services/myreports.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlassMinus} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlassMinus";

function MyReportsPage(props) {
    let navigate = useNavigate();
    const [savedReports, setSavedReports] = useState();

    useEffect(() => {
        MyReportsService.getUserSavedReports()
            .then((response) => {
                setSavedReports(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [setSavedReports]);

    const handleOpenCustomSaerch = (e, row) => {
        // navigate(`/customsearch/${row.row?.original.id}`, {
        //     state: { data: row.row?.original },
        // });
        navigate(`/customsearch/${row.row?.original._id}`, {
            state: {data: row.row?.original},
        });
    };

    return (
        <div className="page" id="myReportsPage">
            <Header title="My Reports"/>
            <BackButton navigateUrl="/"/>

            {savedReports?.results?.length ? (
                <ReactTable
                    tableData={savedReports?.results}
                    showColumns={savedReports?.headers}
                    handleOpen={handleOpenCustomSaerch}
                    canOpen={true}
                    customHeader={true}
                    customSearch={true}
                ></ReactTable>
            ) : <div className="loadingCircle">
                    You Have No Saved Reports
                    <span style={{marginLeft: "10px"}}> <FontAwesomeIcon icon={faMagnifyingGlassMinus}/> </span>
                </div>
            }

        </div>
    );
}

export default MyReportsPage;
