import axios from "../_helpers/axiosinstance";
import {config} from "@fortawesome/fontawesome-svg-core";

const getStations = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/stations/all`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
    },
    {
      requiresIdentityId: true,
      // headers: {
      //   header1: "header1",
      // },
    }
  );

  return response;
  // const data = handleResponse(response);
  //return data;
};

const stationsSearch = async (page, pageSize, params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/stations/search`,
    {
      page: page || 0,
      pageSize: pageSize || 10,
      stationName: params?.stationName || undefined,
    },
    {
      requiresIdentityId: true,
      // headers: {
      //   header1: "header1",
      // },
    }
  );

  return response;
  // const data = handleResponse(response);
  //return data;
};

const getHazardCodesAll = async (enabled) => {
  return await axios.get(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/hazard-codes/all?enabled=${enabled}`
  );
};

const getHazardCodesPaged = async (params, sortOptions) => {
    let sortBy = undefined;
    let sortOrder = undefined;

    if (sortOptions?.accessor) {
        sortBy = sortOptions.accessor;
    }

    if (sortOptions?.direction) {
        sortOrder = sortOptions.direction;
    }

  const response = await axios.post(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/hazard-codes/all`,
    {
      page: params?.page || 0,
      pageSize: params?.pageSize || 500,
      sortBy: sortBy || undefined,
      sortOrder: sortOrder || undefined
    },
    {
      requiresIdentityId: true,
    }
  );

  return response;
};

const createHazardCode = async (params) => {
  const response = await axios.post(
      `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/hazard-codes/create`,
      {
        description: params.description,
        hazard_code: params.hazardCode,
        enabled: params.enabled || false
      }
  );

  return response;
}

const updateHazardCode = async (params) => {
  const response = axios.post(`${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/hazard-codes/update`,
      {
        id: params.id,
        description: params.description,
        hazard_code: params.hazardCode,
        enabled: params.enabled || false
      });
  return response;
}

const getHazardCodesEnvironment = async () => {
  return await axios.get(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/hazard-codes/environment`
  );
};

const getHazardCodesEnvironmentPaged = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/hazard-codes/environment`,
    {
      page: params?.page || 0,
      pageSize: params?.pageSize || 20,
    },
    {
      requiresIdentityId: true,
    }
  );

  return response;
};

const getHazardCodesSafetyCodes = async () => {
  return axios.get(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/hazard-codes/safety-codes`
  );
};

const getHazardCodesSafetyCodesPaged = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/hazard-codes/safety-codes`,
    {
      page: params?.page || 0,
      pageSize: params?.pageSize || 20,
    },
    {
      requiresIdentityId: true,
    }
  );

  return response;
};

const getHazardCodesAccessPoints = async () => {
  return await axios.get(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/hazard-codes/access-points`
  );
};

const getHazardCodesOther = async () => {
    return axios.get(
        `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/hazard-codes/other`
    )
}

const getHazardCodesOtherPaged = async (params) => {
    return axios.post(
        `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/hazard-codes/other`,
        {
            page: params?.page || 0,
            pageSize: params?.pageSize || 20,
        },
        {
            requiresIdentityId: true,
        }
    )
}

const getRoutes = async (enabled) => {
    let url = `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/route/all?enabled=${enabled}`;
    if (enabled === undefined) {
        url = `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/route/all`
    }
  return await axios.get(url);
};

const getRoutesPaged = async (params, sortOptions) => {
    let sortBy = undefined;
    let sortOrder = undefined;

    if (sortOptions?.accessor) {
        sortBy = sortOptions.accessor;
    }

    if (sortOptions?.direction) {
        sortOrder = sortOptions.direction;
    }
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/route/all`,
    {
      page: params?.page || 0,
      pageSize: params?.pageSize || 20,
      sortBy: sortBy || undefined,
      sortOrder: sortOrder || undefined,
    },
    {
      requiresIdentityId: true,
    }
  );

  return response;
};

const createRoute = async (params) => {
    const response = await axios.post(
        `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/route/create`,
        {
            zonedescription: params.zonedescription,
            zonecode: params.zonecode,
            enabled: params.enabled || false
        }
    );

    return response;
};

const updateRoute = async (params) => {
    const response = axios.post(`${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/route/update`,
        {
            id: params.id,
            zonedescription: params.zonedescription,
            zonecode: params.zonecode,
            enabled: params.enabled || false
        });
    return response;
};

const getRoutesZonecode = async (zonecode, params) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/route/${zonecode}`,

    {
      requiresIdentityId: true,
    }
  );

  return response;
};

const getElrs = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/elrs/all`
  );
  return response;
};
const getElrsPaged = async (params) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/elrs/all`,
    {
      page: params?.page || 0,
      pageSize: params?.pageSize || 2000,
    }
  );
  // const data = handleResponse(response);
  // return data;
  return response;
};

const getElrsZonecode = async (zonecode) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/elrs/${zonecode}`
  );
  return response;
};

const getElrsZonecodePaged = async (params, zonecode) => {
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/elrs/${zonecode}`,
    {
      page: params?.page || 0,
      pageSize: params?.pageSize || 2000,
    }
  );
  // const data = handleResponse(response);
  // return data;
  return response;
};

const getMDU = async (params) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/subzonecode/all`,
      {
    }
  );
  return response;
};

const getSubzone = async (subzone) => {
    const response = await axios.get(
        `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/subzonecode/${subzone}`,
        {
        }
    );
    return response;
}

const getSubzoneLookup = async (params) => {
  let zonecodes = [];
  let elrs = [];

  if (params.zonecode) {
    zonecodes.push(params.zonecode);
  }
  if (params.elr) {
    elrs.push(params.elr);
  }

  const response = await axios.post(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/subzonecode/lookup`,
    {
      zonecode: zonecodes || undefined,
      elr: elrs || undefined,
    }
  );
  return response;
}

const getMDUSubzonecode = async (params) => {
    let zonecodes = [];
    let elrs = [];

    if (!Array.isArray(params.zonecode)) {
        zonecodes.push(params.zonecode);
    }
    if (!Array.isArray(params.elr)) {
        elrs.push(params.elr);
    }
  const response = await axios.post(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/subzonecode/zonecode-elr`,
    {
      zonecode: zonecodes.length > 0 ? zonecodes : params.zonecode || undefined,
      elr: elrs.length > 0 ? elrs : params.elr || undefined,
    }
  );
  return response;
};

const getZonecodeMDUSubzonecode = async (params) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/subzonecode/zonecode/${params.zonecode}`,
    {}
  );
  // const data = handleResponse(response);
  // return data;
  return response;
};

const getAllTrackId = async () => {
  return await axios.get(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/trackid/all`
  );
};

const getSubmissionStatus = async (params) => {
  const response = await axios.get(
    `${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/submission-status/all`,
    {}
  );
  // const data = handleResponse(response);
  // return data;
  return response;
};

const getOperationStatusAll = async () => {
    return axios.get(`${process.env.REACT_APP_nhd_general_data_ingress}/nhd/api/data/operational-status-codes`, {});
}
const GeneralService = {
  getRoutes,
  getRoutesPaged,
  getElrs,
  getElrsPaged,
  getElrsZonecode,
  getElrsZonecodePaged,
  getMDU,
  getSubzone,
  getSubzoneLookup,
  getMDUSubzonecode,
  getZonecodeMDUSubzonecode,
  getAllTrackId,
  getStations,
  stationsSearch,
  getHazardCodesAll,
  getHazardCodesPaged,
  getHazardCodesEnvironment,
  getHazardCodesEnvironmentPaged,
  getHazardCodesSafetyCodes,
  getHazardCodesSafetyCodesPaged,
  getHazardCodesOther,
  getHazardCodesOtherPaged,
  getRoutesZonecode,
  getSubmissionStatus,
  getHazardCodesAccessPoints,
  createHazardCode,
  updateHazardCode,
  createRoute,
  updateRoute,
  getOperationStatusAll
};

export default GeneralService;
